import { withRouter, Link } from 'react-router-dom';
import PubSub from '../../../../app/pub_sub.js';

const updateLoginAttribute = (attribute, value) => {
  PubSub.publish('UPDATE_LOGIN_ATTRIBUTE', { key: attribute, value: value });
};

const preventClick = (e, props) => {
  e.preventDefault();
  props.submitNewPasswordForm(e);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.submitNewPasswordForm(e);
  }
};

const NewPassword = (props) => {
  const { classes } = props;

  return(
    <div className="authentication-content">
      <section id="cmp_09f4305d-bea7-4c59-a856-b6eeae4482f9" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
				<span><h1>Forgot your password?</h1>
<p>No worries, we'll help you reset it and get back in.</p></span>
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_e50d8125-71b8-4874-943d-77750df4b009" className="component-forgot-password-form">
        <div className="container-fluid">
          <div className="row">
            <div className="card col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1">
              <div className="card-block">
                {!props.emailSent && <p>
                  Don't have an account? <Link to="/subscribe">Subscribe!</Link>
                </p>}
                {!props.emailSent && <div className="form-group">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email address"
                    className="form-control input-xlarge"
                    required=""
                    autoFocus={true}
                    value={props.email}
                    onChange={(event) => updateLoginAttribute('email', event.target.value)}
                    onKeyPress={(e) => {catchReturn(e, props)}}
                  />
                </div>}
                {props.emailSent && <p>
                  An email was successfully sent to {props.email}!
                </p>}
                {!props.emailSent && <div className="form-group text-center">
                  <button
                    id="submit"
                    name="submit"
                    className="btn btn-med btn-primary"
                    onClick={(e) => {preventClick(e, props)}}
                  >
                    Submit
                  </button>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewPassword;
