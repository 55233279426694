const getAddresses = (data, success, error, complete) => {
  return {
    type: 'API:ADDRESSES:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/addresses`,
    data,
    success,
    error,
    complete,
  };
};

const fetchAddress = (data, success, error, complete) => {
  return {
    type: 'API:ADDRESS:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/addresses/${data.id}`,
    success,
    error,
    complete,
  };
};

const createAddress = (data, success, error, complete) => {
  return {
    type: 'API:ADDRESSES:CREATE',
    API: true,
    method: 'POST',
    url: `/api/v1/addresses`,
    data,
    success,
    error,
    complete,
  }
};

const deleteAddress = (data, success, error, complete) => {
  return {
    type: 'API:ADDRESSES:DELETE',
    API: true,
    method: 'DELETE',
    url: `/api/v1/addresses/${data.id}`,
    data,
    success,
    error,
    complete,
  }
};

const updateAddress = (data, success, error, complete) => {
  return {
    type: 'API:ADDRESSES:UPDATE',
    API: true,
    method: 'PATCH',
    url: `/api/v1/addresses/${data.id}`,
    data,
    success,
    error,
    complete,
  }
};

export default {
  getAddresses,
  fetchAddress,
  createAddress,
  updateAddress,
  deleteAddress,
};
