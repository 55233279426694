import { Link } from 'react-router-dom';

const PaymentMethods = (props) => {
  if (!props.paymentMethods) {
    return (<div></div>)
  }

  return (
    <section id='payment-methods-content'>
      <section id="cmp_df96442d-5a85-416d-9e47-2c7cdf41dac6" className="container">
        <div className="account-header row content">
          <div className="col-lg-4">

          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <h2 className="text-center">
              Your Payment Methods
            </h2>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center" id="new-payment-method-button">
            <Link to="/customer/payment_methods/new" className="btn btn-primary">
              Add a Payment Method
            </Link>
          </div>
        </div>
        <div className="my-account">
          <div className="container-fluid">
            <div className="form-container">
              <div className="row content">
                {props.paymentMethods.map((paymentMethod) => {
                  return (
                    <PaymentMethodBox
                      paymentMethod={paymentMethod}
                      key={paymentMethod.id}
                      deletePaymentMethod={props.deletePaymentMethod}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

const PaymentMethodBox = (props) => {
  const attrs = [
    ['first_name', 'First Name'],
    ['last_name', 'Last Name'],
    ['address_1', 'Billing Address'],
    ['last_4', 'Last 4 Digits'],
    ['card_type', 'Card Type'],
    ['expiration_month', 'Expiration Month'],
    ['expiration_year', 'Expiration Year'],
    ['zip_code', 'Zip Code'],
  ];

  return(
    <div className="payment-method-box col-lg-4 col-md-6 col-xs-12" key={props.paymentMethod.id}>
      <div className="panel panel-cratejoy payment-method-panel">
        <table>
          <tbody>
            {attrs.map((arr) => {
              let interiorEl;
              if (arr[0] === 'card_type') {
                interiorEl = (
                  <td>
                    <div className={`creditcard-${props.paymentMethod[arr[0]]}`}></div>
                  </td>
                );
              } else {
                interiorEl = (
                  <td>
                    {props.paymentMethod[arr[0]]}
                  </td>
                );
              }

              return(
                <tr key={arr[0]}>
                  <td>
                    {arr[1]}:
                  </td>
                  {interiorEl}
                </tr>
              )
            })}
          </tbody>
        </table>
        <Link
          to={`/customer/payment_methods/${props.paymentMethod.id}`}
          className="btn btn-primary"
        >
          Edit
        </Link>
        <div className="row payment-method-footer">
          <div className="col-xs-12">
            {props.paymentMethod.earfleek_subscriptions.length === 0 &&
              <button
                className="btn delete-payment-method"
                onClick={(e) => { props.deletePaymentMethod(e, props.paymentMethod.id) }}
              >
                Delete this payment method
              </button>
            }
            {props.paymentMethod.earfleek_subscriptions.length > 0 && <div>
              Payment Method for:
              {props.paymentMethod.earfleek_subscriptions.map((subscription, idx) => {
                return(
                  <div key={idx}>
                    <Link
                      to={`/customer/subscriptions/${subscription.id}`}
                    >
                      Subscription #{subscription.id}
                    </Link>
                  </div>
                );
              })}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
