import { Link } from 'react-router-dom';
import { humanizedSubscriptionTypes, hardcodedTermPrices } from '../../../../../app/lib/constants.js';

import Modal from 'react-modal';

const Subscription = (props) => {
  let headerText = "Edit Your Subscription.";
  let subHeadingText = "";

  if (!props.subscription) {
    return(<div></div>);
  }

  if (props.subscription.state === 'cancelled') {
    headerText = "Your Subscription is Cancelled";
    subHeadingText = "Please reactivate your subscription to continue receiving shipments.";
  }

  if (props.subscription.state === 'past_due') {
    headerText = "Your Subscription's Payment Method is Failing!";
    subHeadingText = "Please update your payment method to continue receiving shipments. Renewal will be attempted again overnight.";
  }

  return(
    <section id='subscription-content'>
      <section id="cmp_df96442d-5a85-416d-9e47-2c7cdf41dac6">
        <ConfirmationModal {...props}/>
        <div className="account-header">
          <div className="container-fluid">
            <h2 className="text-center">
              {headerText}
            </h2>
            <p className="subheading-text">
              {subHeadingText}
            </p>
          </div>
        </div>
        <div className="my-account">
          <div className="container-fluid">
            <div className="form-container">
              <div className="row content">
                <div className="col-lg-8 col-lg-offset-2 col-xs-12">
                  <div className="col-xs-12" id="account-edit-view">
                    {(props.subscription.state === 'active') && <div className="row asdf">
                      <SkipLink {...props} />
                    </div>}
                    {props.subscription.state === 'cancelled' || props.subscription.state === 'past_due' &&
                      <div className="panel panel-cratejoy panel-product">
                        <div className="panel-heading">
                          <h3 className="panel-title">
                            Subscription Metadata:
                          </h3>
                          <div className="panel-body">
                            <div className="row">
                              <div className="metadata-row">
                                Style Type: <span className="bolded-value">{props.subscription.subscription_product.primary_style_type}</span>
                              </div>
                              <div className="metadata-row">
                                Cadence: <span className="bolded-value">{props.subscription.humanized_term}</span>
                              </div>
                              <div className="metadata-row">
                                Price per Renewal: <span className="bolded-value">
                                  ${(props.subscription.subscription_product.price_cents / 100).toFixed(2)}
                                </span>
                              </div>
                              <div className="metadata-row">
                                Price per Shipment: <span className="bolded-value">
                                  ${(props.subscription.subscription_product.price_cents / 100 / props.subscription.subscription_product.paid_months).toFixed(2)}
                                </span>
                              </div>
                              <div className="metadata-row">
                                Shipping Address: <span className="bolded-value">
                                  <OneLineAddress address={props.subscription.address}/>
                                </span>
                              </div>
                              {false && <div className="metadata-row">
                                Payment Method: <span className="bolded-value">
                                  <OneLinePaymentMethod paymentMethod={props.subscription.payment_method}/>
                                </span>
                              </div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {props.subscription.state === 'cancelled' && <button
                      className="btn btn-primary reactivate-button"
                      onClick={() => { props.reactivateSubscription() }}
                    >
                      REACTIVATE SUBSCRIPTION NOW
                    </button>}
                    {(props.subscription.state === 'active') && <div className="panel panel-cratejoy panel-product">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          Choose a New Style Type
                        </h3>
                      </div>
                      <div className="panel-body">
                        <div className="row">
                          {humanizedSubscriptionTypes().map((arr, idx) => {
                            let className = "col-md-4 col-xs-6";
                            if (arr[0] === props.newSubscriptionProduct.primary_style_type) {
                              className += ' selected'
                            }
                            return(
                              <div
                                className={className}
                                key={idx}
                                onClick={(event) => props.updateSubscriptionAttribute({ key: 'primary_style_type', value: arr[0] })}
                              >
                                <label className="btn_class cj-btn">
                                  <div>{arr[1]}</div>
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                    {(props.subscription.state === 'active') && <div className="panel panel-cratejoy panel-product">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          Choose a New Subscription Tier
                        </h3>
                      </div>
                      <div className="panel-body">
                        <div className="row">
                          {[['regular', 'Regular'], ['premiere', 'Premiere']].map((arr, idx) => {
                            let className = "col-md-6 col-xs-6";
                            if (arr[0] === props.newSubscriptionProduct.tier) {
                              className += ' selected'
                            }
                            return(
                              <div
                                className={className}
                                key={idx}
                                onClick={(event) => props.updateSubscriptionAttribute({ key: 'tier', value: arr[0] })}
                              >
                                <label className="btn_class cj-btn term-button">
                                  <div>
                                    <h4>{arr[1]}</h4>
                                  </div>
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                    {(props.subscription.state === 'active' && props.subscription.can_become_quarterly) && <div className="panel panel-cratejoy panel-product">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          Choose a New Shipping Cadence
                        </h3>
                      </div>
                      <div className="panel-body">
                        <div className="row">
                          {[['Monthly', 'Monthly'], ['Quarterly', 'Quarterly']].map((arr, idx) => {
                            let className = "col-md-6 col-xs-6";
                            if (arr[0] === props.newSubscriptionProduct.shipping_cadence) {
                              className += ' selected'
                            }
                            return(
                              <div
                                className={className}
                                key={idx}
                                onClick={(event) => props.updateSubscriptionAttribute({ key: 'shipping_cadence', value: arr[0] })}
                              >
                                <label className="btn_class cj-btn term-button">
                                  <div>
                                    <h4>{arr[1]}</h4>
                                  </div>
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                    {(props.subscription.state === 'active') && <div className="panel panel-cratejoy panel-product">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          Choose a New Subscription Term
                        </h3>
                      </div>
                      <div className="panel-body">
                        <div className="row">
                          {hardcodedTermPrices(props.newSubscriptionProduct.tier).map((termPrice, idx) => {
                            let className = "col-md-6 col-xs-6";
                            if (termPrice.paid_months === props.newSubscriptionProduct.paid_months) {
                              className += ' selected'
                            }
                            if (props.newSubscriptionProduct.shipping_cadence === 'Quarterly' && termPrice.paid_months !== 1) {
                              return(
                                <div
                                  className={className}
                                  key={idx}
                                ></div>
                              );
                            }

                            if (props.subscription.subscription_product.paid_months === 1) {

                            } else if (termPrice.paid_months === 1 && !props.reduxTokenAuth.currentUser.attributes.opted_out_of_mandatory_prepay) {
                              return(
                                <div
                                  key={idx}
                                ></div>
                              );
                            }

                            return(
                              <div
                                className={className}
                                key={idx}
                                onClick={(event) => props.updateSubscriptionAttribute({ key: 'paid_months', value: termPrice.paid_months })}
                              >
                                <label className="btn_class cj-btn term-button">
                                  <div>
                                    <h4>{termPrice.humanizedTerm}</h4>
                                    <h6><span className="monthly-total-text">${(termPrice.price_cents / 100 / termPrice.paid_months).toFixed(2)}</span>/shipment</h6>
                                    <div className="total-text">
                                      ${(termPrice.price_cents / 100 ).toFixed(2)} total per renewal
                                    </div>
                                  </div>
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                    {(props.subscription.state === 'active') && <div className="panel panel-cratejoy" id="shipping-address-panel">
                      <div className="panel-heading">
                        <h3 className="panel-title" style={{display: 'inline-block'}}>
                          Choose a New Shipping Address
                        </h3>
                      </div>
                      <div className="panel-body">
                        <div className="row" id="shipping-address-container">
                          <select
                            className="form-control state-selector"
                            value={props.newAddressID}
                            onChange={(event) => props.updateSubscriptionAttribute({ key: 'newAddressID', value: event.target.value })}
                          >
                            {props.addresses.map((address) => {
                              const selectString = `${address.to}, ${address.street}, ${address.unit}, ${address.city}, ${address.state}`;
                              return(
                                <option
                                  value={address.id}
                                  key={address.id}
                                >
                                  {selectString}
                                </option>
                              )
                            })}
                          </select>
                          <Link to="/customer/addresses" className='new-item-link'>
                            Create New Shipping Address
                          </Link>
                        </div>
                      </div>
                    </div>}
                    {(props.subscription.state !== 'cancelled') && <div className="panel panel-cratejoy" id="payment-method-panel">
                      <div className="panel-heading">
                        <h3 className="panel-title" style={{display: 'inline-block'}}>
                          Choose a New Payment Method
                        </h3>
                      </div>
                      <div className="panel-body">
                        <div className="row" id="payment-method-container">
                          <select
                            className="form-control state-selector"
                            value={props.newPaymentMethodID}
                            onChange={(e) => props.updateSubscriptionAttribute({ key: 'newPaymentMethodID', value: e.target.value })}
                          >
                            <option
                              value={0}
                              key={0}
                            >
                              {''}
                            </option>
                            {props.paymentMethods.map((paymentMethod) => {
                              const selectString = `${paymentMethod.card_type}, ****${paymentMethod.last_4}, expires ${paymentMethod.expiration_month} / ${paymentMethod.expiration_year}`;
                              return(
                                <option
                                  value={paymentMethod.id}
                                  key={paymentMethod.id}
                                >
                                  {selectString}
                                </option>
                              )
                            })}
                          </select>
                          <Link to="/customer/payment_methods" className='new-item-link'>
                            Create new Payment Method
                          </Link>
                        </div>
                      </div>
                    </div>}
                    {(props.subscription.state !== 'cancelled') && <div className="row">
                      <div className="col-xs-12">
                        {(props.subscription.state !== 'cancelled') && <Link
                          className="btn btn-primary cancel pull-left"
                          to={`/customer/subscriptions/${props.subscription.id}/cancel`}
                        >
                          Cancel Subscription
                        </Link>}
                        <button
                          type="submit"
                          name="submission-type"
                          value="change"
                          className={`btn btn-primary pull-right ${!canSubmit(props) ? 'disabled' : ''}`}
                          id="confirm-btn"
                          onClick={() => { canSubmit(props) && props.showConfirmationModal()}}
                        >
                          Change
                        </button>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const canSubmit = (props) => {
  return props.formValid() && !props.isSubmittingForm;
};

const ConfirmationModal = (props) => {
  // return(<div></div>);
  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      // height: '80vh',
      zIndex: 2,
    }
  };
  return(
    <Modal
      isOpen={props.confirmationModalShown}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onRequestClose={props.hideConfirmationModal}
    >
      <div className="confirmation-header">
        <h2>
          Confirm Changes:
        </h2>
      </div>
      {styleChanged(props) &&
        <div>
          <h2 className='change-confirmation-header'>
            old style type:
          </h2>
          <div>
            {props.subscription.subscription_product.primary_style_type}
          </div>
          <h2 className='change-confirmation-header'>
            new style type:
          </h2>
          <div>
            {props.newSubscriptionProduct.primary_style_type}
          </div>
        </div>
      }
      {tierChanged(props) &&
        <div>
          <h2 className='change-confirmation-header'>
            old tier:
          </h2>
          <div>
            {props.subscription.subscription_product.tier}
          </div>
          <h2 className='change-confirmation-header'>
            new tier:
          </h2>
          <div>
            {props.newSubscriptionProduct.tier}
          </div>
        </div>
      }
      {termChanged(props) &&
        <div>
          <h2 className='change-confirmation-header'>
            old term:
          </h2>
          <div>
            {props.subscription.subscription_product.paid_months} Months
          </div>
          <h2 className='change-confirmation-header'>
            new term:
          </h2>
          <div>
            {props.newSubscriptionProduct.paid_months} Months
          </div>
        </div>
      }
      {shippingCadenceChanged(props) &&
        <div>
          <h2 className='change-confirmation-header'>
            old shipping cadence:
          </h2>
          <div>
            {props.subscription.subscription_product.shipping_cadence}
          </div>
          <h2 className='change-confirmation-header'>
            new shipping cadence:
          </h2>
          <div>
            {props.newSubscriptionProduct.shipping_cadence}
          </div>
        </div>
      }
      {addressChanged(props) &&
        <div>
          <h2 className='change-confirmation-header'>
            old address:
          </h2>
          <OneLineAddress address={props.subscription.address} />
          <h2 className='change-confirmation-header'>
            new address:
          </h2>
          <OneLineAddress address={props.newAddress} />
        </div>
      }
      {paymentMethodChanged(props) &&
        <div>
          <h2 className='change-confirmation-header'>
            old payment method:
          </h2>
          <OneLinePaymentMethod paymentMethod={props.subscription.payment_method} />
          <h2 className='change-confirmation-header'>
            new payment method:
          </h2>
          <OneLinePaymentMethod paymentMethod={props.newPaymentMethod} />
        </div>
      }
      <button
        type="submit"
        name="submission-type"
        value="change"
        className={`btn btn-primary pull-right ${!canSubmit(props) ? 'disabled' : ''}`}
        id="confirm-btn-2"
        onClick={(e) => { canSubmit(props) && props.submitSubscriptionChanges(e)}}
      >
        Change
      </button>
    </Modal>
  );
};

const OneLineAddress = (props) => {
  return(
    <div>
      {props.address.to}, {props.address.street} {props.address.unit}, {props.address.city}, {props.address.state} {props.address.zip_code}
    </div>
  )
};

const OneLinePaymentMethod = (props) => {
  if (!props.paymentMethod) {
    return(
      <div>
        <div className=''></div>
      </div>
    );
  };
  return(
    <div>
      <div className={`creditcard-${props.paymentMethod.card_type}`}></div>
      ****{props.paymentMethod.last_4}, {props.paymentMethod.expiration_month}/{props.paymentMethod.expiration_year}
    </div>
  )
};

const SkipLink = (props) => {
  let linkText = "Skip Next Renewal";

  if (props.subscription.skipped_date) {
    linkText = "Un-Skip Next Renewal";
  }

  return(
    <div className="col-sm-12">
      <a
        href="/"
        id="skip_button"
        className="cj-btn skip_button pull-right"
        onClick={(e) => { props.skipToggle(e)}}
      >
        {linkText}
      </a>
    </div>
  )
};

const addressChanged = (props) => {
  return(props.subscription.address_id !== props.newAddressID);
};

const paymentMethodChanged = (props) => {
  return(props.subscription.payment_method_id !== props.newPaymentMethodID);
};

const styleChanged = (props) => {
  return(props.subscription.subscription_product.primary_style_type !== props.newSubscriptionProduct.primary_style_type);
};

const termChanged = (props) => {
  return(props.subscription.subscription_product.paid_months !== props.newSubscriptionProduct.paid_months);
};

const shippingCadenceChanged = (props) => {
  return(props.subscription.subscription_product.shipping_cadence !== props.newSubscriptionProduct.shipping_cadence);
};

const tierChanged = (props) => {
  return(props.subscription.subscription_product.tier !== props.newSubscriptionProduct.tier);
};

export default Subscription;
