const getSignedInCustomer = () => {
  return {
    type: 'API:CUSTOMER:GET_SIGNED_IN_CUSTOMER',
    API: true,
    url: `/api/v1/customers/0`,
  };
};

const updateCustomerInformation = (data, success, error, complete) => {
  return {
    type: 'API:CUSTOMER:UPDATE',
    API: true,
    method: 'POST',
    url: `/api/v1/customers`,
    data,
    success,
    error,
    complete,
  };
};

const toggleShipmentNotifications = (data, success, error, complete) => {
  return {
    type: 'API:CUSTOMER:TOGGLE_SHIPMENT_NOTIFICATIONS',
    API: true,
    method: 'POST',
    url: `/api/v1/customers/${data.customer_id}/shipment_notifications`,
    data,
    success,
    error,
    complete,
  };
};

export default {
  getSignedInCustomer,
  toggleShipmentNotifications,
  updateCustomerInformation,
};
