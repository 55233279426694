import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/addresses/addresses.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';
import { isValidZipCode } from '../../../../../app/lib/validators.js';
import { USStatesHash } from '../../../../../app/lib/constants.js'

import Address from './address.jsx';

class EditAddressContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      newAddress: {
        to: '',
        street: '',
        unit: '',
        city: '',
        zip_code: '',
        state: '',
      },
      isSubmittingForm: false,
      updateSubscriptions: true,
    };

    this.updateAddressAttribute = this.updateAddressAttribute.bind(this);
    this.submitAddressForm = this.submitAddressForm.bind(this);
    this.formValid = this.formValid.bind(this);
    this.fetchAddress = this.fetchAddress.bind(this);
    this.toggleUpdateSubscriptions = this.toggleUpdateSubscriptions.bind(this);
  }

  componentDidMount() {
    this.fetchAddress();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editingAddress !== prevProps.editingAddress) {
      // initially, put what the server gets into component state
      this.setState({
        newAddress: {
          to: this.props.editingAddress.to,
          street: this.props.editingAddress.street,
          unit: this.props.editingAddress.unit,
          city: this.props.editingAddress.city,
          zip_code: this.props.editingAddress.zip_code,
          state: this.props.editingAddress.state,
        }
      })
    }
  }

  fetchAddress() {
    const action = Actions.address.fetchAddress({id: this.props.match.params.id});
    this.props.dispatch(action);
  }

  updateAddressAttribute(opts) {
    let newNewAddress = Object.assign({}, this.state.newAddress);
    newNewAddress[opts.key] = opts.value;
    this.setState({
      newAddress: newNewAddress
    })
  }

  formValid() {
    const newAddr = this.state.newAddress;
    const valid = isValidZipCode(newAddr.zip_code) &&
      newAddr.to.length >= 2 &&
      newAddr.street.length >= 2 &&
      newAddr.city.length >= 2 &&
      newAddr.state.length >= 2 &&
      Object.keys(USStatesHash()).indexOf(newAddr.state) >= 0;

    //the new address MUST differ from the old address in at least one way
    const minOneDiff = newAddr.to !== this.props.editingAddress.to ||
      newAddr.street !== this.props.editingAddress.street ||
      newAddr.unit !== this.props.editingAddress.unit ||
      newAddr.city !== this.props.editingAddress.city ||
      newAddr.state !== this.props.editingAddress.state ||
      newAddr.zip_code !== this.props.editingAddress.zip_code ||
      this.state.updateSubscriptions //can update existing address to cover every existing shipment and subscription

    return (valid && minOneDiff);
  }

  submitAddressForm(e) {
    e.preventDefault();
    if (this.state.isSubmittingForm) return;
    if (!this.formValid()) return;

    this.setState({
      isSubmittingForm: true,
    }, () => {
      let data = this.state.newAddress;
      data.id = this.props.editingAddress.id;
      data.updateSubscriptions = this.state.updateSubscriptions;

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You updated your address!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/addresses');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to update your address!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isSubmittingForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Updating your address...'});
      this.props.dispatch(warn);

      const action = Actions.address.updateAddress(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  toggleUpdateSubscriptions() {
    this.setState({
      updateSubscriptions: !this.state.updateSubscriptions,
    })
  }

  render() {
    if (Object.keys(this.props.editingAddress).length === 0) {
      return(<div></div>);
    }
    return(
      <Address
        {...this.props}
        newAddress={this.state.newAddress}
        isSubmittingForm={this.state.isSubmittingForm}
        toggleUpdateSubscriptions={this.toggleUpdateSubscriptions}
        updateSubscriptions={this.state.updateSubscriptions}
        headerText="Edit your address"
        updateAddressAttribute={this.updateAddressAttribute}
        submitAddressForm={this.submitAddressForm}
        formValid={this.formValid}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    editingAddress: state.addresses.editingAddress,
  };
}

export default connect(mapStateToProps)(withRouter(EditAddressContainer));
