import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Become from './become.jsx';

class BecomeContainer extends React.Component {
  constructor() {
    super(...arguments);
  }

  componentDidMount() {
    window.localStorage.setItem('access-token', this.props.becomeCustomer.accessToken);
    window.localStorage.setItem('client', this.props.becomeCustomer.client);
    window.localStorage.setItem('uid', this.props.becomeCustomer.uid);
  }

  componentDidUpdate() {
    const canRedirect = this.props.reduxTokenAuth.currentUser.isSignedIn;
    if (canRedirect) {
      this.props.history.push('/customer/account');
    }
  }

  render() {
    return(
      <Become
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    reduxTokenAuth: state.reduxTokenAuth,
    becomeCustomer: state.app.becomeCustomer,
  };
}

export default connect(mapStateToProps)(withRouter(BecomeContainer));
