const initialState = {
  shipments: [],
  subscriptions: [],
};


function reducer(state = initialState, action) {
  switch(action.type) {
    case 'API:HISTORY:GET:SUCCESS':
      let unshippedCounts = [];

      // calc unshipped shipments for each sub
      action.response.subscriptions.forEach((sub, idx) => {
        let countUnshipped = 0;
        sub.shipments.forEach((shipment, jdx) => {
          if (shipment.status === 'unshipped') {
            countUnshipped += 1;
          }
        });
        if (countUnshipped > 0) {
          unshippedCounts.push( [ sub.id, countUnshipped ] )
        }
      });
      return { ...state, shipments: action.response.shipments, subscriptions: action.response.subscriptions, unshippedCounts: unshippedCounts };
    default:
      return state;
  }
}

export default reducer;
