const setMessage = (message, timeout) => {
  return (dispatch, data) => {
    dispatch({
      type: 'APP:SET_MESSAGE',
      message,
    });

    if (timeout) {
      setTimeout(() => dispatch(clearMessage()), timeout);
    }
  };
};

const clearMessage = () => {
  return {
    type: 'APP:CLEAR_MESSAGE',
  };
};

const clearCurrentCustomer = () => {
  return {
    type: 'APP:CLEAR_CURRENT_CUSTOMER',
  };
};

const showMobileMenu = () => {
  return {
    type: 'APP:MOBILE_MENU:SHOW',
  };
};

const hideMobileMenu = () => {
  return {
    type: 'APP:MOBILE_MENU:HIDE',
  };
};

const collapseMobileMenu = () => {
  return {
    type: 'APP:MOBILE_MENU:COLLAPSE',
  };
};

const handleNotificationReceived = (data) => {
  return {
    type: 'APP:HANDLE_NOTIFICATION',
  };
};

export default {
  setMessage,
  clearMessage,
  clearCurrentCustomer,
  collapseMobileMenu,
  handleNotificationReceived,
  hideMobileMenu,
  showMobileMenu,
};
