import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Product from './product.jsx';

class ProductContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      upsellModalShown: false,
      activePhoto: 'catalog_photos', //model_photos showcase_photos
    };

    this.addToCart = this.addToCart.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.closeUpsellModal = this.closeUpsellModal.bind(this);
    this.canSelectImages = this.canSelectImages.bind(this);
    this.setActivePhoto = this.setActivePhoto.bind(this);
  }

  componentDidMount() {
    this.fetchEarfleekProduct(this.props.match.params.id);

    window.setTimeout(() => {
      if (!this.props.customer && !window.localStorage.getItem('EarfleekHasSeenPDPUpsell')) {
        window.localStorage.setItem('EarfleekHasSeenPDPUpsell', 'true');
        this.setState({
          upsellModalShown: true,
        })
      }
    }, 2000);
  }

  componentWillUnmount() {
  }

  closeUpsellModal() {
    this.setState({
      upsellModalShown: false,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.product.title !== this.props.product.title) {
      document.title = `EarFleek Shop - ${this.props.product.title}`;
      // document.title = `EarFleek Shop - ${this.props.product.title}`;

      if (this.props.product.seo_optimized) {
        document.querySelector('meta[name="description"]').setAttribute("content", this.props.product.description);
      }
    }
  }

  fetchEarfleekProduct(id) {
    const action = Actions.products.fetchEarfleekProduct(id);
    this.props.dispatch(action);
  }

  addToCart() {
    const action = Actions.products.addEarfleekProductToCart(this.props.product);
    this.props.dispatch(action);
  }

  removeFromCart() {
    const action = Actions.products.removeEarfleekProductFromCart(this.props.product.vendor_number, 'EarfleekProduct');
    this.props.dispatch(action);
  }

  canSelectImages() {
    if (!this.props.product) return false;
    let tracker = 0;

    ['catalog_photos', 'model_photos', 'showcase_photos'].map((property) => {
      if (this.props.product[property]) {
        if (this.props.product[property].length === 0) {

        } else {
          tracker += 1;
        }
      } else {
      }
    })
    return (tracker > 1);
  }

  setActivePhoto(property) {
    this.setState({activePhoto: property})
  }

  render() {
    return (
      <Product
        {...this.props}
        upsellModalShown={this.state.upsellModalShown}
        activePhoto={this.state.activePhoto}
        addToCart={this.addToCart}
        removeFromCart={this.removeFromCart}
        closeUpsellModal={this.closeUpsellModal}
        canSelectImages={this.canSelectImages}
        setActivePhoto={this.setActivePhoto}
      />
    );
  }
}

function mapStateToProps(state) {
  let asdf = true;

  try {
    asdf = state.reduxTokenAuth.currentUser.isSignedIn;
  } catch (err) {

  }

  return {
    product: state.products.shopPageViewingProduct,
    cartProducts: state.earfleekCart.products,
    isSignedIn: asdf,
  };
}

export default connect(mapStateToProps)(withRouter(ProductContainer));
