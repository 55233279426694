import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/checkout/checkout.scss';
import leftImage from 'assets/images/consumer/checkout/pop-art.png';
import removeCartImage from 'assets/images/consumer/checkout/remove-from-cart.png';
import giftPic from '../../../../assets/images/checkout/gift.svg';

// import 'react-tooltip/dist/react-tooltip.css'
// import { Tooltip } from 'react-tooltip'
import ReactTooltip from 'react-tooltip';


import { USStatesHash, creditCardMonthsArray, creditCardYearsArray, isChristmasSeason } from '../../../../app/lib/constants.js';

import Modal from 'react-modal';

const ErrorsModal = (props) => {
  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      minWidth: '260px',
      zIndex: 2,
    }
  };
  return(
    <Modal
      isOpen={props.errorModalShown}
      style={customStyles}
      contentLabel="Errors Modal"
      ariaHideApp={false}
      onRequestClose={props.closeErrorsModal}
    >
      <div className="error-modal">
        <div className="errors-header">
          Errors!
        </div>
        {props.errorModalMessages.map((section, idx) => {
          return(
            <div key={idx}>
              <div className="section-heading">
                {section[0]}:
              </div>
              <ul>
                {section[1].map((error, jdx) => {
                  return(<li key={jdx} className="specific-error-message">
                    {error}
                  </li>)
                })}
              </ul>
            </div>
          );
        })}
        <div>Trouble checking out? Please email <a href="mailto:support@earfleek.com">support@earfleek.com!</a></div>
        <button
          onClick={() => {props.closeErrorsModal()}}
          className="btn btn-primary pull-right"
        >
          OK
        </button>
      </div>
    </Modal>
  );
};

const Checkout = (props) => {
  if (props.products.length === 0 && props.subscriptions.length === 0) {
    return(
      <section id='checkout-content'>
        <ErrorsModal {...props}/>
        <section id="cmp_cf44a631-6f61-40d7-b360-6d0842e0d050">
          <div className="container-fluid">
            <div className="checkout_content newco checkout_3">
              <div className="left-col">
                <section className="cart_listing styled_cart">
                  <header>
                    <img src={leftImage} />
                    <span className="no-cart-error-message">There is nothing in your cart! Please add items to your cart <Link to="/subscribe">here</Link></span>
                  </header>
                </section>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }

  return (
    <section id='checkout-content'>
      <ErrorsModal {...props}/>
      <section id="cmp_cf44a631-6f61-40d7-b360-6d0842e0d050">
        <div className="container-fluid">
          <div className="checkout_content newco checkout_3">
            <div className="left-col">
              <section className="cart_listing styled_cart">
                <header>
                  <img src={leftImage} />
                  <h3>Order Summary</h3>
                </header>
                {props.referralInfo && props.referralInfo.referredByCustomerID && <div>
                  <div className="referral_info">
                    Referred by: {props.referralInfo.firstName} {props.referralInfo.lastName}
                  </div>
                </div>}

                <table className="item_table">
                  <tbody>
                    {props.subscriptions.map((subscription, idx) => {
                      return(
                        <tr key={idx}>
                          <RemoveFromCart subscription={subscription} removeFromCart={props.removeFromCart}/>
                          <CartProductDescription subscription={subscription} />
                          <CartProductPrice subscription={subscription} customer={props.customer}/>
                        </tr>
                      );
                    })}
                    {props.products.map((product, idx) => {
                      return(
                        <tr key={idx}>
                          <RemoveFromCart product={product} removeFromCart={props.removeFromCart}/>
                          <CartProductDescription product={product} />
                          <CartProductPrice product={product} customer={props.customer}/>
                        </tr>
                      );
                    })}
                    {props.coupon.code && (typeof props.coupon.discount_cents === 'number') && <tr>
                      <td className="delete_column">
                        <img
                          src={removeCartImage}
                          alt="Remove Coupon"
                          onClick={() => { props.removeCoupon()}}
                        />
                      </td>
                      <td>
                        <div>
                          <div className="coupon-code"> {props.coupon.code} </div>
                          <div className="coupon-description"> {props.coupon.description} </div>
                        </div>
                      </td>
                      <td className="price_column currency discount">
                        -${(props.coupon.discount_cents / 100).toFixed(2)}
                      </td>
                    </tr>}
                  </tbody>
                </table>
                <table className="price_table">
                  <tbody>
                    <tr id="shipping_row" className="detail-row">
                      <td id="shipping_price_estimated_label"><strong>Shipping:</strong></td>
                      <td className="currency" id="shipping_price">FREE</td>
                    </tr>
                    <tr id="sub_total_row" className="detail-row">
                      <td><strong>Subtotal:</strong></td>
                      <td className="currency" id="subtotal_price">${(props.subtotalCents / 100).toFixed(2)}</td>
                    </tr>
                    <tr id="sales_tax_row" className="detail-row">
                      <td><strong>Sales Tax: {(props.taxRate * 100).toFixed(2)}%</strong></td>
                      <td className="currency" id="sales_tax_price">${(props.salesTaxCents / 100).toFixed(2)}</td>
                    </tr>
                    <tr id="total_row" className="total-row ">
                      <th>Total</th>
                      <th className="currency" id="total_price">${(props.grandTotalCents / 100).toFixed(2)}</th>
                    </tr>
                  </tbody>
                </table>
                {!props.earfleekCart.coupon.code && !props.customer && <div className="coupon_section">
                  <div className="row">
                    <div className="col-xs-12 col-md-8 col-lg-8">
                      <div className="referral_info">
                        One coupon per customer. New customers only.
                      </div>
                      <div className="input-group">
                        <input
                          type="text"
                          id="id_coupon_code"
                          name="coupon_code"
                          placeholder="Coupon or Gift Code"
                          className={`input`}
                          value={props.tempCouponCode}
                          onChange={(event) => {props.updateCheckoutAttribute({tempCouponCode: event.target.value})}}
                          onKeyPress={(e) => {catchReturnCoupon(e, props)}}
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-primary"
                            id="add_coupon_code"
                            onClick={() => {props.updateCartCouponCode()}}
                          >Apply</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>}
              </section>
            </div>
            <div className="right-col">
              <div className="cart_addresses">
                <div className="ship_to form_holder">
                  <ShippingAddress {...props}/>
                </div>
              </div>
              <div className="pay_with">
                <BillingInfo {...props} />
              </div>
              <div className="cta">
                <button
                  type="submit"
                  className="checkout_button primary-button pull-right"
                  id="checkout_button"
                  onClick={(e) => { preventClick(e, props)} }
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const BillingInfo = (props) => {
  let firstNameClassName = "col-xs-12 col-md-6";
  let lastNameClassName = "col-xs-12 col-md-6";
  let emailClassName = "col-xs-12 col-md-12 col-lg-9";
  let cardNumberClassName = "col-xs-12 col-md-9 col-lg-9";
  let cvvClassName = "col-xs-4 col-md-4 col-lg-4";
  let monthClassName = "col-xs-4 col-md-4 col-lg-4";
  let yearClassName = "col-xs-4 col-md-4 col-lg-4";
  let zipClassName = "col-xs-12 col-md-3 col-lg-3";
  let billingAddressClassName = "col-xs-12 col-md-9 col-lg-9";

  try {
    if (props.validationObject.billingInfo.first_name === false) {
      firstNameClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.last_name === false) {
      lastNameClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.email === false) {
      emailClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.number === false) {
      cardNumberClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.cvv === false) {
      cvvClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.expiration_month === false) {
      monthClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.expiration_year === false) {/**/
      yearClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.zip_code === false) {/**/
      zipClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.billingInfo.address_1 === false) {
      billingAddressClassName += ' invalid'
    }
  } catch(err) {}

  const monthsArray = creditCardMonthsArray();
  const yearsArray = creditCardYearsArray();

  const dataTip = "By clicking on 'Checkout', you confirm that you accept our Terms of Service & that your subscription will automatically renew based on the subscription billing period. \n\nYour credit card will automatically be charged the subscription fee until you cancel your subscription. You can cancel your subscription anytime online or by contacting us at support@earfleek.com";

  return(
    <section>
      <h3>
        Billing Information
      </h3>
      <div className="renewal-disclaimer">
        Plans automatically renew. Cancel any time.
        You agree to our <a
          data-tip={dataTip}
          className="learn-more"
        > ToS</a>
        <ReactTooltip
          place="top"
          type="dark"
          effect="float"
          className="learn-more-tooltip"
        /> by checking out.
      </div>
      {props.paymentMethods.length > 0 && <ExistingPaymentMethodRadio {...props}/>}
      {!props.existingPaymentMethod && <div className="editor">
        <div className="row" id="billing-name">
          <div className={firstNameClassName}>
            <label>
              <div className="default-label">
                First Name:
              </div>
              <div className="form-error-message">
                Cannot Be Blank
              </div>
              <input
                autoComplete="billing given-name"
                className="form-control"
                name="first_name"
                placeholder="Jane"
                type="text"
                value={props.billingInfo.first_name}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'first_name', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'first_name')}}
              />
            </label>
          </div>
          <div className={lastNameClassName}>
            <label>
              <div className="default-label">
                Last Name:
              </div>
              <div className="form-error-message">
                Cannot Be Blank
              </div>
              <input
                autoComplete="billing family-name"
                className="form-control"
                name="last_name"
                placeholder="Doe"
                type="text"
                value={props.billingInfo.last_name}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'last_name', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'last_name')}}
              />
            </label>
          </div>
        </div>
        {!props.customer && <div className="row">
          <div className={emailClassName}>
            <label>
              <div className="default-label">
                Email:
              </div>
              <div className="form-error-message">
                Email is invalid
              </div>
              <input
                autoComplete="billing email"
                className="form-control"
                name="email"
                placeholder="asdf@example.com"
                type="email"
                value={props.billingInfo.email}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'email', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'email')}}
              />
            </label>
          </div>
        </div>}
        <div className="row">
          <div className={cardNumberClassName}>
            <label>
              <div className="default-label">
                Credit Card Number:
              </div>
              <div className="form-error-message">
                Card Number is invalid
              </div>
              <div className="right-inner-addon">
                <i className="icon-lock">
                  {'\uD83D\uDD12'}
                  <div className={`creditcard-${props.billingInfo.card_type}`}></div>
                </i>
              </div>
              <input
                autoComplete="billing cc-number"
                className="form-control"
                placeholder={`**** **** **** ****`}
                required=""
                type="number"
                value={props.billingInfo.number}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'number', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'number')}}
              />
            </label>
          </div>
        </div>
        <div className="row">
          <div className={cvvClassName}>
            <label>
              <div className="default-label">
                CVV:
              </div>
              <div className="form-error-message">
                CVV is invalid
              </div>
              <input
                autoComplete="billing cc-csc"
                className="form-control"
                name="cvv"
                placeholder="999"
                type="text"
                value={props.billingInfo.cvv}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'cvv', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'cvv')}}
              />
            </label>
          </div>
          <div className={monthClassName}>
            <label>
              <div className="default-label">
                Month:
              </div>
              <div className="form-error-message">
                Month must be selected
              </div>
              <select
                autoComplete="billing cc-exp-month"
                name="exp_month"
                className="form-control state-selector"
                value={props.billingInfo.expiration_month}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'expiration_month', value: event.target.value })}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'expiration_month')}}
              >
                <option
                  value=""
                >
                </option>
                {monthsArray.map((arr) => {
                  return(
                    <option
                      value={arr[0]}
                      key={arr[0]}
                    >
                      {`${arr[1]} - ${arr[2]}`}
                    </option>
                  )
                })}
              </select>
            </label>
          </div>
          <div className={yearClassName}>
            <label>
              <div className="default-label">
                Year:
              </div>
              <div className="form-error-message">
                Year is invalid
              </div>
              <select
                autoComplete="billing cc-exp-year"
                name="exp_year"
                className="form-control state-selector"
                value={props.billingInfo.expiration_year}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'expiration_year', value: event.target.value })}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'expiration_year')}}
              >
                <option
                  value=""
                >
                </option>
                {yearsArray.map((int) => {
                  return(
                    <option
                      value={int}
                      key={int}
                    >
                      {int}
                    </option>
                  )
                })}
              </select>
            </label>
          </div>
        </div>
        <div className="row">
          <div className={billingAddressClassName}>
            <label>
              <div className="default-label">
                Billing Street Address:
              </div>
              <div className="form-error-message">
                Billing Street Address is Invalid
              </div>
              <input
                autoComplete="billing street-address"
                className="form-control"
                name="card_address"
                placeholder="924 9th Street"
                type="text"
                value={props.billingInfo.address_1}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'address_1', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'address_1')}}
              />
            </label>
          </div>
          <div className={zipClassName}>
            <label>
              <div className="default-label">
                Billing Zip:
              </div>
              <div className="form-error-message">
                Billing Zip is Invalid
              </div>
              <input
                autoComplete="billing postal-code"
                className="form-control"
                name="card_zip_code"
                placeholder="90210"
                type="text"
                value={props.billingInfo.zip_code}
                onChange={(event) => props.updateBillingInfoAttribute({ key: 'zip_code', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('billingInfo', 'zip_code')}}
              />
            </label>
          </div>
        </div>
      </div>
      }
    </section>
  );
};

const ShippingAddress = (props) => {
  let firstNameClassName = "col-xs-12 col-md-6";
  let giftFirstNameClassName = "col-xs-12 col-md-6 col-lg-6";
  let lastNameClassName = "col-xs-12 col-md-6";
  let giftLastNameClassName = "col-xs-12 col-md-6 col-lg-6";
  let giftEmailClassName = "col-xs-12 col-md-12 col-lg-12";
  let streetAddressClassName = "col-xs-12 col-md-9";
  let unitClassName = "col-xs-12 col-md-4";
  let cityClassName = "col-xs-12 col-md-3";
  let shippingZipClassName = "col-xs-12 col-md-3";
  let shippingStateClassName = "col-md-6 col-xs-12";

  try {
    if (props.validationObject.shippingAddress.first_name === false) {
      firstNameClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.giftInfo.first_name === false) {
      giftFirstNameClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.shippingAddress.last_name === false) {
      lastNameClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.giftInfo.last_name === false) {
      giftLastNameClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.giftInfo.email === false) {
      giftEmailClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.shippingAddress.street === false) {
      streetAddressClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.shippingAddress.city === false) {
      cityClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.shippingAddress.zip_code === false) {
      shippingZipClassName += ' invalid'
    }
  } catch(err) {}

  try {
    if (props.validationObject.shippingAddress.state === false) {
      shippingStateClassName += ' invalid'
    }
  } catch(err) {}

  const usStates = USStatesHash();

  return(
    <section>
      {isChristmasSeason() && <div className="christmas-disclaimer">**Please note - the final day to order product for receipt before Christmas is <span>12/14/{new Date().getFullYear().toString().substr(-2)}</span>**
      </div>}
      <h3>Shipping Address</h3>
      {props.addresses.length > 0 && <ExistingShippingAddressRadio {...props}/>}
      {!props.existingShippingAddress && <div className="editor">
        <div className="row">
          <div className={firstNameClassName}>
            <label>
              <div className="default-label">
                First Name:
              </div>
              <div className="form-error-message">
                Cannot Be Blank
              </div>
              <input
                autoComplete="shipping given-name"
                className="form-control"
                name="first_name"
                placeholder="Jane"
                autoFocus={true}
                type="text"
                value={props.shippingAddress.first_name}
                onChange={(event) => props.updateShippingAddressAttribute({ key: 'first_name', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('shippingAddress', 'first_name')}}
              />
            </label>
          </div>
          <div className={lastNameClassName}>
            <label>
              <div className="default-label">
                Last Name:
              </div>
              <div className="form-error-message">
                Cannot Be Blank
              </div>
              <input
                autoComplete="shipping family-name"
                className="form-control"
                name="last_name"
                placeholder="Doe"
                type="text"
                value={props.shippingAddress.last_name}
                onChange={(event) => props.updateShippingAddressAttribute({ key: 'last_name', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('shippingAddress', 'last_name')}}
              />
            </label>
          </div>
        </div>
        <div className="row">
          <div className={streetAddressClassName}>
            <label>
              <div className="default-label">
                Street:
              </div>
              <div className="form-error-message">
                Cannot Be Blank
              </div>
              <input
                autoComplete="shipping street-address"
                className="form-control"
                name="street"
                placeholder="1426 Montana Ave"
                type="text"
                value={props.shippingAddress.street}
                onChange={(event) => props.updateShippingAddressAttribute({ key: 'street', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('shippingAddress', 'street')}}
              />
            </label>
          </div>
          <div className={unitClassName}>
            <label>
              <div className="default-label">
                Apt/Unit (optional):
              </div>
              <input
                autoComplete="shipping address-line2"
                className="form-control"
                name="unit"
                placeholder="Apt 1"
                type="text"
                value={props.shippingAddress.unit}
                onChange={(event) => props.updateShippingAddressAttribute({ key: 'unit', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('shippingAddress', 'unit')}}
              />
            </label>
          </div>
        </div>
        <div className="row">
          <div className={cityClassName}>
            <label>
              <div className="default-label">
                City:
              </div>
              <div className="form-error-message">
                Cannot Be Blank
              </div>
              <input
                autoComplete="shipping address-level2"
                className="form-control"
                name="city"
                placeholder="Santa Monica"
                type="text"
                value={props.shippingAddress.city}
                onChange={(event) => props.updateShippingAddressAttribute({ key: 'city', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('shippingAddress', 'city')}}
              />
            </label>
          </div>
          <div className={shippingStateClassName}>
            <label>
              <div className="default-label">
                State:
              </div>
              <div className="form-error-message">
                State must be selected
              </div>
              <select
                className="form-control state-selector"
                value={props.shippingAddress.state}
                onChange={(event) => props.updateShippingAddressAttribute({ key: 'state', value: event.target.value })}
                onBlur={(e) => {props.validateCheckoutProperty('shippingAddress', 'state')}}
              >
                <option
                  value=""
                >
                </option>
                {Object.keys(usStates).map((keyName) => {
                  return(
                    <option
                      value={keyName}
                      key={keyName}
                    >
                      {usStates[keyName]}
                    </option>
                  )
                })}
              </select>
            </label>
          </div>
          <div className={shippingZipClassName}>
            <label>
              <div className="default-label">
                Zip Code:
              </div>
              <div className="form-error-message">
                Zip Code is Invalid
              </div>
              <input
                autoComplete="shipping postal-code"
                className="form-control"
                name="zip_code"
                placeholder="90403"
                type="text"
                value={props.shippingAddress.zip_code}
                onChange={(event) => props.updateShippingAddressAttribute({ key: 'zip_code', value: event.target.value })}
                onKeyPress={(e) => { catchReturn(e, props)}}
                onBlur={(e) => {props.validateCheckoutProperty('shippingAddress', 'zip_code')}}
              />
            </label>
          </div>
        </div>
      </div>}
      <div className="editor" id="gift-form">
        <div
          className="gift-choice"
          onClick={() => { props.toggleGift() }}
        >
          <input
            id="is_gift"
            name="is_gift"
            type="checkbox"
            checked={props.isGift}
            readOnly={true}
          />
          <label className="gift-label">
            This is a gift
          </label>
          <div className="gift-icon">
            <img src={giftPic} />
          </div>
        </div>
        {props.isGift && <div className="gift-info">
          <div className="gift-input">
            <div className="gift-exciting">
              Exciting! We need a few details about who you're giving the gift to.
            </div>
            <div className="row">
              <div className={giftFirstNameClassName}>
                <label>
                  <div className="default-label">
                    Recipient First Name:
                  </div>
                  <div className="form-error-message">
                    Cannot Be Blank
                  </div>
                  <input
                    autoComplete="shipping given-name"
                    className="form-control"
                    name="first_name"
                    placeholder="Jane"
                    type="text"
                    value={props.giftInfo.first_name}
                    onChange={(event) => props.updateGiftInfoAttribute({ key: 'first_name', value: event.target.value })}
                    onKeyPress={(e) => { catchReturn(e, props)}}
                    onBlur={(e) => {props.validateCheckoutProperty('giftInfo', 'first_name')}}
                  />
                </label>
              </div>
              <div className={giftLastNameClassName}>
                <label>
                  <div className="default-label">
                    Recipient Last Name:
                  </div>
                  <div className="form-error-message">
                    Cannot Be Blank
                  </div>
                  <input
                    autoComplete="shipping family-name"
                    className="form-control"
                    name="last_name"
                    placeholder="Doe"
                    type="text"
                    value={props.giftInfo.last_name}
                    onChange={(event) => props.updateGiftInfoAttribute({ key: 'last_name', value: event.target.value })}
                    onKeyPress={(e) => { catchReturn(e, props)}}
                    onBlur={(e) => {props.validateCheckoutProperty('giftInfo', 'last_name')}}
                  />
                </label>
              </div>
              <div className={giftEmailClassName}>
                <label>
                  <div className="default-label">
                    Recipient Email:
                  </div>
                  <div className="form-error-message">
                    Email is invalid
                  </div>
                  <input
                    autoComplete="gift email"
                    className="form-control"
                    name="email"
                    placeholder="asdf@example.com"
                    type="text"
                    value={props.giftInfo.email}
                    onChange={(event) => props.updateGiftInfoAttribute({ key: 'email', value: event.target.value })}
                    onKeyPress={(e) => { catchReturn(e, props)}}
                    onBlur={(e) => {props.validateCheckoutProperty('giftInfo', 'email')}}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="renew-choice">
            <div className="col-md-8 col-xs-12 renew-notif">
              <label>
                This gift will renew
              </label>
            </div>
          </div>
        </div>}
      </div>
    </section>
  );
};

const ExistingShippingAddressRadio = (props) => {
  let existingAddressID = 0;
  if (props.existingShippingAddress) {
    existingAddressID = props.existingShippingAddress.id;
  }

  return(
    <div className="editor" id="shipping-address-radio">
      <div className="radio-choice">
        <div className="existing_address">
          <input
            type="radio"
            value="existing_address"
            className={`${props.existingShippingAddress ? 'selected' : ''}`}
            checked={!_.isEmpty(props.existingShippingAddress)}
            onClick={(event) => { props.setDefaultAddress() }}
            onChange={() => {}}
            tabIndex="0"
          />
          <label
            htmlFor="existing_address"
            onClick={(event) => { props.setDefaultAddress() }}
          >
            Use Existing Address
          </label>
          {props.addresses.length >= 1 && <select
            className="form-control state-selector"
            value={existingAddressID}
            onChange={(event) => props.updateExistingShippingAddress(parseInt(event.target.value))}
          >
            {props.addresses.map((address) => {
              let selectString = `${address.to}, ${address.street}, `;
              if (address.unit && address.unit.length >= 1) {
                selectString += `${address.unit}, `
              }
              selectString += `${address.city} ${address.state}`;
              return(
                <option
                  value={address.id}
                  key={address.id}
                >
                  {selectString}
                </option>
              )
            })}
          </select>}
        </div>
        <div
          className="new_address"
          onClick={(event) => { props.clearSelectedAddress() }}
        >
          <input
            type="radio"
            value="new_address"
            className={`${!props.existingShippingAddress ? 'selected' : ''}`}
            checked={!props.existingShippingAddress}
            onClick={() => { }}
            onChange={() => {}}
            tabIndex="0"
          />
          <label htmlFor="new_address">
            Create New Address
          </label>
        </div>
      </div>
    </div>
  );
};

const ExistingPaymentMethodRadio = (props) => {
  let existingPaymentMethodID = 0;
  if (props.existingPaymentMethod) {
    existingPaymentMethodID = props.existingPaymentMethod.id;
  }

  return(
    <div className="editor" id="payment-method-radio">
      <div className="radio-choice">
        <div className="existing_payment_method">
          <input
            type="radio"
            value="existing_payment_method"
            className={`${props.existingPaymentMethod ? 'selected' : ''}`}
            checked={!_.isEmpty(props.existingPaymentMethod)}
            onClick={(event) => { props.setDefaultPaymentMethod() }}
            onChange={() => {}}
            tabIndex="0"
          />
          <label
            htmlFor="existing_payment_method"
            onClick={(event) => { props.setDefaultPaymentMethod() }}
          >
            Use Existing Payment Method
          </label>
          {props.paymentMethods.length >= 1 && <select
            className="form-control state-selector"
            value={existingPaymentMethodID}
            onChange={(event) => props.updateExistingPaymentMethod(parseInt(event.target.value))}
          >
            {props.paymentMethods.map((paymentMethod) => {
              const selectString = `${paymentMethod.card_type}, ****${paymentMethod.last_4}, expires ${paymentMethod.expiration_month} / ${paymentMethod.expiration_year}`;

              return(
                <option
                  value={paymentMethod.id}
                  key={paymentMethod.id}
                >
                  {selectString}
                </option>
              )
            })}
          </select>}
        </div>
        <div
          className="new_payment_method"
          onClick={(event) => { props.clearSelectedPaymentMethod() }}
        >
          <input
            type="radio"
            value="new_payment_method"
            className={`${!props.existingPaymentMethod ? 'selected' : ''}`}
            checked={!props.existingPaymentMethod}
            onClick={() => { }}
            onChange={() => {}}
            tabIndex="0"
          />
          <label htmlFor="new_address">
            Create New Payment Method
          </label>
        </div>
      </div>
    </div>
  );
};

const RemoveFromCart = (props) => {
  let id, productType;
  if (props.subscription) {
    id = props.subscription.id;
    productType = 'EarfleekSubscription';
  } else if (props.product) {
    id = props.product.vendor_number;
    productType = 'EarfleekProduct';
  }
  return(
    <td className="delete_column">
      <img
        src={removeCartImage}
        alt="Remove From Cart"
        onClick={() => { props.removeFromCart(id, productType)}}
      />
    </td>
  )
};

const CartProductDescription = (props) => {
  let description, title;

  if (props.product) {
    title = props.product.title;
  } else if (props.subscription) {
    switch (props.subscription.primary_style_type) {
      case 'IDGAH':
        title = 'I Don\'t Give a Hoot!';
        break;
      case 'Silly & Fun':
        title = 'Silly & Fun';
        break;
      case 'Minimalist':
        title = 'Minimalist but Groovy';
        break;
      case 'Boho':
        title = 'Boho Babe';
        break;
      default:

    }
  }

  return(
    <td className="product_column">
      <div>
        <div className="product-name">
          {title}
        </div>
        {props.subscription && <div className="subscription-plan">
          {props.subscription.description}
        </div>}
        {props.subscription && <div className="subscription-autorenew">
          <strong>
            {props.subscription.paid_months} {props.subscription.paid_months === 1 ? 'delivery': 'deliveries'}<span className="auto-renew-cart">, auto-renewing</span>
          </strong>
        </div>}
      </div>
    </td>
  );
};

const CartProductPrice = (props) => {
  let priceString;
  if (props.product) {
    if (props.customer && props.customer){
      priceString = (props.product.member_price_cents / 100).toFixed(2);
    } else {
      priceString = (props.product.retail_price_cents / 100).toFixed(2);
    }
  } else {
    priceString = (props.subscription.price_cents / 100).toFixed(2)
  }

  return(
    <td className="price_column currency">
      ${priceString}
    </td>
  );
};

const catchReturnCoupon = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.updateCartCouponCode();
  }
};

const preventClick = (e, props) => {
  e.preventDefault();
  props.submitCheckoutForm(e);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.submitCheckoutForm(e);
  }
};

export default Checkout;
