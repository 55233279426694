const getPaymentMethods = (data, success, error, complete) => {
  return {
    type: 'API:PAYMENT_METHODS:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/payment_methods`,
    data,
    success,
    error,
    complete,
  };
};

const fetchPaymentMethod = (data, success, error, complete) => {
  return {
    type: 'API:PAYMENT_METHOD:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/payment_methods/${data.id}`,
    data,
    success,
    error,
    complete,
  };
};

const createPaymentMethod = (data, success, error, complete) => {
  return {
    type: 'API:PAYMENT_METHODS:CREATE',
    API: true,
    method: 'POST',
    url: `/api/v1/payment_methods`,
    data,
    success,
    error,
    complete,
  }
};

const deletePaymentMethod = (data, success, error, complete) => {
  return {
    type: 'API:PAYMENT_METHODS:DELETE',
    API: true,
    method: 'DELETE',
    url: `/api/v1/payment_methods/${data.id}`,
    data,
    success,
    error,
    complete,
  }
};

const updatePaymentMethod = (data, success, error, complete) => {
  return {
    type: 'API:PAYMENT_METHODS:UPDATE',
    API: true,
    method: 'PATCH',
    url: `/api/v1/payment_methods/${data.id}`,
    data,
    success,
    error,
    complete,
  }
};

export default {
  getPaymentMethods,
  fetchPaymentMethod,
  createPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
};
