import { Link } from 'react-router-dom';
import twitter from 'assets/images/consumer/refer/twitter.png'

const preventClick = (e, props) => {
  e.preventDefault();
  props.submitReferForm(e);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.submitReferForm(e);
  }
};

const canSubmit = (props) => {
  return props.formValid() && !props.isSubmittingReferralForm;
};

const Refer = (props) => {
  if (!props.customer) {
    return (<div></div>)
  }

  let countString = '0 friends';
  if (props.customer.referral_invitations) {
    const refCount = props.customer.referral_invitations.length;
    if (refCount > 0 && refCount <= 1) {
      countString = '1 friend'
    } else if (refCount > 1) {
      countString = `${refCount} friends`
    }
  }

  return (
    <section id='refer-content'>
      <section id="cmp_0c350164-8990-4e71-aa4d-e39ad1f003dd" className="container">
        <div className="container my-account my-referral">
          <div className="row">
            <div className="col-md-12" id="refer-back-link">
              <Link to="/customer/account">Back to Account Page</Link>
            </div>
            <div className="col-md-6" id="refer-email-col">
              <h2>Get $1.97 off your next shipment!</h2>
              <h4>Refer a friend and you'll get a discount!</h4>
              <div className="refer-row row">
                <div className="col-xs-5">
                  <input
                    name="first-name_0"
                    type="text"
                    placeholder="First name"
                    autoFocus={true}
                    value={props.name}
                    onChange={(event) => props.updateReferAttribute({ key: 'name', value: event.target.value })}
                    onKeyPress={(e) => {catchReturn(e, props)}}
                  />
                </div>
                <div className="col-xs-7">
                  <input
                    name="email_0"
                    type="email"
                    placeholder="Email address"
                    value={props.email}
                    onChange={(event) => props.updateReferAttribute({ key: 'email', value: event.target.value })}
                    onKeyPress={(e) => {catchReturn(e, props)}}
                  />
                </div>
              </div>
              <button
                id="refer-btn"
                className={`btn btn-primary primary-button ${!canSubmit(props) ? 'disabled' : ''}`}
                onClick={(e) => {preventClick(e, props)}}
              >
                Send invite to your friend!
              </button>
            </div>
            <div className="col-md-6" id="refer-link-col">
              <h2>Share referral link:</h2>
              <h4>Prefer to share over Facebook, Twitter or elsewhere? Here's your unique referral URL that you can share anywhere!</h4>
              <div className="refer-link" onClick={() => { props.copyReferralLinkToClipboard() }}>
                https://www.earfleek.com/refer/{props.customer.earfleek_referral_code}
              </div>
              <div className="refer-social-links">
                <iframe src={`https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.earfleek.com%2Frefer%2F${props.customer.earfleek_referral_code}&layout=button&size=large&width=73&height=28&appId`} width="73" height="28" style={{border: 'none', overflow: 'hidden', width: 80}} scrolling="no" frameBorder="0"></iframe>

                <a href={`https://twitter.com/intent/tweet?text=Check%20out%20EarFleek!%20It's%20a%20low-cost%20monthly%20subscription%20for%20Earrings%20delivered%20straight%20to%20your%20door!%20%23subscription%20%23earrings&amp;url=https%3A%2F%2Fwww.earfleek.com%2Frefer%2F${props.customer.earfleek_referral_code}`} id="refer-twt-share" className="refer-share-btn">
                  <img src={twitter} style={{height: 29}}/>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <h2>
              You've referred {countString} so far!
            </h2>
          </div>
          <table id="referrals-table">
            <thead className="referrals-table-header">
              {(props.customer.referral_invitations.length > 0) && <tr>
                <td>
                  Name
                </td>
                <td>
                  EMail
                </td>
                <td>
                  Signed Up?
                </td>
                <td>
                  Spent Money?
                </td>
                <td>
                  Referral Bonus Received?
                </td>
              </tr>}
            </thead>
            <tbody>
              {props.customer.referral_invitations.map((ri, idx) => {
                const customerBool = ri.referred_customer_id;
                const revenueBool = ri.revenue_threshold_met;
                const paidBool = ri.referral_paid_out;

                let paidLanguage = '';

                if (customerBool) {
                  paidLanguage = 'NO';
                  if (revenueBool) {
                    paidLanguage = 'NOT YET';
                    if (paidBool) {
                      paidLanguage = 'YES';
                    }
                  }
                }

                return(
                  <tr key={idx}>
                    <td>
                      {ri.name}&nbsp;
                    </td>
                    <td className="bold black">
                      {ri.referred_email}
                    </td>
                    <td className={customerBool ? 'green' : 'gray'}>
                      {customerBool ? 'YES' : 'NO'}
                    </td>
                    <td className={revenueBool ? 'green' : 'gray'}>
                      {revenueBool ? 'YES' : 'NO'}
                    </td>
                    <td className={paidBool ? 'green' : 'gray'}>
                      {paidLanguage}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </section>
    </section>
  )
};

export default Refer;
