const initialState = {
  //for time being, cannot have more than one subscription in cart
  subscriptions: JSON.parse(window.localStorage.getItem('EarfleekCartSubscriptions')) || [],
  // subscriptions: [
  //   {
  //     id: 20,
  //     description: "Month to Month Plan @ $9.93/mo",
  //     primary_style_type: "IDGAH",
  //     shipping_cadence: "Monthly",
  //     tier: "premiere",
  //     paid_months: 1,
  //     price_cents: 993,
  //     old_cratejoy_name: "i-dont-give-a-hoot-premiere",
  //   }
  // ],
  products: JSON.parse(window.localStorage.getItem('EarfleekCartProducts')) || [],
  // products: [
  //   {
  //     id: 528,
  //     retail_price_cents: 1499,
  //     title: "Starry Eyes - Black",
  //   }
  // ],
  coupon: {
    code: undefined,
    discountCents: undefined,
    description: undefined,
  },
  // coupon: {
  //   code: 'WO3NUP',
  //   discount_cents: 349,
  //   description: 'Get 50% off your first month',
  // },
  subtotalCents: 0,
  salesTaxCents: 0,
  taxRate: 0.0,
  // taxRate: 0.075,
  errorModalMessages: [],
  successfulOrders: [],
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
    case 'EARFLEEK:SUBSCRIPTION:ADD_TO_CART':
      try {
        fbq('track', 'AddToCart');
        rdt('track', 'AddToCart');
      } catch(err) {}
      const clonedSubscription = Object.assign({}, action.earfleekSubscriptionProduct);
      let clonedSubscriptions = [...state.subscriptions];
      clonedSubscriptions.push(clonedSubscription);
      window.localStorage.setItem('EarfleekCartSubscriptions', JSON.stringify(clonedSubscriptions));
      return { ...state, subscriptions: clonedSubscriptions };
    case 'EARFLEEK:PRODUCT:ADD_TO_CART':
      const cloneProduct = Object.assign({}, action.earfleekProduct);
      let clonedProducts = [...state.products];
      clonedProducts.push(cloneProduct);
      window.localStorage.setItem('EarfleekCartProducts', JSON.stringify(clonedProducts));
      return { ...state, products: clonedProducts };
    case 'EARFLEEK:PRODUCT:REMOVE_FROM_CART':
      if (action.productType === 'EarfleekProduct') {
        let clonedProducts2 = [...state.products];
        clonedProducts2 = clonedProducts2.filter((cp) => {
          return(cp.vendor_number !== action.productIdentifier);
        });
        window.localStorage.setItem('EarfleekCartProducts', JSON.stringify(clonedProducts2));
        return { ...state, products: clonedProducts2 };
      } else if (action.productType === 'EarfleekSubscription') {
        let clonedSubscriptions2 = [];

        let hit = false;
        [...state.subscriptions].forEach((subscription) => {
          if (!hit) {
            if (subscription.id === action.productIdentifier) {
              hit = true;
            } else {
              clonedSubscriptions2.push(subscription);
            }
          } else {
            clonedSubscriptions2.push(subscription);
          }
        });

        // clonedSubscriptions2 = clonedSubscriptions2.filter((clonedSubscription) => { clonedSubscription.id !== action.productID});
        window.localStorage.setItem('EarfleekCartSubscriptions', JSON.stringify(clonedSubscriptions2));
        return { ...state, subscriptions: clonedSubscriptions2 };
      }
    case 'EARFLEEK:COUPON:ADD_COUPON':
      return { ...state, coupon: { code: action.code } };
    case 'EARFLEEK:COUPON:REMOVE_COUPON':
      return { ...state, coupon: { code: undefined, discount_cents: undefined, description: undefined } };
    case 'API:CHECKOUT:SUBTOTAL:SUCCESS':
      // discount_cents: 175
      // grand_total_cents: 2491
      // sales_tax_cents: 174
      // subtotal_cents: 2492
      return {
        ...state,
        subtotalCents: response.subtotal_cents,
        salesTaxCents: response.sales_tax_cents,
        grandTotalCents: response.grand_total_cents,
        coupon: response.coupon,
      };
    case 'APP:CART:SET_ERROR_MESSAGES':
      return {
        ...state,
        errorModalMessages: action.errorModalMessages,
      };
    case 'APP:CHECKOUT:SET_SUCCESSFUL_ORDERS':
      return {
        ...state,
        successfulOrders: action.orders,
      };
    case 'API:CHECKOUT:TAX_RATE:SUCCESS':
      return {
        ...state,
        taxRate: response.tax_rate,
      };
    case 'CHECKOUT:SET_EXISTING_TAX_RATE':
      return {
        ...state,
        taxRate: parseFloat(action.data)
      };
    case 'API:CHECKOUT:CHECKOUT:SUCCESS':
      //remove stuff from cart here
      window.localStorage.setItem('EarfleekCartSubscriptions', JSON.stringify([]));
      window.localStorage.setItem('EarfleekCartProducts', JSON.stringify([]));

      const ordersStr = window.localStorage.getItem('SuccessfulEarfleekOrders') || '[]';
      let ordersObj = JSON.parse(ordersStr);

      ordersObj.push(response.successful_order);
      window.localStorage.setItem('SuccessfulEarfleekOrders', JSON.stringify(ordersObj));
      //notify 3rd parties of successful checkout HERE, not anywhere else

      try {
        const amt = (response.successful_order.total / 100).toFixed(2);
        fbq('track', 'Purchase', {currency: "USD", value: amt});
        rdt('track', 'Purchase');
        ga('send', 'event', 'conversion', {
          'send_to': 'AW-832362156/10cOCKjv69EBEKyt84wD',
          'value': amt,
          'currency': 'USD',
          'transaction_id': response.successful_order.id
        });
        EF.conversion({aid: 337});
      } catch(err) {}

      return {
        ...state,
        products: [],
        subscriptions: [],
        successfulOrders: ordersObj,
        coupon: {},
        subtotalCents: 0,
        salesTaxCents: 0,
        taxRate: 0.0,
      };
    case 'API:EARFLEEK_ORDER:GET:SUCCESS':
      return {
        ...state,
        successfulOrders: [response],
      };
    default:
      return state;
  }
}

export default reducer;
