import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/daily_deals/daily_deals.scss';
import Actions from '../../../../app/actions/consumer/actions.js';

import DailyDeals from './daily_deals.jsx';

class DailyDealsContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      inspectState: {},
      activePage: 'productsIndex', //confirmationScreen //successScreen
      selectedPaymentMethod: undefined,
      selectedAddress: undefined,
      isCheckingOut: false,
      tickerComplete: false,
    };

    this.addToCart = this.addToCart.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.selectAddress = this.selectAddress.bind(this);
    this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
    this.setInspectState = this.setInspectState.bind(this);
    this.setActivePage = this.setActivePage.bind(this);
    this.checkoutDeals = this.checkoutDeals.bind(this);
    this.removeSubscriptionFromCart = this.removeSubscriptionFromCart.bind(this);
    this.setTickerComplete = this.setTickerComplete.bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedAddress !== prevState.selectedAddress) {
      const action2 = Actions.checkout.setExistingSalesTaxRate(this.state.selectedAddress.sales_tax_rate);
      this.props.dispatch(action2);
    }
  }

  setTickerComplete() {
    this.setState({
      tickerComplete: true,
    });
  }

  checkoutDeals() {
    this.setState({
      isCheckingOut: true,
    }, () => {
      let data = {
        isGift: false,
        memberPricing: true,
        cart: this.props.cart,
        existingShippingAddressID: this.state.selectedAddress.id,
        existingPaymentMethodID: this.state.selectedPaymentMethod.id,
        shippingAddress: {},
        billingInfo: {},
        giftInfo: {},
      };

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'Score! You Got EarFleek Daily Deals!',
        }, 3000);
        this.props.dispatch(callbackAction);
        this.setState({
          isCheckingOut: false,
          activePage: 'successScreen',
        })

        // this.props.history.push('/customer/thank_you');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to check out these deals!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isCheckingOut: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Checking out deals... Please do not hit the back button or refresh the page...'});
      this.props.dispatch(warn);

      const action = Actions.checkout.checkout(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  addToCart(product) {
    const action = Actions.products.addEarfleekProductToCart(product);
    this.props.dispatch(action);
  }

  removeSubscriptionFromCart(product) {
    const action = Actions.products.removeEarfleekProductFromCart(product.id, 'EarfleekSubscription');
    this.props.dispatch(action);
  }

  removeFromCart(product) {
    const action = Actions.products.removeEarfleekProductFromCart(product.vendor_number, 'EarfleekProduct');
    this.props.dispatch(action);
  }

  selectAddress(id) {
    let address;
    this.props.addresses.map((a) => {
      if (a.id === id) {
        address = a;
      }
    })
    this.setState({
      selectedAddress: address,
    })
  }

  selectPaymentMethod(id) {
    let pm;
    this.props.paymentMethods.map((method) => {
      if (method.id === id) {
        pm = method;
      }
    })
    this.setState({
      selectedPaymentMethod: pm,
    })
  }

  setInspectState(vendorNumber, prop) {
    let dupedState = Object.assign({}, this.state.inspectState);
    dupedState[vendorNumber] = prop;
    this.setState({
      inspectState: dupedState,
    })
  }

  setActivePage(activePage) {
    this.setState({
      activePage: activePage,
    })
  }

  render() {
    return (
      <DailyDeals
        {...this.props}
        addToCart={this.addToCart}
        setInspectState={this.setInspectState}
        removeFromCart={this.removeFromCart}
        removeSubscriptionFromCart={this.removeSubscriptionFromCart}
        setActivePage={this.setActivePage}
        selectAddress={this.selectAddress}
        selectPaymentMethod={this.selectPaymentMethod}
        inspectState={this.state.inspectState}
        activePage={this.state.activePage}
        selectedPaymentMethod={this.state.selectedPaymentMethod}
        selectedAddress={this.state.selectedAddress}
        tickerComplete={this.state.tickerComplete}
        isCheckingOut={this.state.isCheckingOut}
        checkoutDeals={this.checkoutDeals}
        setTickerComplete={this.setTickerComplete}
      />
    );
  }
}

function mapStateToProps(state) {
  let cartProductVendorNumbers = [];
  state.earfleekCart.products.map((product) => {
    cartProductVendorNumbers.push(product.vendor_number);
  });

  return {
    customer: state.app.currentCustomer,
    addresses: state.addresses.addresses,
    paymentMethods: state.paymentMethods.paymentMethods,
    reduxTokenAuth: state.reduxTokenAuth,
    dailyDeals: state.products.dailyDeals,
    dailyDealsCutoff: state.products.dailyDealsCutoff,
    cart: state.earfleekCart,
    cartProducts: state.earfleekCart.products,
    subscriptions: state.earfleekCart.subscriptions,
    cartProductVendorNumbers: cartProductVendorNumbers,
  };
}

export default connect(mapStateToProps)(withRouter(DailyDealsContainer));
