import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Contact from './contact.jsx';

class ContactContainer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.title = 'EarFleek - Contact';
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Contact
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(ContactContainer));
