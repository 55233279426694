import { Link } from 'react-router-dom';
import PaymentMethodSelectorContainer from '../checkout/payment_method_selector_container.jsx';
import AddressSelectorContainer from '../checkout/address_selector_container.jsx';
import removeCartImage from 'assets/images/consumer/checkout/remove-from-cart.png';
import Countdown from 'react-countdown';

const DailyDeals = (props) => {
  if (!props.customer) {
    return (<div></div>)
  }

  let subtotalCents = 0;

  props.subscriptions.map((subscription) => {
    subtotalCents += subscription.price_cents;
  });

  props.cartProducts.map((product) => {
    subtotalCents += product.member_price_cents;
  });

  let salesTaxCents = 0;
  if (props.selectedAddress) {
    salesTaxCents = subtotalCents * props.selectedAddress.sales_tax_rate;
  }

  const totalCents = subtotalCents + salesTaxCents;

  if (props.tickerComplete) {
    return (
      <section id='daily-deals' className="row">
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 confirm-button-wrapper"
        >
          Dang! These deals have expired. Please refresh this page to see the new <span className="complete-span">EarFleek Daily Deals!</span>
        </div>
      </section>
    );
  }

  if (props.activePage === 'successScreen') {
    return (
      <section id='daily-deals' className="row">
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 confirm-button-wrapper"
        >
          Score! You checked out, and we sent a receipt to <span style={{fontWeight: 'bold'}}>{props.customer.email}</span>
        </div>
      </section>
    );
  }

  if (props.activePage === 'confirmationScreen') {
    return(
      <section id='daily-deals' className="row">
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 confirm-button-wrapper"
        >
          <button
            className="go-back-button btn btn-primary"
            onClick={() => { props.setActivePage('productsIndex') }}
          >
            {`< Go Back`}
          </button>
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 payment-method-area">
          <div>
            Select Payment Method:
          </div>
          <PaymentMethodSelectorContainer
            selectPaymentMethod={props.selectPaymentMethod}
            selectedPaymentMethod={props.selectedPaymentMethod}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 address-area">
          <div>
            Select Address:
          </div>
          <AddressSelectorContainer
            selectAddress={props.selectAddress}
            selectedAddress={props.selectedAddress}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 cart-detail">
          <div className="cart-header">
            Cart:
          </div>
          <table className="item_table">
            <tbody>
              {props.subscriptions.map((subscription, idx) => {
                return(
                  <tr key={idx}>
                    <RemoveFromCart subscription={subscription} rfc={props.removeSubscriptionFromCart}/>
                    <CartProductDescription subscription={subscription} />
                    <CartProductPrice subscription={subscription} />
                  </tr>
                );
              })}
              {props.cartProducts.map((product, idx) => {
                return(
                  <tr key={idx}>
                    <RemoveFromCart product={product} rfc={props.removeFromCart}/>
                    <CartProductDescription product={product} />
                    <CartProductPrice product={product} />
                  </tr>
                );
              })}
            </tbody>
          </table>
          {props.selectedAddress && <table className="price_table">
            <tbody>
            <tr id="shipping_row" className="detail-row">
              <td id="shipping_price_estimated_label"><strong>Shipping:</strong></td>
              <td className="currency" id="shipping_price">FREE</td>
            </tr>
            <tr id="sub_total_row" className="detail-row">
              <td><strong>Subtotal:</strong></td>
              <td className="currency" id="subtotal_price">${(subtotalCents / 100).toFixed(2)}</td>
            </tr>
            <tr id="sales_tax_row" className="detail-row">
              <td><strong>Sales Tax: {(props.selectedAddress.sales_tax_rate * 100).toFixed(2)}%</strong></td>
              <td className="currency" id="sales_tax_price">${(salesTaxCents / 100).toFixed(2)}</td>
            </tr>
            <tr id="total_row" className="total-row ">
              <td>Total</td>
              <td className="currency" id="total_price">${(totalCents / 100).toFixed(2)}</td>
            </tr>
            </tbody>
          </table>}
        </div>
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 confirm-button-wrapper"
        >
          {(props.cartProducts.length >= 1 || props.subscriptions.length >= 1) && props.selectedAddress && props.selectedPaymentMethod && <button
            className="confirm-button btn btn-primary final-button"
            onClick={() => { props.checkoutDeals() }}
          >
            {`Get Deals >`}
          </button>}
        </div>
      </section>
    )
  }

  if (props.activePage === 'productsIndex') {
    return (
      <section id='daily-deals' className="row">
        {props.dailyDealsCutoff &&
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="daily-deals-countdown"
          >
            Act fast - these deals expire in:
            <Countdown
              className='actual-ticker'
              date={props.dailyDealsCutoff}
              daysInHours={true}
              onComplete={() => { props.setTickerComplete()}}
            />
          </div>
        }
        {(props.cartProducts.length >= 1) && <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 confirm-button-wrapper"
        >
          <button
            className="confirm-button btn btn-primary"
            onClick={() => { props.setActivePage('confirmationScreen') }}
          >
            Confirm Deals >
          </button>
        </div>}
        {props.dailyDeals.map((product) => {
          let CTAClassName = "cart-button btn btn-primary";
          let buttonCTA = "Get Deal";
          let buttonAction = props.addToCart;

          if (props.cartProductVendorNumbers.indexOf(product.vendor_number) > -1) {
            CTAClassName = "cart-button btn btn-disabled";
            buttonCTA = "Remove Deal";
            buttonAction = props.removeFromCart;
          }

          let selectedImageProperty = 'catalog_photos';
          if (props.inspectState[product.vendor_number]) {
            selectedImageProperty = props.inspectState[product.vendor_number];
          }

          let hasModelPhotos = false;
          if ((product.showcase_photos && product.showcase_photos.length >= 1) || (product.model_photos && product.model_photos.length >= 1)) {
            hasModelPhotos = true;
          }

          const memberPriceString = `$${(product.member_price_cents / 100).toFixed(2)}`;
          const originalPriceString = `$${(product.retail_price_cents / 100).toFixed(2)}`;
          const discountString = (parseInt(((product.retail_price_cents - product.member_price_cents) / product.retail_price_cents) * 100));

          return(
            <div
              className="col-xs-6 col-sm-6 col-lg-4 col-xl-4 daily-deal-card"
              key={product.vendor_number}
            >
              <div className="images-wrapper">
                <img src={product[selectedImageProperty][0].image.medium.url} style={{left: 0, width: '100%', top: 0}} alt={product.title}/>
                <div className="selector-images">
                  {hasModelPhotos && ['catalog_photos', 'model_photos', 'showcase_photos'].map((prop) => {
                    if (prop === selectedImageProperty) {
                      return;
                    }
                    if (product[prop].length === 0) {
                      return;
                    }
                    return(
                      <div
                        className="selector-image"
                        key={`${product.vendor_number}-${prop}`}
                        onClick={() => { props.setInspectState(product.vendor_number, prop) }}
                      >
                        <img src={product[prop][0].image.thumb.url} style={{width: '100%', top}} alt={product.title}/>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="product-title">
                {product.title}
              </div>
              <div className="product-pricing">
                <div>
                  <span className="original-price">{originalPriceString}</span>&nbsp;
                  <span className="member-price">{memberPriceString}</span>&nbsp;
                  <span className="discount-explainer"> - {discountString}% off</span>
                </div>
              </div>
              <button
                className={CTAClassName}
                onClick={() => { buttonAction(product) }}
              >
                {buttonCTA}
              </button>
            </div>
          );
        })}
      </section>
    );
  }
};

const RemoveFromCart = (props) => {
  let id, productType;
  if (props.subscription) {
    id = props.subscription.id;
    productType = 'EarfleekSubscription';
  } else if (props.product) {
    id = props.product.vendor_number;
    productType = 'EarfleekProduct';
  }
  return(
    <td className="delete_column">
      <img
        src={removeCartImage}
        alt="Remove From Cart"
        onClick={() => { props.rfc((props.product || props.subscription), productType)}}
      />
    </td>
  )
};

const CartProductDescription = (props) => {
  let description, title;

  if (props.product) {
    title = props.product.title;
  } else if (props.subscription) {
    switch (props.subscription.primary_style_type) {
      case 'IDGAH':
        title = 'I Don\'t Give a Hoot!';
        break;
      case 'Silly & Fun':
        title = 'Silly & Fun';
        break;
      case 'Minimalist':
        title = 'Minimalist but Groovy';
        break;
      case 'Boho':
        title = 'Boho Babe';
        break;
      default:

    }
  }

  return(
    <td className="product_column">
      <div>
        <div className="product-name">
          {title}
        </div>
        {props.subscription && <div className="subscription-plan">
          {props.subscription.description}
        </div>}
        {props.subscription && <div className="subscription-autorenew">
          <strong>
            {props.subscription.paid_months} {props.subscription.paid_months === 1 ? 'delivery': 'deliveries'}<span className="auto-renew-cart">, auto-renewing</span>
          </strong>
        </div>}
      </div>
    </td>
  );
};

const CartProductPrice = (props) => {
  let priceString;
  if (props.product) {
    priceString = (props.product.member_price_cents / 100).toFixed(2);
  } else {
    priceString = (props.subscription.price_cents / 100).toFixed(2)
  }

  return(
    <td className="price_column currency">
      ${priceString}
    </td>
  );
};

export default DailyDeals;
