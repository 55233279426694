import { set as SET } from '../helpers';

const SET_PAGE = SET('PRODUCTS:SET_PAGE');

const getAvailableIvoryClaspProducts = (authToken) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'API:IVORY_CLASP_PRODUCT:GET_AVAILABLE_PRODUCTS',
      API: true,
      url: `/api/v1/ivory_clasp_products/available_products?order_auth_token=${authToken}`,
    });
  };
};

const getIvoryClaspProduct = (id) => {
  return {
    type: 'API:IVORY_CLASP_PRODUCT:GET',
    API: true,
    url: `/api/v1/ivory_clasp_products/${id}`,
  };
};

const fetchEarfleekProduct = (id) => {
  return {
    type: 'API:PRODUCT:GET',
    API: true,
    url: `/api/v1/products/${id}`,
  };
};

const fetchEarfleekProducts = (opts) => {
  return {
    type: 'API:PRODUCTS:GET',
    API: true,
    url: `/api/v1/products?product_type=${opts.product_type}&style_type=${opts.style_type}&page=${opts.page}&per_page=${opts.per_page}`,
  };
};

const fetchEarfleekTag = (id) => {
  return {
    type: 'API:TAG:GET',
    API: true,
    url: `/api/v1/tags/${id}`,
  };
};

const stopInspectingProduct = (id) => {
  return {
    type: 'PRODUCT:STOP_INSPECTING',
    API: false,
  };
};

const addEarfleekProductToCart = (earfleekProduct) => {
  return {
    type: 'EARFLEEK:PRODUCT:ADD_TO_CART',
    earfleekProduct: earfleekProduct,
    API: false,
  };
};

const removeEarfleekProductFromCart = (productIdentifier, productType) => {
  return {
    type: 'EARFLEEK:PRODUCT:REMOVE_FROM_CART',
    productIdentifier: productIdentifier,
    productType: productType,
    API: false,
  };
};

const removeCoupon = () => {
  return {
    type: 'EARFLEEK:COUPON:REMOVE_COUPON',
    API: false,
  };
};

const addCoupon = (code) => {
  return {
    type: 'EARFLEEK:COUPON:ADD_COUPON',
    API: false,
    code: code,
  };
};

const getAvailablePremiereProducts = (vendor, tier) => {
  return {
    type: 'API:EARFLEEK_PRODUCTS:AVAILABLE_PREMIERE',
    API: true,
    url: `/api/v1/available_premiere`,
  }
};

const fetchDailyDeals = () => {
  return (
    {
      type: 'API:EARFLEEK_PRODUCTS:DAILY_DEALS',
      API: true,
      url: '/api/v1/daily_deals',
    }
  );
};

export default {
  addEarfleekProductToCart,
  removeEarfleekProductFromCart,
  getAvailableIvoryClaspProducts,
  getAvailablePremiereProducts,
  getIvoryClaspProduct,
  fetchDailyDeals,
  fetchEarfleekProduct,
  fetchEarfleekProducts,
  fetchEarfleekTag,
  stopInspectingProduct,
  removeCoupon,
  addCoupon,
};
