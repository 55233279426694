import { Link } from 'react-router-dom';
import { USStatesHash } from '../../../../../app/lib/constants.js';

const Address = (props) => {
  const attrs = [
    ['To', 'to'],
    ['Street', 'street'],
    ['Unit', 'unit'],
    ['City', 'city'],
  ];

  const usStates = USStatesHash();
  return(
    <section id='new-address-content'>
      <section id="cmp_df96442d-5a85-416d-9e47-2c7cdf41dac6">
        <div className="account-header">
          <div className="container-fluid">
            <h2 className="text-center">
              {props.headerText}
            </h2>
          </div>
        </div>
        <div className="my-account">
          <div className="container-fluid">
            <div className="form-container">
              <div className="row content">
                <div className="col-lg-8 col-lg-offset-2 col-xs-12">
                  <div className="col-lg-8 col-lg-offset-2 col-xs-12" id="account-edit-view">
                    <div className="panel panel-cratejoy no-border no-shadow">
                      <table className="table address-info">
                        <tbody>
                          {attrs.map((arr) => {
                            const uglyAttr = arr[1];
                            const prettyAttr = arr[0];
                            return(
                              <tr key={uglyAttr}>
                                <td className="col-lg-4 info-label no-border">
                                  {prettyAttr}
                                </td>
                                <td className="col-lg-8 info-value no-border">
                                  <input
                                    className="form-control"
                                    placeholder={prettyAttr}
                                    required=""
                                    type="text"
                                    value={props.newAddress[uglyAttr]}
                                    onChange={(event) => props.updateAddressAttribute({ key: uglyAttr, value: event.target.value })}
                                    onKeyPress={(e) => {catchReturn(e, props)}}

                                  />
                                </td>
                              </tr>
                            )
                          })}
                          <tr>
                            <td className="col-lg-4 info-label no-border">
                              State
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <select
                                className="form-control state-selector"
                                value={props.newAddress.state}
                                onChange={(event) => props.updateAddressAttribute({ key: 'state', value: event.target.value })}
                              >
                                <option
                                  value=""
                                >
                                </option>
                                {Object.keys(usStates).map((keyName) => {
                                  return(
                                    <option
                                      value={keyName}
                                      key={keyName}
                                    >
                                      {usStates[keyName]}
                                    </option>
                                  )
                                })}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td className="col-lg-4 info-label no-border">
                              Zip Code
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <input
                                className="form-control"
                                placeholder={'Zip Code'}
                                required=""
                                type="text"
                                value={props.newAddress['zip_code']}
                                onChange={(event) => props.updateAddressAttribute({ key: 'zip_code', value: event.target.value })}
                                onKeyPress={(e) => {catchReturn(e, props)}}
                              />
                            </td>
                          </tr>
                          <tr
                            className="update-subscriptions"
                            onClick={() => { props.toggleUpdateSubscriptions() }}
                          >
                            <td className="col-lg-4 info-label no-border">
                              Update Subscriptions/Shipments
                            </td>
                            <td
                              className="col-lg-8 info-value no-border"
                            >
                              <input
                                id="update_subscriptions"
                                name="update_subscriptions"
                                type="checkbox"
                                checked={props.updateSubscriptions}
                                readOnly={true}
                              >
                              </input>
                              If selected, all subscriptions and unshipped shipments will be updated to use the new address.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="panel-footer no-border">
                        <div className="row">
                          <div className="col-lg-12 col-xs-12">
                            <div id="edit-address-footer">
                              <button
                                id="save-form"
                                className={`btn btn-primary pull-right ${!canSubmit(props) ? 'disabled' : ''}`}
                                onClick={(e) => {preventClick(e, props)}}
                              >
                                Save
                              </button>
                              <Link to="/customer/addresses" className="btn btn-primary pull-left">
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const canSubmit = (props) => {
  return props.formValid() && !props.isSubmittingForm;
};

const preventClick = (e, props) => {
  e.preventDefault();
  props.submitAddressForm(e);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.submitAddressForm(e);
  }
};

export default Address;
