import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/shipments/researching_shipment.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';

import ResearchingShipment from './researching_shipment.jsx';

class ResearchingShipmentContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmittingForm: false,
      selectedImageProperty: 'catalog_photos',
    };

    this.submitProductID = this.submitProductID.bind(this);
    this.setInspectState = this.setInspectState.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Premiere Self-Style';
    const action = Actions.customer.getSignedInCustomer();
    this.props.dispatch(action);
    this.getShipment(this.props.match.params.id);

    const action2 = Actions.products.fetchEarfleekProduct(this.props.match.params.vendor_number);
    this.props.dispatch(action2);
  }

  getShipment(id) {
    const action = Actions.shipment.getShipment({id: id});
    this.props.dispatch(action);
  }

  setInspectState(attribute) {
    this.setState({
      selectedImageProperty: attribute,
    })
  }

  submitProductID() {
    if (!confirm('Are you sure you wish to select this style? This action cannot be undone.')) {
      return;
    }
    if (this.state.isSubmittingForm) return;
    this.setState({
      isSubmittingForm: true,
    }, () => {
      let data = {
        id: this.props.shipment.id,
        vendor_number: this.props.product.vendor_number,
      };

      const onSuccess = function(dispatch, data) {
        this.setState({
          isSubmittingForm: false,
        });
        const success = Actions.app.setMessage({ type: 'SUCCESS', text: 'You successfully styled your shipment!'}, 3000);
        this.props.dispatch(success);
        this.props.history.push(`/customer/shipments/${this.props.shipment.id}`);
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'Oh no! There was an error and we were unable to style your shipment!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);

        this.setState({
          isSubmittingForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Styling your shipment...'});
      this.props.dispatch(warn);

      const action = Actions.shipment.selfStyle(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  render() {
    if (!this.props.customer) {
      return(<div></div>);
    } else {
      return (
        <ResearchingShipment
          {...this.props}
          submitProductID={this.submitProductID}
          selectedImageProperty={this.state.selectedImageProperty}
          setInspectState={this.setInspectState}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    shipment: state.shipments.shipment,
    earfleekProducts: state.products.earfleekProducts,
    product: state.products.product,
  };
}

export default connect(mapStateToProps)(withRouter(ResearchingShipmentContainer));
