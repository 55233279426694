import '@babel/polyfill'
import 'url-search-params-polyfill';
import '../../../assets/stylesheets/components/consumer/all.scss';
import { Provider } from 'react-redux';

import { withRouter, Router, Route, IndexRoute, IndexRedirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import ConsumerStore from '../../../app/store/consumer_store.js';
import OrderContainer from './orders/order_container.jsx';
import TinderGameContainer from './tinder_game/tinder_game_container.jsx';
import HeaderContainer from './header/header_container.jsx';
import HomeContainer from './home/home_container.jsx';
import FAQContainer from './home/faq_container.jsx';
import PremiereContainer from './home/premiere_container.jsx';
import BecomeContainer from './authentication/become_container.jsx';
import ContactContainer from './home/contact_container.jsx';
import CheckoutContainer from './checkout/checkout_container.jsx';
import ThankYouContainer from './checkout/thank_you_container.jsx';
import SubscribeContainer from './subscribe/subscribe_container.jsx';
import ReviewsContainer from './home/reviews_container.jsx';
import PricingChangeContainer from './home/pricing_change_container.jsx';
import PolicyContainer from './home/policy_container.jsx';
import ProductsContainer from './products/products_container.jsx';
import TagContainer from './products/tag_container.jsx';
import ProductContainer from './products/product_container.jsx';
import HowItWorksContainer from './home/how_it_works_container.jsx';
import MessageContainer from './header/message_container.jsx';
import FooterContainer from './footer/footer_container.jsx';

import LoginContainer from './authentication/login_container.jsx';
import NewPasswordContainer from './authentication/new_password_container.jsx';
import EditPasswordContainer from './authentication/edit_password_container.jsx';

import RTAC from './redux_token_auth_config';
import { generateRequireSignInWrapper } from 'redux-token-auth';

import AccountContainer from './customer/account_container.jsx';
import HistoryContainer from './customer/history_container.jsx';
import VotesContainer from './customer/votes_container.jsx';
import AddressesContainer from './customer/addresses/addresses_container.jsx';
import NewAddressContainer from './customer/addresses/new_address_container.jsx';
import EditAddressContainer from './customer/addresses/edit_address_container.jsx';
import EarfleekOrderContainer from './customer/earfleek_orders/earfleek_order_container.jsx';
import PaymentMethodsContainer from './customer/payment_methods/payment_methods_container.jsx';
import NewPaymentMethodContainer from './customer/payment_methods/new_payment_method_container.jsx';
import EditPaymentMethodContainer from './customer/payment_methods/edit_payment_method_container.jsx';
import ReferContainer from './customer/refer_container.jsx';
import CustomerEditContainer from './customer/edit_container.jsx';
import SubscriptionContainer from './customer/subscriptions/subscription_container.jsx';
import CancelContainer from './customer/subscriptions/cancel_container.jsx';
import BecomeQuarterlyContainer from './customer/subscriptions/become_quarterly_container.jsx';

import ShipmentContainer from './customer/shipments/shipment_container.jsx';
import ResearchingShipmentContainer from './customer/shipments/researching_shipment_container.jsx';

import ScrollToTop from './scroll_to_top.jsx';

import '../../../assets/stylesheets/vendor_styles.scss';

const removeDropdownHack = (e) => {
  if (e.target.className === 'dropdown-toggle nav-link') return;
  let el = document.getElementById('login-dropdown'); //TODO: put reference to this element on window
  if (el) {
    el.className = 'dropdown';
  }
};

class ConsumerApp extends React.Component {
  constructor() {
    super();
  }

  render () {
    RTAC.verifyCredentials(ConsumerStore);

    let browserHistory = createBrowserHistory();

    const requireSignIn = generateRequireSignInWrapper({
      redirectPathIfNotSignedIn: '/customer/login',
    });

    return (
      <Provider store={ConsumerStore}>
        <Router history={browserHistory}>
          <ScrollToTop />
          <div onClick={(e) => {removeDropdownHack(e)}}>
            <HeaderContainer useReactRouter={true} />
            <MessageContainer useReactRouter={true} />

            <Route exact path='/' component={HomeContainer} />
            <Route path='/checkout' component={CheckoutContainer} />
            <Route path='/contact' component={ContactContainer} />
            <Route path='/faq' component={FAQContainer} />
            <Route path='/premiere' component={PremiereContainer} />
            <Route path='/howitworks' component={HowItWorksContainer} />
            <Route path='/policy' component={PolicyContainer} />
            <Route path='/subscribe' component={SubscribeContainer} />
            <Route path='/three' component={SubscribeContainer} />

            <Switch>
              <Route exact path='/products' component={ProductsContainer} />
              <Switch>
                <Route exact path='/products/tags/:id' component={TagContainer} />
                <Route exact path='/products/:id' component={ProductContainer} />
              </Switch>
            </Switch>

            <Route path='/reviews' component={ReviewsContainer} />
            <Route path='/2021-pricing-change' component={PricingChangeContainer} />
            <Route path='/orders/:id' component={OrderContainer} />
            <Route path='/tg/:id' component={TinderGameContainer} />

            <Switch>
              <Route exact path='/customer/shipments/:id' component={requireSignIn(ShipmentContainer)} />
              <Route exact path='/customer/shipments/:id/research/:vendor_number' component={requireSignIn(ResearchingShipmentContainer)} />
            </Switch>

            <Route path='/customer/become' component={BecomeContainer} />

            <Route path='/customer/login' component={LoginContainer} />
            <Route path='/customer/password/new' component={NewPasswordContainer} />
            <Route path='/customer/password/edit' component={EditPasswordContainer} />

            <Route exact path='/customer/account' component={requireSignIn(AccountContainer)} />
            <Route exact path='/customer/votes' component={requireSignIn(VotesContainer)} />
            <Route exact path='/customer/history' component={requireSignIn(HistoryContainer)} />
            <Switch>
              <Route exact path='/customer/addresses' component={requireSignIn(AddressesContainer)} />
              <Route path='/customer/addresses/new' component={requireSignIn(NewAddressContainer)} />
              <Route path='/customer/addresses/:id' component={requireSignIn(EditAddressContainer)} />
            </Switch>
            <Route path='/customer/order/:id' component={requireSignIn(EarfleekOrderContainer)} />
            <Switch>
              <Route exact path='/customer/payment_methods' component={requireSignIn(PaymentMethodsContainer)} />
              <Route path='/customer/payment_methods/new' component={requireSignIn(NewPaymentMethodContainer)} />
              <Route path='/customer/payment_methods/:id' component={requireSignIn(EditPaymentMethodContainer)} />
            </Switch>
            <Route exact path='/customer/subscriptions/:id' component={requireSignIn(SubscriptionContainer)} />
            <Route exact path='/customer/subscriptions/:id/cancel' component={requireSignIn(CancelContainer)} />
            <Route exact path='/customer/subscriptions/:id/become_quarterly' component={requireSignIn(BecomeQuarterlyContainer)} />
            <Route exact path='/customer/refer' component={requireSignIn(ReferContainer)} />
            <Route exact path='/customer/edit' component={requireSignIn(CustomerEditContainer)} />
            <Route exact path='/customer/thank_you' component={ThankYouContainer} />
            <FooterContainer useReactRouter={true} />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default ConsumerApp;
