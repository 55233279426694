import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/subscribe/subscribe.scss';

import { stringSubscriptionPrice } from '../../../../app/lib/constants.js'

import sillyFunPic from 'assets/images/consumer/onboarding/type/silly.jpg'
import minimalistPic from 'assets/images/consumer/onboarding/type/minimalist.jpg'
import bohoPic from 'assets/images/consumer/onboarding/type/boho.jpg'
import idgahPic from 'assets/images/consumer/onboarding/type/idgah.jpg'

import Modal from 'react-modal';

const Subscribe = (props) => {
  return (
    <section id='subscribe-content'>
      {!props.primaryStyleType && <OnboardingStep1 {...props}/>}
      {props.primaryStyleType && <OnboardingStep2 {...props}/>}
      <SubscribeWizardProgressBar {...props}/>
    </section>
  );
};

const SubscribeWizardProgressBar = (props) => {
  let baseClassName = "btn btn-large";
  let btn1ClassName = baseClassName;
  let btn2ClassName = baseClassName;
  let btn3ClassName = baseClassName + ' disabled';

  let spacer1ClassName = 'flow-spacer icono-arrow2-left';

  let subscribeLink = '/subscribe';

  if (props.tier) {
    subscribeLink += `?tier=${props.tier}`;
  }

  if (props.primaryStyleType) {
    btn2ClassName += ' active';
    btn1ClassName += ' clickable';
    spacer1ClassName += ' clickable';
  } else {
    btn2ClassName += ' disabled';
    btn1ClassName += ' active';
  }

  return(
    <div id="subscribe-wizard-progress-bar" className={props.className}>
      <div className="flow-buttons">
        <Link to={subscribeLink}>
          <button className={btn1ClassName}>
            TYPE
          </button>
        </Link>
        <div className={spacer1ClassName}></div>
        <button className={btn2ClassName}>
          TERM
        </button>
        <div className="flow-spacer icono-arrow2-left second"></div>
        <button className={btn3ClassName}>
          CHECKOUT
        </button>
      </div>
    </div>
  );
};

const OnboardingStep1 = (props) => {
  return(
    <div>
      <section id="cmp_5ef424bd-2f58-4ef1-ab54-e30fd1fe1e34">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
<h3><strong>Pick your STYLE TYPE&nbsp;</strong></h3>
              We send you styles in one of these groupings:
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_da1eee17-709d-4f28-b401-2f50a05627df" className="component-subscription-product-list">
        <div className="container-fluid">
          <div className="row sf-grid">
            <div className="col-md-4 col-xs-12 sf-grid__container">
              <div className="sf-item" onClick={() => { props.setTypeAndAdvance('Silly & Fun', props.tier)}}>
                <div className="sf-item__content">
                  <img src={sillyFunPic} />
                  <h3 className="sf-item__name">Silly &amp; Fun</h3>
                  <p className="sf-item__description">
                    100% Nickel Free!
                    Receive (1) pair every month.
                  </p>
                </div>
                <div className="sf-item__footer">
                  <h3>
                    <p>{stringSubscriptionPrice(1, props.tier)}</p>
                  </h3>
                  <button className="btn btn-large btn-primary">
                    SELECT
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 sf-grid__container">
              <div className="sf-item" onClick={() => { props.setTypeAndAdvance('Minimalist', props.tier)}}>
                <div className="sf-item__content">
                  <img src={minimalistPic} />
                  <h3 className="sf-item__name">Minimalist but Groovy</h3>
                  <p className="sf-item__description">
                    100% Nickel Free!
                    Receive (1) pair every month.
                  </p>
                </div>
                <div className="sf-item__footer">
                  <h3>
                    <p>{stringSubscriptionPrice(1, props.tier)}</p>
                  </h3>
                  <button className="btn btn-large btn-primary">
                    SELECT
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 sf-grid__container">
              <div className="sf-item" onClick={() => { props.setTypeAndAdvance('Boho', props.tier)}}>
                <div className="sf-item__content">
                  <img src={bohoPic} />
                  <h3 className="sf-item__name">Boho Babe</h3>
                  <p className="sf-item__description">
                    100% Nickel Free!
                    Receive (1) pair every month.
                  </p>
                </div>
                <div className="sf-item__footer">
                  <h3>
                    <p>{stringSubscriptionPrice(1, props.tier)}</p>
                  </h3>
                  <button className="btn btn-large btn-primary">
                    SELECT
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12 sf-grid__container">
              <div className="sf-item" onClick={() => { props.setTypeAndAdvance('IDGAH', props.tier)}}>
                <div className="sf-item__content">
                  <img src={idgahPic} />
                  <h3 className="sf-item__name">I Don't Give A Hoot</h3>
                  <p className="sf-item__description">
                    A mix of all three if you can't decide on just one!
                    100% Nickel Free!
                    Receive (1) pair every month.
                  </p>
                </div>
                <div className="sf-item__footer">
                  <h3>
                    <p>{stringSubscriptionPrice(1, props.tier)}</p>
                  </h3>
                  <button className="btn btn-large btn-primary">
                    SELECT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const OnboardingStep2 = (props) => {

  if (props.subscriptionProducts.length === 0) {
    return (
      <div></div>
    )
  }

  // const displayedProducts = props.subscriptionProducts.filter(el => (el.tier === props.tier) && (el.primary_style_type === props.primaryStyleType) && (el.paid_months !== 3));
  const displayedProducts = props.subscriptionProducts.filter(el => (el.tier === props.tier) && (el.primary_style_type === props.primaryStyleType));

  // const siblingProducts = props.subscriptionProducts.filter(el => (el.tier !== props.tier) && (el.primary_style_type === props.primaryStyleType) && (el.paid_months !== 3));
  const siblingProducts = props.subscriptionProducts.filter(el => (el.tier !== props.tier) && (el.primary_style_type === props.primaryStyleType));

  const threeMonthSub = displayedProducts.filter(el => (el.paid_months === 3))[0];
  const sixMonthSub = displayedProducts.filter(el => (el.paid_months === 6))[0];
  const twelveMonthSub = displayedProducts.filter(el => (el.paid_months === 12))[0];
  const oneMonthSub = displayedProducts.filter(el => (el.paid_months === 1))[0];
  const threeMonthSibling = siblingProducts.filter(el => (el.paid_months === 3))[0];
  const sixMonthSibling = siblingProducts.filter(el => (el.paid_months === 6))[0];
  const twelveMonthSibling = siblingProducts.filter(el => (el.paid_months === 12))[0];
  const oneMonthSibling = siblingProducts.filter(el => (el.paid_months === 1))[0];

  //<SubscribeTermCard {...props} advertisedSubscription={oneMonthSub} siblingSubscription={oneMonthSibling}/>

  let monthlyActive = false;

  if (window.localStorage.getItem('monthlyShown') === 'true' || true) {
    monthlyActive = true;
  }

  return(
    <div>
      <section id="cmp_5ef424bd-2f58-4ef1-ab54-e30fd1fe1e34">
        <UpgradeModal {...props}/>
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
              <h3><strong>Pick your TERM&nbsp;</strong></h3>
              {monthlyActive && `Pay month-to-month, or pre-pay!`}
              {!monthlyActive && `Choose from 3, 6, and 12-month options!`}
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_da1eee17-709d-4f28-b401-2f50a05627df" className="component-subscription-product-list term-section">
        <div className="container-fluid">
          <div className="text-center">
            Questions? Visit our <a href="/faq">FAQ</a> or EMail <a href="mailto:support@earfleek.com?subject=Questions About Signing Up For EarFleek"><b>support@earfleek.com</b></a>
          </div>
          <div className="row sf-grid">
            <SubscribeTermCard {...props} advertisedSubscription={sixMonthSub} siblingSubscription={sixMonthSibling}/>
            <SubscribeTermCard {...props} advertisedSubscription={twelveMonthSub} siblingSubscription={twelveMonthSibling}/>
            {!monthlyActive && threeMonthSub && <SubscribeTermCard {...props} advertisedSubscription={threeMonthSub} siblingSubscription={threeMonthSibling}/>}
            {monthlyActive && <SubscribeTermCard {...props} advertisedSubscription={oneMonthSub} siblingSubscription={oneMonthSibling}/>}
          </div>
        </div>
      </section>
    </div>
  )
};

const SubscribeTermCard = (props) => {
  const impliedMonthlyPrice = (props.advertisedSubscription.price_cents / 100 / props.advertisedSubscription.paid_months).toFixed(2);

  const title = generateTitle(props.advertisedSubscription);

  let deltaWord = 'Upgrade';
  let tierMultiplier = 10;

  let upgradeDowngrade = props.openModal;

  const deltaCents = (props.advertisedSubscription.price_cents - props.siblingSubscription.price_cents) * -1;
  if (props.advertisedSubscription.tier === 'premiere') {
    deltaWord = 'Downgrade';
    tierMultiplier = 24;
    upgradeDowngrade = props.downgrade;
  }

  let deltaCurrencyString;
  if (deltaCents > 0) {
    deltaCurrencyString = `$${deltaCents / 100}`;
  } else {
    deltaCurrencyString = `-$${(deltaCents * -1) / 100}`;
  }

  const modalOpts = {
    modalAdvertisedSubscription: props.advertisedSubscription,
    modalAdvertisedSiblingSubscription: props.siblingSubscription,
  };

  return(
    <div className={`col-md-4 col-xs-12 sf-grid__container term-card months-${props.advertisedSubscription.paid_months}`}>
      <div className="sf-item term-sf-item">
        <div className="sf-item__content" onClick={() => {props.addSubscriptionToCart(props.advertisedSubscription)}}>
          <div className="best-value-banner">
            Best Value!
          </div>
          <h3 className="sf-item__name">{title}</h3>
          <h3 className="plan-price-header">
            <p className="plan-price">${impliedMonthlyPrice}/month</p>
          </h3>
          <p className="sf-item__description">
            MSRP: ${(props.advertisedSubscription.paid_months * tierMultiplier)}
          </p>
        </div>
        <div className="sf-item__footer">
          <button className="btn btn-large btn-primary" onClick={() => {props.addSubscriptionToCart(props.advertisedSubscription)}}>
            ${props.advertisedSubscription.price_cents / 100}
          </button>

          <a href="" onClick={(e) => { e.preventDefault(); upgradeDowngrade(modalOpts) }}>
            <div className="upgrade-cta">
              [{deltaWord}: {deltaCurrencyString}]
            </div>
          </a>
        </div>
      </div>
    </div>
  )
};

const UpgradeModal = (props) => {
  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      zIndex: 2,
    }
  };

  if (!props.modalAdvertisedSubscription) {
    return(<div></div>);
  }

  const diff = ((props.modalAdvertisedSiblingSubscription.price_cents - props.modalAdvertisedSubscription.price_cents) / 100).toFixed(2);

  let title = generateTitle(props.modalAdvertisedSiblingSubscription);
  const impliedMonthlyPrice = generateImpliedMonthlyPrice(props.modalAdvertisedSiblingSubscription);

  if (props.modalAdvertisedSiblingSubscription.paid_months > 1) {
    title = title.replace('Months', 'Month Prepay');
  };

  return(
    <Modal
      isOpen={props.upgradeModalOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onRequestClose={props.closeModal}
    >
      <div className="upgrade-header">
        <h2>
          Upgrade to Premiere for ${diff}?
        </h2>
        <h4>
          {title} @{impliedMonthlyPrice}/mo
        </h4>
      </div>
      <h6>
        Benefits of Premiere:
      </h6>
      <ul>
        <li>- **Pick Your Own Style**</li>
        <li>- $20.00++ MSRP</li>
        <li>- Ships in Box!</li>
        <li>- Broader Variety</li>
        <li>- Tracked Shipping</li>
      </ul>
      <button
        className="btn btn-secondary modal-button no"
        onClick={() => {props.closeModal()}}
      >
        <span className="ph no" />
      </button>
      <button
        className="btn btn-primary modal-button yes"
        onClick={() => {props.addSubscriptionToCart(props.modalAdvertisedSiblingSubscription)}}
      >
        <span className="ph yes" />
      </button>
    </Modal>
  );
};

const generateTitle = (advertisedSubscription) => {
  let title = `${advertisedSubscription.paid_months} Months`;

  if (advertisedSubscription.paid_months === 1) {
    title = 'Month to Month';
  }
  return title;
};

const generateImpliedMonthlyPrice = (advertisedSubscription) => {
  const price = `$${(advertisedSubscription.price_cents / advertisedSubscription.paid_months / 100).toFixed(2)}`;
  return price;
};

export default Subscribe;
