import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/products/products.scss';
import ReactPaginate from 'react-paginate';

const Products = (props) => {
  if (typeof props.products === 'undefined') {
    return(<div></div>);
  }

  return (
    <section id='products-content'>
      <section id="cmp_44cc9dc6-f54e-44d8-80f6-effe2e45dd3a" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
				      <span>
                <h1>EarFleek: Shop</h1>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_114473ec-03b3-421f-94b4-8d1949d756b1" className="component-shop-tags">
        <div className="container-fluid">
          <div className="row flex-center">
            <ul className="nav nav-pills">
              <li className="nav-item">
                <Link className={`nav-link ${props.product_type === 'earrings-regular' ? 'active' : ''}`} to={`/products?product_type=earrings-regular&style_type=${props.style_type}&page=1`}>Earrings - Regular</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${props.product_type === 'earrings-premiere' ? 'active' : ''}`} to={`/products?product_type=earrings-premiere&style_type=${props.style_type}&page=1`}>Earrings - Premiere</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${props.product_type === 'necklaces' ? 'active' : ''}`} to={`/products?product_type=necklaces&style_type=${props.style_type}&page=1`}>Necklaces</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${props.product_type === 'bracelets' ? 'active' : ''}`} to={`/products?product_type=bracelets&style_type=${props.style_type}&page=1`}>Bracelets</Link>
              </li>
            </ul>
          </div>
        </div>
        <br/>
        {(props.product_type === 'earrings-regular' || props.product_type === 'earrings-premiere') &&
          <div className="container-fluid">
            <div className="row flex-center">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <Link className={`nav-link ${['boho', 'silly-fun', 'minimalist'].indexOf(props.style_type) === -1 ? 'active' : ''}`} to={`/products?product_type=${props.product_type}&style_type=all&page=1`}>All</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${props.style_type === 'boho' ? 'active' : ''}`} to={`/products?product_type=${props.product_type}&style_type=boho&page=1`}>Boho</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${props.style_type === 'silly-fun' ? 'active' : ''}`} to={`/products?product_type=${props.product_type}&style_type=silly-fun&page=1`}>Silly &amp; Fun</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${props.style_type === 'minimalist' ? 'active' : ''}`} to={`/products?product_type=${props.product_type}&style_type=minimalist&page=1`}>Minimalist</Link>
                </li>
              </ul>
            </div>
          </div>
        }
      </section>
      <ProductsPagination
        {...props}
        topPagination={true}
      />
      <section id='cmp_504258a4-0415-4c99-a4e5-27c67f50a544' className='component-shop-listing'>
        <div className='container-fluid'>
          <div className='row flex-center'>
            {props.products.map((product, idx) => {
              return(<EarfleekProductThumbnail product={product} key={idx} idx={idx} customer={props.currentUser}/>);
            })}
          </div>
        </div>
      </section>
      <ProductsPagination
        {...props}
        topPagination={false}
      />
    </section>
  )
};

const ProductsPagination = (props) => {
  if (props.products.length === 0) {
    return (<div></div>);
  }

  let paginationLocation, paginationFunction;
  if (props.topPagination) {
    paginationLocation = 'topPaginationRef';
    paginationFunction = 'setTopPaginationRef';
  } else {
    paginationLocation = 'bottomPaginationRef';
    paginationFunction = 'setBottomPaginationRef';
  }

  return(
    <div className='row flex-center'>
      <ReactPaginate
        ref={(instance) => { (typeof props[paginationLocation] === 'undefined') && props[paginationFunction](instance); }}
        previousLabel={'Prev Page'}
        nextLabel={'Next Page'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={props.pages}
        initialPage={props.page - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        disableInitialCallback={true}
        onPageChange={(e) => {props.handlePageClick(e)}}
        containerClassName={'product_paging'}
        pageClassName={'product_page'}
        previousClassName={'prev_page'}
        nextClassName={'next_page'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  )
};

const EarfleekProductThumbnail = (props) => {
  const imgStyle = {
    backgroundImage: `url(${props.product.catalog_photos[0].image.medium.url})`,
  };

  let fakeImgStyle;
  if (props.idx % 2 === 0) {
    fakeImgStyle = {
      backgroundColor: 'blue',
    };
  } else {
    fakeImgStyle = {
      backgroundColor: 'red',
    };
  }

  const discountedPrice = props.product.member_price_cents / 100;
  const retailPrice = props.product.retail_price_cents / 100;

  let className = 'logged-out';

  if (props.customer.isSignedIn) {
    className = 'logged-in';
  }

  return(
    <div className='col-md-3 col-sm-4 col-xs-6'>
      <Link to={`/products/${props.product.slug}`} className='shop-listing-item'>
        <div className='col-xs-12 square-img-container'>
          <div style={imgStyle} className='shop-listing-item__image square-img-content'></div>
          {/*<div style={fakeImgStyle} className='shop-listing-item__image square-img-content'></div>*/}
        </div>
        <h3 className='shop-listing-item__name'>{props.product.title}</h3>
        <div className={`shop-listing-item__price ${className}`}>
          <div className={'retail-price'}>${(retailPrice).toFixed(2)}</div>
          <div className={'member-price'}>${(discountedPrice).toFixed(2)}</div>
        </div>
      </Link>
    </div>
  )
};

export default Products;
