import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Policy from './policy.jsx';

class PolicyContainer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    if (this.props.policy === '') {
      this.fetchPolicy();
    }
  }

  componentWillUnmount() {
  }

  fetchPolicy() {
    const action = Actions.policy.fetchPolicy();
    this.props.dispatch(action);
  }

  render() {
    return (
      <Policy
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    policy: state.policy.policy,
  };
}

export default connect(mapStateToProps)(withRouter(PolicyContainer));
