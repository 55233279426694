import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/orders/order_container.scss';
import PubSub from '../../../../app/pub_sub.js';
import { Collapse } from 'react-collapse';

const Order = (props) => {
  var dummy = (<div></div>);
  if (!props.products) return dummy;
  if (!props.products.page1) return dummy;
  if (props.products.page1.length === 0) return dummy;
  let slices = [[]];
  let dupedProducts = _.clone(props.products.page1);
  while (dupedProducts.length > 0) {
    if (slices[slices.length -1].length === 4) {
      slices.push([]);
    }
    slices[slices.length -1].push(dupedProducts.shift());
  }

  return (
    <section id='order-content' className='order-container'>
      <div id='products-grid' className='container'>
        <div id='explainer-area' className='col-sm-12 col-md-12 col-lg-12' onClick={() => toggleExplainer()}>
          <div className='explainer-heading'>
            <div className='actual-text'>
              ABOUT THIS PAGE
            </div>
            <div className={`toggler ${props.explainerOpen}`}>
              {'\u25b6'}
            </div>
          </div>
          <Collapse isOpened={props.explainerOpen}>
            <div className='explainer-body'>
              <p>
                This is Ivory Clasp's self-styling page! On this page you are able to see handbags that we currently have in stock, and choose your bag yourself!
              </p>
              <p>
                Once you pick your perfect bag, we will start the fulfillment process to send it to you.
              </p>
              <p>
                If you don't select a bag below, our expert in house styling team will match you with a bag according to your style quiz (just like always). Your bag must be chosen within 72 hours of receiving your notification email.
              </p>
            </div>
          </Collapse>
        </div>
        {_.map(slices, (slice, idx) => {
          var r = generateBigRow(slice, idx);
          return r;
        })}
      </div>
    </section>
  );
};

const generateBigRow = (slice, idx) => {
  if (slice.length === 0) return;
  let internalStuff;

  internalStuff = (
    <div className='placeholder-wrapper' key={idx}>
      {generateProductCell(slice[0])}
      {generateProductCell(slice[1])}
      {generateProductCell(slice[2])}
      {generateProductCell(slice[3])}
    </div>
  );

  return internalStuff;
};

const generateProductCell = (product) => {
  if (product === undefined) return (<div className='col-sub-div'></div>);

  let imagePreview;
  if (product.catalog_photos.length > 0) {
    const src = product.catalog_photos[0].image.medium.url;
    imagePreview = (
      <img src={src} className='preview-order-image' />
    );
  } else {
    imagePreview = (
      <div className='dummy-image'>
        NO IMAGE
      </div>
    );
  }

  return (
    <div className='col-sub-div col-lg-3 col-md-6 col-sm-6 col-xs-6' onClick={() => inspectProductID(product.id)}>
      {imagePreview}
      <div className='metadata-area'>
        <span className='brand'>{product.brand.name}</span><br />
        <span className='title'>{product.title}</span>
      </div>
    </div>
  );
};

const inspectProductID = (id) => {
  PubSub.publish('ORDER_INSPECT_POTENTIAL_PRODUCT', { id: id, open: true, newProductID: '' });
};

const toggleExplainer = () => {
  PubSub.publish('ORDER_TOGGLE_EXPLAINER');
};



export default Order;
