import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../../app/actions/consumer/actions.js';

import EarfleekOrder from './earfleek_order.jsx';

class EarfleekOrderContainer extends React.Component {
  constructor() {
    super();
    this.fetchEarfleekOrder = this.fetchEarfleekOrder.bind(this);
  }

  componentDidMount() {
    this.fetchEarfleekOrder();
  }

  fetchEarfleekOrder() {
    const action = Actions.earfleekOrders.fetchEarfleekOrder({id: this.props.match.params.id});
    this.props.dispatch(action);
  }

  render() {
    return(
      <EarfleekOrder
        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    earfleekOrder: state.earfleekCart.successfulOrders[0],
  };
}

export default connect(mapStateToProps)(withRouter(EarfleekOrderContainer));
