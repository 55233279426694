import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PubSub from '../../../../app/pub_sub.js';
// import '../../../../assets/stylesheets/components/admin/login/login_styles.scss';
import Actions from '../../../../app/actions/consumer/actions.js';

import Login from './login.jsx';

class LoginContainer extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      email: '',
      password: '',
    };

    this.updateLoginAttribute = this.updateLoginAttribute.bind(this);
    this.submitLoginForm = this.submitLoginForm.bind(this);
  }

  componentDidMount() {
    PubSub.subscribe('UPDATE_LOGIN_ATTRIBUTE', this.updateLoginAttribute);
    // special check to see if the menu is open
    if (document.querySelectorAll('#navbar-menu.navbar-toggleable-sm.collapse.in').length > 0) {
      // dismiss the expanded menu on mount of login component
      try {
        document.querySelectorAll('.navbar-toggler.hidden-md-up')[0].click();
      } catch(err) {
      }
    };
  }

  componentWillUnmount() {
    PubSub.unsubscribe('UPDATE_LOGIN_ATTRIBUTE', this.updateLoginAttribute);
  }

  updateLoginAttribute(opts) {
    this.setState({
      [opts.key]: opts.value,
    })
  }

  submitLoginForm(e) {
    e.preventDefault();
    const {
      email,
      password,
    } = this.state;

    let redirectUrl = '/customer/account';

    if (this.props.postLoginRedirectUrl) {
      redirectUrl = this.props.postLoginRedirectUrl;
    }

    const action = Actions.auth.signInUser({ email, password });
    this.props.dispatch(action).then(() => {
      this.props.history.push(redirectUrl);
    }).catch((err) => {
      const action2 = Actions.app.setMessage({
        type: 'ERROR',
        text: 'Your username or password are incorrect.',
      }, 4000);
      this.props.dispatch(action2);
      this.props.history.push('/customer/login');
    });
  }

  render() {
    return(
      <Login
        {...this.props}
        submitLoginForm={this.submitLoginForm}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    email: state.email,
    password: state.password,
    postLoginRedirectUrl: state.app.postLoginRedirectUrl,
  };
}

export default connect(mapStateToProps)(withRouter(LoginContainer));
