const initialState = {
  id: null,
  shipstation_state: null,
  ivory_clasp_product_id: null,
  subscription_id: null,
  created_at: null,
  auth_token: null,
};


function reducer(state = initialState, action) {
  switch(action.type) {
  case 'API:ORDER:GET:SUCCESS':
    return { ...action.response };
  case 'API:ORDER:UPDATE:SUCCESS':
    return { ...action.response };
  case 'API:ORDER:UPDATE:ERROR':
    return { ...state };
  default:
    return state;
  }
}

export default reducer;
