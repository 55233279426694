import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';
import PubSub from '../../../../app/pub_sub.js';

import Order from './order.jsx';
import ConfirmedOrder from './confirmed_order.jsx';
import InspectingProduct from './inspecting_product.jsx';

class OrderContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmittingProductID: false,
      explainerOpen: false,
    };

    this.inspectProduct = this.inspectProduct.bind(this);
    this.submitProductID = this.submitProductID.bind(this);
    this.stopInspectingProduct = this.stopInspectingProduct.bind(this);
    this.toggleExplainer = this.toggleExplainer.bind(this);
  }

  componentDidMount() {
    this.fetchOrder(this.props.match.params.id);
    this.getAvailableIvoryClaspProducts(this.props.match.params.id);
    PubSub.subscribe('ORDER_INSPECT_POTENTIAL_PRODUCT', this.inspectProduct);
    PubSub.subscribe('ORDER_SUBMIT_PRODUCT_ID', this.submitProductID);
    PubSub.subscribe('ORDER_STOP_INSPECTING_POTENTIAL_PRODUCT', this.stopInspectingProduct);
    PubSub.subscribe('ORDER_TOGGLE_EXPLAINER', this.toggleExplainer);
  }

  componentWillUnmount() {
    PubSub.unsubscribe('ORDER_INSPECT_POTENTIAL_PRODUCT', this.inspectProduct);
    PubSub.unsubscribe('ORDER_SUBMIT_PRODUCT_ID', this.submitProductID);
    PubSub.unsubscribe('ORDER_STOP_INSPECTING_POTENTIAL_PRODUCT', this.stopInspectingProduct);
    PubSub.unsubscribe('ORDER_TOGGLE_EXPLAINER', this.toggleExplainer);
  }

  toggleExplainer() {
    this.setState({ explainerOpen: !this.state.explainerOpen});
  }

  inspectProduct(opts) {
    const action = Actions.products.getIvoryClaspProduct(opts.id);
    this.props.dispatch(action);
  }

  submitProductID(opts) {
    if (this.state.isSubmittingProductID) return; //prevent duplicate submission
    this.setState({
      isSubmittingProductID: true,
    }, () => {
      const data = {
        id: this.props.order.id,
        ivory_clasp_product_id: opts.id,
      };

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'Woohoo! You styled your order!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.setState({
          isSubmittingProductID: false,
        })
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'Oh no! There was a problem styling this order!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Styling your order! Don\'t leave this page...'});
      this.props.dispatch(warn);

      const action = Actions.order.update(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
      this.stopInspectingProduct();
    });
  }

  stopInspectingProduct() {
    const action = Actions.products.stopInspectingProduct();
    this.props.dispatch(action);
  }

  fetchOrder(id) {
    const action = Actions.order.getOrder(id);
    this.props.dispatch(action);
  }

  getAvailableIvoryClaspProducts(authToken) {
    const action = Actions.products.getAvailableIvoryClaspProducts(authToken);
    this.props.dispatch(action);
  }

  render() {
    if (this.props.inspectingProduct) {
      return (
        <InspectingProduct
          {...this.props}
        />
      );
    } else if (this.props.order.ivory_clasp_product_id) {
      return (
        <ConfirmedOrder
          {...this.props}
        />
      );
    } else {
      return (
        <Order
          {...this.props} explainerOpen={this.state.explainerOpen}
        />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    product: state.products.product,
    inspectingProduct: state.products.inspectingProduct,
    products: state.products,
    order: state.order,
    confirmedProduct: state.order.product,
  };
}

export default connect(mapStateToProps)(withRouter(OrderContainer));
