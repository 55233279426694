import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/addresses/addresses.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';

import Addresses from './addresses.jsx';

class AddressesContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      isDeletingAddress: false,
    };

    this.deleteAddress = this.deleteAddress.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Addresses';
    this.fetchAddresses();
  }

  fetchAddresses() {
    const action = Actions.address.getAddresses();
    this.props.dispatch(action);
  }

  deleteAddress(e, id) {
    e.preventDefault();
    if (this.state.isDeletingAddress) return;

    this.setState({
      isDeletingAddress: true,
    }, () => {
      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You deleted this address!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.fetchAddresses();
        this.setState({
          isDeletingAddress: false,
        });
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to delete this address!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isDeletingAddress: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Deleting an address...'});
      this.props.dispatch(warn);

      const action = Actions.address.deleteAddress(
        {id: id},
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  render() {
    return(
      <Addresses
        {...this.props}
        deleteAddress={this.deleteAddress}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    addresses: state.addresses.addresses,
  };
}

export default connect(mapStateToProps)(withRouter(AddressesContainer));
