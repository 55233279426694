import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PubSub from '../../../../app/pub_sub.js';
// import '../../../../assets/stylesheets/components/admin/login/login_styles.scss';
import Actions from '../../../../app/actions/consumer/actions.js';

import EditPassword from './edit_password.jsx';

class EditPasswordContainer extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      email: '',
      password: '',
      password_confirmation: '',
      reset_password_token: '',
      uid: '',
      client: '',
      accessToken: '',
    };

    this.updateLoginAttribute = this.updateLoginAttribute.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  componentDidMount() {

    const url_string = window.location.href;
    const url = new URL(url_string);
    const reset_password_token = url.searchParams.get('token'),
      client = url.searchParams.get('client_id'),
      email = url.searchParams.get('uid'),
      accessToken = url.searchParams.get('access-token');
    this.setState({
      reset_password_token,
      email: email,
      client,
      accessToken,
    });

    PubSub.subscribe('UPDATE_LOGIN_ATTRIBUTE', this.updateLoginAttribute);
  }

  componentWillUnmount() {
    PubSub.unsubscribe('UPDATE_LOGIN_ATTRIBUTE', this.updateLoginAttribute);
  }

  updateLoginAttribute(opts) {
    this.setState({
      [opts.key]: opts.value,
    })
  }

  updatePassword(e) {
    e.preventDefault();

    const {
      reset_password_token,
      password,
      password_confirmation,
      client,
      accessToken,
      email,
    } = this.state;


    const onSuccess = (dispatch, data) => {
      const successMessage = Actions.app.setMessage({ type: 'SUCCESS', text: 'You successfully updated your password, please login'}, 5000);
      this.props.history.push(`/customer/login`);
      this.props.dispatch(successMessage);
    };

    const onError = Actions.app.setMessage({
        type: 'ERROR',
        text: 'You failed to update your password.' }
      , 4000);

    const action = Actions.auth.updatePassword({
      password,
      password_confirmation,
      reset_password_token,
      client,
      accessToken,
      email,
    }, onSuccess, onError);

    this.props.dispatch(action);
  }

  render() {
    return(
      <EditPassword
        {...this.props}
        password={this.state.password}
        password_confirmation={this.state.password_confirmation}
        reset_password_token={this.state.reset_password_token}
        updatePassword={this.updatePassword}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    authenticity_token: state.app.authenticityToken,
    password: state.password,
    password_confirmation: state.password_confirmation,
    reset_password_token: state.reset_password_token,
  };
}

export default connect(mapStateToProps)(withRouter(EditPasswordContainer));
