import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/addresses/addresses.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';
import { isValidZipCode } from '../../../../../app/lib/validators.js';
import { USStatesHash } from '../../../../../app/lib/constants.js'

import Address from './address.jsx';

class NewAddressContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      newAddress: {
        to: '',
        street: '',
        unit: '',
        city: '',
        zip_code: '',
        state: '',
      },
      isSubmittingForm: false,
      updateSubscriptions: false,
    };

    this.updateAddressAttribute = this.updateAddressAttribute.bind(this);
    this.submitAddressForm = this.submitAddressForm.bind(this);
    this.formValid = this.formValid.bind(this);
    this.toggleUpdateSubscriptions = this.toggleUpdateSubscriptions.bind(this);
  }

  updateAddressAttribute(opts) {
    let newNewAddress = Object.assign({}, this.state.newAddress);
    newNewAddress[opts.key] = opts.value;
    this.setState({
      newAddress: newNewAddress
    })
  }

  toggleUpdateSubscriptions() {
    this.setState({
      updateSubscriptions: !this.state.updateSubscriptions,
    })
  }

  formValid() {
    const newAddr = this.state.newAddress;
    const valid = isValidZipCode(newAddr.zip_code) &&
      newAddr.to.length >= 2 &&
      newAddr.street.length >= 2 &&
      newAddr.city.length >= 2 &&
      newAddr.state.length >= 2 &&
      Object.keys(USStatesHash()).indexOf(newAddr.state) >= 0;
    return (valid);
  }

  submitAddressForm(e) {
    e.preventDefault();
    if (this.state.isSubmittingForm) return;
    if (!this.formValid()) return;

    this.setState({
      isSubmittingForm: true,
    }, () => {
      const data = this.state.newAddress;
      data.updateSubscriptions = this.state.updateSubscriptions;

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You created an address!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/addresses');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to create an address!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isSubmittingForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Creating an address...'});
      this.props.dispatch(warn);

      const action = Actions.address.createAddress(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  render() {
    return(
      <Address
        {...this.props}
        newAddress={this.state.newAddress}
        isSubmittingForm={this.state.isSubmittingForm}
        updateSubscriptions={this.state.updateSubscriptions}
        headerText="Add a new address"
        updateAddressAttribute={this.updateAddressAttribute}
        toggleUpdateSubscriptions={this.toggleUpdateSubscriptions}
        submitAddressForm={this.submitAddressForm}
        formValid={this.formValid}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
  };
}

export default connect(mapStateToProps)(withRouter(NewAddressContainer));
