const initialState = {
  shipment: {
    earfleek_premiere_product: {}
  },
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
    case 'API:SHIPMENT:GET:SUCCESS':
      return { ...state, shipment: response };
    case 'API:SHIPMENT:SELF_STYLE:SUCCESS':
    case 'API:SHIPMENT:GET:SUCCESS':
      return { ...state, shipment: response };
    default:
      return state;
  }
}

export default reducer;
