import { withRouter, Link } from 'react-router-dom';
import PubSub from '../../../../app/pub_sub.js';
import '../../../../assets/stylesheets/components/consumer/authentication/authentication_styles.scss';

const updateLoginAttribute = (attribute, value) => {
  PubSub.publish('UPDATE_LOGIN_ATTRIBUTE', { key: attribute, value: value });
};

const preventClick = (e, props) => {
  e.preventDefault();
  props.submitLoginForm(e);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.submitLoginForm(e);
  }
};

const Login = (props) => {
  const { classes } = props;

  return (
    <div className="authentication-content">
      <section id="cmp_3fbfa7d5-4e0f-49e7-94c8-4b65a7b50c6c" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
				<span><h1>Account Login</h1>
<p>Manage your subscription &amp; other information regarding your account</p></span>
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_47f4f904-eaab-4b1a-aeaf-91eab977e649" className="component-login-form login-form">
        <div className="container-fluid">
          <div className="row">
            <div className="card col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1 text-center">

              <div className="card-block row">
                <div className="col-xs-12 form-container">
                  <p>
                    Don't have an account? <Link to="/subscribe">Subscribe!</Link>
                  </p>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Email address"
                      required=""
                      autoFocus={true}
                      value={props.email}
                      onChange={(event) => updateLoginAttribute('email', event.target.value)}
                      onKeyPress={(e) => {catchReturn(e, props)}}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      id="inputPassword"
                      name="password"
                      placeholder="Password"
                      required=""
                      value={props.password}
                      onChange={(event) => updateLoginAttribute('password', event.target.value)}
                      onKeyPress={(e) => {catchReturn(e, props)}}
                    />
                  </div>
                  <div className="row">
                    <Link to='/customer/password/new' className="btn btn-link col-md-6 col-xs-12">
                      Forgot Password?
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-primary col-md-6 col-xs-12"
                      onClick={(e) => {preventClick(e, props)}}
                    >
                      LOGIN
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
