const update = (data, success, error, complete) => {
  return {
    type: 'API:ORDER:UPDATE',
    API: true,
    method: 'PATCH',
    url: `/api/v1/orders/${data.id}/update`,
    data,
    success,
    error,
    complete,
  };
};

const getOrder = (id) => {
  return {
    type: 'API:ORDER:GET',
    API: true,
    url: `/api/v1/orders/${id}`,
  };
};

export default {
  update,
  getOrder,
};
