import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/contact/contact.scss';

const Contact = (props) => {
  return (
    <section id='contact-content'>
      <section id="cmp_2405f72d-a8b2-4510-bcc5-6b12dc8272aa" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center" id="blabla">
              <span><h1>Contact</h1></span>
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_e331e09d-98d5-4d73-b317-4db17f34ba51" className="component-rich-text-with-button">
        <div className="container-fluid">
          <div className="component-rich-text-with-button__content-row row">
            <div className="col-xs-12 text-center">
              <span>
                <h1>Customer Support</h1>
                <h3>support@earfleek.com</h3>
                <h1>Marketing &amp; Advertising</h1>
                <h3>support@earfleek.com</h3>
                <h1>Influencers / Box Reviewers / Brand Ambassadors</h1>
                <h3>support@earfleek.com</h3>
                <h1>Billing</h1>
                <h3>support@earfleek.com</h3>
                <h1>Technology</h1>
                <h3>support@earfleek.com</h3>
                <h3><br/></h3>
                <p><br/></p>
                <p><br/></p>
              </span>
            </div>
          </div>

          <div className="component-rich-text-with-button__button-row row">
            <div className="col-xs-12 text-center">
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

export default Contact;
