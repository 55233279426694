import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Message from './message.jsx';

class MessageContainer extends React.Component {
  constructor() {
    super();
    this.dismissMessage = this.dismissMessage.bind(this);
  }

  dismissMessage() {
    const action = Actions.app.clearMessage();
    this.props.dispatch(action);
  }

  render() {
    return <Message {...this.props} dismissMessage={this.dismissMessage}/>;
  }
}

function mapStateToProps(state) {
  return {
    message: state.app.message,
  };
}

export default connect(mapStateToProps)(withRouter(MessageContainer));
