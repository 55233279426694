const getVotes = () => {
  return {
    type: 'API:VOTES:GET',
    API: true,
    url: `/api/v1/votes`,
  };
};

export default {
  getVotes,
};
