import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/account/account.scss';
import Actions from '../../../../app/actions/consumer/actions.js';
import { randomizedSurveyResponses } from '../../../../app/lib/constants.js';

import Account from './account.jsx';

class AccountContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      dealsExpanded: true,
    };

    this.toggleShipmentNotifications = this.toggleShipmentNotifications.bind(this);
    this.surveyVote = this.surveyVote.bind(this);
    this.toggleDeals = this.toggleDeals.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Account';
    if (!this.props.customer) {
      const action = Actions.customer.getSignedInCustomer();
      this.props.dispatch(action);
    }
    const action = Actions.address.getAddresses();
    this.props.dispatch(action);
    const action2 = Actions.subscription.getSubscriptions();
    this.props.dispatch(action2);
    const action3 = Actions.paymentMethod.getPaymentMethods();
    this.props.dispatch(action3);
    //const action4 = Actions.survey.getSurveyOptions();
    //this.props.dispatch(action4);
    const action4 = Actions.products.fetchDailyDeals();
    this.props.dispatch(action4);
  }

  componentWillUnmount() {
  }

  toggleShipmentNotifications() {
    const action = Actions.customer.toggleShipmentNotifications({customer_id: this.props.customer.id});
    this.props.dispatch(action);
  }

  surveyVote(optionName) {
    const action = Actions.survey.surveyVote({response: optionName});
    this.props.dispatch(action);
  }

  toggleDeals() {
    this.setState({
      dealsExpanded: !this.state.dealsExpanded,
    })
  }

  render() {
    return (
      <Account
        {...this.props}
        toggleShipmentNotifications={this.toggleShipmentNotifications}
        surveyVote={this.surveyVote}
        dealsExpanded={this.state.dealsExpanded}
        toggleDeals={this.toggleDeals}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    addresses: state.addresses.addresses,
    paymentMethods: state.paymentMethods.paymentMethods,
    subscriptions: state.subscriptions.subscriptions,
    surveyOptions: state.surveyOptions.surveyOptions,
    currentChoice: state.surveyOptions.currentChoice,
    reduxTokenAuth: state.reduxTokenAuth,
    dailyDeals: state.products.dailyDeals,
  };
}

export default connect(mapStateToProps)(withRouter(AccountContainer));
