import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/products/product.scss';
import toCurrency from '../../../../app/lib/to_currency';
import Modal from 'react-modal';
import { Helmet } from "react-helmet";

const Product = (props) => {
  if (props.product.id === 0) {
    return(<div></div>);
  }

  if (!props.product.catalog_photos || props.product.catalog_photos.length === 0) {
    return(<div></div>);
  }

  const addToCart = props.addToCart;
  const removeFromCart = props.removeFromCart;

  let cartButton;

  if (props.product.effective_count > 3) {
    if (!alreadyInCart(props)) {
      cartButton = (<button className="btn btn-primary" onClick={addToCart}>ADD TO CART</button>);
    } else {
      cartButton = (<button className="btn btn-primary" onClick={removeFromCart}>REMOVE FROM CART</button>);
    }
  } else {
    cartButton = (<button className="btn btn-primary disabled">OUT OF STOCK</button>);
  }

  // const asdf = 2;

  let stockString = "http://schema.org/InStock";

  if (props.product.count <= 0) {
    let stockString = "http://schema.org/OutOfStock";
  }

  let aggObj;
  if (props.product) {
    aggObj = {
      "@type": "AggregateRating",
      ratingValue: props.product.aggregate_seo_rating || "5",
      bestRating: "5",
      ratingCount: props.product.total_votes_count || 1
    }
  } else {
    aggObj = {
      "@type": "AggregateRating",
      ratingValue: "5",
      bestRating: "5",
      ratingCount: props.product.id
    }
  }

  const productColors = [
    "black",
    "blue",
    "bronze",
    "brown",
    "dark blue",
    "gold",
    "gray",
    "green",
    "hot pink",
    "light blue",
    "multicolored",
    "orange",
    "pink",
    "purple",
    "rainbow",
    "red",
    "rose gold",
    "silver",
    "white",
    "yellow"
  ];

  let colorTags = props.product.tags
    .filter(tag => productColors.includes(tag.name.toLowerCase()))
    .map(tag => tag.name);

  const jsonLDobject = {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: `EarFleek - ${props.product.title}`,
    url: `https://www.earfleek.com/products/${props.product.slug}`,
    image: props.product.catalog_photos.map(photo => photo.image.giant.url),
    description: `${props.product.description}`,
    brand: {
      "@type": "Thing",
      name: "EarFleek"
    },
    color: colorTags,
    gtin: `EARFLEEK-${props.product.vendor_number}`,
    aggregateRating: aggObj,
    sku: props.product.vendor_number,
    audience: {
      "@type": "PeopleAudience",
      suggestedGender: 'female',
      suggestedMinAge: 13,
    },
    size: "OS",
    offers: [
      {
        "@type" : "Offer",
        availability: stockString,
        price: `${(props.product.retail_price_cents / 100).toFixed(2)}`,
        priceCurrency: "USD",
        url: `https://www.earfleek.com/products/${props.product.slug}`,
        priceValidUntil: "2026-12-31",
        itemOffered: {
          "@type": "Product",
          image: props.product.catalog_photos.map(photo => photo.image.giant.url),
          color: colorTags,
          name: `EarFleek - ${props.product.title}`,
          sku: props.product.vendor_number,
          url: `https://www.earfleek.com/products/${props.product.slug}`,
          gtin: `EARFLEEK-${props.product.vendor_number}`,
          aggregateRating: aggObj,
          description: `${props.product.description}`,
          size: "OS",
          brand: {
            "@type": "Thing",
            name: "EarFleek"
          }
        }
      }
    ]
  };

  let loggedInClassName = "pdp-logged-out";

  if (props.isSignedIn) {
    loggedInClassName = "pdp-logged-in";
  }

  return (
    <section id='product-content'>
      <UpsellModal {...props}/>
      <Helmet>
        <meta property="og:title" content={`EarFleek - ${props.product.title}`}/>
        <script
          type="application/ld+json"
        >
          {`${JSON.stringify(jsonLDobject)}`}
        </script>
      </Helmet>
      <section id="cmp_1966219e-ad13-412b-911c-1f11396d151c" className="component-shop-product-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 text-center">
              <h1>{props.product.title}</h1>
              <p><Link to="/products">All Products</Link></p>
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_b1721203-a6f1-4dbc-8019-4f8fbf06e6e5" className="component-shop-product">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="pika-stage" style={{minHeight: 0}}>
                <img src={props.product[props.activePhoto][0].image.giant.url} style={{left: 0, width: '100%', top: 0}} alt={props.product.title}/>
              </div>
              {props.canSelectImages() && <div className="container-fluid" id="image-selector-area">
                <div className="row">
                  {['catalog_photos', 'model_photos', 'showcase_photos'].map((property) => {
                    let cn = "col-xs-3 text-center image-selector";

                    if (props.activePhoto === property) {
                      cn += " selected";
                    }

                    let canShowImage = false;

                    try {
                      props.product[property][0].image.thumb.url;
                      canShowImage = true;
                    } catch(err) {
                      canShowImage = false;
                    }

                    if (!canShowImage) {
                      return(<div></div>);
                    }

                    return(
                      <div
                        className={cn}
                        key={property}
                        onClick={() => { props.setActivePhoto(property)}}
                      >
                        <img src={props.product[property][0].image.thumb.url} style={{left: 0, width: '100%', width: '100%', top: 0}} alt={props.product.title}/>
                      </div>
                    );
                  })}
                </div>
              </div>}
            </div>
            <div className="col-md-6 col-xs-12">
              {props.product.seo_optimized && <div id="extended-seo-area">
                <h5>
                  Description:
                </h5>
                <div>
                  {props.product.description}
                </div>
              </div>}
              {props.product.seo_optimized && <div id="tags-area">
                <div className="tag-heading">
                  Tags:
                </div>
                <div>
                  {props.product.tags.map(( tag, idx) => {
                    return(<TagLink tag={tag} key={idx}/>)
                  })}
                </div>
              </div>}
              <h3 className={`shop-product__price ${loggedInClassName} retail-price`}>Retail Price: ${(props.product.retail_price_cents / 100).toFixed(2)}</h3>
              <h3 className={`shop-product__price ${loggedInClassName} member-price`}>Member Price: ${(props.product.member_price_cents / 100).toFixed(2)}</h3>
              {cartButton}
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const TagLink = (props) => {
  return(
    <Link className="tag-link" to={`/products/tags/${props.tag.slug}`}>
      {props.tag.name}
    </Link>
  );
}

const UpsellModal = (props) => {
  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      minWidth: '260px',
      zIndex: 2,
    }
  };

  return(
    <Modal
      isOpen={props.upsellModalShown}
      contentLabel="Upsell Modal"
      ariaHideApp={false}
      style={customStyles}
      onRequestClose={props.closeUpsellModal}
    >
      <div className="upsell-modal">
        <h1 className="upsell-header">
          Love Earrings?
        </h1>
        <div className="upsell-body">
          <p>
            Subscribe to EarFleek using the code <span className="bold">PDP</span> and take <span className="bold">50% off</span> your first month!
          </p>
          <p>
            Treat yourself to the most affordable subscription ever!
          </p>
        </div>
        <button
          onClick={() => {props.closeUpsellModal()}}
          className="btn btn-secondary modal-button no"
        >
          No Thanks...
        </button>
        <Link
          to="/subscribe"
          className="btn btn-primary modal-button yes pull-right"
        >
          SUBSCRIBE!
        </Link>
      </div>
    </Modal>
  );
}

const alreadyInCart = (props) => {
  let flag = false;
  props.cartProducts.forEach((cartProduct) => {
    if (cartProduct.vendor_number === props.product.vendor_number) {
      flag = true;
    }
  });
  return flag;
};

export default Product;
