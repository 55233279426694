import '../../../../assets/stylesheets/components/consumer/footer/footer.scss';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  if (props.location.pathname.includes('/tg/') || props.location.pathname.includes('customer/shipments')) {
    return (
      <div>
      </div>
    );
  } else if (props.location.pathname.includes('/orders/')) {
    return (
      <footer id="footer" className="clearfix" data-ma-theme="blue">
        {'\u00A9'} Ivory Clasp {(new Date()).getFullYear()}
      </footer>
    );
  } else {
    if (isInSubscribeFlow(props)) {
      return(
        <div>
        </div>
      );
    }

    let footerSubscribePath = "/subscribe";

    if (props.location.pathname.indexOf('/premiere') > -1) {
      footerSubscribePath = '/subscribe?tier=premiere';
    }

    const footerLinkColumnClassName = "col-lg-4 col-md-4 col-xs-6 col-sm-6 footer-link-column";
    return (
      <footer id="cmp_3e2f9c70-319d-404f-a269-0d6d49409d81" className="component-footer footer">
        <div className="container-fluid"><center>
          <div className="row">
            <div className="footer-subscribe flex-center text-center col-md-12 col-sm-12 col-xs-12">
              <Link type="button" className="btn btn-large btn-primary" to={footerSubscribePath}>
                SUBSCRIBE TO EARFLEEK
              </Link>
            </div>
            <div className="footer-social flex-center text-center col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <a href="https://www.facebook.com/EarFleek">
                <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Subscribe-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Betterman-Subscribe-970" transform="translate(-930.000000, -1113.000000)" fill="#D7BEE5">
                      <g id="Desktop-HD-Footer" transform="translate(215.000000, 1078.000000)">
                        <g id="Icons" transform="translate(715.000000, 35.000000)" >
                          <g id="Facebook">
                            <path d="M15.09,29.4557143 C23.1411429,29.4557143 29.6682857,22.9294286 29.6682857,14.8774286 C29.6682857,6.82714286 23.1411429,0.3 15.09,0.3 C7.03885714,0.3 0.511714286,6.82714286 0.511714286,14.8774286 C0.511714286,22.9294286 7.03885714,29.4557143 15.09,29.4557143 Z M11.5731429,12.4088571 L13.08,12.4088571 L13.08,10.9448571 C13.08,10.2985714 13.0962857,9.30257143 13.566,8.68542857 C14.0597143,8.03228571 14.7385714,7.58828571 15.9051429,7.58828571 C17.8062857,7.58828571 18.6077143,7.85914286 18.6077143,7.85914286 L18.2305714,10.092 C18.2305714,10.092 17.6022857,9.91114286 17.0168571,9.91114286 C16.4305714,9.91114286 15.9051429,10.1211429 15.9051429,10.7065714 L15.9051429,12.4088571 L18.3085714,12.4088571 L18.1414286,14.5902857 L15.9051429,14.5902857 L15.9051429,22.1665714 L13.08,22.1665714 L13.08,14.5902857 L11.5731429,14.5902857 L11.5731429,12.4088571 Z" id="Fill-1"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <a href="https://www.instagram.com/EarFleek/">
                <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Subscribe-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Betterman-Subscribe-970" transform="translate(-1050.000000, -1113.000000)" fill="#D7BEE5">
                      <g id="Desktop-HD-Footer" transform="translate(215.000000, 1078.000000)">
                        <g id="Icons" transform="translate(715.000000, 35.000000)">
                          <g id="Instagram" transform="translate(120.000000, 0.000000)">
                            <path d="M15.2305714,29.4762857 C23.2825714,29.4762857 29.8088571,22.9482857 29.8088571,14.8971429 C29.8088571,6.84685714 23.2825714,0.319714286 15.2305714,0.319714286 C7.17857143,0.319714286 0.653142857,6.84685714 0.653142857,14.8971429 C0.653142857,22.9482857 7.17857143,29.4762857 15.2305714,29.4762857 Z M19.704,7.60885714 L10.7614286,7.60885714 C9.20742857,7.60885714 7.944,8.87228571 7.944,10.4262857 L7.944,19.3697143 C7.944,20.9237143 9.20742857,22.1871429 10.7614286,22.1871429 L19.704,22.1871429 C21.258,22.1871429 22.5214286,20.9237143 22.5214286,19.3697143 L22.5214286,10.4262857 C22.5214286,8.87228571 21.258,7.60885714 19.704,7.60885714 Z M20.5131429,9.28885714 L20.8354286,9.288 L20.8354286,11.7591429 L18.372,11.7668571 L18.3634286,9.29571429 L20.5131429,9.28885714 L20.5131429,9.28885714 Z M13.1528571,13.4014286 C13.6191429,12.7551429 14.3768571,12.3317143 15.2322857,12.3317143 C16.0894286,12.3317143 16.8462857,12.7551429 17.3125714,13.4014286 C17.6177143,13.8231429 17.7994286,14.3391429 17.7994286,14.898 C17.7994286,16.3122857 16.6474286,17.4634286 15.2322857,17.4634286 C13.818,17.4634286 12.6668571,16.3122857 12.6668571,14.898 C12.6668571,14.3391429 12.8485714,13.8231429 13.1528571,13.4014286 L13.1528571,13.4014286 Z M21.1011429,19.3697143 C21.1011429,20.1402857 20.4754286,20.766 19.704,20.766 L10.7614286,20.766 C9.99,20.766 9.36428571,20.1402857 9.36428571,19.3697143 L9.36428571,13.4014286 L11.5405714,13.4014286 C11.352,13.8642857 11.2465714,14.3691429 11.2465714,14.898 C11.2465714,17.0957143 13.0345714,18.8845714 15.2322857,18.8845714 C17.4308571,18.8845714 19.2188571,17.0957143 19.2188571,14.898 C19.2188571,14.3691429 19.1125714,13.8642857 18.9248571,13.4014286 L21.1011429,13.4014286 L21.1011429,19.3697143 L21.1011429,19.3697143 Z" id="Fill-1"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </div>
            <div className={footerLinkColumnClassName}>
              <ol>
                <li className="heading">Explore</li>
                <li><Link to="/products">See Styles</Link></li>
                <li><Link to="/premiere">Premiere Tier</Link></li>
                <li><Link to="/howitworks">How it Works</Link></li>
                <li><a href="https://www.instagram.com/explore/tags/earfleek/?hl=en">Past Boxes</a></li>
                <li><a href="https://www.youtube.com/results?search_query=earfleek+unboxing">Unboxing</a></li>
                <li><Link to="/reviews">Reviews</Link></li>
              </ol>
            </div>
            <div className={footerLinkColumnClassName}>
              <ol>
                <li className="heading">Company</li>
                <li><a href="https://blog.earfleek.com">Blog</a></li>
                <li><Link to="/policy">Policy</Link></li>
              </ol>
            </div>
            <div className={footerLinkColumnClassName}>
              <ol>
                <li className="heading">Contact</li>
                <li><a href="mailto:support@earfleek.com">Support</a></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
              </ol>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              © {new Date().getFullYear()} EarFleek. All Rights Reserved.
              <br />
                <br />
                  <span style={{color: '#fff', fontSize: 10}}>Marketing by <a href="http://www.avizolty.com" style={{color: '#fff', fontSize: 10}}>Avi</a>.</span>
            </div>
          </div>
        </center>
        </div>
      </footer>
    );
  }
};

const isInSubscribeFlow = (props) => {
  let outBool = false;

  switch (props.location.pathname) {
    case '/subscribe':
      outBool = true;
      break;
    case '/checkout':
      outBool = true;
      break;
    default:
      outBool = false;
  }
  return outBool;
};

export default Footer;
