import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';
import PubSub from '../../../../app/pub_sub.js';

import Home from './home.jsx';

class HomeContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      oneSecondPassed: false,
      twoSecondsPassed: false,
    }
  }

  componentDidMount() {
    document.title = 'EarFleek - Affordable Monthly Earring Subscription';

    window.setTimeout(() => {
      const script = document.createElement("script");

      script.src = 'https://assets.juicer.io/embed.js';
      script.async = true;

      document.body.appendChild(script);

      try {
        EF.click({offer_id:EF.urlParameter("oid"),affiliate_id:EF.urlParameter("affid"),sub1:EF.urlParameter("sub1"),sub2:EF.urlParameter("sub2"),sub3:EF.urlParameter("sub3"),sub4:EF.urlParameter("sub4"),sub5:EF.urlParameter("sub5"),uid:EF.urlParameter("uid"),transaction_id: EF.urlParameter('utm_term')});
      } catch(err) {}

      this.setState({
        oneSecondPassed: true
      })
    }, 1000);

    window.setTimeout(() => {
      if (window.location.pathname === "/") {
        this.setState({
          twoSecondsPassed: true
        });
        let s = document.createElement('script');
        s.async = true;
        s.src = 'https://cdn.listagram.com/static/api/listagram.js';
        document.body.appendChild(s);
        window.LISTAGRAM_CFG = {
          'token': 'deb1ca30-b4f9-49b3-8d9b-2d795089bd3e',
          'base_media': 'https://cdn.listagram.com/media/',
          'base_static': 'https://cdn.listagram.com/static/',
          'base_api': 'https://www.listagram.com/api/',
        };
        window.addEventListener("listagram:wheel:played", (e) => {
          localStorage.setItem('listagram-email', e.detail.email);
          localStorage.setItem('listagram-coupon', e.detail.winning_slice_value);
        });
      }
    }, 2000)
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Home
        {...this.props}
        oneSecondPassed={this.state.oneSecondPassed}
        twoSecondsPassed={this.state.twoSecondsPassed}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.reduxTokenAuth.currentUser,
  };
}

export default connect(mapStateToProps)(withRouter(HomeContainer));
