import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/subscriptions/subscriptions.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';

import Subscription from './subscription.jsx';

class SubscriptionContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      newSubscriptionProduct: {
        primary_style_type: '',
        paid_months: 0,
        tier: '',
        shipping_cadence: '',
        price_cents: 0,
      },
      newAddressID: 0,
      newAddress: {},
      newPaymentMethodID: 0,
      newPaymentMethod: {},
      isSubmittingForm: false,
      isSkipping: false,
      isReactivating: false,
      confirmationModalShown: false,
    };

    this.updateSubscriptionAttribute = this.updateSubscriptionAttribute.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.hideConfirmationModal = this.hideConfirmationModal.bind(this);
    this.submitSubscriptionChanges = this.submitSubscriptionChanges.bind(this);
    this.formValid = this.formValid.bind(this);
    this.skipToggle = this.skipToggle.bind(this);
    this.reactivateSubscription = this.reactivateSubscription.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Edit Subscription';
    this.getSubscription(this.props.match.params.id);
    if(this.props.addresses.length === 0) {
      const action = Actions.address.getAddresses();
      this.props.dispatch(action);
    }
    if(this.props.paymentMethods.length === 0) {
      const action = Actions.paymentMethod.getPaymentMethods();
      this.props.dispatch(action);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.subscription !== prevProps.subscription) {
      let npmID = 0;

      if (this.props.subscription && this.props.subscription.payment_method) {
        npmID = this.props.subscription.payment_method.id;
      }
      this.setState({
        newSubscriptionProduct: {
          primary_style_type: this.props.subscription.subscription_product.primary_style_type,
          paid_months: this.props.subscription.subscription_product.paid_months,
          tier: this.props.subscription.subscription_product.tier,
          shipping_cadence: this.props.subscription.subscription_product.shipping_cadence,
          price_cents: this.props.subscription.subscription_product.price_cents,
        },
        newAddressID: this.props.subscription.address.id,
        newPaymentMethodID: npmID,
      })
    }
  }

  getSubscription(id) {
    const action = Actions.subscription.getSubscription({id: id});
    this.props.dispatch(action);
  }

  skipToggle(e) {
    e.preventDefault();
    let confirmText;

    if (this.props.subscription.skipped_date) {
      const niceDate = moment(this.props.subscription.skipped_date).format("MMM Do, YYYY");
      confirmText = `Are you sure you wish to un-skip this subscription, and have it renew on ${niceDate}?`;
    } else {
      const niceDate = moment(this.props.subscription.end_date).format("MMM Do, YYYY");
      const futureDate = moment(this.props.subscription.end_date).add(1, 'M').format("MMM Do, YYYY");
      confirmText = `Are you sure you wish to skip this subscription, and move its renewal from ${niceDate} to ${futureDate}?`;
    }

    if (confirm(confirmText)) {
      if (this.state.isSkipping) return;
      this.setState({
        isSkipping: true,
      }, () => {
        let data = {};

        data.id = this.props.subscription.id;

        const onSuccess = function(dispatch, data) {
          const callbackAction = Actions.app.setMessage({
            type: 'SUCCESS',
            text: 'You updated this subscription!',
          }, 5000);
          this.props.dispatch(callbackAction);
          this.props.history.push('/customer/account');
        }.bind(this);

        const onError = function(dispatch, data) {
          let responseMsg;
          if (data && data.errors) {
            responseMsg = data.errors;
          } else {
            responseMsg = 'You failed to update this subscription!';
          }
          const callbackAction = Actions.app.setMessage({
            type: 'ERROR',
            text: responseMsg,
          }, 3000);
          this.setState({
            isSkipping: false,
          });

          this.props.dispatch(callbackAction);
        }.bind(this);


        const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Updating this subscription...'});
        this.props.dispatch(warn);

        const action = Actions.subscription.skipToggle(
          data,
          onSuccess,
          onError
        );

        this.props.dispatch(action);
      })
    }
  }

  updateSubscriptionAttribute(opts) {
    if (opts.key === 'newAddressID' || opts.key === 'newPaymentMethodID') {
      if (opts.key === 'newAddressID') {
        // let clonedAddresses = [...this.props.addresses];
        const newAddress = this.props.addresses.filter(obj => {
          return obj.id === parseInt(opts.value)
        })[0];
        this.setState({
          [opts.key]: parseInt(opts.value),
          newAddress: newAddress
        })
      } else if (opts.key === 'newPaymentMethodID') {
        // let clonedAddresses = [...this.props.addresses];
        const newPaymentMethod = this.props.paymentMethods.filter(obj => {
          return obj.id === parseInt(opts.value)
        })[0];
        this.setState({
          [opts.key]: parseInt(opts.value),
          newPaymentMethod: newPaymentMethod
        })
      }
    } else {
      let newNewSubscriptionProduct = Object.assign({}, this.state.newSubscriptionProduct);
      newNewSubscriptionProduct[opts.key] = opts.value;
      //special check - force paid_months to 1 if subscription becomes quarterly
      if (opts.value === 'Quarterly') {
        newNewSubscriptionProduct['paid_months'] = 1;
      }
      this.setState({
        newSubscriptionProduct: newNewSubscriptionProduct
      })
    }
  }

  formValid() {
    const newSubscriptionProduct = this.state.newSubscriptionProduct;

    //the new subscription MUST differ from the old subscription in at least one way
    const minOneDiff = this.state.newAddressID !== this.props.subscription.address_id ||
      (this.state.newPaymentMethodID !== this.props.subscription.payment_method_id && this.state.newPaymentMethodID !== 0) ||
      newSubscriptionProduct.primary_style_type !== this.props.subscription.subscription_product.primary_style_type ||
      newSubscriptionProduct.tier !== this.props.subscription.subscription_product.tier ||
      newSubscriptionProduct.shipping_cadence !== this.props.subscription.subscription_product.shipping_cadence ||
      newSubscriptionProduct.paid_months !== this.props.subscription.subscription_product.paid_months

    return (minOneDiff);
  }

  showConfirmationModal() {
    this.setState({
      confirmationModalShown: true,
    })
  }

  hideConfirmationModal() {
    this.setState({
      confirmationModalShown: false,
    })
  }

  submitSubscriptionChanges(e) {
    e.preventDefault();
    if (this.state.isSubmittingForm) return;
    if (!this.formValid()) return;

    this.setState({
      isSubmittingForm: true,
    }, () => {
      let data = this.state.newSubscriptionProduct;

      data.newAddressID = this.state.newAddressID;
      data.newPaymentMethodID = this.state.newPaymentMethodID;
      data.id = this.props.subscription.id;

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You updated this subscription!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/account');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to update this subscription!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isSubmittingForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Updating this subscription...'});
      this.props.dispatch(warn);

      const action = Actions.subscription.updateSubscription(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });

  }

  reactivateSubscription() {
    if (this.state.isReactivating) return;

    this.setState({
      isReactivating: true,
    }, () => {
      const data = {
        id: this.props.subscription.id,
      };

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You reactivated your subscription!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/account');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to reactivate this subscription!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isReactivating: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Reactivating this subscription...'});
      this.props.dispatch(warn);

      const action = Actions.subscription.reactivateSubscription(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  render() {
    return(
      <Subscription
        {...this.props}
        updateSubscriptionAttribute={this.updateSubscriptionAttribute}
        showConfirmationModal={this.showConfirmationModal}
        hideConfirmationModal={this.hideConfirmationModal}
        isSubmittingForm={this.state.isSubmittingForm}
        newSubscriptionProduct={this.state.newSubscriptionProduct}
        newAddressID={this.state.newAddressID}
        newAddress={this.state.newAddress}
        newPaymentMethodID={this.state.newPaymentMethodID}
        newPaymentMethod={this.state.newPaymentMethod}
        confirmationModalShown={this.state.confirmationModalShown}
        formValid={this.formValid}
        skipToggle={this.skipToggle}
        reactivateSubscription={this.reactivateSubscription}
        submitSubscriptionChanges={this.submitSubscriptionChanges}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    subscription: state.subscriptions.subscription,
    addresses: state.addresses.addresses,
    paymentMethods: state.paymentMethods.paymentMethods,
  };
}

export default connect(mapStateToProps)(withRouter(SubscriptionContainer));
