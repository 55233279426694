import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/checkout/thank_you.scss';
import EarfleekOrder from '../customer/earfleek_orders/earfleek_order.jsx';
import { Collapse } from 'react-collapse';
import DailyDealsContainer from '../products/daily_deals_container.jsx';

const ThankYou = (props) => {

  let voteToken;

  props.successfulOrders.map((so) => {
    const tempFlag = (so.humanized_object && so.humanized_object.humanized_subscriptions.length > 0 && props.reduxTokenAuth.currentUser.isSignedIn && so.humanized_object.tinder_game_auth_token && !so.humanized_object.is_gift);
    if (tempFlag) {
      voteToken = so.humanized_object.tinder_game_auth_token;
    }
  });

  let noun = "Order Receipt";
  if (props.successfulOrders.length > 1) {
    noun += 's'
  };

  let togglerClassName = 'toggler';

  if (props.dealsExpanded) {
    togglerClassName += " rotated";
  }

  return (
    <div id="thank-you-content">
      <section className="cmp_189a7a09-853d-4d04-8b54-6cec65670047">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="checkout_content">
                <h1 className="order_thanks">
                  Thank You!
                </h1>
                <p className="subheading">
                  You've successfully checked out!
                </p>
                {voteToken && <p className="subheading">
                  Customize what you want in future shipments! Start voting on our inventory <Link to={`/tg/${voteToken}`}>here</Link>!
                </p>
                }
                <h1 className="order_thanks receipt">
                  {noun}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row content">
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 deals-wrapper">
            <div className="panel panel-cratejoy">
              <div
                className="panel-heading deals-heading"
                onClick={() => { props.toggleDeals() }}
              >
                <h3 className="panel-title">EarFleek Daily Deals</h3>
                <div className={togglerClassName}>
                  {/*{'\u2715'}*/}
                  {/*{'\uFF1E'}*/}
                  &rsaquo;
                </div>
              </div>
              <Collapse isOpened={props.dealsExpanded}>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12">
                      <div className="daily-deals-container">
                        <DailyDealsContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          {props.successfulOrders.map((so) => {
            return(
              <EarfleekOrder
                {...props}
                iteratedEarfleekOrder={so}
                key={so.id}
              />
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
