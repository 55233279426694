const initialState = {
  earfleekProducts: [],
  proposedEarfleekProducts: [],
  customerCanPurchase: false,
  customer: {},
  addresses: [],
  paymentMethods: [],
};


function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
  case 'API:TINDER_GAMES:EARFLEEK_PRODUCTS:FETCH:SUCCESS':
    if (typeof response === 'undefined') {
      return {
        ...state
      }
    }
    return {
      ...state,
      earfleekProducts: response.earfleek_products,
      proposedEarfleekProducts: response.proposed_earfleek_products,
      customerCanPurchase: response.can_purchase_in_tinder_game,
    };
  case 'API:TINDER_GAMES:CUSTOMER:FETCH:SUCCESS':
    return {
      ...state,
      customer: response,
    };
  case 'API:TINDER_GAMES:ADDRESSES:FETCH:SUCCESS':
    return {
      ...state,
      addresses: response,
    };
  case 'API:TINDER_GAMES:PAYMENT_METHODS:FETCH:SUCCESS':
    return {
      ...state,
      paymentMethods: response,
    };
  default:
    return state;
  }
}

export default reducer;
