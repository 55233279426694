import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/orders/inspecting_product.scss';
import PubSub from '../../../../app/pub_sub.js';
import ProductInfoTable from './product_info_table.jsx';

const InspectingProduct = (props) => {
  var dummy = (<div></div>);
  if (!props.inspectingProduct) return dummy;

  let src;

  try {
    src = props.product.catalog_photos[0].image.giant.url;
  } catch (err) {
    src = '/no-image.jpg'
  }

  return (
    <section id='content' className='inspecting-product-container'>
      <div className='col-md-12 back-area'>
        <button
          id='back-button'
          className='btn btn-default'
          onClick={() => stopInspectingProduct()}
        >
          {'<'} GO BACK TO RESULTS
        </button>
      </div>
      <div id='main-product-image' className='col-md-6'>
        <img src={src} alt=''/>
      </div>
      <div id='product-info-area' className='col-md-6'>
        <ProductInfoTable product={props.product} />
        <button
          id='finalize-button'
          className='btn btn-default'
          onClick={() => submitProductID(props.product.id)}
        >
          SEND ME THIS BAG >
        </button>
      </div>
    </section>
  );
};

const stopInspectingProduct = () => {
  PubSub.publish('ORDER_STOP_INSPECTING_POTENTIAL_PRODUCT');
};

const submitProductID = (id) => {
  if (confirm("Are you sure you wish to select this bag? This action cannot be undone.")) {
    PubSub.publish('ORDER_SUBMIT_PRODUCT_ID', { id: id });
  }
};


export default InspectingProduct;
