// #  first_name         :string(255)
// #  last_name          :string(255)
// #  last_4             :string(255)
// #  card_type          :string(255)
// #  expiration_month   :integer
// #  expiration_year

const initialState = {
  paymentMethods: [],
  editingPaymentMethod: {
    customer_id: 0,
    first_name: '',
    last_name: '',
    last_4: '',
    card_type: 'unknown',
    expiration_month: '',
    expiration_year: '',
    zip_code: '00000'
  },
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
  case 'API:PAYMENT_METHODS:GET:SUCCESS':
    return { ...state, paymentMethods: response };
  case 'redux-token-auth/SIGNOUT_REQUEST_SUCCEEDED':
    return { ...state, paymentMethods: [] };
  case 'API:PAYMENT_METHOD:GET:SUCCESS':
    return { ...state, editingPaymentMethod: response };
  case 'API:CHECKOUT:CHECKOUT:SUCCESS':
    if (response.successful_payment_method) {
      let clonedPaymentMethods = [...state.paymentMethods];

      let alreadyInState = false;
      clonedPaymentMethods.map((pm) => {
        if (pm.id === response.successful_payment_method.id) {
          alreadyInState = true;
        }
      });

      if (!alreadyInState) {
        clonedPaymentMethods.push(response.successful_payment_method);
      }
      return { ...state, paymentMethods: clonedPaymentMethods };
    } else {
      return { ...state };
    }
  default:
    return state;
  }
}

export default reducer;
