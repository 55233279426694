const CONSTS = [];
const API_ACTIONS = ['STARTED', 'SUCCESS', 'ERROR', 'COMPLETE'];

const get = (constant) => {
  const foundConstant = _.find(CONSTS, (c) => c === constant);

  if (!foundConstant) throw `Constant ${constant} not found`;

  return foundConstant;
};

const getApi = (constant, apiAction) => {
  const foundConstant = get(`API:${constant}`),
        cleanApiAction = apiAction.toUpperCase(),
        foundApiAction = _.find(API_ACTIONS, (a) => a === cleanApiAction);
  if (!foundApiAction) throw `Api action ${apiAction} not found`;

  return `${foundConstant}:${foundApiAction}`;
};

const set = (constant) => {
  CONSTS.push(constant);
  return constant;
};

const setApi = (constant) => {
  const apiConstant = `API:${constant}`;
  CONSTS.push(apiConstant);
  return apiConstant;
};

if (typeof window !== 'undefined') window.CONSTS = CONSTS;

export {
  get,
  getApi,
  set,
  setApi,
};
