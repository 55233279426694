import { withRouter, Link } from 'react-router-dom';
import PubSub from '../../../../app/pub_sub.js';

const updateLoginAttribute = (attribute, value) => {
  PubSub.publish('UPDATE_LOGIN_ATTRIBUTE', { key: attribute, value: value });
};

const preventClick = (e, props) => {
  e.preventDefault();
  props.updatePassword(e);
};

const validPassword = (props) => {
  if (!props.password) return false;
  if (!props.password_confirmation) return false;
  return (props.password === props.password_confirmation && props.password.length >= 8);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    if (validPassword(props)) {
      props.updatePassword(e);
    }
  }
};

const EditPassword = (props) => {
  return(
    <div className="authentication-content">
      <section id="cmp_5fd14745-57b2-4908-ae9f-67046081192c" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
				<span><h1>Password Reset</h1>
<p>Reset your password here!</p></span>
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_4582e4d2-851c-4ae5-ba58-e4e7b8120799" className="component-password-reset-form form">
        <div className="container-fluid">
          <div className="row">

            <div className="card col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1">
              <div className="card-block">
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    className="form-control"
                    name="password"
                    type="password"
                    required="required"
                    placeholder="Minimum 8 characters"
                    value={props.password}
                    onChange={(event) => updateLoginAttribute('password', event.target.value)}
                    onKeyPress={(e) => {catchReturn(e, props)}}
                    autoFocus={true}
                  />
                </div>

                <div className="form-group">
                  <label>New Password Again</label>
                  <input
                    className="form-control"
                    name="password2"
                    type="password"
                    required="required"
                    placeholder="Repeat your password"
                    value={props.password_confirmation}
                    onChange={(event) => updateLoginAttribute('password_confirmation', event.target.value)}
                    onKeyPress={(e) => {catchReturn(e, props)}}
                  />
                </div>

                <div className="form-group text-center">
                  <button
                    id="submit"
                    name="submit"
                    className="btn btn-med btn-primary"
                    onClick={(e) => {preventClick(e, props)}}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default EditPassword;
