const getShipment = (data, success, error, complete) => {
  return {
    type: 'API:SHIPMENT:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/shipments/${data.id}`,
    success,
    error,
    complete,
  };
};

const selfStyle = (data, success, error, complete) => {
  return {
    type: 'API:SHIPMENT:SELF_STYLE',
    API: true,
    method: 'PATCH',
    url: `/api/v1/shipments/${data.id}/self_style`,
    data,
    success,
    error,
    complete,
  };
};

export default {
  getShipment,
  selfStyle,
};
