function USStatesHash() {
  return ({
    'AL': "Alabama",
    'AK': "Alaska",
    'AS': "American Samoa",
    'AA': "Armed Forces Americas",
    'AE': "Armed Forces Europe",
    'AP': "Armed Forces Pacific",
    'AR': "Arkansas",
    'AZ': "Arizona",
    'CA': "California",
    'CO': "Colorado",
    'CT': "Connecticut",
    'DE': "Delaware",
    'DC': "District of Columbia",
    'FM': "Federated States of Micronesia",
    'FL': "Florida",
    'GA': "Georgia",
    'GU': "Guam",
    'HI': "Hawaii",
    'ID': "Idaho",
    'IL': "Illinois",
    'IN': "Indiana",
    'IA': "Iowa",
    'KS': "Kansas",
    'KY': "Kentucky",
    'LA': "Louisiana",
    'ME': "Maine",
    'MH': "Marshall Islands",
    'MD': "Maryland",
    'MA': "Massachusetts",
    'MI': "Michigan",
    'MN': "Minnesota",
    'MS': "Mississippi",
    'MO': "Missouri",
    'MT': "Montana",
    'NE': "Nebraska",
    'NV': "Nevada",
    'NH': "New Hampshire",
    'NJ': "New Jersey",
    'NM': "New Mexico",
    'NY': "New York",
    'NC': "North Carolina",
    'ND': "North Dakota",
    'MP': "Northern Mariana Islands",
    'OH': "Ohio",
    'OK': "Oklahoma",
    'OR': "Oregon",
    'PW': "Palau",
    'PA': "Pennsylvania",
    'PR': "Puerto Rico",
    'RI': "Rhode Island",
    'SC': "South Carolina",
    'SD': "South Dakota",
    'TN': "Tennessee",
    'TX': "Texas",
    'UT': "Utah",
    'VT': "Vermont",
    'VI': "Virgin Islands",
    'VA': "Virginia",
    'WA': "Washington",
    'WV': "West Virginia",
    'WI': "Wisconsin",
    'WY': "Wyoming"
  });
};

function humanizedSubscriptionTypes() {
  return(
    [['Silly & Fun', 'Silly & Fun'], ['Minimalist', 'Minimalist but Groovy'], ['Boho', 'Boho Babe'], ['IDGAH', 'I Don\'t Give a Hoot']]
  );
};

function hardcodedTermPrices(tier) {
  switch(tier) {
    case 'regular':
      return(
        [
          {
            paid_months: 1,
            humanizedTerm: 'Month to Month',
            price_cents: 439,
          },
          {
            paid_months: 3,
            humanizedTerm: 'Three Month Prepay',
            price_cents: 1317,
          },
          {
            paid_months: 6,
            humanizedTerm: 'Six Month Prepay',
            price_cents: 2500,
          },
          {
            paid_months: 12,
            humanizedTerm: 'Twelve Month Prepay',
            price_cents: 4800,
          },
        ]
      );
    case 'premiere':
      return(
        [
          {
            paid_months: 1,
            humanizedTerm: 'Month to Month',
            price_cents: 1143,
          },
          {
            paid_months: 3,
            humanizedTerm: 'Three Month Prepay',
            price_cents: 3429,
          },
          {
            paid_months: 6,
            humanizedTerm: 'Six Month Prepay',
            price_cents: 6500,
          },
          {
            paid_months: 12,
            humanizedTerm: 'Twelve Month Prepay',
            price_cents: 11700,
          },
        ]
      );
    default:
      return [];
  }
}

function stringSubscriptionPrice(months, tier) {
  switch(months) {
    case 1:
      return `$${(hardcodedTermPrices(tier)[0]['price_cents'] / 100).toFixed(2)}`;
    case 3:
      return `$${(hardcodedTermPrices(tier)[1]['price_cents'] / 100).toFixed(2)}`;
    case 6:
      return `$${(hardcodedTermPrices(tier)[2]['price_cents'] / 100).toFixed(2)}`;
    case 12:
      return `$${(hardcodedTermPrices(tier)[3]['price_cents'] / 100).toFixed(2)}`;
    default:
      return `$${(hardcodedTermPrices(tier)[0]['price_cents'] / 100).toFixed(2)}`;
  }
}

function perShipmentStringSubscriptionPrice(months, tier) {
  switch(months) {
    case 1:
      return `$${(hardcodedTermPrices(tier)[0]['price_cents'] / 1 / 100).toFixed(2)}`;
    case 3:
      return `$${(hardcodedTermPrices(tier)[1]['price_cents'] / 3 / 100).toFixed(2)}`;
    case 6:
      return `$${(hardcodedTermPrices(tier)[2]['price_cents'] / 6 / 100).toFixed(2)}`;
    case 12:
      return `$${(hardcodedTermPrices(tier)[3]['price_cents'] / 12 / 100).toFixed(2)}`;
    default:
      return `$${(hardcodedTermPrices(tier)[0]['price_cents'] / 1 / 100).toFixed(2)}`;
  }
}

function creditCardMonthsArray() {
  return(
    [
      [ 1, '01', 'January'],
      [ 2, '02', 'February'],
      [ 3, '03', 'March'],
      [ 4, '04', 'April'],
      [ 5, '05', 'May'],
      [ 6, '06', 'June'],
      [ 7, '07', 'July'],
      [ 8, '08', 'August'],
      [ 9, '09', 'September'],
      [ 10, '10', 'October'],
      [ 11, '11', 'November'],
      [ 12, '12', 'December'],
    ]
  );
};

function creditCardYearsArray() {
  let constructedArray = [];

  let start = (new Date()).getFullYear();

  for (let i = start; i < start + 15; i++) {
    constructedArray.push(i);
  }

  return(constructedArray);
};

function isChristmasSeason() {
  let date = new Date();
  return date.getMonth() === 11 && (date.getDate() <= 15);
}

export {
  USStatesHash,
  humanizedSubscriptionTypes,
  hardcodedTermPrices,
  creditCardMonthsArray,
  creditCardYearsArray,
  isChristmasSeason,
  stringSubscriptionPrice,
  perShipmentStringSubscriptionPrice,
}
