import addresses from './addresses_reducer';
import app from './app_reducer';
import order from './order_reducer';
import history from './history_reducer';
import votes from './votes_reducer';
import tinderGame from './tinder_game_reducer';
import earfleekCart from './earfleek_cart_reducer';
import onboarding from './onboarding_reducer';
import paymentMethods from './payment_methods_reducer';
import products from './products_reducer';
import policy from './policy_reducer';
import shipments from './shipments_reducer';
import subscriptions from './subscriptions_reducer';
import surveyOptions from './survey_options_reducer';
import { reduxTokenAuthReducer } from 'redux-token-auth';

export default {
  reduxTokenAuth: reduxTokenAuthReducer,
  addresses,
  app,
  order,
  onboarding,
  earfleekCart,
  tinderGame,
  paymentMethods,
  policy,
  products,
  shipments,
  subscriptions,
  surveyOptions,
  history,
  votes,
};
