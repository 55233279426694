import '../../../../assets/stylesheets/components/consumer/tinder_game/tinder_game.scss';
import Cards from 'react-swipe-card';
import { Card } from 'react-swipe-card';
import { action } from '@storybook/addon-actions';

import Modal from 'react-modal';

const BuyNowModal = (props) => {
  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '80vw',
      zIndex: 2,
    }
  };

  return(
    <Modal
      isOpen={props.buyNowModalOpen}
      style={customStyles}
      contentLabel="Buy Now Modal"
      ariaHideApp={false}
      onRequestClose={props.closeBuyNowModal}
    >
      {props.viewingEarfleekProduct && <div id="buy-now-modal-content">
        <div className="container">
          <div className="row">
            <div className="product-photo col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img
                src={props.viewingEarfleekProduct.catalog_photos[0].image.medium.url} alt=""
                className="actual-product-image"
              />
              <div className="product-title">
                {props.viewingEarfleekProduct.title}
              </div>
            </div>
            <div className="metadata-selector col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <TinderSubtotal {...props}/>
              {props.addresses.length === 1 && <p className="selector-label">Ships to:</p>}
              {props.addresses.length === 1 && props.buyNowAddress && <OneLineAddress {...props}/>}

              {props.addresses.length > 1 && <p className="selector-label">Select Address:</p>}
              {props.addresses.length > 1 && props.buyNowAddress && <select
                className="form-control state-selector"
                value={props.buyNowAddress.id}
                onChange={(event) => props.updateBuyNowAddress(parseInt(event.target.value))}
              >
                {props.addresses.map((address) => {
                  const selectString = `${address.to}, ${address.street}, ${address.unit}, ${address.city}, ${address.state}`;
                  return(
                    <option
                      value={address.id}
                      key={address.id}
                    >
                      {selectString}
                    </option>
                  )
                })}
              </select>}

              {props.paymentMethods.length === 1 && <p className="selector-label">Bills to:</p>}
              {props.paymentMethods.length === 1 && props.buyNowPaymentMethod && <OneLinePaymentMethod {...props}/>}

              {props.paymentMethods.length > 1 && <p className="selector-label">Select Payment Method:</p>}
              {props.paymentMethods.length > 1 && props.buyNowPaymentMethod && <select
                className="form-control state-selector"
                value={props.buyNowPaymentMethod.id}
                onChange={(event) => props.updateBuyNowPaymentMethod(parseInt(event.target.value))}
              >
                {props.paymentMethods.map((paymentMethod) => {
                  const selectString = `${paymentMethod.card_type}, ****${paymentMethod.last_4}, expires ${paymentMethod.expiration_month} / ${paymentMethod.expiration_year}`;
                  return(
                    <option
                      value={paymentMethod.id}
                      key={paymentMethod.id}
                    >
                      {selectString}
                    </option>
                  )
                })}
              </select>}
              <div className="buttons-row">
                <button
                  className="btn btn-secondary cta"
                  onClick={() => {props.closeBuyNowModal()}}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary cta"
                  onClick={(e) => {props.buyNow(e)}}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </Modal>
  )
};

const TinderGame = (props) => {
  const data = ['Alexandre', 'Thomas', 'Lucien'];

  const CustomAlertLeft = () => <span className='disliked-alert'>Disliked</span>
  const CustomAlertRight = () => <span className='liked-alert'>Liked!</span>

  const earfleekProducts = props.earfleekProducts;
  const proposedEarfleekProducts = props.proposedEarfleekProducts;

  const factor = parseInt(props.voteTicker / props.magicAndroidInteger);
  const currentSlice = [factor * props.magicAndroidInteger, props.magicAndroidInteger * (factor + 1) + 4];

  let tempEarfleekProducts = [];

  earfleekProducts.map((item, idx) => {
    if (idx >= currentSlice[0] && idx < currentSlice[1]) {
      tempEarfleekProducts.push(item);
    }
  });

  let tempProposedEarfleekProducts = [];

  proposedEarfleekProducts.map((item, idx) => {
    if (idx >= currentSlice[0] && idx < currentSlice[1]) {
      tempProposedEarfleekProducts.push(item);
    }
  });

  let nextStep = 1;
  if (tempEarfleekProducts.length === 0) {
    nextStep = 2;
  }
  const cards0 = (
    <Cards
      onEnd={() => customOnEnd(props, nextStep)}
      className='master-root'
      alertRight={<CustomAlertRight />}
      alertLeft={<CustomAlertLeft />}
      ref={(instance) => { (typeof props.currentSwiperRef === 'undefined') && props.setCurrentSwiperRef(instance);}}
    >
      {['asdf'].map((item) => {
        //ref={(instance) => { (typeof props.cardRef === 'undefined') && props.setCardRef(instance); }}
        return(
          <Card
            onSwipeLeft={action('swipe left')}
            onSwipeRight={action('swipe right')}
            key={item}
            className="dummy"
            childRef={(instance) => {if (typeof props.cardRef === 'undefined') { props.setCardRef(instance); this.child = instance }}}
          >
            <div
              className='explainer'
            >
              <div className='bookend-heading'>
                Welcome to the <span className='corporate-title'>EarFleek</span> Inventory Voting Page!
              </div>
              <br/>
              <div className='bookend-body'>
                We use your responses to this survey to fulfill your upcoming shipments with us.
              </div>
              <div className='bookend-body end'>
                <span className='directions'>Directions:</span>
                <br/>Swipe Left or Right<span className='wide-explainer'>, or press one of the buttons below,</span> to begin!
                <br/>
                Right = liked, Left = disliked ;)
              </div>
            </div>
          </Card>
        )
      })}
    </Cards>
  );

  const cards1 = (
    <Cards
      onEnd={() => customOnEnd(props, 2)}
      className='master-root'
      alertRight={<CustomAlertRight />}
      alertLeft={<CustomAlertLeft />}
      ref={(instance) => { (typeof props.currentSwiperRef === 'undefined') && props.setCurrentSwiperRef(instance); }}
    >
      {tempEarfleekProducts.length !== 0 && tempEarfleekProducts.map((item, idx) => {
        const src = item.catalog_photos[0].image.giant.url;

        const refKey = `earfleek-product-id-${item.id}`;

        let tagStrings = [];
        item.tags.map((tag) => {
          tagStrings.push(tag.name);
        });

        let bodyType = '';
        try {
          if (tagStrings.indexOf('stud') > 0) {
            bodyType = 'Studs';
          } else if (tagStrings.indexOf('dangle') > 0) {
            bodyType = 'Dangles';
          } else if (tagStrings.indexOf('threader') > 0) {
            bodyType = 'Threaders';
          } else if (tagStrings.indexOf('hoop') > 0) {
            bodyType = 'Hoops';
          } else {
            bodyType = '';
          }
        } catch(err) { }

        return(
          <Card
            onSwipeLeft={() => {swipeLeft(props, item, 'EarfleekProduct')}}
            onSwipeRight={() => {swipeRight(props, item, 'EarfleekProduct')}}
            key={refKey}
          >
            <img
              src={src}
              alt=''
              className='img'
              data-future-src={src}
              data-type="EarfleekProduct"
              data-id={item.id}
            />
            <div className='body-type'>Body Type: {bodyType}</div>
            <div className='title-text'>{item.title}</div>
          </Card>
        );
      })}
    </Cards>
  );

  let nextNextStep = 3;
  if (proposedEarfleekProducts.length === 0) {
    nextNextStep = 4;
  }

  const cards2 = (
    <Cards
      onEnd={() => customOnEnd(props, nextNextStep)}
      className='master-root'
      alertRight={<CustomAlertRight />}
      alertLeft={<CustomAlertLeft />}
      ref={(instance) => { (typeof props.currentSwiperRef === 'undefined') && props.setCurrentSwiperRef(instance); }}
    >
      {['qwer'].map((item) => {
        return(
          <Card
            onSwipeLeft={action('swipe left')}
            onSwipeRight={action('swipe right')}
            key={item}
            className="dummy"
          >
            <div
              className='explainer'
            >
              <div className='bookend-heading'>
                You've voted on all <span className='corporate-title'>EarFleek</span> inventory!
              </div>
              <br/>
              <div className='bookend-body'>
                Keep playing to determine what styles we source in the future!
              </div>
            </div>
          </Card>
        )
      })}
    </Cards>
  );

  const cards3 = (
    <Cards
      onEnd={() => customOnEnd(props, 4)}
      className='master-root'
      alertRight={<CustomAlertRight />}
      alertLeft={<CustomAlertLeft />}
      ref={(instance) => { (typeof props.currentSwiperRef === 'undefined') && props.setCurrentSwiperRef(instance); }}
    >
      {tempProposedEarfleekProducts.length !== 0 && tempProposedEarfleekProducts.map((item, idx) => {
        const src = item.catalog_photos[0].image.giant.url;

        return(
          <Card
            onSwipeLeft={() => {swipeLeft(props, item, 'ProposedEarfleekProduct')}}
            onSwipeRight={() => {swipeRight(props, item, 'ProposedEarfleekProduct')}}
            key={`proposed-earfleek-product-id-${item.id}`}
          >
            <img
              src={src}
              alt=''
              className='img'
              data-future-src={src}
              data-type="ProposedEarfleekProduct"
              data-id={item.id}
            />
            <div className='title-text'>{item.title}</div>
          </Card>
        );
      })}
    </Cards>
  );

  const cards4 = (
    <Cards
      onEnd={action('end')}
      className='master-root'
      alertRight={<CustomAlertRight />}
      alertLeft={<CustomAlertLeft />}
      ref={(instance) => { (typeof props.currentSwiperRef === 'undefined') && props.setCurrentSwiperRef(instance); }}
    >
      {['asdf'].map((item) => {
        return(
          <Card
            onSwipeLeft={action('swipe left')}
            onSwipeRight={action('swipe right')}
            key={item}
            className="dummy"
          >
            <div
              id='end-slide'
              className='img'
            >
              <div className='bookend-heading'>
                You've voted on all the products you can vote on, for now! Thanks!
              </div>
              <br/>
                If you'd like to re-vote on our inventory, please click <a className='restart-link' href={`/tg/${props.match.params.id}?show_all=true`}>here</a>
            </div>
          </Card>
        )
      })}
    </Cards>
  );

  let buyButtonStyle;

  let retailPrice = '';
  let memberPrice = '';

  if (props.viewingEarfleekProduct && props.viewingEarfleekProduct.effective_count > 0 && props.viewingEarfleekProduct.member_price_cents > 0 && props.customer.can_purchase_in_tinder_game) {
    retailPrice = `$${(props.viewingEarfleekProduct.retail_price_cents / 100).toFixed(2)}`;
    memberPrice = `$${(props.viewingEarfleekProduct.member_price_cents / 100).toFixed(2)}`;
  } else {
    buyButtonStyle = {
      visibility: 'hidden'
    };
  }

  const tinderGameButtons = (
    <div id='buttons-area'>
      <div className='actual-buttons'>
        <div className='tinder-dislike tinder-button' onClick={() => { pseudoSwipe(props, 'Left') }}>
          <svg className='action-image' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
        </div>
        <div
          className='tinder-buy-now tinder-button'
          style={buyButtonStyle}
          onClick={() => { props.openBuyNowModal() }}
        >
          <div className="buy-text-area">
            <div className="cta">
              BUY NOW!
            </div>
            <div className="retail-price strikethrough">
              {retailPrice}
            </div>
            <div className="member-price">
              {memberPrice}
            </div>
          </div>
        </div>
        <div className='tinder-like tinder-button' onClick={() => { pseudoSwipe(props, 'Right') }}>
          <svg className='action-image' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
        </div>
      </div>
    </div>
  );

  return (
    <section id='tinder-game-content' className='tinder-game-container'>
      {(props.currentStep === 0) && cards0}
      {(props.currentStep === 1) && cards1}
      {(props.currentStep === 2) && cards2}
      {(props.currentStep === 3) && cards3}
      {(props.currentStep === 4) && cards4}
      {tinderGameButtons}
      <BuyNowModal {...props}/>
    </section>
  );
};

const pseudoSwipe = (props, direction) => {
  const nodes = document.querySelectorAll('.card img');

  const node = nodes[nodes.length - 1];
  //must do this first!
  props.currentSwiperRef.removeCard(direction, 0);

  if (node) {
    //log left swipe
    const id = node.getAttribute('data-id');
    const type = node.getAttribute('data-type');
    if (id && type) {
      const liked = (direction === 'Right');
      // props.logSwipe({type: type, id: id, liked: liked});
      let correctChild, compositeKey;

      if (type === 'EarfleekProduct') {
        compositeKey = `earfleek-product-id-${id}`
      } else if (type === 'ProposedEarfleekProduct') {
        compositeKey = `proposed-earfleek-product-id-${id}`
      }

      if (compositeKey) {
        props.currentSwiperRef.props.children.forEach((propChild) => {
          if (propChild.key === compositeKey) {
            correctChild = propChild;
          }
        });
      }
      // figure out the correct child
      if (correctChild) {
        const fnName = `onSwipe${direction}`;
        correctChild.props[fnName]();
      }
    }
  }
};

const swipeLeft = (props, item, type) => {
  props.logSwipe({type: type, id: item.id, liked: false});
  props.incrementVoteTicker();
  action('swipe left');
};

const swipeRight = (props, item, type) => {
  props.logSwipe({type: type, id: item.id, liked: true});
  props.incrementVoteTicker();
  action('swipe right');
};

const customOnEnd = (props, nextStep) => {
  props.setCurrentStep(nextStep);
  action('end');
};

const OneLinePaymentMethod = (props) => {
  if (!props.buyNowPaymentMethod) {
    return(
      <div>
        <div className=''></div>
      </div>
    );
  };
  return(
    <div>
      <div className={`creditcard-${props.buyNowPaymentMethod.card_type}`}></div>
      ****{props.buyNowPaymentMethod.last_4}, {props.buyNowPaymentMethod.expiration_month}/{props.buyNowPaymentMethod.expiration_year}
    </div>
  )
};

const TinderSubtotal = (props) => {
  let totalString;

  const taxRate = parseFloat(props.buyNowAddress.sales_tax_rate)
  if (taxRate > 0) {
    totalString = `Total (w/tax): ${(props.tinderSubtotalString())}; Shipping: FREE`
  } else {
    totalString = `Total: ${(props.tinderSubtotalString())}; Shipping: FREE`
  }
  return(
    <div className="subtotal-area">
      {totalString}
    </div>
  )
};

const OneLineAddress = (props) => {
  return(
    <div>
      {props.buyNowAddress.to}, {props.buyNowAddress.street} {props.buyNowAddress.unit}, {props.buyNowAddress.city}, {props.buyNowAddress.state} {props.buyNowAddress.zip_code}
    </div>
  )
};

export default TinderGame;
