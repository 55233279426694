import RTAC from '../../components/consumer/redux_token_auth_config'

const forgotPassword = (data, success, error, complete) => {
  return {
    type: 'redux-token-auth/FORGOT_PASSWORD',
    API: true,
    method: 'POST',
    url: '/api/v1/auth/password',
    data,
    success,
    error,
    complete,
  };
};

const updatePassword = (data, success, error, complete) => {
  return {
    type: 'redux-token-auth/UPDATE_PASSWORD',
    API: true,
    method: 'PUT',
    url: '/api/v1/auth/password',
    data,
    success,
    error,
    complete,
  };
};

export default {
  signInUser: RTAC.signInUser,
  signOutUser: RTAC.signOutUser,
  verifyCredentials: RTAC.verifyCredentials,
  verifyToken: RTAC.verifyToken,
  forgotPassword,
  updatePassword,
};
