import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/reviews/reviews.scss';

import MSA from 'assets/images/consumer/reviews/MSA.png'
import helloSubscription from 'assets/images/consumer/reviews/hello_subscription.png'
import instagram from 'assets/images/consumer/reviews/instagram.png'
import youTube from 'assets/images/consumer/reviews/youtube.png'
import bow from 'assets/images/consumer/reviews/bow.png'

const PricingChange = (props) => {

  const bowStyle = {
    backgroundImage: `url(${bow})`,
  };

  let lastBowStyle;

  if (window.innerWidth < 575) {
    lastBowStyle = {
      backgroundImage: `url(${bow})`,
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      paddingBottom: 60,
      paddingTop: 50,
      verticalAlign: 'middle',
    };
  }

  return (
    <section id="cmp_04ce2a8a-1645-45b9-aca9-e02f46884af8" className="component-rich-text-with-button">
      <div className="inner_page_banner text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner_banner_head pricing-changes">
                <h2>2021 Pricing Change</h2>
                <p>
                  We at EarFleek recently made the difficult decision to increase the price of our service. This amounts to a 12 to 15% increase, across all products. This is the ***first*** price increase in the 5-year history of our company. Over the past 5 years, we have released the following features that have greatly enhanced our service:
                </p>
                <ul>
                  <li>
                    Voting system to quasi-customize future shipments
                  </li>
                  <li>
                    New admin tools to speed fulfillment and reduce errors
                  </li>
                  <li>
                    Massively improved supply chain that allows customers to determine what styles are sourced
                  </li>
                  <li>
                    Features to prevent the sending of duplicates and "near" duplicates
                  </li>
                  <li>
                    Self-style for "Premiere" customers
                  </li>
                  <li>
                    Massive improvements to our Customer Service function
                  </li>
                </ul>
                <p>
                  On the cost side, here are the pressures we have faced in those five years:
                </p>
                <ul>
                  <li>
                    20%+ increase in postage rates from USPS
                  </li>
                  <li>
                    30%+ increases in worldwide raw input shipping rates
                  </li>
                  <li>
                    A blended ***200%*** increase in credit card processing fees from our payment gateway
                  </li>
                </ul>
                <p>
                  We hope that with this information in mind, you remain a loyal customer of EarFleek for many years to come. If you have any questions, please email support@earfleek.com for more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default PricingChange;
