import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/faq/faq.scss';

const FAQ = (props) => {
  const FAQpart1 = (
    <section id="cmp_b1778f3f-3ac2-42c7-b880-8911736d226c" className="component-rich-text-center">
      <div className="container-fluid">
        <div className="content row">
          <div className="col-xs-12 text-center">
    				<span>
              <h2>
                Frequently Asked Questions
              </h2>
              <h3>
                What is EarFleek?
              </h3>
              <p>
                EarFleek is an online subscription service that sends you a fun and trendy pair of earrings every month.&nbsp;
              </p>
              <h3>
                Why should I join EarFleek?
              </h3>
              <p>
                Earrings are the ultimate accessory for anyone serious about fashion. Whether it's teaming cute studs with everyday outfits or going big &amp; bold with drops for special occasions, EarFleek has it all! Fun and cool designs, why go elsewhere!
              </p>
              <h3>
                Does EarFleek have a rewards/referral program?
              </h3>
              <p>
                Yes! &nbsp;Our referral program allows subscribers to get rewarded when you share EarFleek to your friends. When you share EarFleek with your friends, you receive a special link and you will earn $1.97 off future renewals for each friend who signs up! &nbsp;Find your unique link by logging in and visiting <Link to="/customer/refer">this page</Link>.
              </p>
              <h3>
                Do I get to choose the earrings I want?
              </h3>
              <p>
                You select one of four personality subscription options and our stylists make sure to send you something you will love every month that reflects the personality of your choice. In addition, we have a feature that allows you to "swipe" through our new and trendy styles every renewal just in case we have style that really stands out or may want a style from another personality type!&nbsp;
              </p>
              <h3>
                What are the earrings made of?
              </h3>
              <p>
                We have many different types of earrings. Depending on the type we have mixes of: zinc, copper, platinum, plastic and titanium.&nbsp;
              </p>
              <h3>
                Do the earrings contain nickel?
              </h3>
              <p>
                We use Nickel Alert by Athena Allergy to test our earrings for nickel and we found no traces of nickel in any of our earrings.
              </p>
              <h3>
                Where are the earrings made?
              </h3>
              <p>
                We have a variety of earrings that are made in different places across the world! There is no one place that makes all of the earrings.
              </p>
              <h3>
                Where do I enter my referral/promo code?
              </h3>
              <p>
                You can enter your referral/promo code on the checkout page under coupon or gift code then hit apply.
              </p>
              <h3>
                <strong>How can I gift the subscription to someone?</strong>
              </h3>
              <p>
                On the checkout page you have the option to give as a gift. Be sure to check the box "This is a gift", and enter the recipient's name and email address! After checkout, they'll receive a generic message saying "someone has given you the gift of EarFleek."
              </p>
              <h3>
                When is my EarFleek membership renewal date?
              </h3>
              <p>
                EarFleek renews on approximately the 15th of every month.&nbsp;
              </p>
              <h3>
                When does EarFleek ship out for new subscribers?
              </h3>
              <p>
                EarFleek ships one batch mailing per month to keep costs low for all customers. If you sign up **after** our mid-month mailing goes out but before the first of the next month, you will receive two shipments in the subsequent mailing!
              </p>
              <h3>
                When does EarFleek ship out for renewals?
              </h3>
              <p>
                EarFleek ships out on approximately the 15th of every month for renewals, depending on the day of the week.
              </p>
              <h3>
                How long does it take to ship?
              </h3>
              <p>
                We ship one batch per month, on approximately the 15th of each month. After signup, you can view your log of shipments <Link to="/customer/history">here</Link>.
              </p>
              <h3>
                Do you ship outside of the U.S.?
              </h3>
              <p>
                We do not ship internationally at this time.
              </p>
              <h3>
                What is the return policy?
              </h3>
              <p>
                We review returns that are related to damages after having been sent sufficient proof of the damaged product. However, we do not accept returns over matters of taste.
              </p>
              <h3>
                Is it hard to cancel?
              </h3>
              <p>
                Not at all! Simply log in to your EarFleek account and &nbsp;you can skip a month or you may cancel your account in general.
              </p>
            </span>
          </div>
        </div>
      </div>
    </section>
  );

  const FAQpart2 = (
    <section id="cmp_3f245c7d-4024-4fd7-8eb5-6c9091d79d19" className="component-rich-text-with-button">
      <div className="container-fluid">
        <div className="component-rich-text-with-button__content-row row">
          <div className="col-xs-12 text-center">
            <span><h2>Are you ready to subscribe to EarFleek?</h2></span>
          </div>
        </div>
        <div className="component-rich-text-with-button__button-row row">
          <div className="col-xs-12 text-center">
            <Link className="btn btn-large btn-primary" to="/subscribe">
              Subscribe Here
            </Link>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <section id='faq-content'>
      {FAQpart1}
      {FAQpart2}
    </section>
  )
};

export default FAQ;
