import { Link } from 'react-router-dom';
import { humanizedSubscriptionTypes, hardcodedTermPrices } from '../../../../../app/lib/constants.js';

import Modal from 'react-modal';

const Cancel = (props) => {

  //['question-1', 'issue-box', 'product-box', 'other-box', 'final-box', 'email-confirm-box'];
  if (props.activePanel === undefined) {
    return <div></div>;
  }

  if (props.activePanel === 'FinalFinalBox') {
    return(
      <FinalFinalBox {...props}/>
    )
  }

  return(
    <section id='cancel-content'>
      <div id="big-cancel-modal">
        {props.activePanel === 'Question1' && <Question1 {...props}/>}
        {props.activePanel === 'IssueBox' && <IssueBox {...props}/>}
        {props.activePanel === 'ProductBox' && <ProductBox {...props}/>}
        {props.activePanel === 'OtherBox' && <OtherBox {...props}/>}
        {props.activePanel === 'FinalBox' && <FinalBox {...props}/>}
        {props.activePanel === 'QuarterlyBox' && <QuarterlyBox {...props}/>}
        {props.activePanel === 'EmailConfirmBox' && <EmailConfirmBox {...props}/>}
        {props.activePanel === 'Loading' && <Loading {...props}/>}
      </div>
    </section>
  )
};

const FinalFinalBox = (props) => {
  return(
    <section
      id="cmp_df96442d-5a85-416d-9e47-2c7cdf41dac6"
      className="component-customer-edit"
    >
      <div className="account-header">
        <div className="container-fluid">
          <h2 className="text-center">
            Update your information
          </h2>
        </div>
      </div>
      <div className="my-account">
        <div className="container-fluid">
          <div className="form-container">
            <div className="row content">
              <div className="col-lg-8 col-lg-offset-2 col-xs-12">
                <div className="col-lg-8 col-lg-offset-2 col-xs-12" id="account-edit-view">
                  <div className="panel panel-cratejoy">
                    <div className="panel-heading">
                      <h3 className="panel-title">Cancel Your Subscription</h3>
                    </div>
                    <div className="panel-body">
                      <div className="form-group">
                        <label className="control-label">Please tell us why you are leaving</label>
                        <select
                          id="form_cancel_reason"
                          name="reason"
                          className="form-control"
                          required="required"
                          onChange={(event) => { props.updateCancelReason({ key: 'newPaymentMethodID', value: event.target.value })}}
                        >
                          <option value="">Please select a reason</option>
                          {props.cancellationReasons.map((cr) => {
                            return(
                              <option value={cr[0]} key={cr[0]}>{cr[1]}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <textarea
                          name="note"
                          id="note"
                          placeholder="What could we have done better?"
                          required="required"
                          className="form-control"
                          onChange={(e) => { props.updateCancelText({value: e.target.value}) }}
                        />
                        <p className="cancel-reason-required">Please leave a cancellation reason</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <a href="/customer/account" className="btn btn-med btn-primary btn-primary pull-right">No, Don't cancel</a>
                      {props.formValid() && <button
                        className="true-cancel-button btn btn-med btn-primary btn-primary pull-left"
                        type="submit"
                        onClick={() => { props.cancelSubscription() }}
                      >
                        Yes, Please cancel
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

const Loading = (props) => {
  return(
    <div id="loading">
      Loading...
    </div>
  )
};

const FinalBox = (props) => {
  let exitText = 'Please click "Skip Renewal" option, and "Confirm" on the following page';

  return(
    <div id="final-box">
      <div className="question-title">
        <div className="part-1">
          You're paying ${((props.subscription.subscription_product.price_cents) / 100).toFixed(2)} per renewal and our price is subject to change. Cancelling may result in a higher price in the future.
        </div>
        <div className="part-2">
          Would you rather SKIP a month?
        </div>
        <div className="row content">
          <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.advanceWithRandomWait('FinalFinalBox') }}>
            <div className="question-button">
              <div className="main-text">No</div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.exitWithAlertText(exitText) }}>
            <div className="question-button">
              <div className="main-text">Yes</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const QuarterlyBox = (props) => {
  let exitText = 'Please click the "Monthly" option, and confirm on the following page';

  const isQuarterly = (props.subscription.subscription_product.shipping_cadence === 'Quarterly');

  let newTerm = 'Quarterly';
  let newQuestion = `Your current subscription sends you earrings _monthly_. Are you receiving earrings too frequently? We have a solution!`;
  if (isQuarterly) {
    newTerm = 'Monthly';
    newQuestion = `Your current subscription sends you earrings _quarterly_. Are you receiving earrings too infrequently? We have a solution!`;
  }

  return(
    <div id="final-box">
      <div className="question-title">
        <div className="part-1">
          {newQuestion}
        </div>
        <div className="part-2">
          Would you like to SWITCH to {newTerm}?
        </div>
        <div className="row content">
          <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.advanceWithRandomWait('Question1') }}>
            <div className="question-button">
              <div className="main-text">No</div>
            </div>
          </div>
          {isQuarterly && <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.exitWithAlertText(exitText) }}>
            <div className="question-button">
              <div className="main-text">Yes</div>
            </div>
          </div>}
          {!isQuarterly && <div className="col-lg-6 col-md-6 col-xs-12" >
            <Link
              className="main-text"
              to={`/customer/subscriptions/${props.subscription.id}/become_quarterly`}
            >
              <div className="question-button">
                <div className="main-text">
                  Yes
                </div>
              </div>
            </Link>
          </div>}
        </div>
      </div>
    </div>
  )
};

const OtherBox = (props) => {
  let headerText = 'We have a newly launched PREMIERE subscription that offers higher quality product.';

  if (props.subscription.subscription_product.tier === 'premiere') {
    headerText = 'We have a lower cost REGULAR TIER subscription.';
  }

  return(
    <div id="other-box">
      <div className="question-title">
        <div className="part-1">
          {headerText}
        </div>
        <div className="part-2">
          Do you want to cancel instead of giving that a try?
        </div>
        <div className="row content">
          <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.advanceWithRandomWait('FinalBox') }}>
            <div className="question-button">
              <div className="main-text">Yes</div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.exitWithAlertText('Please change the "Subscription Tier" option, and confirm on the following page') }}>
            <div className="question-button">
              <div className="main-text">No</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IssueBox = (props) => {
  return(
    <div id="issue-box">
      <div className="question-title">
        <div className="part-1">
          Yuck! No one wants to receive the same product more than once. Or worse... something damaged!
        </div>
        <div className="part-2">
          We're a new company still trying to work out the kinks. Would you like a <span className="emphasized">customer service rep to reach out </span>to you in the next 24 hours?
        </div>
        <div className="row content">
          <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.createRetention() }}>
            <div className="question-button">
              <div className="main-text">Yes</div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-12" onClick={() => { props.advanceWithRandomWait('OtherBox')}}>
            <div className="question-button">
              <div className="main-text">No</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const EmailConfirmBox = (props) => {
  return(
    <div id="email-confirm-box">
      <div className="question-title">
        <div className="part-1">
          Thanks for giving us a second chance! A Customer Service representative will be reaching out to you within 24 hours.
        </div>
        <div
          className="back-home-link"
        >
          <Link
            to="/customer/account"
          >
            Back to Account Page...
          </Link>
        </div>
      </div>
    </div>
  )
};

const ProductBox = (props) => {
  let actionText = 'TRY HIGHER END';
  let questionText = "Don't like the quality? Maybe you want to:";

  if (props.subscription.subscription_product.tier === 'premiere') {
    actionText = 'TRY REGULAR TIER';
    questionText = 'Price too high?'
  }

  return (
    <div id="product-box">
      <div className="question-title">
        <div className="part-1">
          Not your style? Maybe you want to:
        </div>
        <div className="row content">
          <div
            className="col-lg-12 col-md-12 col-xs-12"
            onClick={() => { props.exitWithAlertText('Please change the "Style Type" option, and confirm on the following page') }}
          >
            <div className="question-button">
              <div className="main-text">CHANGE STYLE PREFERENCES</div>
            </div>
          </div>
          <div className="part-2">
            {questionText}
          </div>
          <div
            className="col-lg-12 col-md-12 col-xs-12"
            onClick={() => { props.exitWithAlertText('Please change the "Subscription Tier" option, and confirm on the following page') }}
          >
            <div className="question-button">
              <div className="main-text">{actionText}</div>
            </div>
          </div>
          <div className='wide-cancel-link col-lg-12 col-md-12 col-xs-12' onClick={() => { props.advanceWithRandomWait('FinalBox') }}>
            No, I just want to <span className="word-cancel">Cancel...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Question1 = (props) => {
  return(
    <div id="question-1">
      <div className="question-title">
        Please let us know why you're unhappy so we can improve!
      </div>
      <div className="row content">
        <div className="col-lg-6 col-md-6 col-xs-12 product" onClick={() => { props.advanceWithRandomWait('ProductBox') }}>
          <div className="question-button product">
            <div className="main-text">Product</div>
            <div className="sub-text">Styling, Quality, etc...</div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-xs-12 issues" onClick={() => { props.advanceWithRandomWait('IssueBox') }}>
          <div className="question-button issues">
            <div className="main-text">Issues</div>
            <div className="sub-text">Damages, duplicates etc.</div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-xs-12 other" onClick={() => { props.advanceWithRandomWait('OtherBox') }}>
          Other...
        </div>
      </div>
    </div>
  );
};

export default Cancel;
