// #  first_name         :string(255)
// #  last_name          :string(255)
// #  last_4             :string(255)
// #  card_type          :string(255)
// #  expiration_month   :integer
// #  expiration_year

const initialState = {
  surveyOptions: [],
  currentChoice: '',
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
  case 'API:SURVEY_OPTIONS:GET:SUCCESS':
    return { ...state, surveyOptions: response.survey_options, currentChoice: response.current_choice };
  case 'API:SURVEY_OPTIONS:VOTE:SUCCESS':
    return { ...state, currentChoice: response };
  default:
    return state;
  }
}

export default reducer;
