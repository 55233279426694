import '../../../../assets/stylesheets/components/consumer/header/header.scss';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import subscriptionImage from 'assets/images/consumer/onboarding/type/idgah.jpg'

import { stringSubscriptionPrice, hardcodedTermPrices } from '../../../../app/lib/constants.js';

const Header = (props) => {
  if (props.location.pathname.includes('/tg/')) {
    return (
      <header id="tinder-game-header">
        EarFleek
      </header>
    );
  } else if (props.location.pathname.includes('/orders/')) {
    return (
      <header id="header" className="clearfix" data-ma-theme="blue">
        Ivory Clasp - Style Delivered
      </header>
    );
  } else {
    let menuClassName = `navbar-toggleable-sm collapse`;

    if (props.menuOpen) {
      menuClassName += ' in'
    }

    let conditionalCheckout;

    if (props.cartLength === 0 || (!validLocation())) {
      conditionalCheckout = (<li><Link to="/reviews" className="nav-link">Reviews</Link></li>);
    } else {
      conditionalCheckout = (
        <li className="checkout-li">
          <Link to="/checkout" className="nav-link checkout-btn">
            Checkout
            <div className="checkout-link-image">
              <div className='cart-count'>
                {props.cartLength}
              </div>
            </div>
          </Link>
        </li>
      );
    }

    let navLink;

    if (props.currentUser && props.currentUser.isSignedIn) {
      navLink = (
        <li className="dropdown" id="login-dropdown">
          <a
            href=''
            className='dropdown-toggle nav-link'
            onClick={(e) => props.hackOpenLink(e)}
          >
            {props.currentUser.attributes.first_name}
            </a>
          <ul className="dropdown-menu">
            <li><Link to="/customer/account" className="nav-link">My Account</Link></li>
            <li>
              <a
                className="nav-link"
                href=''
                onClick={(e) => props.signOutUser(e)}
              >
                Log out
              </a>
            </li>
          </ul>
        </li>
      );
    } else {
      navLink = (<Link to="/customer/login" className="nav-link">Log in</Link>);
    }

    let globalHelmet = (<div></div>);

    if (props.location.pathname.indexOf('products') > -1) {
      globalHelmet = (<div></div>);
    } else {
      const jsonLDobject = {
        "@context": "http://schema.org/",
        "@type": "Product",
        name: `EarFleek - Monthly Earring Subscription`,
        url: `https://www.earfleek.com/subscribe`,
        image: [
          subscriptionImage
        ],
        description: `Sign up for our ${stringSubscriptionPrice(1, 'regular')}/month earring subscription, and receive new hypoallergenic earrings each month. We are the most affordable fashion subscription in the world!`,
        brand: {
          "@type": "Thing",
          name: "EarFleek"
        },
        sku: "SUB001",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "96",
          bestRating: "100",
          ratingCount: "296"
        },
        gtin: "EARFLEEK-SUB001",
        audience: {
          "@type": "PeopleAudience",
          suggestedGender: 'female',
          suggestedMinAge: 13,
        },    
        review: {
          "@type": "Review",
          itemReviewed: {
            "@type": "Product",
            "image": subscriptionImage,
            "name": "EarFleek - Monthly Earring Subscription",
            "priceRange": "$",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "96",
              bestRating: "100",
              ratingCount: "296"
            },
            brand: {
              "@type": "Thing",
              name: "EarFleek"
            },
            sku: "SUB001",
            description: `Sign up for our ${stringSubscriptionPrice(1, 'regular')}/month earring subscription, and receive new hypoallergenic earrings each month. We are the most affordable fashion subscription in the world!`,
            gtin: "EARFLEEK-SUB001",
          },
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5"
          },
          "name": "Affordable Hypoallergenic Earrings",
          "author": {
            "@type": "Person",
            "name": "Alexandria Ryan"
          },
          "reviewBody": "Cheapest Subscription Ever!",
          "publisher": {
            "@type": "Organization",
            "name": "Alexandria Ryan - YouTube"
          }
        },
        offers: [
          {
            "@type" : "Offer",
            availability: "http://schema.org/InStock",
            price: `${hardcodedTermPrices('regular')[0]['price_cents'].toFixed(2)}`,
            priceCurrency: "USD",
            priceValidUntil: "2025-12-31",
            url: "https://www.earfleek.com/subscribe",
            itemOffered: {
              "@type": "Product",
              image: subscriptionImage,
              name: "EarFleek - Monthly Earring Subscription",
              sku: "SUB001",
              gtin: "EARFLEEK-SUB001",
              url: "https://www.earfleek.com/subscribe",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "96",
                bestRating: "100",
                ratingCount: "296"
              },
              size: "OS",
              brand: {
                "@type": "Thing",
                name: "EarFleek"
              },
              sku: "SUB001",
              description: `Sign up for our ${stringSubscriptionPrice(1, 'regular')}/month earring subscription, and receive new hypoallergenic earrings each month. We are the most affordable fashion subscription in the world!`,
            }
          }
        ]
      };

      globalHelmet = (
        <Helmet>
          <script
            type="application/ld+json"
          >
            {`${JSON.stringify(jsonLDobject)}`}
          </script>
        </Helmet>
      );
    }

    return (
      <section id="cmp_b53e3dbe-05da-425c-b88a-2938b47b0ce5" className="component-nav">
        {globalHelmet}
        <div className="container-fluid">
          <div className="navbar row">
            <Link to="/" className="navbar-brand">
              <span className="store-name">EarFleek</span>
            </Link>
            {validLocation(props) && props.cartLength !== 0 && <Link to="/checkout" className="mobile-checkout-link btn btn-large btn-primary" type="button">
              Checkout
            </Link>}
            {props.showMobileMenu && <button type="button" className="navbar-toggler hidden-md-up" onClick={props.toggleMenu}>Menu</button>}
            <div id="navbar-menu" className={menuClassName}>
              <ul className="navbar-nav">
                <li><Link to="/subscribe" className="nav-link">Subscribe</Link></li>
                <li><Link to="/products" className="nav-link">Shop Styles</Link></li>
                <li><Link to="/faq" className="nav-link">FAQ</Link></li>
                <li><Link to="/howitworks" className="nav-link">How it Works</Link></li>
                {conditionalCheckout}
                {navLink}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

};

const validLocation = (props) => {
  return (window.location.pathname.indexOf('checkout') < 0) &&
    (window.location.pathname.indexOf('account') < 0) &&
    (window.location.pathname.indexOf('customer/thank_you') < 0);
}

export default Header;
