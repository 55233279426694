import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import FAQ from './faq.jsx';

class FAQContainer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.title = 'EarFleek - FAQ';
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <FAQ
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(FAQContainer));
