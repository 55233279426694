import '../../../app/actions/consumer/products_actions';

const initialState = {
  product: {
    id: null,
    title: '',
    description: '',
    catalog_photos: [],
  },
  inspectingProduct: false,
  totalPages: 0,
  totalCount: 0,
  pageSize: 10,
  inStock: false,
  page1: [],
  earfleekProducts: {
    products: [],
    pages: 0,
    total_count: 0,
    page: 1,
    per_page: 20,
    style_type: 'all',
    product_type: 'earrings-regular',
  },
  shopPageViewingProduct: {
    id: 0,
  },
  dailyDealsCutoff: undefined,
  dailyDeals: [],
  tag: {}
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
    case 'API:IVORY_CLASP_PRODUCT:GET_AVAILABLE_PRODUCTS:SUCCESS':
      //possibly an anti pattern:
      if (!response) {
        return { ...state }
      }
      var key = `page${response.page}`;
      return {
        ...state,
        [key]: response.products,
      };
    case 'API:IVORY_CLASP_PRODUCT:GET:SUCCESS':
      return { ...state, product: response, inspectingProduct: true};
    case 'API:PRODUCT:GET:SUCCESS':
      return { ...state, shopPageViewingProduct: response, product: response};
    case 'API:PRODUCTS:GET:SUCCESS':
      return { ...state, earfleekProducts: response };
    case 'API:TAG:GET:SUCCESS':
      return { ...state, earfleekProducts: {
        products: response.products,
        pages: 1,
        total_count: response.products.length,
        per_page: response.products.length,
        style_type: 'all',
        product_type: 'earrings-regular',
      }, tag: response.tag };
    case 'API:EARFLEEK_PRODUCTS:AVAILABLE_PREMIERE:SUCCESS':
      // this is done to refresh **counts** and keep original ordering in place
      if (state.earfleekProducts.products.length === 0) {
        return { ...state, earfleekProducts: response };
      } else {
        let dupedEarfleekProducts = Object.assign({}, state.earfleekProducts);

        let constructedProducts = Object.assign([], state.earfleekProducts.products);

        // iterate through what was returned from the server
        response.products.map((ep) => {
          let productInArray = false;
          constructedProducts.map((constructedEarfleekProduct) => {
            if (constructedEarfleekProduct.id === ep.id) {
              // constructedEarfleekProduct.effective_count = ep.effective_count
              constructedEarfleekProduct.effective_count = 1;
              productInArray = true;
            }
          });
        //
          if (!productInArray) {
            constructedProducts.push(ep);
          }
        });

        dupedEarfleekProducts.products = constructedProducts;

        return { ...state, earfleekProducts: dupedEarfleekProducts };
      }
    case 'API:EARFLEEK_PRODUCTS:DAILY_DEALS:SUCCESS':
      return { ...state, dailyDeals: (response.daily_deals || []), dailyDealsCutoff: response.daily_deals_cutoff }
    case 'PRODUCT:STOP_INSPECTING':
      const emptyProduct = {
        id: null,
        title: '',
        description: '',
        catalog_photos: [],
      };
      return { ...state, product: emptyProduct, inspectingProduct: false};
    default:
      return state;
  }
}

export default reducer;
