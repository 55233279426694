import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import creditCardType from 'credit-card-type';
import Actions from '../../../../app/actions/consumer/actions.js';
import {
  isValidCity, isValidEmail, isValidName, isValidStreetAddress,
  isValidZipCode, isValidStateAbbreviation, isValidCardNumber, isValidCvv, isValidMonth, isValidExpirationDate } from '../../../../app/lib/validators.js';

import Checkout from './checkout.jsx';

class CheckoutContainer extends React.Component {
  constructor() {
    super();

    let constructedEmail = '';
    let constructedCoupon = '';

    if (sessionStorage.getItem('listagram-test') === 'email' || sessionStorage.getItem('listagram-test') === 'email-coupon') {
      if (localStorage.getItem('listagram-email')) {
        constructedEmail = localStorage.getItem('listagram-email');
      }
    }

    if (sessionStorage.getItem('listagram-test') === 'email-coupon') {
      if (localStorage.getItem('listagram-coupon')) {
        constructedCoupon = localStorage.getItem('listagram-coupon');
      }
    }

    this.state = {
      shippingAddress: {
        // first_name: 'Ronald',
        first_name: '',
        // last_name: 'McDonald',
        last_name: '',
        // street: '123 Fake St',
        street: '',
        // unit: 'A',
        unit: '',
        // city: 'Santa Monica',
        city: '',
        // state: 'CA',
        state: '',
        // zip_code: '90403',
        zip_code: '',
      },
      giftInfo: {
        // first_name: 'Santa',
        first_name: '',
        // last_name: 'Claus',
        last_name: '',
        // email: 'joseph.e.combs+santa@gmail.com',
        email: '',
      },
      billingInfo: {
        // email: 'joseph.e.combs+weiyrwer@gmail.com',
        email: constructedEmail,
        // email: 'joe+migration@ivoryclasp.com', //has CustomerGateway
        // email: 'bginab420@gmail.com', //has used a viral code
        // email: 'cmmr1022@gmail.com', //has used a groupon
        first_name: '',
        // first_name: 'Joe',
        last_name: '',
        // last_name: 'Combs',
        number: '',
        // number: '5555444444444444',
        // cvv: '555',
        cvv: '',
        // expiration_month: 1,
        expiration_month: '',
        // expiration_year: 2022,
        expiration_year: '',
        // address_1: '924 9th Street',
        address_1: '',
        // zip_code: '90403',
        zip_code: '',
        // card_type: 'visa',
        card_type: '',
      },
      validationObject: {

      },
      lastTaxCheckedAddress: {
        first_name: '',
        last_name: '',
        street: '',
        city: '',
        state: '',
        zip_code: '',
      },
      isGift: false,
      tempCouponCode: constructedCoupon,
      isSubmittingCheckoutForm: false,
      errorModalShown: false,
      existingShippingAddress: undefined,
      existingPaymentMethod: undefined,
    };

    this.removeFromCart = this.removeFromCart.bind(this);
    this.removeCoupon = this.removeCoupon.bind(this);
    this.recalculateCart = this.recalculateCart.bind(this);
    this.updateCheckoutAttribute = this.updateCheckoutAttribute.bind(this);
    this.updateCartCouponCode = this.updateCartCouponCode.bind(this);
    this.updateShippingAddressAttribute = this.updateShippingAddressAttribute.bind(this);
    this.updateGiftInfoAttribute = this.updateGiftInfoAttribute.bind(this);
    this.updateBillingInfoAttribute = this.updateBillingInfoAttribute.bind(this);
    this.submitCheckoutForm = this.submitCheckoutForm.bind(this);
    this.validateCheckoutProperty = this.validateCheckoutProperty.bind(this);
    this.toggleGift = this.toggleGift.bind(this);
    this.closeErrorsModal = this.closeErrorsModal.bind(this);
    this.clearSelectedAddress = this.clearSelectedAddress.bind(this);
    this.clearSelectedPaymentMethod = this.clearSelectedPaymentMethod.bind(this);
    this.setDefaultAddress = this.setDefaultAddress.bind(this);
    this.setDefaultPaymentMethod = this.setDefaultPaymentMethod.bind(this);
    this.updateExistingShippingAddress = this.updateExistingShippingAddress.bind(this);
    this.updateExistingPaymentMethod = this.updateExistingPaymentMethod.bind(this);
  }

  componentDidMount() {
    this.recalculateCart();
    const action1 = Actions.app.hideMobileMenu();
    this.props.dispatch(action1);
    document.title = 'EarFleek - Checkout';

    if (this.props.addresses.length === 0) {
      const action2 = Actions.address.getAddresses();
      this.props.dispatch(action2);
    } else {
      this.setDefaultAddress();
    }

    if (this.props.paymentMethods.length === 0) {
      const action3 = Actions.paymentMethod.getPaymentMethods();
      this.props.dispatch(action3);
    } else {
      this.setDefaultPaymentMethod();
    }

    if (!this.props.customer) {
      const action4 = Actions.customer.getSignedInCustomer();
      this.props.dispatch(action4);
    }

    if (this.props.customer && this.props.customer.email) {
      this.updateBillingInfoAttribute({key: 'email', value: this.props.customer.email});
    }

    try {
      fbq('track', 'InitiateCheckout');
    } catch(err) {}

    if (this.state.tempCouponCode !== '') {
      // sends the listagram coupon code through normal process
      this.updateCartCouponCode();
    }

    // start sales tax checking loop
    const salesTaxIntervalID = setInterval(() => {
      if (this.canCheckSalesTaxRate()) {
        this.checkSalesTaxRate();
      }
    }, 5000);

    this.setState({
      salesTaxIntervalID: salesTaxIntervalID,
    })
  }

  checkSalesTaxRate() {
    if (this.state.existingShippingAddress) {
      if (this.state.existingShippingAddress.sales_tax_rate) {
        if (parseFloat(this.props.earfleekCart.taxRate) !== parseFloat(this.state.existingShippingAddress.sales_tax_rate)) {
          const action2 = Actions.checkout.setExistingSalesTaxRate(this.state.existingShippingAddress.sales_tax_rate);
          this.props.dispatch(action2);
        }
      }
    } else {
      const address = {
        street: this.state.shippingAddress.street,
        city: this.state.shippingAddress.city,
        state: this.state.shippingAddress.state,
        zip_code: this.state.shippingAddress.zip_code,
      };

      const onSuccess = function(dispatch, data) {
        this.setState({
          lastTaxCheckedAddress: address
        })
      }.bind(this);

      const onError = function(dispatch, data) {
      }.bind(this);

      const action = Actions.checkout.checkSalesTaxRate(
        address,
        onSuccess,
        onError
      );
      this.props.dispatch(action);
    }
  }

  canCheckSalesTaxRate() {
    if (this.state.existingShippingAddress) {
      return true;
    }
    // state differs from checked value
    let hasDifferences = false;

    if (this.state.lastTaxCheckedAddress.street !== this.state.shippingAddress.street ||
      this.state.lastTaxCheckedAddress.city !== this.state.shippingAddress.city ||
      this.state.lastTaxCheckedAddress.state !== this.state.shippingAddress.state ||
      this.state.lastTaxCheckedAddress.zip_code !== this.state.shippingAddress.zip_code
    ) {
      hasDifferences = true;
    }
    if (!hasDifferences) { return false; }

    let isValid = false;

    if (isValidStreetAddress(this.state.shippingAddress.street) &&
      isValidCity(this.state.shippingAddress.city) &&
      isValidStateAbbreviation(this.state.shippingAddress.state) &&
      isValidZipCode(this.state.shippingAddress.zip_code)
    ) {
      isValid = true
    }

    if (isValid) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    try {
      clearInterval(this.state.salesTaxIntervalID);
    } catch(err) {}
    const action = Actions.app.showMobileMenu();
    this.props.dispatch(action);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const subscriptionsUnequal = this.props.earfleekCart.subscriptions !== prevProps.earfleekCart.subscriptions;
    const productsUnequal = this.props.earfleekCart.products !== prevProps.earfleekCart.products;
    const taxRateUnequal = this.props.earfleekCart.taxRate !== prevProps.earfleekCart.taxRate;

    //I have no idea why I need to do this this way
    const couponUnequal = this.props.earfleekCart.coupon.code !== prevProps.earfleekCart.coupon.code || this.props.earfleekCart.coupon.description !== prevProps.earfleekCart.coupon.description || this.props.earfleekCart.coupon.discount_cents !== prevProps.earfleekCart.coupon.discount_cents;
    if (
      subscriptionsUnequal ||
      productsUnequal ||
      taxRateUnequal ||
      couponUnequal
    ) {
      this.recalculateCart();
    }

    if (!prevProps.customer && this.props.customer) {
      this.updateBillingInfoAttribute({key: 'email', value: this.props.customer.email})
    }

    // if addresses length is longer than addresses, set the existing address to the last address
    if (this.props.addresses.length > prevProps.addresses.length) {
      this.setDefaultAddress();
    }
    if (this.props.paymentMethods.length > prevProps.paymentMethods.length) {
      this.setDefaultPaymentMethod();
    }
    // if paymentMethods length is longer than paymentMethods, set the existing address to the last address
  }

  updateShippingAddressAttribute(opts) {
    let newShippingAddress = Object.assign({}, this.state.shippingAddress);
    newShippingAddress[opts.key] = opts.value;
    this.setState({
      shippingAddress: newShippingAddress,
    })
  }

  updateGiftInfoAttribute(opts) {
    let newGiftInfo = Object.assign({}, this.state.giftInfo);
    newGiftInfo[opts.key] = opts.value;
    this.setState({
      giftInfo: newGiftInfo,
    })
  }

  updateBillingInfoAttribute(opts) {
    let newBillingInfo = Object.assign({}, this.state.billingInfo);

    let hasErrors = false;
    //special validators
    if (opts.key === 'number') {

      if (opts.value.length > 16) {
        hasErrors = true;
      }

      if (opts.value.length > 0 && isNaN(opts.value)) {
        hasErrors = true;
      }

      if (!hasErrors) {
        //infer the credit card type
        const type = creditCardType(opts.value);
        if (type.length === 1) {
          newBillingInfo['card_type'] = type[0].type;
        } else {
          newBillingInfo['card_type'] = 'unknown';
        }
        newBillingInfo['number'] = opts.value;
      }
    } else if (opts.key === 'cvv') {
      if (opts.value.length > 4) {
        hasErrors = true;
      }

      if (opts.value.length > 0 && isNaN(opts.value)) {
        hasErrors = true;
      }

      if (!hasErrors) {
        newBillingInfo['cvv'] = opts.value;
      }
    } else {
      newBillingInfo[opts.key] = opts.value;
    }

    this.setState({
      billingInfo: newBillingInfo,
    })
  }

  validateCheckoutProperty(firstProperty, secondProperty, thirdProperty) {
    if (secondProperty === 'first_name' || secondProperty === 'last_name') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidName(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'street' || secondProperty === 'address_1') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidStreetAddress(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'city') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidCity(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'zip_code') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidZipCode(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'state') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidStateAbbreviation(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'email') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidEmail(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'number') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidCardNumber(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'cvv') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidCvv(this.state[firstProperty][secondProperty]))
    } else if (secondProperty === 'expiration_month') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidMonth(this.state[firstProperty][secondProperty]));
      //if year already filled in, need to revalidate year
      if (this.state.billingInfo.expiration_year.length > 3) {
        this.addToValidationObject('billingInfo', 'expiration_year', null, isValidExpirationDate(this.state.billingInfo.expiration_month, this.state.billingInfo.expiration_year));
      }
    } else if (secondProperty === 'expiration_year') {
      this.addToValidationObject(firstProperty, secondProperty, null, isValidExpirationDate(this.state.billingInfo.expiration_month, this.state.billingInfo.expiration_year))
    }
  }

  addToValidationObject(firstProperty, secondProperty, thirdProperty, validity) {
    let newValidationObject = Object.assign({}, this.state.validationObject);

    if (thirdProperty) {
      let intermediateObj = Object.assign({}, this.state.validationObject[firstProperty][secondProperty]);
      intermediateObj[thirdProperty] = validity;
      newValidationObject[firstProperty][secondProperty] = intermediateObj;
    } else if (secondProperty) {
      let intermediateObj = Object.assign({}, this.state.validationObject[firstProperty]);
      intermediateObj[secondProperty] = validity;
      newValidationObject[firstProperty] = intermediateObj;
    } else {
      newValidationObject[firstProperty] = validity;
    }

    this.setState({
      validationObject: newValidationObject,
    })
  }

  recalculateCart() {
    const action = Actions.checkout.calculateSubtotal(
      {
        subscriptions: this.props.subscriptions,
        products: this.props.products,
        coupon: this.props.coupon,
        tax_rate: this.props.taxRate,
        shippingAddress: this.state.shippingAddress,
      }
    );
    this.props.dispatch(action);
  }

  removeFromCart(id, productType) {
    const action = Actions.products.removeEarfleekProductFromCart(id, productType);
    this.props.dispatch(action);
  }

  removeCoupon() {
    const action = Actions.products.removeCoupon();
    this.props.dispatch(action);
  }

  updateCheckoutAttribute(opts) {
    this.setState({...opts});
  }

  updateCartCouponCode() {
    const action = Actions.products.addCoupon(this.state.tempCouponCode);
    this.props.dispatch(action);
    this.setState({
      tempCouponCode: '',
    });
  }

  submitCheckoutForm(e) {
    e.preventDefault();
    const obj = this.revalidateAllCheckoutFields();

    if (obj.errorMessages.length > 0) {
      this.setState({
        validationObject: obj.newValidationObject,
        errorModalShown: true,
      });
      const action = Actions.checkout.setClientSideErrorMessages(obj.errorMessages);
      this.props.dispatch(action);
    } else {
      if (this.state.isSubmittingCheckoutForm) return;

      this.setState({
        isSubmittingCheckoutForm: true,
      }, () => {
        let data = {
          shippingAddress: this.state.shippingAddress,
          isGift: this.state.isGift,
          giftInfo: this.state.giftInfo,
          billingInfo: this.state.billingInfo,
          cart: this.props.earfleekCart,
          referralInfo: this.props.referralInfo,
        };

        if (this.state.existingPaymentMethod) {
          data.existingPaymentMethodID = this.state.existingPaymentMethod.id;
          // data.existingPaymentMethodID = 7000000;
        }
        if (this.state.existingShippingAddress) {
          data.existingShippingAddressID = this.state.existingShippingAddress.id;
          // data.existingShippingAddressID = 6000000;
        }

        const onSuccess = function(dispatch, data) {
          const callbackAction = Actions.app.clearMessage();
          this.props.dispatch(callbackAction);

          try {
            clearInterval(this.state.salesTaxIntervalID);
          } catch(err) {}

          this.props.history.push('/customer/thank_you');
        }.bind(this);

        const onError = function(dispatch, data) {
          let responseMsg;
          if (data && data.errors) {
            responseMsg = data.errors;
          } else {
            responseMsg = [['Payment Method', ['You failed to check out!']]];
          }

          this.setState({
            errorModalShown: true,
            isSubmittingCheckoutForm: false,
          });

          const callbackAction = Actions.checkout.setClientSideErrorMessages(responseMsg);
          this.props.dispatch(callbackAction);
          const callbackAction2 = Actions.app.clearMessage();
          this.props.dispatch(callbackAction2);
        }.bind(this);

        const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Checking out... Please do not hit the back button or refresh the page...'});
        this.props.dispatch(warn);

        const action = Actions.checkout.checkout(
          data,
          onSuccess,
          onError
        );

        this.props.dispatch(action);
      });
    }
  }

  revalidateAllCheckoutFields() {
    let newValidationObject = {

    };

    if (this.state.existingShippingAddress) {
      newValidationObject.shippingAddress = {
        first_name: true,
        last_name: true,
        street: true,
        city: true,
        state: true,
        zip_code: true,
      }
    } else {
      newValidationObject.shippingAddress = {
        first_name: isValidName(this.state.shippingAddress.first_name),
        last_name: isValidName(this.state.shippingAddress.last_name),
        street: isValidStreetAddress(this.state.shippingAddress.street),
        city: isValidCity(this.state.shippingAddress.city),
        state: isValidStateAbbreviation(this.state.shippingAddress.state),
        zip_code: isValidZipCode(this.state.shippingAddress.zip_code),
      }
    }

    if (this.state.existingPaymentMethod) {
      newValidationObject.billingInfo = {
        first_name: true,
        last_name: true,
        address_1: true,
        zip_code: true,
        email: true,
        number: true,
        cvv: true,
        expiration_month: true,
        expiration_year: true,
      }
    } else {
      newValidationObject.billingInfo = {
        first_name: isValidName(this.state.billingInfo.first_name),
        last_name: isValidName(this.state.billingInfo.last_name),
        address_1: isValidStreetAddress(this.state.billingInfo.address_1),
        zip_code: isValidZipCode(this.state.billingInfo.zip_code),
        email: isValidEmail(this.state.billingInfo.email),
        number: isValidCardNumber(this.state.billingInfo.number),
        cvv: isValidCvv(this.state.billingInfo.cvv),
        expiration_month: isValidMonth(this.state.billingInfo.expiration_month),
        expiration_year: isValidExpirationDate(this.state.billingInfo.expiration_month, this.state.billingInfo.expiration_year),
      }
    }

    if (this.state.isGift) {
      newValidationObject.giftInfo = {
        first_name: isValidName(this.state.giftInfo.first_name),
        last_name: isValidName(this.state.giftInfo.last_name),
        email: isValidEmail(this.state.giftInfo.email),
      };
    }

    return {
      newValidationObject: newValidationObject,
      errorMessages: this.generateClientSideErrorMessages(newValidationObject)
    };
  }

  generateClientSideErrorMessages(newValidationObject) {
    //assume state validation object has everything set in this
    let errorMessages = [];

    let shippingAddressErrorMessages = [];
    let giftInfoErrorMessages = [];
    let billingInfoErrorMessages = [];

    try {
      if (newValidationObject.shippingAddress.first_name === false) { shippingAddressErrorMessages.push('First Name cannot be blank') }
      if (newValidationObject.shippingAddress.last_name === false) { shippingAddressErrorMessages.push('Last Name cannot be blank') }
      if (newValidationObject.shippingAddress.street === false) { shippingAddressErrorMessages.push('Street cannot be blank') }
      if (newValidationObject.shippingAddress.city === false) { shippingAddressErrorMessages.push('City cannot be blank') }
      if (newValidationObject.shippingAddress.state === false) { shippingAddressErrorMessages.push('State must be selected') }
      if (newValidationObject.shippingAddress.zip_code === false) { shippingAddressErrorMessages.push('Zip Code is invalid') }
    } catch(err) {

    }

    if (this.state.isGift) {
      try {
        if (newValidationObject.giftInfo.first_name === false) { giftInfoErrorMessages.push('Gift Recipient first name cannot be blank') }
        if (newValidationObject.giftInfo.last_name === false) { giftInfoErrorMessages.push('Gift Recipient last name cannot be blank') }
        if (newValidationObject.giftInfo.email === false) { giftInfoErrorMessages.push('Email is invalid') }
      } catch(err) {

      }
    }

    try {
      if (newValidationObject.billingInfo.first_name === false) { billingInfoErrorMessages.push('First Name cannot be blank') }
      if (newValidationObject.billingInfo.last_name === false) { billingInfoErrorMessages.push('Last Name cannot be blank') }
      if (newValidationObject.billingInfo.address_1 === false) { billingInfoErrorMessages.push('Billing Address cannot be blank') }
      if (newValidationObject.billingInfo.zip_code === false) { billingInfoErrorMessages.push('Zip Code is invalid') }
      if (newValidationObject.billingInfo.email === false) { billingInfoErrorMessages.push('Email is invalid') }
      if (newValidationObject.billingInfo.number === false) { billingInfoErrorMessages.push('Credit Card Number is invalid') }
      if (newValidationObject.billingInfo.cvv === false) { billingInfoErrorMessages.push('Card Verification Code is invalid') }
      if (newValidationObject.billingInfo.expiration_month === false) { billingInfoErrorMessages.push('Card Expiration Month is invalid') }
      if (newValidationObject.billingInfo.expiration_year === false) { billingInfoErrorMessages.push('Card Expiration Year is invalid') }
    } catch(err) {

    }

    if (shippingAddressErrorMessages.length > 0) {
      errorMessages.push(['Shipping Address', shippingAddressErrorMessages])
    }

    if (giftInfoErrorMessages.length > 0) {
      errorMessages.push(['Gift Recipient Information', giftInfoErrorMessages])
    }

    if (billingInfoErrorMessages.length > 0) {
      errorMessages.push(['Billing Information', billingInfoErrorMessages])
    }

    return errorMessages;
  }

  toggleGift() {
    this.setState({
      isGift: !this.state.isGift,
    })
  }

  closeErrorsModal() {
    this.setState({
      errorModalShown: false,
    })
  }

  clearSelectedAddress() {
    this.setState({
      existingShippingAddress: undefined,
    })
  };

  setDefaultAddress() {
    this.setState({existingShippingAddress: this.props.addresses[this.props.addresses.length - 1]})
  };

  updateExistingShippingAddress(id) {
    this.props.addresses.map((address) => {
      if (address.id === id) {
        this.setState({existingShippingAddress: address})
      }
    });
  };

  setDefaultPaymentMethod() {
    this.setState({existingPaymentMethod: this.props.paymentMethods[this.props.paymentMethods.length - 1]})
  };

  clearSelectedPaymentMethod() {
    this.setState({
      existingPaymentMethod: undefined,
    })
  };

  updateExistingPaymentMethod(id) {
    this.props.paymentMethods.map((paymentMethod) => {
      if (paymentMethod.id === id) {
        this.setState({existingPaymentMethod: paymentMethod})
      }
    });
  };

  render() {
    return (
      <Checkout
        {...this.props}
        removeFromCart={this.removeFromCart}
        removeCoupon={this.removeCoupon}
        updateCheckoutAttribute={this.updateCheckoutAttribute}
        updateCartCouponCode={this.updateCartCouponCode}
        updateShippingAddressAttribute={this.updateShippingAddressAttribute}
        updateGiftInfoAttribute={this.updateGiftInfoAttribute}
        updateBillingInfoAttribute={this.updateBillingInfoAttribute}
        validateCheckoutProperty={this.validateCheckoutProperty}
        submitCheckoutForm={this.submitCheckoutForm}
        toggleGift={this.toggleGift}
        closeErrorsModal={this.closeErrorsModal}
        clearSelectedPaymentMethod={this.clearSelectedPaymentMethod}
        clearSelectedAddress={this.clearSelectedAddress}
        setDefaultAddress={this.setDefaultAddress}
        setDefaultPaymentMethod={this.setDefaultPaymentMethod}
        updateExistingShippingAddress={this.updateExistingShippingAddress}
        updateExistingPaymentMethod={this.updateExistingPaymentMethod}
        tempCouponCode={this.state.tempCouponCode}
        shippingAddress={this.state.shippingAddress}
        giftInfo={this.state.giftInfo}
        billingInfo={this.state.billingInfo}
        validationObject={this.state.validationObject}
        isGift={this.state.isGift}
        errorModalShown={this.state.errorModalShown}
        existingShippingAddress={this.state.existingShippingAddress}
        existingPaymentMethod={this.state.existingPaymentMethod}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    earfleekCart: state.earfleekCart,
    subscriptions: state.earfleekCart.subscriptions,
    products: state.earfleekCart.products,
    coupon: state.earfleekCart.coupon,
    taxRate: state.earfleekCart.taxRate,
    grandTotalCents: state.earfleekCart.grandTotalCents,
    subtotalCents: state.earfleekCart.subtotalCents,
    salesTaxCents: state.earfleekCart.salesTaxCents,
    referralInfo: state.app.referralInfo,
    errorModalMessages: state.earfleekCart.errorModalMessages,
    customer: state.app.currentCustomer,
    addresses: state.addresses.addresses,
    paymentMethods: state.paymentMethods.paymentMethods,
  };
}

export default connect(mapStateToProps)(withRouter(CheckoutContainer));
