const getSubscription = (data, success, error, complete) => {
  return {
    type: 'API:SUBSCRIPTION:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/subscriptions/${data.id}`,
    success,
    error,
    complete,
  };
};

const getSubscriptions = (data, success, error, complete) => {
  return {
    type: 'API:SUBSCRIPTIONS:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/subscriptions`,
    success,
    error,
    complete,
  };
};

const updateSubscription = (data, success, error, complete) => {
  return {
    type: 'API:SUBSCRIPTION:UPDATE',
    API: true,
    method: 'PATCH',
    url: `/api/v1/subscriptions/${data.id}`,
    data,
    success,
    error,
    complete,
  }
};

const cancelSubscription = (data, success, error, complete) => {
  return {
    type: 'API:SUBSCRIPTION:CANCEL',
    API: true,
    method: 'DELETE',
    url: `/api/v1/subscriptions/${data.id}`,
    data,
    success,
    error,
    complete,
  }
};

const reactivateSubscription = (data, success, error, complete) => {
  return {
    type: 'API:SUBSCRIPTION:REACTIVATE',
    API: true,
    method: 'POST',
    url: `/api/v1/subscriptions/${data.id}/reactivate`,
    data,
    success,
    error,
    complete,
  }
};

const becomeQuarterly = (data, success, error, complete) => {
  return {
    type: 'API:SUBSCRIPTION:BECOME_QUARTERLY',
    API: true,
    method: 'POST',
    url: `/api/v1/subscriptions/${data.id}/become_quarterly`,
    data,
    success,
    error,
    complete,
  }
};

const skipToggle = (data, success, error, complete) => {
  return {
    type: 'API:SUBSCRIPTION:SKIP',
    API: true,
    method: 'POST',
    url: `/api/v1/subscriptions/${data.id}/skip`,
    data,
    success,
    error,
    complete,
  }
};

const createRetention = (data, success, error, complete) => {
  return {
    type: 'API:RETENTIONS:CREATE',
    API: true,
    method: 'POST',
    url: `/api/v1/retentions?subscription_id=${data.id}`,
    data,
    success,
    error,
    complete,
  }
};

export default {
  getSubscription,
  getSubscriptions,
  updateSubscription,
  skipToggle,
  createRetention,
  cancelSubscription,
  reactivateSubscription,
  becomeQuarterly,
};
