import { Link } from 'react-router-dom';

import { stringSubscriptionPrice } from '../../../../../app/lib/constants.js';

const BecomeQuarterly = (props) => {
  let headerText = "Update to Quarterly";
  let subHeadingText = "Please click the button below to become quarterly!";
  let subHeadingText2 = "";

  let perShipmentPrice = stringSubscriptionPrice(1, 'regular');

  if (props.subscription.state === 'cancelled') {
    subHeadingText2 = " Your subscription will be reactivated, and will be renewed at the new cadence!"
  }

  if (!props.subscription) {
    return(<div></div>);
  }

  if (!props.subscription.address) {
    return(<div></div>);
  }

  if (props.subscription.subscription_product.tier === 'premiere') {
    perShipmentPrice = stringSubscriptionPrice(1, 'premiere');
  }

  return(
    <section id='subscription-content'>
      <div className="account-header">
        <div className="container-fluid">
          <h2 className="text-center">
            {headerText}
          </h2>
          <p className="subheading-text">
            {subHeadingText}
          </p>
          <p className="subheading-text">
            {subHeadingText2}
          </p>

          <div className="panel panel-cratejoy panel-product">
            <div className="panel-heading">
              <h3 className="panel-title">
                Subscription Metadata:
              </h3>
              <div className="panel-body">
                <div className="row">
                  <div className="metadata-row">
                    Style Type: <span className="bolded-value">{props.subscription.subscription_product.primary_style_type}</span>
                  </div>
                  <div className="metadata-row">
                    Cadence: <span className="bolded-value">Quarterly</span>
                  </div>
                  <div className="metadata-row">
                    Price per Shipment: <span className="bolded-value">{perShipmentPrice}</span>
                  </div>
                  <div className="metadata-row">
                    Shipping Address: <span className="bolded-value">
                                  <OneLineAddress address={props.subscription.address}/>
                                </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            className="btn btn-primary reactivate-button"
            onClick={() => { props.becomeQuarterly() }}
          >
            BECOME QUARTERLY NOW
          </button>
        </div>
      </div>
    </section>
  )
};

const OneLineAddress = (props) => {
  return(
    <div>
      {props.address.to}, {props.address.street} {props.address.unit}, {props.address.city}, {props.address.state} {props.address.zip_code}
    </div>
  )
};

export default BecomeQuarterly;
