import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/orders/product_info_table.scss';
import PubSub from '../../../../app/pub_sub.js';

const ProductInfoTable = (props) => {
  var dummy = (<div></div>);
  if (!props.product) return dummy;
  const product = props.product;

  return (
    <div id='product-info-table' className='product-info-table'>
      Product Detail:
      <table id='attribute-table' className='table-striped'>
        {/*<thead>*/}
          {/*<th>Attribute</th>*/}
          {/*<th>Value</th>*/}
        {/*</thead>*/}
        <tbody>
          <tr>
            <td>Brand</td>
            <td>{product.brand.name}</td>
          </tr>
          <tr>
            <td>Title</td>
            <td>{product.title}</td>
          </tr>
          <tr>
            <td>Print</td>
            <td>{humanizePrint(product.print)}</td>
          </tr>
          <tr>
            <td>Shoulder Strap</td>
            <td>{product.has_shoulder_strap ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Handle</td>
            <td>{product.has_handle ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Weight</td>
            <td>{`${Math.floor(product.ounces / 16)} lbs., ${product.ounces % 16} oz.`}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>{product.box.name}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const humanizePrint = (string) => {
  const dict = {
    'none': 'None',
    'floral': 'Floral',
    'snakeskin': 'Snakeskin',
    'colorblock': 'Colorblock',
    'geometric': 'Geometric',
  };

  if (dict[string]) {
    return (dict[string]);
  } else {
    return 'None';
  }
};

const stopInspectingProduct = () => {
  PubSub.publish('ORDER_STOP_INSPECTING_POTENTIAL_PRODUCT');
};

const submitProductID = (id) => {
  PubSub.publish('ORDER_SUBMIT_PRODUCT_ID', { id: id });
};


export default ProductInfoTable;
