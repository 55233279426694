import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/votes/votes.scss';
import Actions from '../../../../app/actions/consumer/actions.js';

import Votes from './votes.jsx';

class VotesContainer extends React.Component {
  constructor() {
    super();

    this.state = {
    };

    // this.surveyVote = this.surveyVote.bind(this);
    // this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Votes';
    const action2 = Actions.vote.getVotes();
    this.props.dispatch(action2);
  }

  render() {
    return (
      <Votes
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    likes: state.votes.likes,
    dislikes: state.votes.dislikes,
    unvoted: state.votes.unvoted,
  };
}

export default connect(mapStateToProps)(withRouter(VotesContainer));
