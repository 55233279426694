const calculateSubtotal = (data, success, error, complete) => {
  return {
    type: 'API:CHECKOUT:SUBTOTAL',
    API: true,
    method: 'POST',
    url: `/api/v1/checkout/subtotal`,
    data,
    success,
    error,
    complete,
  };
};

const checkout = (data, success, error, complete) => {
  return {
    type: 'API:CHECKOUT:CHECKOUT',
    API: true,
    method: 'POST',
    url: `/api/v1/checkout`,
    data,
    success,
    error,
    complete,
  };
};

const checkSalesTaxRate = (data, success, error, complete) => {
  return {
    type: 'API:CHECKOUT:TAX_RATE',
    API: true,
    method: 'POST',
    url: `/api/v1/checkout/tax_rate`,
    data,
    success,
    error,
    complete,
  };
};

const setExistingSalesTaxRate = (data) => {
  return {
    type: 'CHECKOUT:SET_EXISTING_TAX_RATE',
    data,
  };
};

const setClientSideErrorMessages = (messages) => {
  return {
    type: 'APP:CART:SET_ERROR_MESSAGES',
    errorModalMessages: messages,
  }
};

const setSuccessfulOrders = (orders) => {
  return {
    type: 'APP:CHECKOUT:SET_SUCCESSFUL_ORDERS',
    orders: orders,
  }
};

export default {
  calculateSubtotal,
  setClientSideErrorMessages,
  setSuccessfulOrders,
  checkout,
  checkSalesTaxRate,
  setExistingSalesTaxRate,
};
