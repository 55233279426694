/* eslint-disable no-console */

import global from './global.js';
const PubSub = {};

const ADMIN_CUSTOMERS_INDEX_EVENT_NAMES = [
  'SET_NEW_SEARCH_TERM',
  'TOGGLE_PAGINATION_DROPDOWN',
  'ESC_STYLE_MODAL',
  'PRODUCT_GO_BACK',
  'PRODUCT_PAGE_IMAGE_ATTACH',
  'PRODUCT_PAGE_IMAGE_ATTACH_FAILURE',
  'PRODUCT_PAGE_IMAGE_DETACH',
  'PRODUCT_PAGE_IMAGE_SUBMIT',
  'PRODUCT_PAGE_EDIT_PRODUCT',
  'PRODUCT_PAGE_STOP_EDITING_PRODUCT',
  'TOGGLE_UPDATE_COUNTS_FORM',
  'TOGGLE_IN_STOCK',
  'CUSTOMER_OPEN_PRODUCT_ID_FIELD',
  'CUSTOMER_CLOSE_PRODUCT_ID_FIELD',
  'CUSTOMER_UPDATE_PRODUCT_ID_FIELD',
  'CUSTOMER_SUBMIT_PRODUCT_ID_FIELD',
  'TOGGLE_CUSTOMER_STYLE_MODAL',
  'CUSTOMER_DELETE_NOTE',
  'CUSTOMER_SUBMIT_NOTE',
  'CUSTOMER_UPDATE_NOTE',
  'CUSTOMER_CLOSE_NOTE_FIELD',
  'CUSTOMER_OPEN_NOTE_FIELD',
  'CUSTOMER_ADVANCE_NEXT_UNSTYLED',
  'CUSTOMER_NUKE_ORDER_STYLING',
];

const CONSUMER_ORDER_EVENT_NAMES = [
  'ORDER_INSPECT_POTENTIAL_PRODUCT',
  'ORDER_STOP_INSPECTING_POTENTIAL_PRODUCT',
  'ORDER_SUBMIT_PRODUCT_ID',
];

const CONSUMER_AUTHENTICATION_EVENTS = [
  'UPDATE_LOGIN_ATTRIBUTE',
];

const EXTENDED_ATTRIBUTES_EVENT_NAMES = [
  'PRODUCT_TOGGLE_ATTRIBUTE',
  'PRODUCT_PAGE_SAVE_EXTENDED_ATTRIBUTES',
  'PRODUCT_SET_ATTRIBUTE',
  'ORDER_TOGGLE_EXPLAINER',
];

const RETURN_WIZARD_EVENT_NAMES = [
  'SUBMIT_RETURNING_ORDER_ID',
  'SUBMIT_RETURNING_SHIPMENT_ID',
  'UPDATE_RETURNING_ORDER_ID',
  'UPDATE_RETURNING_SHIPMENT_ID',
  'UPDATE_RETURNING_ORDER_PARENT_STATE',
  'UPDATE_RETURNING_SHIPMENT_PARENT_STATE',
  'TAKE_ACTION',
];

const ADDRESS_EVENT_NAMES = [
  'LOAD_NEXT_ADDRESS',
  'UPDATE_ADDRESS',
  'SUBMIT_NEW_ADDRESS',
  'SET_NEW_COMBO_CODE',
  'INFER_ADDRESS_VALUE',
  'INFER_ALL_ADDRESS_VALUES',
  'MARK_ADDRESS_OK',
  'SHOW_ERRORS',
  'HIDE_ERRORS',
  'FORCE_GET_ADDRESSES',
];

const EARFLEEK_FULFILLMENT_EVENT_NAMES = [
  'DOWNLOAD_SHIPMENTS_CSV',
  'SET_LONG_TAIL_SIZE',
  'VALIDATE_SHIPMENTS_XLSX',
  'CLEAR_UPLOADED_ROWS',
  'BULK_LABEL',
  'SHOW_SHIPMENT_EXCEPTIONS_AREA',
  'SET_REFUND_OR_RESHIP',
  'PROCESS_BAD_SHIPMENT',
  'EARFLEEK_DNR_OUTREACH',
];

const EARFLEEK_PRODUCTS_EVENT_NAMES = [
  'EARFLEEK_PRODUCT_PAGE_EDIT_PRODUCT',
  'EARFLEEK_PRODUCT_PAGE_IMAGE_ATTACH_FAILURE',
  'EARFLEEK_PRODUCT_PAGE_IMAGE_ATTACH',
  'EARFLEEK_PRODUCT_PAGE_IMAGE_DETACH',
  'EARFLEEK_PRODUCT_PAGE_IMAGE_SUBMIT',
  'EARFLEEK_PRODUCT_PAGE_SET_VIEW',
  'EARFLEEK_PRODUCT_PAGE_SET_VENDOR',
  'EARFLEEK_PRODUCT_UPDATE_EDITING_PRODUCT_ATTRIBUTE',
  'EARFLEEK_PRODUCT_SAVE_EARFLEEK_PRODUCT_ATTRIBUTES',
  'EARFLEEK_PRODUCT_PAGE_DELETE_PRODUCT_IMAGE',
  'FETCH_ALL_EARFLEEK_PRODUCTS',
  'EARFLEEK_FULFILLMENT_FORCE_STYLE',
  'GET_RECOMMENDED_STATS',
  'COPY_TINDER_GAME_URL',
];

const EVENT_NAMES = [
  ...CONSUMER_AUTHENTICATION_EVENTS,
  ...ADMIN_CUSTOMERS_INDEX_EVENT_NAMES,
  ...CONSUMER_ORDER_EVENT_NAMES,
  ...EXTENDED_ATTRIBUTES_EVENT_NAMES,
  ...RETURN_WIZARD_EVENT_NAMES,
  ...ADDRESS_EVENT_NAMES,
  ...EARFLEEK_FULFILLMENT_EVENT_NAMES,
  ...EARFLEEK_PRODUCTS_EVENT_NAMES,
];

if (!_.isEqual(EVENT_NAMES, _.uniq(EVENT_NAMES)) && global.ENV === 'development') {
  const dupes = EVENT_NAMES.sort().reduce((arr, name, i) => {
    if (name === EVENT_NAMES[i + 1]) arr.push(name);
    return arr;
  }, []);
  console.warn('PubSub: Duplicate event names: ' + dupes.join(' '));
}

function CONST(name) {
  const index = _.indexOf(EVENT_NAMES, name);

  if (index >= 0) {
    return EVENT_NAMES[index];
  } else {
    throw `PubSub: Constant not found: ${name}. Add constant to array for fewer bugs.`;
  }
}

PubSub.events = {};

PubSub.subscribe = function(name, fn, opts = {}) {
  name = CONST(name);
  const events = PubSub.events[name];

  if (!fn && global.ENV === 'development') {
    console.warn(`Argument error: event ${name} missing function`);
    return;
  }

  // multi-event subscription can be enabled but...why?
  if (!opts.dangerouslyAllowMultipleEvents && events && events.length) {
    if (global.ENV === 'development') {
      console.warn(`Event: "${name}" has already been subscribed to. ` +
        'Current thinking only allows one callback per event.');
    }
  }

  if (!events) PubSub.events[name] = [];
  PubSub.events[name].push(fn);
};

PubSub.publish = function(name, payload) {
  _.each(PubSub.events[name], (event) => {
    if (typeof event === 'function') {
      event(payload);
    }
  });
};

PubSub.unsubscribe = function(name, fn) {
  const events = PubSub.events[name];
  if (events && events.length) {
    if (events.length > 1 && !fn && global.ENV === 'development') {
      console.warn(`WARN: PubSub.unsubscribe. Multiple events subscribed to ${name}. ` +
        'Must pass subscribed function.');
    }
    if (fn) {
      PubSub.events[name] = _.without(events, fn);
    } else {
      PubSub.events[name] = [];
    }
  }
};

PubSub.clearAll = function() {
  _.each(PubSub.events, (v, k) => PubSub.events[k] = []);
};

export default PubSub;
