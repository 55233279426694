import '../../../../assets/stylesheets/components/consumer/header/message.scss';

const Message = (props) => {
  if (!props.message) {
    return(
      <div></div>
    );
  }

  return (
    <div id='app-message' className={`${props.message.type.toLowerCase()}`}>
      {props.message.text}
      <div
        id="dismiss-message-icon"
        onClick={props.dismissMessage}
      >
        {'\u2715'}
      </div>
    </div>
  );
};

export default Message;
