// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../../../../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-fluid{max-width:1140px}.collapse.in{display:block}\n", "",{"version":3,"sources":["webpack://./bootstrap/cj-bootstrap.scss"],"names":[],"mappings":"AACA,iBACE,gBAAiB,CAClB,aAGC,aAAc","sourcesContent":["/*these are styles that need to apply globally in the consumer components that are not included in bootstrap-css-only*/\n.container-fluid {\n  max-width: 1140px;\n}\n\n.collapse.in {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
