const getSurveyOptions = (data, success, error, complete) => {
  return {
    type: 'API:SURVEY_OPTIONS:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/survey_options`,
    data,
    success,
    error,
    complete,
  };
};

const surveyVote = (data, success, error, complete) => {
  return {
    type: 'API:SURVEY_OPTIONS:VOTE',
    API: true,
    method: 'POST',
    url: `/api/v1/survey_options/vote`,
    data,
    success,
    error,
    complete,
  };
};

export default {
  getSurveyOptions,
  surveyVote,
};
