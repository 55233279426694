const fetchEarfleekProducts = (tinderGameAuthToken, showAll, browserFingerprint) => {
  return {
    type: 'API:TINDER_GAMES:EARFLEEK_PRODUCTS:FETCH',
    API: true,
    url: `/api/v1/tinder_games?tinder_game_auth_token=${tinderGameAuthToken}&show_all=${showAll}&browser=${browserFingerprint}`,
  };
};

const logSwipe = (data) => {
  return {
    type: 'API:TINDER_GAMES:VOTE',
    API: true,
    url: `/api/v1/tinder_games/vote`,
    method: 'post',
    data: data
  };
};
const buyNow = (data, success, error, complete) => {
  return {
    type: 'API:TINDER_GAMES:BUY_NOW',
    API: true,
    method: 'POST',
    url: `/api/v1/tinder_games/buy_now`,
    data,
    success,
    error,
    complete,
  };
};

const fetchTinderGameCustomer = (tinderGameAuthToken) => {
  return {
    type: 'API:TINDER_GAMES:CUSTOMER:FETCH',
    API: true,
    url: `/api/v1/tinder_games/customer?tinder_game_auth_token=${tinderGameAuthToken}`,
  };
};

const fetchTinderGameAddresses = (tinderGameAuthToken) => {
  return {
    type: 'API:TINDER_GAMES:ADDRESSES:FETCH',
    API: true,
    url: `/api/v1/tinder_games/addresses?tinder_game_auth_token=${tinderGameAuthToken}`,
  };
};

const fetchTinderGamePaymentMethods = (tinderGameAuthToken) => {
  return {
    type: 'API:TINDER_GAMES:PAYMENT_METHODS:FETCH',
    API: true,
    url: `/api/v1/tinder_games/payment_methods?tinder_game_auth_token=${tinderGameAuthToken}`,
  };
};

export default {
  fetchEarfleekProducts,
  logSwipe,
  buyNow,
  fetchTinderGameCustomer,
  fetchTinderGameAddresses,
  fetchTinderGamePaymentMethods,
};
