const PaymentMethodSelector = (props) => {
  let selectedPaymentMethodID = 0;
  if (props.selectedPaymentMethod) {
    selectedPaymentMethodID = props.selectedPaymentMethod.id;
  }

  return(
    <select
      id="payment-method-selector"
      className="form-control state-selector"
      value={selectedPaymentMethodID}
      onChange={(event) => props.selectPaymentMethod(parseInt(event.target.value))}
    >
      {props.paymentMethods.map((paymentMethod) => {
        const selectString = `${paymentMethod.card_type}, ****${paymentMethod.last_4}, expires ${paymentMethod.expiration_month} / ${paymentMethod.expiration_year}`;

        return(
          <option
            value={paymentMethod.id}
            key={paymentMethod.id}
          >
            {selectString}
          </option>
        )
      })}
    </select>
  );
}

export default PaymentMethodSelector;
