import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/home/home.scss';

//this is referring to an image in the standard rails asset pipeline
import SwipeImage from 'assets/images/consumer/home/swipegamepic-min.jpg'
import ClearNewBG from 'assets/images/consumer/home/clearnewbg-min.jpg'
import aviEnvelope from 'assets/images/consumer/home/avi-envelope.png'
import premiereBox from 'assets/images/consumer/home/premiere-box.png'

import { stringSubscriptionPrice, hardcodedTermPrices } from '../../../../app/lib/constants.js';

const Home = (props) => {

  const part1BGStyle = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url('${ClearNewBG}')`,
  };

  const part1 = (
    <section
      id="cmp_dc14cc2c-463a-4b8f-b4dd-61da5a0b37f7"
      className="component-hero-banner row"
      style={part1BGStyle}
    >
      <div className="container-fluid">
        <div className="hero-row row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 hero-content">
            <h1>Monthly Earrings</h1>
            <h2>{stringSubscriptionPrice(1, 'regular')}/mo </h2>
            <h2>FREE SHIPPING</h2>
            <h2>ALLERGY FREE</h2>
            <div className="button-container">
              <Link to="/subscribe" type="button" className="btn btn-large btn-primary">
                Subscribe
              </Link>
              <Link to="/products" type="button" className="btn btn-large btn-secondary">
                Shop Styles
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const part2 = (
    <section id="cmp_b5521c32-8b32-499c-886e-5dc7d5d7b8c2">
      <div className="row ">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 flex-center text-center hero-content no-padding" style={{display: 'block', margin: '0 auto'}}>
          <div>
        <span><h1>INTRODUCING:</h1>
<h2>SWIPE</h2>
<h3>Available after signup</h3>
<p><Link to ="/howitworks">[Learn more]</Link></p></span>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 no-padding" style={{display: 'block', margin: '0 auto'}}>
          <img
            src={SwipeImage}
            style={{maxWidth: '100%'}}
          />
        </div>
      </div>
    </section>
  );

  const part3BGStyleA = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url('${aviEnvelope}')`,
  };

  const part3BGStyleB = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url('${premiereBox}')`,
  };

  let premiereLink = "/subscribe?tier=premiere";

  if (window.sessionStorage.getItem('premiere-education-test') === 'true') {
    premiereLink = "/premiere";
  }

  const part3 = (
    <section
      id="cmp_9633017c-acf5-437b-a21e-3b14a1b466ac"
      className="component-captioned-image-bar"
      style={{paddingTop: 15}}
    >
      <div className="container-fluid">
        <div className="content-row row join-now">
          <h2>Join Now!</h2>
        </div>
        <div className="content-row row">
          <div className="col-md-4 col-xs-12 content flex-center text-center">
            <Link to={premiereLink} id="premiere-onboarding-link">
              <span>
                <p style={{textAlign: 'center'}}>
                  <br />
                </p>
              </span>
              <div
                className="square-image"
                style={part3BGStyleB}>
              </div>
              <span>
                <h1>PREMIERE TIER</h1>
                <h3>{stringSubscriptionPrice(1, 'premiere')}/mo</h3>
              </span>
            </Link>
          </div>
          <div className="col-md-4 col-xs-12 content flex-center text-center">
            <Link to="/subscribe">
              <span>
                <p style={{textAlign: 'center'}}>
                  <br/>
                </p>
              </span>
              <div
                className="square-image"
                style={part3BGStyleA}
              >
              </div>
              <span>
                <h1>REGULAR TIER</h1>
                <h3>{stringSubscriptionPrice(1, 'regular')}/mo</h3>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );

  const part3a = (
    <section>
      <div className="unboxing_sec text-center container-fluid">
        <div className="container content-row">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h1>
                Earring Subscription
              </h1>
              <p>
                Put simply, EarFleek is a monthly earring subscription. In reality, EarFleek is a monthly FUN subscription. Every month you will receive a fun and stylish pair of earrings delivered right to your door. The earrings are yours to keep and shipping and handling is included in our {stringSubscriptionPrice(1, 'regular')} price. All our earrings MSRP at $13+ and are hypoallergenic, that means they won't irritate sensitive ears. We have four different style types to choose from: Boho (Bohemian), Minimalist (Geometric), Silly & Fun (Eccentric) and I Don't Give A Hoot (Random). Every user is sent a personalized link to swipe through our current and future inventory - which allows you to share with us the types of styles you LIKE or DON'T like. We keep this information in mind when choosing what to ship you as well as when we design new earrings. In other words, EarFleek is constantly evolving and staying on top of today's hottest trends. Despite being the <span style={{fontWeight: 'bold'}}>cheapest, most inexpensive subscription box on the planet</span>, we pride ourselves in providing top quality earrings and a top quality experience. For those who want a bit more say in what they get, we have a premium offering - <span style={{fontWeight: 'bold'}}>EarFleek Premiere</span>. These earrings retail at $25+ and come in special packaging with tracking labels. Premiere customers are also able to "self-style" meaning they get to choose exactly what they are going to get. So get fun and sign up for the EarFleek Earring Subscription Box today!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const iframeStyle = {
    width: '100% !important',
    height: 'auto !important',
  };

  const part4 = (
    <section id="cmp_351d42cb-6597-4498-bfea-2f65ae97e57czzz">
      <div className="unboxing_sec text-center container-fluid">
        <div className="container content-row">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="section_head">
                <h1>
                  UNBOXING
                </h1>
                <h2>
                  See what some beautiful youtubers say!
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 content flex-center text-center">
              <div className="video_box">
                <div className="unboxing_video">
                  <iframe
                    style={iframeStyle}
                    src="https://www.youtube.com/embed/we3bHL-sLdQ"
                    frameBorder="0"
                    allowFullScreen=""
                  >
                  </iframe>
                </div>
                <div className="video_title">
                  <h3>
                    Earfleek
                  </h3>
                </div>
                <div className="video_description">
                  <p>
                    "They're allergy free!"
                  </p>
                </div>
                <div className="video_by">
                  <a href="https://www.youtube.com/channel/UC8N-gvpcK3JpcnZG8qWNamw" target="_blank">
                    Subscription Unbox
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 content flex-center text-center">
              <div className="video_box">
                <div className="unboxing_video">
                  <iframe
                    style={iframeStyle}
                    src="https://www.youtube.com/embed/pBIrx_Ci-6A"
                    frameBorder="0"
                  >
                  </iframe>
                  <div className="video_title">
                    <h3>
                      EARFLEEK
                    </h3>
                  </div>
                  <div className="video_description">
                    <p>
                      "Cheapest Subscription Ever"
                    </p>
                  </div>
                  <div className="video_by">
                    <a href="https://www.youtube.com/channel/UCQBamlN6ATTOkM5m459dAiA" target="_blank">
                      Alexandria Ryan
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 content flex-center text-center">
              <div className="video_box">
                <div className="unboxing_video">
                  <iframe
                    style={iframeStyle}
                    src="https://www.youtube.com/embed/meegoPzUNtk"
                    frameBorder="0"
                    allowFullScreen="">
                  </iframe>
                </div>
                <div className="video_title">
                  <h3>
                    EARFLEEK
                  </h3>
                </div>
                <div className="video_description">
                  <p>
                    "Cheapest Subscription Ever"
                  </p>
                </div>
                <div className="video_by">
                  <a
                    href="https://www.youtube.com/channel/UCNYoeVMUzm1cE8O6Ftt9oyw"
                    target="_blank"
                  >
                    Curvyhipsandtintedlips
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const juicerBool = (props.currentUser && props.currentUser.isSignedIn)

  const part5 = (
    <section id="cmp_a6ade3a9-3cff-4cd7-b258-5c07f2c7c551">
      <div className="container">
        <h2 className="text-center">Past Months:</h2>
        <center>
          <p>
            See what others got. Search <a href="https://www.instagram.com/earfleek/" target="_blank">#Earfleek</a> to see more!
          </p>
        </center>
      </div>
      {!juicerBool && <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css" />}
      {!juicerBool && <ul className="juicer-feed" data-feed-id="earfleek" height="1000">
      </ul>}
    </section>
  );

  return (
    <section id='home-content'>
      {true && part1}
      {true && part2}
      {true && props.oneSecondPassed && part3}
      {true && part3a}
      {true && props.twoSecondsPassed && part4}
      {true && props.twoSecondsPassed && part5}
    </section>
  )
};

export default Home;
