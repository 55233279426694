const initialState = {
  likes: [],
  dislikes: [],
  unvoted: [],
};

function reducer(state = initialState, action) {
  switch(action.type) {
    case 'API:VOTES:GET:SUCCESS':
      return {
        ...state,
        likes: action.response.likes,
        dislikes: action.response.dislikes,
        unvoted: action.response.unvoted
      };
    default:
      return state;
  }
}

export default reducer;
