import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/checkout/address_selector.scss';
import Actions from '../../../../app/actions/consumer/actions.js';

import AddressSelector from './address_selector.jsx';

class AddressSelectorContainer extends React.Component {
  constructor() {
    super();

    this.state = {

    }
  }

  componentDidMount() {
    if (this.props.addresses.length >= 1) {
      this.props.selectAddress(this.props.addresses[this.props.addresses.length - 1].id);
    }
  }

  render() {
    return (
      <AddressSelector
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    addresses: state.addresses.addresses,
  };
}

export default connect(mapStateToProps)(withRouter(AddressSelectorContainer));
