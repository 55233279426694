import { Link } from 'react-router-dom';
import PubSub from '../../../../app/pub_sub.js';
import { Collapse } from 'react-collapse';
import '../../../../assets/stylesheets/components/consumer/orders/confirmed_order.scss';


const ConfirmedOrder = (props) => {
  if (!props.order.ivory_clasp_product) {
    return (<section></section>);
  }
  let src;

  try {
    src = props.order.ivory_clasp_product.catalog_photos[0].image.giant.url;
  } catch (err) {
    src = '';
  }
  return (
    <section id='order-content' className='order-container'>
      <div className='col-md-12 confirm-explainer'>
        <div className='confirm-explain-header'>
          <p>Nice choice! Your order is on its way! </p>
          <p>Questions? email <span className='emailcontact'>support@ivoryclasp.com</span></p>
          <p>Refer to your Customer ID <span className='emailcontact'>{props.order.customer_id}</span></p>
        </div>
        <div className='confirm-explainer-pic'>
          <img src={src} alt=""/>
        </div>
      </div>
    </section>
  );
};

export default ConfirmedOrder;
