import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PubSub from '../../../../app/pub_sub.js';
import Actions from '../../../../app/actions/consumer/actions.js';

import NewPassword from './new_password.jsx';

class NewPasswordContainer extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      email: '',
      emailSent: false,
    };

    this.updateLoginAttribute = this.updateLoginAttribute.bind(this);
    this.submitNewPasswordForm = this.submitNewPasswordForm.bind(this);
  }

  componentDidMount() {
    PubSub.subscribe('UPDATE_LOGIN_ATTRIBUTE', this.updateLoginAttribute);
  }

  componentWillUnmount() {
    PubSub.unsubscribe('UPDATE_LOGIN_ATTRIBUTE', this.updateLoginAttribute);
  }

  updateLoginAttribute(opts) {
    this.setState({
      [opts.key]: opts.value,
    })
  }

  submitNewPasswordForm(e) {
    e.preventDefault();
    const {
      email,
    } = this.state;

    const onSuccess = function(dispatch, data) {
      const onSuccess2 = Actions.app.setMessage({
        type: 'SUCCESS',
        text: 'Please check your email for further instructions.',
      }, 3000);

      this.setState({
        emailSent: true,
      });

      this.props.dispatch(onSuccess2)
    }.bind(this);

    const onError = Actions.app.setMessage({
      type: 'ERROR',
      text: 'This email is not associated with an account in our system. Please sign up.' }
    , 40000);

    var getUrl = window.location;
    var baseUrl = getUrl.protocol + "//" + getUrl.host + "/customer/password/edit";
    const action = Actions.auth.forgotPassword({ email, redirect_url: baseUrl }, onSuccess, onError);

    this.props.dispatch(action);
  }

  render() {
    return(
      <NewPassword
        {...this.props}
        submitNewPasswordForm={this.submitNewPasswordForm}
        emailSent={this.state.emailSent}
        email={this.state.email}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(NewPasswordContainer));
