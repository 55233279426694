import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/checkout/payment_method_selector.scss';
import Actions from '../../../../app/actions/consumer/actions.js';

import PaymentMethodSelector from './payment_method_selector.jsx';

class PaymentMethodSelectorContainer extends React.Component {
  constructor() {
    super();

    this.state = {

    }
  }

  componentDidMount() {
    if (this.props.paymentMethods.length >= 1) {
      this.props.selectPaymentMethod(this.props.paymentMethods[this.props.paymentMethods.length - 1].id);
    }
  }

  render() {
    return (
      <PaymentMethodSelector
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    paymentMethods: state.paymentMethods.paymentMethods,
  };
}

export default connect(mapStateToProps)(withRouter(PaymentMethodSelectorContainer));
