import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/subscriptions/cancel.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';

import Cancel from './cancel.jsx';

class CancelContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      activePanel: undefined,
      cancellationReasons: [],
      cancellationReasonCode: undefined,
      cancellationText: undefined,
      isCancelling: false,
    };

    this.advanceWithRandomWait = this.advanceWithRandomWait.bind(this);
    this.exitWithAlertText = this.exitWithAlertText.bind(this);
    this.createRetention = this.createRetention.bind(this);
    this.updateCancelReason = this.updateCancelReason.bind(this);
    this.updateCancelText = this.updateCancelText.bind(this);
    this.formValid = this.formValid.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Cancel Subscription';
    this.getSubscription(this.props.match.params.id);
    this.setCancellationReasonsOrder();

    if (this.props.subscription && this.props.subscription.customer) {
      this.conditionalSetPanel();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.subscription && prevProps.subscription
      && (prevProps.subscription.customer !== this.props.subscription.customer)
    ) {
      this.conditionalSetPanel();
    }
  }

  conditionalSetPanel() {
    if (this.state.activePanel === 'QuarterlyBox') {
      if (this.props.subscription.customer.total_revenue_cents > 2500) {
        this.setState({activePanel: 'FinalFinalBox'});
      } else {
        this.setState({activePanel: 'Question1'})
      }
    } else {
      this.setState({activePanel: 'QuarterlyBox'});
    }
  }

  updateCancelReason(opts) {
    this.setState({
      cancellationReasonCode: opts.value,
    })
  }

  updateCancelText(opts) {
    this.setState({
      cancellationText: opts.value,
    })
  }

  setCancellationReasonsOrder() {
    let reasons = this.shuffle([[6, "Not worth the price"], [13, "You billed me twice before I received anything"], [3, "Accidentally ordered more than once"], [5, "Shipping issues"], [2, "Just wanted to test it out"], [8, "Other service issues"], [15, "Purchased this as a gift"], [9, "Trying other service"], [7, "Did not like the product"], [14, "Fraud"], [4, "Planning on re-subscribing later"], [12, "I never wanted it to renew"]]);
    reasons.push([10, "Other"]);

    this.setState({
      cancellationReasons: reasons
    })
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  advanceWithRandomWait(newPanel) {
    const max = 4000;
    // const max = 500;
    const min = 1000;
    // const min = 50;
    const randomMS = Math.floor(Math.random() * (max - min + 1) + min);

    // let newPanel === 'Question1';

    if (this.props.subscription.total_revenue_cents > 2500) {
      newPanel = 'FinalFinalBox';
    }

    if (true) {
      this.setState({
        activePanel: 'Loading'
      }, () => {
        setTimeout(() => {
          this.setState({
            activePanel: newPanel,
          })
        }, randomMS);
      });
    }
  }

  createRetention() {
    const action = Actions.subscription.createRetention({id: this.props.subscription.id});
    this.props.dispatch(action);
    this.advanceWithRandomWait('EmailConfirmBox');
  }

  exitWithAlertText(alertText) {
    alert(alertText);
    this.props.history.push(`/customer/subscriptions/${this.props.subscription.id}`);
  };

  getSubscription(id) {
    const action = Actions.subscription.getSubscription({id: id});
    this.props.dispatch(action);
  }

  formValid() {
    return(this.state.cancellationReasonCode && this.state.cancellationText && (this.state.cancellationText.length > 3))
  }

  cancelSubscription() {
    if (this.state.isCancelling) return;
    if (!this.formValid()) return;

    this.setState({
      isCancelling: true,
    }, () => {
      const data = {
        id: this.props.subscription.id,
        cancellationReasonCode: this.state.cancellationReasonCode,
        cancellationText: this.state.cancellationText
      };

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You cancelled your subscription!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/account');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to cancel this subscription!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isCancelling: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Cancelling this subscription...'});
      this.props.dispatch(warn);

      const action = Actions.subscription.cancelSubscription(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });

  }

  render() {
    return(
      <Cancel
        {...this.props}
        activePanel={this.state.activePanel}
        advanceWithRandomWait={this.advanceWithRandomWait}
        exitWithAlertText={this.exitWithAlertText}
        createRetention={this.createRetention}
        updateCancelReason={this.updateCancelReason}
        updateCancelText={this.updateCancelText}
        cancellationReasons={this.state.cancellationReasons}
        formValid={this.formValid}
        cancelSubscription={this.cancelSubscription}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    subscription: state.subscriptions.subscription,
  };
}

export default connect(mapStateToProps)(withRouter(CancelContainer));
