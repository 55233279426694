import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import creditCardType from 'credit-card-type';
import '../../../../../assets/stylesheets/components/consumer/payment_methods/payment_methods.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';
import { isValidCardNumber, isValidCvv, isValidExpirationDate, isValidZipCode } from '../../../../../app/lib/validators.js';

import PaymentMethod from './payment_method.jsx';

class NewPaymentMethodContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      newPaymentMethod: {
        // first_name: 'Joe',
        first_name: '',
        // last_name: 'Combs',
        last_name: '',
        // number: '5555555555554444',
        number: '',
        // cvv: '777',
        cvv: '',
        // expiration_month: '4',
        expiration_month: '',
        // expiration_year: '2020',
        expiration_year: '',
        // address_1: '924 9th St',
        address_1: '',
        zip_code: '',
        card_type: '',
        // card_type: 'mastercard',
      },
      isSubmittingForm: false,
      updateSubscriptions: true,
    };

    this.updatePaymentMethodAttribute = this.updatePaymentMethodAttribute.bind(this);
    this.submitPaymentMethodForm = this.submitPaymentMethodForm.bind(this);
    this.formValid = this.formValid.bind(this);
    this.toggleUpdateSubscriptions = this.toggleUpdateSubscriptions.bind(this);
  }

  updatePaymentMethodAttribute(opts) {
    let newNewPaymentMethod = Object.assign({}, this.state.newPaymentMethod);

    let hasErrors = false;
    //special validators
    if (opts.key === 'number') {

      if (opts.value.length > 16) {
        hasErrors = true;
      }

      if (opts.value.length > 0 && isNaN(opts.value)) {
        hasErrors = true;
      }

      if (!hasErrors) {
        //infer the credit card type
        const type = creditCardType(opts.value);
        if (type.length === 1) {
          newNewPaymentMethod['card_type'] = type[0].type;
        } else {
          newNewPaymentMethod['card_type'] = 'unknown';
        }
        newNewPaymentMethod['number'] = opts.value;
      }
    } else if (opts.key === 'cvv') {
      if (opts.value.length > 4) {
        hasErrors = true;
      }

      if (opts.value.length > 0 && isNaN(opts.value)) {
        hasErrors = true;
      }

      if (!hasErrors) {
        newNewPaymentMethod['cvv'] = opts.value;
      }
    } else {
      newNewPaymentMethod[opts.key] = opts.value;
    }
    this.setState({
      newPaymentMethod: newNewPaymentMethod
    })
  }

  formValid() {
    const newPaymentMethod = this.state.newPaymentMethod;
    const valid =
      isValidZipCode(newPaymentMethod.zip_code) &&
      isValidCardNumber(newPaymentMethod.number) &&
      isValidCvv(newPaymentMethod.cvv) &&
      newPaymentMethod.address_1 &&
      newPaymentMethod.first_name.length >= 2 &&
      newPaymentMethod.last_name.length >= 2 &&
      newPaymentMethod.address_1.length >= 2 &&
      isValidExpirationDate(newPaymentMethod.expiration_month, newPaymentMethod.expiration_year);
    return (valid);
  }

  submitPaymentMethodForm(e) {
    e.preventDefault();
    if (this.state.isSubmittingForm) return;
    if (!this.formValid()) return;

    this.setState({
      isSubmittingForm: true,
    }, () => {
      let data = this.state.newPaymentMethod;
      data.updateSubscriptions = this.state.updateSubscriptions;

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You created a payment method!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/payment_methods');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to create an payment method!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isSubmittingForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Creating a payment method...'});
      this.props.dispatch(warn);

      const action = Actions.paymentMethod.createPaymentMethod(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  toggleUpdateSubscriptions() {
    this.setState({
      updateSubscriptions: !this.state.updateSubscriptions,
    })
  }

  render() {
    return(
      <PaymentMethod
        {...this.props}
        newPaymentMethod={this.state.newPaymentMethod}
        isSubmittingForm={this.state.isSubmittingForm}
        headerText="Add a new payment method"
        toggleUpdateSubscriptions={this.toggleUpdateSubscriptions}
        updateSubscriptions={this.state.updateSubscriptions}
        updatePaymentMethodAttribute={this.updatePaymentMethodAttribute}
        submitPaymentMethodForm={this.submitPaymentMethodForm}
        formValid={this.formValid}
        confirmText="Create"
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
  };
}

export default connect(mapStateToProps)(withRouter(NewPaymentMethodContainer));
