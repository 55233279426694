const fetchEarfleekOrder = (data, success, error, complete) => {
  return {
    type: 'API:EARFLEEK_ORDER:GET',
    API: true,
    method: 'GET',
    url: `/api/v1/earfleek_orders/${data.id}`,
    success,
    error,
    complete,
  };
};

export default {
  fetchEarfleekOrder,
};
