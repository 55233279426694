import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';

const Shipment = (props) => {

  if (!props.shipment) {
    return(<div></div>);
  }

  var dummy = (<div></div>);
  if (!props.earfleekProducts) return dummy;
  if (!props.earfleekProducts.products) return dummy;
  if (props.earfleekProducts.products.length === 0) return dummy;

  let slices = [[]];
  let dupedProducts = _.clone(props.earfleekProducts.products);
  while (dupedProducts.length > 0) {
    if (slices[slices.length -1].length === 4) {
      slices.push([]);
    }
    slices[slices.length -1].push(dupedProducts.shift());
  }

  return(
    <section id='shipment-content'>
      <div id='products-grid' className='container'>
        <div id='explainer-area' className='col-sm-12 col-md-12 col-lg-12' onClick={() => props.toggleExplainer()}>
          <div className='explainer-heading'>
            <div className='actual-text'>
              ABOUT THIS PAGE
            </div>
            <div className={`toggler ${props.explainerOpen}`}>
              {'\u25b6'}
            </div>
          </div>
          <Collapse isOpened={props.explainerOpen}>
            <div className='explainer-body'>
              <p>
                This is EarFleek's Premiere self-styling page! On this page you are able to see earrings that we currently have in stock, and choose your perfect style!
              </p>
              <p>
                If you don't select a style below, our expert in house styling system will match you with a pair of earrings, like it always has. Your earrings must be chosen within 72 hours of receiving your notification email!
              </p>
              <p>
                Your shipment will go in our monthly batch - we ship out on approximately the 15th of each month!
              </p>
              {/*<p>*/}
                {/*Depending on the date of selection, your shipment will go in one of our two monthly batches - we ship on approximately the 1st and 15th of each month!*/}
              {/*</p>*/}
            </div>
          </Collapse>
        </div>
        {_.map(slices, (slice, idx) => {
          var r = generateBigRow(props, slice, idx);
          return r;
        })}
      </div>
    </section>
  )
};

const generateBigRow = (props, slice, idx) => {
  if (slice.length === 0) return;
  let internalStuff;

  internalStuff = (
    <div className='placeholder-wrapper' key={idx}>
      {generateProductCell(props, slice[0])}
      {generateProductCell(props, slice[1])}
      {generateProductCell(props, slice[2])}
      {generateProductCell(props, slice[3])}
    </div>
  );

  return internalStuff;
};

const generateProductCell = (props, product) => {
  if (product === undefined) return (<div className='col-sub-div'></div>);

  let imagePreview;
  if (product.catalog_photos.length > 0) {
    const src = product.catalog_photos[0].image.doublezoom.url;
    imagePreview = (
      <img src={src} className='preview-order-image' />
    );
  } else {
    imagePreview = (
      <div className='dummy-image'>
        NO IMAGE
      </div>
    );
  }

  let cn = `col-sub-div col-lg-3 col-md-6 col-sm-6 col-xs-6`;
  let id = product.vendor_number;
  if (product.effective_count <= 0) {
    return(
      <div className={cn} id={id}>
        <div className="image-and-word-container">
          <div className="exception-word crooked">
            OUT OF STOCK
          </div>
          {imagePreview}
        </div>
        <div className='metadata-area'>
          <span className='title'>{product.title}</span>
          <div className='brand'></div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={cn} id={id}>
        <Link to={`/customer/shipments/${props.shipment.id}/research/${product.slug}`}>
          <div className="image-and-word-container">
            {imagePreview}
          </div>
          <div className='metadata-area'>
            <span className='title'>{product.title}</span>
            <div className='brand'></div>
          </div>
        </Link>
      </div>
    );
  }
};

export default Shipment;
