const initialState = {
  addresses: [],
  editingAddress: {
    to: '',
    street: '',
    unit: '',
    city: '',
    zip_code: '',
    state: '',
  },
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
  case 'API:ADDRESSES:GET:SUCCESS':
    return { ...state, addresses: response };
  case 'redux-token-auth/SIGNOUT_REQUEST_SUCCEEDED':
    return { ...state, addresses: [] };
  case 'API:ADDRESS:GET:SUCCESS':
    return { ...state, editingAddress: response };
  case 'API:CHECKOUT:CHECKOUT:SUCCESS':
    if (false && response.successful_address) {
      let clonedAddresses = [...state.addresses];

      let alreadyInState = false;
      clonedAddresses.map((addr) => {
        if (addr.id === response.successful_address.id) {
          alreadyInState = true;
        }
      });

      if (!alreadyInState) {
        clonedAddresses.push(response.successful_address);
      }
      return { ...state, addresses: clonedAddresses };
    } else {
      return { ...state };
    }
  default:
    return state;
  }
}

export default reducer;
