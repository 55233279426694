import { Link } from 'react-router-dom';
import Modal from 'react-modal';

const Votes = (props) => {
  return (
    <section id='votes-content'>
      <section className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
				      <span>
                <h1>Your Votes:</h1>
              </span>
            </div>
            <div className="col-xs-6 col-lg-6 col-md-6 col-sm-6 text-center disliked-section">
              Hated:
              <div className="content row">
                {props.dislikes.map((ep) => {
                  return(
                    <VoteThumbnail key={ep.id} earfleekProduct={ep} state="disliked"/>
                  )
                })}
              </div>
            </div>
            <div className="col-xs-6 col-lg-6 col-md-6 col-sm-6 text-center liked-section">
              Loved:
              <div className="content row">
                {props.likes.map((ep) => {
                  return(
                    <VoteThumbnail key={ep.id} earfleekProduct={ep} state="liked"/>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="content row">
            <div className="col-xs-12 text-center">
              Unvoted:
              <div className="content row">
                {props.unvoted.map((ep) => {
                  return(
                    <VoteThumbnail key={ep.id} earfleekProduct={ep} state="unvoted"/>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const VoteThumbnail = (props) => {
  const imgStyle = {
    backgroundImage: `url(${props.earfleekProduct.catalog_photos[0].image.medium.url})`,
  };

  return (
    <div className='col-lg-3 col-xs-12 col-sm-12 col-md-6 vote-item'>
      <div className='col-xs-12 square-img-container'>
        <div style={imgStyle} className='shop-listing-item__image square-img-container'></div>
      </div>
    </div>
  );
}

//<div className='col-lg-1 col-md-3 col-sm-4 col-xs-6 square-img-container'>
//  <div style={imgStyle} className='shop-listing-item__image square-img-content'></div>
//</div>


export default Votes;
