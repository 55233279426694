import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Tag from './tag.jsx';

class TagContainer extends React.Component {
  constructor() {
    super();

    this.state = {
    };
  }

  componentDidMount() {
    if (this.props.tag && this.props.tag.page_title) {
      document.title = `${this.props.tag.page_title}`;

      if (this.props.tag.seo_optimized) {
        document.querySelector('meta[name="description"]').setAttribute("content", this.props.tag.description);
      }
    }
    this.fetchEarfleekTag();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevProps.tag, this.props.tag)
    if ((Object.keys(prevProps.tag).length > 0) && prevProps.tag.name !== this.props.tag.name) {
      document.title = `${this.props.tag.page_title}`;

      if (this.props.tag.seo_optimized) {
        document.querySelector('meta[name="description"]').setAttribute("content", this.props.tag.description);
      }
    }
  }

  fetchEarfleekTag() {
    const action = Actions.products.fetchEarfleekTag(this.props.match.params.id);
    this.props.dispatch(action);
  }

  render() {
    return (
      <Tag
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    products: state.products.earfleekProducts.products,
    tag: state.products.tag,
  };
}

export default connect(mapStateToProps)(withRouter(TagContainer));
