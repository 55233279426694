import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import '../../../../../assets/stylesheets/components/consumer/shipments/confirmed_shipment.scss';


const ConfirmedShipment = (props) => {
  if (!props.shipment.earfleek_premiere_product) {
    return (<section></section>);
  }
  let src;

  try {
    src = props.shipment.earfleek_premiere_product.catalog_photos[0].image.doublezoom.url;
  } catch (err) {
    src = '';
  }

  let shipmentText;
  if ((props.shipment.status === 'shipped') && props.shipment.label && props.shipment.label.easypost_json) {
    const trackingNumber = JSON.parse(props.shipment.label.easypost_json).tracking_code;

    let trackingLink = (<a href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`} target="_blank">{trackingNumber}</a>);
    if (trackingNumber.toString().length === 30) {
      trackingLink = (<a href={`https://www.dhl.com/us-en/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=${trackingNumber}`} target="_blank">{trackingNumber}</a>);
    }
    shipmentText = (<p>Nice choice! Your shipment is on its way! Tracking Number: {trackingLink}</p>);
  } else if (props.shipment.status === 'unshipped') {
    if (props.shipment.styling_algorithm === 'premiere-customer') {
      shipmentText = (<p>Nice choice! Your shipment will go out in our next mailing!</p>);
    } else if (props.shipment.styling_algorithm === 'premiere-auto-stylist') {
      shipmentText = (<p>Our team of stylists selected a pair for you after waiting 3 days for your self-style window to close. This shipment will go out in our next mailing!</p>);
    }
  } else if (props.shipment.status === 'cancelled') {
    shipmentText = (<p>Your shipment is cancelled and will not leave our warehouse</p>);
  }
  return (
    <section id='shipment-content' className='shipment-container'>
      <div className='col-md-12 confirm-explainer'>
        <div className='confirm-explain-header'>
          {shipmentText}
          <p>Questions? email <span className='emailcontact'>support@earfleek.com</span></p>
          <p>Refer to your Customer ID <span className='emailcontact'>{props.customer.id}</span></p>
        </div>
        <div className='confirm-explainer-pic'>
          <h3>{props.shipment.earfleek_premiere_product.title}:</h3>
          <img src={src} alt=""/>
        </div>
      </div>
    </section>
  );
};

export default ConfirmedShipment;
