const fetchPolicy = () => {
  return {
    type: 'API:POLICY:GET',
    API: true,
    url: `/api/v1/policy`,
  };
};

export default {
  fetchPolicy,
};
