import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/payment_methods/payment_methods.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';

import PaymentMethods from './payment_methods.jsx';

class PaymentMethodsContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      isDeletingPaymentMethod: false,
    };

    this.deletePaymentMethod = this.deletePaymentMethod.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Payment Methods';
    this.fetchPaymentMethods();
  }

  fetchPaymentMethods() {
    const action = Actions.paymentMethod.getPaymentMethods();
    this.props.dispatch(action);
  }

  deletePaymentMethod(e, id) {
    e.preventDefault();
    if (this.state.isDeletingPaymentMethod) return;

    this.setState({
      isDeletingPaymentMethod: true,
    }, () => {
      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You deleted this payment method!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.fetchPaymentMethods();
        this.setState({
          isDeletingPaymentMethod: false,
        });
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to delete this payment method!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isDeletingPaymentMethod: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Deleting a payment method...'});
      this.props.dispatch(warn);

      const action = Actions.paymentMethod.deletePaymentMethod(
        {id: id},
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  render() {
    return(
      <PaymentMethods
        {...this.props}
        deletePaymentMethod={this.deletePaymentMethod}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    paymentMethods: state.paymentMethods.paymentMethods,
  };
}

export default connect(mapStateToProps)(withRouter(PaymentMethodsContainer));
