import { generateAuthActions } from 'redux-token-auth';

let authUrl;
const env = window.__PRELOADED_STATE__.app.environment;

switch (env) {
  case 'development':
    authUrl = '/api/v1/auth';
    break;
  case 'staging':
    authUrl = '/api/v1/auth';
    break;
  default:
    authUrl = '/api/v1/auth';
}

const config = {
  authUrl,
  userAttributes: {
    name: 'name',
    first_name: 'first_name',
    last_name: 'last_name',
    email: 'email',
    id: 'id',
    opted_out_of_mandatory_prepay: 'opted_out_of_mandatory_prepay',
  },
  storage: {
    flushGetRequests: false,
  },
  localStorageKeysPrefix: '',
};

const {
  signInUser,
  signOutUser,
  verifyCredentials,
  verifyToken,
} = generateAuthActions(config);

export default {
  signInUser,
  signOutUser,
  verifyCredentials,
  verifyToken,
};
