import { Link } from 'react-router-dom';
import DailyDealsContainer from '../products/daily_deals_container.jsx';
import { Collapse } from 'react-collapse';

const Account = (props) => {
  if (!props.customer) {
    return (<div></div>)
  }
  const tinderUrl = `/tg/${props.customer.tinder_game_auth_token}?show_all=true`;

  const votesUrl = `/customer/votes`;

  let togglerClassName = "toggler";

  // if

  if (props.dealsExpanded) {
    togglerClassName += " rotated"
  }

  return (
    <section id='account-content'>
      <section id="cmp_e726b8aa-b89b-4982-88dd-aeb3ca86fc68" className="container">
        <section className="account-header">
          <div className="row content">
            <div className="col-lg-4 col-xs-12 flex-center">
              <h2>EarFleek - Account Information</h2>
            </div>
            <div className="col-lg-8 col-xs-12">
              <Link to="/customer/refer" className="btn referral-more-btn btn-primary primary-button pull-right cj-view-referral">Refer friends to EarFleek!</Link>
            </div>
          </div>
        </section>
        <section className="my-account">
          <div className="row content">
            <div className="col-lg-8 col-xs-12">
              {true && (props.dailyDeals.length > 0) && <div className="panel panel-cratejoy">
                <div
                  className="panel-heading deals-heading"
                  onClick={() => { props.toggleDeals() }}
                >
                  <h3 className="panel-title">EarFleek Daily Deals</h3>
                  <div className={togglerClassName}>
                    {/*{'\u2715'}*/}
                    {/*{'\uFF1E'}*/}
                    &rsaquo;
                  </div>
                </div>
                <Collapse isOpened={props.dealsExpanded}>
                  <div className="panel-footer">
                    <div className="row">
                      <div className="col-lg-12 col-xs-12">
                        <div className="daily-deals-container">
                          <DailyDealsContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>}
              <div className="panel panel-cratejoy">
                <div className="panel-heading">
                  <h3 className="panel-title">Shipment History</h3>
                </div>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12">
                      <Link
                        to="/customer/history"
                        className="btn btn-large btn-primary pull-right cj-edit-basic_info"
                      >
                        View Shipment History
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {false && (props.surveyOptions.length > 0) && <div className="panel panel-cratejoy">
                <div className="panel-heading">
                  <h3 className="panel-title">Future Products Survey</h3>
                </div>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12">
                      <div className="responses-header">If EarFleek were to offer a subscription to a new category of products, which of the following would you prefer the MOST?</div>
                      <br/>
                      <br/>
                      <div className="responses-container">
                        {props.surveyOptions.map((option, idx) => {
                          return(
                            <SurveyOption key={idx} option={option} {...props}/>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              <div className="panel panel-cratejoy">
                <div className="panel-heading">
                  <h3 className="panel-title">Account Settings</h3>
                </div>
                <table className="table account-info">
                  <thead>
                  <tr>
                    <th className="col-lg-3 info-label">Name</th>
                    <th className="col-lg-4 info-label">Email</th>
                    <th className="col-lg-2 info-label">Password</th>
                    <th className="col-lg-2 info-label">Member since</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td className="col-lg-3 info-value  ">{props.customer.first_name} {props.customer.last_name}</td>
                    <td className="col-lg-4 info-value  ">{props.customer.email}</td>
                    <td className="col-lg-2 info-value  ">********</td>
                    <td className="col-lg-2 info-value  ">{moment(props.customer.created_at).format("MMM Do YYYY")}</td>
                  </tr>
                  </tbody>
                </table>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12">
                      <Link
                        to="/customer/edit"
                        className="btn btn-large btn-primary pull-right cj-edit-basic_info"
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <AddressesPanel {...props}/>
              <PaymentMethodsPanel {...props} />
              <div className="panel panel-cratejoy tinder-game-panel">
                <div className="panel-heading">
                  <h3 className="panel-title">EarFleek Inventory Voting Game</h3>
                </div>
                <div className="status-body panel-heading">
                  You are currently enrolled in the EarFleek Inventory Voting Game. Click to play the game!
                </div>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12" id="tinder-button">
                      {false && <Link to={votesUrl} className="btn btn-large btn-primary pull-left">View Votes</Link>}
                      <Link to={tinderUrl} className="btn btn-large btn-primary pull-right">Play Game Now</Link>
                    </div>
                  </div>
                </div>

              </div>
              <div className="panel panel-cratejoy tinder-game-panel">
                <div className="panel-heading">
                  <h3 className="panel-title">Shipment Notifications</h3>
                </div>
                <div className="status-body panel-heading">
                  Please visit <Link to="/customer/history">this</Link> page to see information about your shipment history!
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xs-12">
              <div className="panel panel-cratejoy">
                <div className="panel-heading">
                  <h3 className="panel-title">Your Subscription(s)</h3>
                </div>
                {props.subscriptions && props.subscriptions.map((subscription) => {
                  return(
                    <table className="table subscription-info" key={subscription.id}>
                      <tbody className="account-view-subscriptions">
                        <tr className="">
                          <td className="col-lg-4 info-label ">Product</td>
                          <td className="col-lg-8 info-value ">{subscription.humanized_product}</td>
                        </tr>
                        <tr className="">
                          <td className="col-lg-4 info-label ">Term</td>
                          <td className="col-lg-8 info-value ">{subscription.humanized_term}</td>
                        </tr>
                        <tr className="">
                          <td className="col-lg-4 info-label ">Price</td>
                          <td className="col-lg-8 info-value ">${(subscription.subscription_product.price_cents / 100).toFixed(2)}</td>
                        </tr>
                        <tr className="">
                          <td className="col-lg-4 info-label ">Coupons</td>
                          <td className="col-lg-8 info-value ">{subscription.coupon}</td>
                        </tr>
                        <tr className="">
                          <td className="col-lg-4 info-label ">Status</td>
                          <td className="col-lg-8 info-value status ">{subscription.state}</td>
                        </tr>
                        {(subscription.state === 'active') && <tr className="">
                          <td className="col-lg-4 info-label ">Renews On (approximate)</td>
                          <td className="col-lg-8 info-value renews ">{moment(subscription.end_date).format("MMM Do, YYYY")}</td>
                        </tr>}
                        <tr className="">
                          <td className="col-lg-4 info-label ">Reference</td>
                          <td className="col-lg-8 info-value reference ">{subscription.id}</td>
                        </tr>
                        <tr className="">
                          <td className="col-lg-4 info-label">Credit Card</td>
                          {subscription.payment_method && <td className="col-lg-8 info-value sub-pay-info">
                            <div className="sub-pay-info-cc-icon">
                              <div className={`creditcard-${subscription.payment_method.card_type}`}></div>
                            </div>
                            <div className="sub-pay-info-cc">
                              ****{subscription.payment_method.last_4}
                              <br />
                              {subscription.payment_method.expiration_month}/{subscription.payment_method.expiration_year}
                            </div>
                          </td>}
                          {!subscription.payment_method && <td className="col-lg-8 info-value sub-pay-info">
                            This subscription is not associated with any payment method!
                          </td>}
                        </tr>
                        <tr className="">
                          <td className="col-lg-4 info-label">Ships To</td>
                          {subscription.address && <td className="col-lg-8 info-value subscription-ship-to">
                            <p>{subscription.address.to}</p>
                            <p>{subscription.address.street}</p>
                            <p>{subscription.address.unit}</p>
                            <p>{subscription.address.city}, {subscription.address.state}</p>
                            <p>{subscription.address.zip_code}</p>
                          </td>}
                          {!subscription.address && <td className="col-lg-8 info-value sub-pay-info">
                            This subscription is not associated with any address!
                          </td>}
                        </tr>
                        <tr className="edit-button-row no-border">
                          <td className="col-lg-4 info-label "></td>
                          <td className="col-lg-8 info-value ">
                            <Link
                              to={`/customer/subscriptions/${subscription.id}`}
                              className="btn btn-med btn-primary pull-right cj-edit-sub "
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  )
};

const SurveyOption = (props) => {
  let cn = "survey-response col-lg-3 col-md-4 col-xs-6";

  if (props.currentChoice && props.currentChoice === props.option.name) {
    cn += ' selected';
  }

  return(
    <div
      className={cn}
      onClick={() => {props.surveyVote(props.option.name)}}
      key={props.option.id}
    >
      <div>
        <img
          src={`${props.option.cropped_image_path}`} alt=""
          className="survey-option-image"
        />
      </div>
      <div>
        {props.option.name}
      </div>
    </div>
  );
};

const PaymentMethodsPanel = (props) => {
  return(
    <div className="panel panel-cratejoy">
      <div className="panel-heading">
        <h3 className="panel-title">Payment Method</h3>
      </div>
      <table className="table payment-method-info">
        <thead>
        <tr>
          <th className="col-lg-2 info-label">Type</th>
          <th className="col-lg-3 info-label">Number</th>
          <th className="col-lg-1 info-label">Expiration</th>
        </tr>
        </thead>
        <tbody>
        {props.paymentMethods && props.paymentMethods.map((paymentMethod) => {
          return(
            <tr className="" key={paymentMethod.id}>
              <td className="col-lg-2 info-value">
                <div className={`creditcard-${paymentMethod.card_type}`}></div>
              </td>
              <td className="col-lg-3 info-value">***{paymentMethod.last_4}</td>
              <td className="col-lg-1 info-value">{paymentMethod.expiration_month}/{paymentMethod.expiration_year}</td>
            </tr>
          )
        })}
        </tbody>
      </table>
      <div className="panel-footer ">
        <div className="row">
          <div className="col-lg-12 col-xs-12">
            {false && <Link to="/customer/payment_methods/new" className="btn btn-large btn-primary pull-left cj-edit-address-add">Add a Payment Method</Link>}
            <Link to="/customer/payment_methods" className="btn btn-large btn-primary pull-right cj-edit-">Edit</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressesPanel = (props) => {
  return (
    <div className="panel panel-cratejoy">
      <div className="panel-heading">
        <h3 className="panel-title">Shipping Address(es)</h3>
      </div>
      <table className="table address-info">
        <thead>
          <tr>
            <th className="col-lg-2 info-label">To</th>
            <th className="col-lg-3 info-label">Address</th>
            <th className="col-lg-1 info-label">Unit</th>
            <th className="col-lg-2 info-label">City</th>
            <th className="col-lg-1 info-label">Zip</th>
            <th className="col-lg-1 info-label">State</th>
            <th className="col-lg-1 info-label">Country</th>
          </tr>
        </thead>
        <tbody>
          {props.addresses && props.addresses.map((address) => {
            return(
              <tr className="" key={address.id}>
                <td className="col-lg-2 info-value">{address.to}</td>
                <td className="col-lg-3 info-value">{address.street}</td>
                <td className="col-lg-1 info-value">{address.unit}</td>
                <td className="col-lg-2 info-value">{address.city}</td>
                <td className="col-lg-1 info-value">{address.zip_code}</td>
                <td className="col-lg-1 info-value">{address.state}</td>
                <td className="col-lg-1 info-value">{address.country}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="panel-footer ">
        <div className="row">
          <div className="col-lg-12 col-xs-12">
            {false && <Link to="/customer/addresses/new" className="btn btn-large btn-primary pull-left cj-edit-address-add">Add an Address</Link>}
            <Link to="/customer/addresses" className="btn btn-large btn-primary pull-right cj-edit-">Edit</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
