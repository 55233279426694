import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/policy/policy.scss';

const Policy = (props) => {
  return (
    <div>
      <section id="#cmp_bb3188d2-882a-4cb4-af2e-f70c887ae40c" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center" id="blabla">
              <h1>Policy</h1>
            </div>
          </div>
        </div>
      </section>
      <section id="cmp_f3cade0e-c1d1-4303-819e-a002a970ea5f" className="component-rich-text-with-button">
        <div className="col-xs-10 col-xs-offset-1">
          <div
            className="row component-rich-text-with-button__content-row"
            dangerouslySetInnerHTML={{__html: props.policy}}
          >
          </div>
        </div>
      </section>
    </div>
  );
};

export default Policy;
