import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Actions from '../../../app/actions/consumer/actions.js';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const searchDifferent = (this.props.location.search !== prevProps.location.search);
    const pathDifferent = (this.props.location.pathname !== prevProps.location.pathname);
    if (pathDifferent || searchDifferent) {
      window.scrollTo(0, 0);
      const action2 = Actions.app.collapseMobileMenu();
      this.props.dispatch(action2);
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(ScrollToTop));
