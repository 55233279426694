import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

// import addons, { mockChannel } from '@storybook/addons';
import { addons, mockChannel } from '@storybook/addons';

import TinderGame from './tinder_game.jsx';

class TinderGameContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      currentStep: 0,
      currentSwiperRef: undefined,
      cardRef: null,
      voteTicker: 0,
      viewingEarfleekProduct: undefined,
      magicAndroidInteger: 10,
      buyNowModalOpen: false,
      buyNowAddress: undefined,
      buyNowPaymentMethod: undefined,
      isBuyingNow: false,
    };

    this.setCurrentStep = this.setCurrentStep.bind(this);
    this.setCurrentSwiperRef = this.setCurrentSwiperRef.bind(this);
    this.setCardRef = this.setCardRef.bind(this);
    this.fetchEarfleekProducts = this.fetchEarfleekProducts.bind(this);
    this.logSwipe = this.logSwipe.bind(this);
    this.incrementVoteTicker = this.incrementVoteTicker.bind(this);
    this.openBuyNowModal = this.openBuyNowModal.bind(this);
    this.closeBuyNowModal = this.closeBuyNowModal.bind(this);
    this.updateBuyNowAddress = this.updateBuyNowAddress.bind(this);
    this.updateBuyNowPaymentMethod = this.updateBuyNowPaymentMethod.bind(this);
    this.tinderSubtotalString = this.tinderSubtotalString.bind(this);
    this.buyNow = this.buyNow.bind(this);
  }

  componentDidMount() {
    console.log('in mount of tgc');
    const qs = queryString.parse(this.props.location.search);
    const showAll = qs.show_all === 'true';
    this.fetchEarfleekProducts(this.props.match.params.id, showAll);
    this.fetchCustomerMetadata(this.props.match.params.id);

    //set the addon channel for the tinder game actions
    addons.setChannel(mockChannel());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addresses.length !== this.props.addresses.length) {
      // set the selected address to the last address returned
      this.setState({
        buyNowAddress: this.props.addresses[this.props.addresses.length - 1],
      })
    }
    if (prevProps.paymentMethods.length !== this.props.paymentMethods.length) {
      // set the selected address to the last address returned
      this.setState({
        buyNowPaymentMethod: this.props.paymentMethods[this.props.paymentMethods.length - 1],
      })
    }
  }

  buyNow(e) {
    e.preventDefault();

    //confirm(`Your card will be immediately charged ${this.tinderSubtotalString()}. This shipment will be sent **in addition** to any existing subscriptions. Proceed?`);
    if (this.state.isBuyingNow) return;
    this.setState({
      isBuyingNow: true,
    }, () => {
      let data = {
        addressID: this.state.buyNowAddress.id,
        paymentMethodID: this.state.buyNowPaymentMethod.id,
        earfleekProductID: this.state.viewingEarfleekProduct.id,
        tinder_game_auth_token: this.props.customer.tinder_game_auth_token,
      };

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.clearMessage();
        this.props.dispatch(callbackAction);

        alert(`You successfully checked out! An email receipt was sent!`);
        this.setState({
          isBuyingNow: false,
          buyNowModalOpen: false,
        })
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          alert(data.errors.join(';'));
        } else {
          alert('You failed to check out!');
        }

        this.setState({
          isBuyingNow: false,
          buyNowModalOpen: false,
        });
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Buying now... Please do not hit the back button or refresh the page...'}, 3000);
      this.props.dispatch(warn);

      const action = Actions.tinderGame.buyNow(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  fetchCustomerMetadata(id) {
    const action1 = Actions.tinderGame.fetchTinderGameCustomer(id);
    this.props.dispatch(action1);
    const action2 = Actions.tinderGame.fetchTinderGameAddresses(id);
    this.props.dispatch(action2);
    const action3 = Actions.tinderGame.fetchTinderGamePaymentMethods(id);
    this.props.dispatch(action3);
  }

  componentWillUnmount() {
  }

  fetchEarfleekProducts(tinderGameAuthToken, showAll) {
    const browserFingerprint = this.inferBrowserFingerprint();
    const action = Actions.tinderGame.fetchEarfleekProducts(tinderGameAuthToken, showAll, browserFingerprint);
    this.props.dispatch(action);
  }

  inferBrowserFingerprint() {
    navigator.sayswho= (function(){
      var ua= navigator.userAgent, tem,
        M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    })();
    const bf = navigator.sayswho;
    return encodeURIComponent(bf);
  }

  setCurrentStep(step) {
    let vep = undefined;
    if (step === 1) {
      vep = this.props.earfleekProducts[0];
    }
    this.setState({
      currentSwiperRef: undefined,
      viewingEarfleekProduct: vep,
      voteTicker: 0,
      currentStep: step,
    })
  }

  setCurrentSwiperRef(instance) {
    if (this.state.currentSwiperRef) {
      return;
    }

    this.setState({
      currentSwiperRef: instance,
    })
  }

  setCardRef(instance) {
    if (this.state.cardRef) {
      return;
    }

    this.setState({
      cardRef: instance,
    })
  }

  logSwipe(opts) {
    let authToken, earfleekProductID, proposedEarfleekProductID;

    const liked = opts.liked;
    const type = opts.type;
    authToken = this.props.match.params.id;

    if (type === 'ProposedEarfleekProduct') {
      proposedEarfleekProductID = opts['id'];
    }

    if (type === 'EarfleekProduct') {
      earfleekProductID = opts['id'];
    }

    const action = Actions.tinderGame.logSwipe({
      auth_token: authToken,
      earfleek_product_id: earfleekProductID,
      proposed_earfleek_product_id: proposedEarfleekProductID,
      liked: liked,
    });
    this.props.dispatch(action);
  }

  incrementVoteTicker() {
    const newCount = this.state.voteTicker + 1;
    if (newCount % this.state.magicAndroidInteger === 0) {
      this.state.currentSwiperRef.setState({index: 0});
    }

    let vep;
    if (this.state.currentStep === 1) {
      vep = this.props.earfleekProducts[newCount];
    }

    this.setState({
      viewingEarfleekProduct: vep,
      voteTicker: newCount,
    });
  }

  openBuyNowModal() {
    this.setState({
      buyNowModalOpen: true,
    })
  }

  closeBuyNowModal() {
    this.setState({
      buyNowModalOpen: false,
    })
  }

  updateBuyNowAddress(id) {
    // get the address from the addresses based on id
    let newAddress;

    this.props.addresses.map((address) => {
      if (address.id === id) {
        newAddress = address;
      }
    });

    this.setState({
      buyNowAddress: newAddress,
    })
  }

  updateBuyNowPaymentMethod(id) {
    // get the address from the addresses based on id
    let newPaymentMethod;

    this.props.paymentMethods.map((paymentMethod) => {
      if (paymentMethod.id === id) {
        newPaymentMethod = paymentMethod;
      }
    });
    this.setState({
      buyNowPaymentMethod: newPaymentMethod,
    })
  }

  tinderSubtotalString() {
    let totalString;

    if (!this.state.buyNowAddress || !this.state.viewingEarfleekProduct) return '';

    const taxRate = parseFloat(this.state.buyNowAddress.sales_tax_rate)
    if (taxRate > 0) {
      totalString = `$${((this.state.viewingEarfleekProduct.member_price_cents * (1 + taxRate)) / 100).toFixed(2)}`
    } else {
      totalString = `$${(this.state.viewingEarfleekProduct.member_price_cents / 100).toFixed(2)}`
    }

    return totalString;
  }

  render() {
    return (
      <TinderGame
        {...this.props}
        currentStep={this.state.currentStep}
        setCurrentStep={this.setCurrentStep}
        currentSwiperRef={this.state.currentSwiperRef}
        cardRef={this.state.cardRef}
        setCurrentSwiperRef={this.setCurrentSwiperRef}
        setCardRef={this.setCardRef}
        logSwipe={this.logSwipe}
        magicAndroidInteger={this.state.magicAndroidInteger}
        voteTicker={this.state.voteTicker}
        incrementVoteTicker={this.incrementVoteTicker}
        closeBuyNowModal={this.closeBuyNowModal}
        openBuyNowModal={this.openBuyNowModal}
        updateBuyNowAddress={this.updateBuyNowAddress}
        buyNow={this.buyNow}
        buyNowAddress={this.state.buyNowAddress}
        updateBuyNowPaymentMethod={this.updateBuyNowPaymentMethod}
        tinderSubtotalString={this.tinderSubtotalString}
        buyNowPaymentMethod={this.state.buyNowPaymentMethod}
        viewingEarfleekProduct={this.state.viewingEarfleekProduct}
        buyNowModalOpen={this.state.buyNowModalOpen}
      />
    );
  }
}

function mapStateToProps(state) {
  // let vep;
  // if (state.tinderGame.earfleekProducts.length > 0) {
  //   vep = state.tinderGame.earfleekProducts[0];
  // }
  return {
    earfleekProducts: state.tinderGame.earfleekProducts,
    proposedEarfleekProducts: state.tinderGame.proposedEarfleekProducts,
    customerCanPurchase: state.tinderGame.customerCanPurchase,
    addresses: state.tinderGame.addresses,
    paymentMethods: state.tinderGame.paymentMethods,
    customer: state.tinderGame.customer,
    // viewingEarfleekProduct: vep,
  };
}

export default connect(mapStateToProps)(withRouter(TinderGameContainer));
