import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Header from './header.jsx';

class HeaderContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      menuOpen: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.signOutUser = this.signOutUser.bind(this);
  }

  componentDidMount() {
  }

  signOutUser(e) {
    e.preventDefault();
    const action = Actions.auth.signOutUser();
    this.props.dispatch(action).then(() => {
      const action2 = Actions.app.setMessage({
        type: 'SUCCESS',
        text: 'You have been signed out of EarFleek!',
      }, 4000);
      this.props.dispatch(action2);
      const action3 = Actions.app.clearCurrentCustomer();
      this.props.dispatch(action3);

      this.props.history.push('/customer/login');
    }).catch((err) => {
      const action2 = Actions.app.setMessage({
        type: 'ERROR',
        text: 'You failed to sign out of EarFleek!',
      }, 4000);
      this.props.dispatch(action2);
    });
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  hackOpenLink(e) {
    e.preventDefault();
    document.getElementById('login-dropdown').className = 'dropdown open';
  }

  render() {
    return <Header
      {...this.props}
      toggleMenu={this.toggleMenu}
      menuOpen={this.state.menuOpen}
      signOutUser={this.signOutUser}
      hackOpenLink={this.hackOpenLink}
    />;
  }
}

function mapStateToProps(state) {
  let cartLength = 0;
  if (state.earfleekCart.products) {
    cartLength += state.earfleekCart.products.length;
  }
  if (state.earfleekCart.subscriptions) {
    cartLength += state.earfleekCart.subscriptions.length;
  }
  // window.localStorage.getItem('EarfleekCartSubscriptions')

  return {
    cart: state.earfleekCart,
    cartLength: cartLength,
    showMobileMenu: state.app.showMobileMenu,
    currentUser: state.reduxTokenAuth.currentUser,
  };
}

export default connect(mapStateToProps)(withRouter(HeaderContainer));
