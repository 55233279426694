import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/static_pages/premiere.scss';

import mobileBanner from 'assets/images/consumer/landing_pages/premiere/earfleek-premiere-hero-mobile.jpg'
import desktopBanner from 'assets/images/consumer/landing_pages/premiere/earfleek-premiere-hero-desktop.jpg'
import earfleekPremiereSKU from 'assets/images/consumer/landing_pages/premiere/earfleek-premiere-sku.jpg'

import { perShipmentStringSubscriptionPrice } from '../../../../app/lib/constants.js'

const Premiere = (props) => {
  const mobBannerContent = (
    <div className="cover-content mob_hide">
      <div className="component-rich-text-with-button__content-row row">
        <div className="col-xs-12 text-center">
          <span>
            <h1>EarFleek Premiere</h1>
            <h4>Take your jewelry box to another level</h4>
            <h4><span className="bold">Prices you won't find anywhere else.</span> FREE Shipping.</h4>
          </span>
        </div>
      </div>
      <div className="component-rich-text-with-button__button-row row">
        <div className="col-xs-12 text-center">
          <Link className="btn btn-large btn-primary" to="/subscribe?tier=premiere">
            SUBSCRIBE TODAY
          </Link>
        </div>
      </div>
    </div>
  );

  const desktopBannerContent = (
    <div className="cover-content mob_hide">
      <div className="component-rich-text-with-button__content-row row">
        <div className="col-xs-12 text-center">
          <span>
            <h2>EarFleek Premiere</h2>
            <h4>Take your jewelry box to another level</h4>
            <h4><span className="bold">Prices you won't find anywhere else.</span> FREE Shipping.</h4>
          </span>
        </div>
      </div>
      <div className="component-rich-text-with-button__button-row row">
        <div className="col-xs-12 text-center">
          <Link className="btn btn-large btn-primary" to="/subscribe?tier=premiere">
            SUBSCRIBE TODAY
          </Link>
        </div>
      </div>
    </div>
  );

  const mobileBannerContent = (
    <div className="cover-content desk_hide">
      <div className="component-rich-text-with-button__content-row row">
        <div className="col-xs-12 text-center">
          <span>
            <h2>EarFleek Premiere</h2>
            <h4>Take your jewelry box to another level. <span className="bold">Monthly Earrings; Free shipping.</span></h4>
          </span>
        </div>
      </div>
      <div className="component-rich-text-with-button__button-row row">
        <div className="col-xs-12 text-center">
          <Link className="btn btn-large btn-primary" to="/subscribe?tier=premiere">
            SUBSCRIBE TODAY
          </Link>
        </div>
      </div>
    </div>
  );

  const banner = (
    <div className="cover-img-content">
      <img src={desktopBanner} alt="Cover Image Desktop" className="mob_hide"/>
      <img src={mobileBanner} alt="Cover Image Mobile" className="desk_hide"/>
      {mobileBannerContent}
      {desktopBannerContent}
    </div>
  );

  const skuContainer = (
    <div className="sku-container">
      <div className="flexbox">
        <div className="col_wrap">
          <div className="product_image">
            <img src={earfleekPremiereSKU} alt="EarFleek Premiere Glamour Shot"/>
          </div>
          <div className="per_month_oder_details">
            <h3>EarFleek Premiere Subscription</h3>
            <div className="p-description">
              <p className="pheading"><strong>Includes:</strong></p>
              <ul>
                <li>One Pair of $25++ MSRP Earrings</li>
                <li>Ships Monthly; Free Shipping</li>
                <li>Optional <span style={{fontWeight: 'bold'}}>Self-customization</span></li>
              </ul>
            </div>
            <div className="finish">
              <Link className="btn btn-subscribe" to="/subscribe?tier=premiere">
                Subscribe Now
              </Link>
              <div className="notice">
                <h6 className="notice-heading">
                  {'\u27F3'} Renewal will occur on the 15th of the month following the date you sign up.
                </h6>
                <div className="notice-text">
                  After signup and renewal, we will email you a unique customization link, you will have 3 days to choose your style. You may cancel at any time prior to renewal.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //take from www.okta.com/pricing

  const features = [
    ["Free Shipping", true, true],
    ["MSRP Sent", "$10++", "$25++"],
    ["Shipping Time",	"4-5 Business Days**", "1-2 Business Days**"],
    ["Shipping Material",	"Padded Envelope",	"Premium Box & Case"],
    ["Access to exclusive Daily Deals",	true,	true],
    ["Self-Style (choose exactly what you get)", false, true],
    ["Tracked Shipping", false,	true]
  ];

  const comparisonSection = (
    <div className="Pricing">
      <div className="Wrap">
        <div className="PricingSection PricingSection--it-products">
          <div className="PricingCard PricingCard--it-single-sign-on is-open">
            <div className="PricingCard-header">
              <div className="PricingCard-column">
                <h6 className="PricingCard-title">
                  Subscription Tier Comparison:
                </h6>
              </div>
              <div className="PricingCard-column">
                <p className="PricingCard-kicker">
                  Regular
                </p>
                <div className="PricingCard-price">
                  <p className="sub-amount">
                    <span>Starts at {perShipmentStringSubscriptionPrice(12, 'regular')}/month</span>
                  </p>
                  <p className="sub-amount cta">
                    <Link to="/subscribe">Subscribe Here</Link>
                  </p>
                </div>
              </div>
              <div className="PricingCard-column">
                <p className="PricingCard-kicker">
                  Premiere
                </p>
                <div className="PricingCard-price">
                  <p className="sub-amount">
                    <span>Starts at {perShipmentStringSubscriptionPrice(12, 'premiere')}/month</span>
                  </p>
                  <p className="sub-amount cta">
                    <Link to="/subscribe?tier=premiere">Subscribe Here</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="PricingCard-content">
              {features.map((featureArray, idx) => {
                return(<FeatureRow featureArray={featureArray} key={idx}/>)
              })}
            </div>
            <div className="disclaimer">
              ** = One batch mailing occurs each month, and is started on approximately 15th of each month. Shipments are *shipped* about 2 days after that. Shipment time is calculated from that date.
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const endCTA = (
    <div className="end-cta">
      <Link to="/subscribe?tier=premiere">
        <button className="btn btn-subscribe">
          SUBSCRIBE TO PREMIERE
        </button>
      </Link>
    </div>
  );

  return (
    <section id='premiere-content' className="static-page-root-section component-rich-text-with-button">
      {banner}
      {skuContainer}
      {comparisonSection}
      {endCTA}
    </section>
  )
};

const FeatureRow = (props) => {
  return(
    <div className="PricingCard-row">
      <div className="PricingCard-column">
        <p className="PricingCard-label">
          {props.featureArray[0]}
        </p>
      </div>
      <FeatureCell val={props.featureArray[1]}/>
      <FeatureCell val={props.featureArray[2]}/>
    </div>
  );
}

const FeatureCell = (props) => {
  // <div className="PricingCard-column has-checkmark">
  //   {'\u2713'}
  // </div>

  let className = "PricingCard-column feature-cell";
  let text = props.val;

  if (props.val === true) {
    className += " has-checkmark";
    text = '\u2713';
  } else if (props.val === false) {
    className += " has-x";
    text = '\u274C';
  }


  return(
    <div className={className}>
      {text}
    </div>
  )
};

export default Premiere;
