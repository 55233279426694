import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/account/customer_edit.scss';
import Actions from '../../../../app/actions/consumer/actions.js';
import { isValidEmail } from '../../../../app/lib/validators.js';

import Edit from './edit.jsx';

class CustomerEditContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmittingEditForm: false,
      newCustomer: {
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
        password2: ''
      },
      hasSetNewCustomer: false,
    };

    this.formValid = this.formValid.bind(this);
    this.submitEditForm = this.submitEditForm.bind(this);
    this.updateNewCustomerAttribute = this.updateNewCustomerAttribute.bind(this);
  }

  componentDidMount() {
    if (!this.props.customer) {
      const action = Actions.customer.getSignedInCustomer();
      this.props.dispatch(action);
    }

    this.conditionallySetNewCustomer();
  }

  componentDidUpdate() {
    this.conditionallySetNewCustomer();
  }

  conditionallySetNewCustomer() {
    if (this.props.customer && !this.state.hasSetNewCustomer) {
      this.setState({
        newCustomer: {
          first_name: this.props.customer.first_name,
          last_name: this.props.customer.last_name,
          email: this.props.customer.email,
          password1: '',
          password2: '',
        },
        hasSetNewCustomer: true
      });
    }
  }

  componentWillUnmount() {
  }

  submitEditForm(e) {
    e.preventDefault();
    if (this.state.isSubmittingEditForm) return;
    if (!this.formValid()) return;

    this.setState({
      isSubmittingEditForm: true,
    }, () => {
      const data = this.state.newCustomer;

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You updated your information!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.setState({
          isSubmittingEditForm: false,
          hasSetNewCustomer: false,
        })
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'Oh no! There was a problem updating your information!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isSubmittingEditForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Updating your information...'});
      this.props.dispatch(warn);

      const action = Actions.customer.updateCustomerInformation(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  updateNewCustomerAttribute(opts) {
    let newNewCustomer = Object.assign({}, this.state.newCustomer);
    newNewCustomer[opts.key] = opts.value;
    this.setState({
      newCustomer: newNewCustomer,
    })
  }


  formValid() {
    let passwordBeingChanged = false;
    if (this.state.newCustomer.password1.length > 0 || this.state.newCustomer.password2.length > 0) {
      if (this.state.newCustomer.password1.length < 8) return false;
      if (this.state.newCustomer.password2.length < 8) return false;
      if (this.state.newCustomer.password1 !== this.state.newCustomer.password2) return false;
      passwordBeingChanged = true;
    }

    if (this.state.newCustomer.first_name.length < 2) return false;
    if (this.state.newCustomer.last_name.length < 2) return false;
    if (!isValidEmail(this.state.newCustomer.email)) return false;

    //if password is not getting changed, something has to differ from existing customer in order to be able to submit
    if (
      this.state.newCustomer.first_name === this.props.customer.first_name &&
      this.state.newCustomer.last_name === this.props.customer.last_name &&
      this.state.newCustomer.email === this.props.customer.email &&
      !passwordBeingChanged
    ) {
      return false
    };

    return true;
  }

  render() {
    return (
      <Edit
        {...this.props}
        isSubmittingEditForm={this.state.isSubmittingEditForm}
        updateNewCustomerAttribute={this.updateNewCustomerAttribute}
        newCustomer={this.state.newCustomer}
        submitEditForm={this.submitEditForm}
        formValid={this.formValid}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
  };
}

export default connect(mapStateToProps)(withRouter(CustomerEditContainer));
