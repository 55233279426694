import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/products/products.scss';

const Tag = (props) => {
  if (typeof props.products === 'undefined') {
    return(<div></div>);
  }

  return (
    <section id='products-content'>
      <section id="cmp_44cc9dc6-f54e-44d8-80f6-effe2e45dd3a" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
				      {props.tag && props.tag.page_title && <span>
                <h1>{props.tag.page_title}</h1>
              </span>}
            </div>
          </div>
        </div>
      </section>
      <section id='cmp_504258a4-0415-4c99-a4e5-27c67f50a544' className='component-shop-listing'>
        <div className='container-fluid'>
          <div className='row flex-center'>
            {props.products.map((product, idx) => {
              return(<EarfleekProductThumbnail product={product} key={idx} idx={idx}/>);
            })}
          </div>
        </div>
      </section>
    </section>
  )
};

const EarfleekProductThumbnail = (props) => {

  const imgStyle = {
    backgroundImage: `url(${props.product.catalog_photos[0].image.medium.url})`,
  };

  let fakeImgStyle;
  if (props.idx % 2 === 0) {
    fakeImgStyle = {
      backgroundColor: 'blue',
    };
  } else {
    fakeImgStyle = {
      backgroundColor: 'red',
    };
  }

  return(
    <div className='col-md-3 col-sm-4 col-xs-6'>
      <Link to={`/products/${props.product.slug}`} className='shop-listing-item'>
        <div className='col-xs-12 square-img-container'>
          <div style={imgStyle} className='shop-listing-item__image square-img-content'></div>
        </div>
        <h3 className='shop-listing-item__name'>{props.product.title}</h3>
        {(props.product.count > 0) && <div className='shop-listing-item__price'>${(props.product.retail_price_cents / 100).toFixed(2)}</div>}
      </Link>
    </div>
  )
};

export default Tag;
