import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Reviews from './reviews.jsx';

class ReviewsContainer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.title = 'EarFleek - Reviews';
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Reviews
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(ReviewsContainer));
