import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/refer/refer.scss';
import Actions from '../../../../app/actions/consumer/actions.js';
import { isValidEmail } from '../../../../app/lib/validators.js';

import Refer from './refer.jsx';

class ReferContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      name: '',
      email: '',
      isSubmittingReferralForm: false,
    };

    this.updateReferAttribute = this.updateReferAttribute.bind(this);
    this.formValid = this.formValid.bind(this);
    this.submitReferForm = this.submitReferForm.bind(this);
    this.copyReferralLinkToClipboard = this.copyReferralLinkToClipboard.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Refer';
    if (!this.props.customer) {
      const action = Actions.customer.getSignedInCustomer();
      this.props.dispatch(action);
    }
  }

  componentWillUnmount() {
  }

  updateReferAttribute(opts) {
    this.setState({
      [opts.key]: opts.value,
    })
  }

  copyReferralLinkToClipboard() {
    const textarea = document.createElement("textarea");
    const text = `https://www.earfleek.com/refer/${this.props.customer.earfleek_referral_code}`;
    textarea.textContent = text;
    textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');  // Security exception may be thrown by some browsers.
      const callbackAction = Actions.app.setMessage({
        type: 'SUCCESS',
        text: 'Your referral link was copied to your clipboard!',
      }, 5000);
      this.props.dispatch(callbackAction);
    } catch (ex) {
      const callbackAction2 = Actions.app.setMessage({
        type: 'ERROR',
        text: 'There was a problem copying your referral link to your clipboard!',
      }, 5000);
      this.props.dispatch(callbackAction2);
    } finally {
      document.body.removeChild(textarea);
    }
    document.execCommand('Copy');
  }

  submitReferForm(e) {
    e.preventDefault();
    if (this.state.isSubmittingReferralForm) return;
    if (!this.formValid()) return;

    this.setState({
      isSubmittingReferralForm: true,
    }, () => {
      const data = {
        email: this.state.email,
        name: this.state.name,
      };

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'Woohoo! You invited your friend to EarFleek!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.setState({
          isSubmittingReferralForm: false,
          name: '',
          email: '',
        })
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'Oh no! There was a problem referring this friend!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isSubmittingReferralForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Referring your friend...'});
      this.props.dispatch(warn);

      const action = Actions.refer.createReferralInvitation(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  formValid() {
    const valid = (this.state.name.length > 1 && isValidEmail(this.state.email));
    return (valid);
  }

  render() {
    return (
      <Refer
        {...this.props}
        updateReferAttribute={this.updateReferAttribute}
        submitReferForm={this.submitReferForm}
        formValid={this.formValid}
        email={this.state.email}
        name={this.state.name}
        copyReferralLinkToClipboard={this.copyReferralLinkToClipboard}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    isSubmittingReferralForm: state.isSubmittingReferralForm,
  };
}

export default connect(mapStateToProps)(withRouter(ReferContainer));
