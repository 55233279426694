__webpack_public_path__ = process.env.ASSET_HOST || 'dq6x9jguheej1.cloudfront.net'; // eslint-disable-line no-undef

import { render } from 'react-dom';
import ConsumerApp from './app/components/consumer/consumer_app.jsx';

import { createConsumerWithToken } from './app/actioncable/createConsumerWithToken';
import WebNotifications from './app/actioncable/WebNotificationsSubscription';
import Actions from './app/actions/consumer/actions.js';
import ConsumerStore from './app/store/consumer_store.js';

render(<ConsumerApp/>, document.getElementById('main'));

//trivial change to consumer pack. Let's see if this matters A

/**** Action cable logic ***/
window.App = {};

// if (isSignedIn === true) {
if (false) {
  //// const authToken = getAuthToken(store.getState());

  const authToken = 'dummyToken';
  createConsumerWithToken(authToken);
  WebNotifications.subscribe((data) => {
    const setMessage = Actions.app.setMessage({
      type: 'WARNING',
      text: data.body,
    });
    ConsumerStore.dispatch(setMessage);
  });
}
