import { Link } from 'react-router-dom';

const ResearchingShipment = (props) => {
  if (!props.product) {
    return (<section></section>);
  }

  let hasModelPhotos = false;
  const product = props.product;

  if ((product.showcase_photos && product.showcase_photos.length >= 1) || (product.model_photos && product.model_photos.length >= 1)) {
    hasModelPhotos = true;
  }

  let src;

  try {
    // src = props.product.catalog_photos[0].image.doublezoom.url;
    src = props.product[props.selectedImageProperty][0].image.doublezoom.url;
  } catch (err) {
    src = '/no-image.jpg'
  }

  return(
    <section id='content' className='inspecting-product-container'>
      <div className='col-md-12 back-area'>
        <Link to={`/customer/shipments/${props.shipment.id}`}>
          <button
            id='back-button'
            className='btn btn-default'
          >
            {'<'} GO BACK TO RESULTS
          </button>
        </Link>
      </div>
      <div id='main-product-image' className='col-md-6'>
        <div className="images-wrapper">
          <img src={src} alt=''/>
          <div className="selector-images">
            {hasModelPhotos && ['catalog_photos', 'model_photos', 'showcase_photos'].map((prop) => {
              if (prop === props.selectedImageProperty) {
                return;
              }
              if (product[prop].length === 0) {
                return;
              }
              return(
                <div
                  className="selector-image"
                  key={`${product.vendor_number}-${prop}`}
                  onClick={() => { props.setInspectState(prop) }}
                >
                  <img src={product[prop][0].image.thumb.url} style={{width: '100%', top}} alt={product.title}/>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id='product-info-area' className='col-md-6'>
        <div id='product-info-table' className='product-info-table'>
          Product Detail:
          <table id='attribute-table' className='table-striped'>
            <tbody>
              <tr>
                <td>Title</td>
                <td>{props.product.title}</td>
              </tr>
              <tr>
                <td>Style Type</td>
                <td>{props.product.primary_style_type}</td>
              </tr>
              <tr>
                <td>Body Type</td>
                <td>{props.product.body_type}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          id='finalize-button'
          className='btn btn-default'
          onClick={() => props.submitProductID(props.product.id)}
        >
          SEND THIS PAIR >
        </button>
      </div>
    </section>
  )
};

export default ResearchingShipment;
