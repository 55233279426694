import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import ThankYou from './thank_you.jsx';

class ThankYouContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      dealsExpanded: true,
    }

    this.toggleDeals = this.toggleDeals.bind(this);
  }

  componentDidMount() {
    //this can handle cases where customer refreshes the thank_you page
    const isSignedIn = this.props.reduxTokenAuth.currentUser.isSignedIn;

    if (this.props.becomeCustomer && (Object.keys(this.props.becomeCustomer).length > 0)) {
      const verifyOpts = {
        'access-token': this.props.becomeCustomer.access_token,
        'client': this.props.becomeCustomer.client,
        'uid': this.props.becomeCustomer.customer.uid,
      };
      const verifyAction = Actions.auth.verifyToken(verifyOpts);
      this.props.dispatch(verifyAction);
    }

    if (this.props.successfulOrders.length === 0) {
      if (window.localStorage.getItem('SuccessfulEarfleekOrders')) {
        const successfulOrders = JSON.parse(window.localStorage.getItem('SuccessfulEarfleekOrders'));
        const action = Actions.checkout.setSuccessfulOrders(successfulOrders);
        this.props.dispatch(action);
      }
    }

    if (this.props.customer) {
      this.dealsNetworkRequests();
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.reduxTokenAuth.currentUser.isSignedIn !== prevProps.reduxTokenAuth.currentUser.isSignedIn) && this.props.reduxTokenAuth.currentUser.isSignedIn === true) {
      const action = Actions.customer.getSignedInCustomer();
      this.props.dispatch(action);
    }

    if (this.props.customer && (this.props.customer !== prevProps.customer)) {
      this.dealsNetworkRequests();
    }
  }

  dealsNetworkRequests() {
    if (this.props.addresses.length === 0) {
      const action = Actions.address.getAddresses();
      this.props.dispatch(action);
    }

    if (this.props.paymentMethods.length === 0) {
      const action2 = Actions.paymentMethod.getPaymentMethods();
      this.props.dispatch(action2);
    }

    if (this.props.dailyDeals.length === 0) {
      const action3 = Actions.products.fetchDailyDeals();
      this.props.dispatch(action3);
    }
  }

  toggleDeals() {
    this.setState({
      dealsExpanded: !this.state.dealsExpanded,
    })
  }

  render() {
    return (
      <ThankYou
        {...this.props}
        dealsExpanded={this.state.dealsExpanded}
        toggleDeals={this.toggleDeals}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    becomeCustomer: state.app.becomeCustomer,
    successfulOrders: state.earfleekCart.successfulOrders,
    dailyDeals: state.products.dailyDeals,
    addresses: state.addresses.addresses,
    paymentMethods: state.paymentMethods.paymentMethods,
  };
}

export default connect(mapStateToProps)(withRouter(ThankYouContainer));
