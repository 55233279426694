import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import creditCardType from 'credit-card-type';
import '../../../../../assets/stylesheets/components/consumer/payment_methods/payment_methods.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';
import { isValidCardNumber, isValidCvv, isValidExpirationDate, isValidZipCode } from '../../../../../app/lib/validators.js';

import PaymentMethod from './payment_method.jsx';

class EditPaymentMethodContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      newPaymentMethod: {
        first_name: '',
        last_name: '',
        number: '',
        cvv: '',
        expiration_month: '',
        expiration_year: '',
        address_1: '',
        zip_code: '',
        card_type: '',
      },
      isSubmittingForm: false,
      updateSubscriptions: true,
    };

    this.updatePaymentMethodAttribute = this.updatePaymentMethodAttribute.bind(this);
    this.submitPaymentMethodForm = this.submitPaymentMethodForm.bind(this);
    this.formValid = this.formValid.bind(this);
    this.fetchPaymentMethod = this.fetchPaymentMethod.bind(this);
    this.toggleUpdateSubscriptions = this.toggleUpdateSubscriptions.bind(this);
  }

  componentDidMount() {
    this.fetchPaymentMethod();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editingPaymentMethod !== prevProps.editingPaymentMethod) {
      // initially, put what the server gets into component state
      this.setState({
        newPaymentMethod: {
          first_name: this.props.editingPaymentMethod.first_name,
          last_name: this.props.editingPaymentMethod.last_name,
          expiration_month: this.props.editingPaymentMethod.expiration_month.toString(),
          expiration_year: this.props.editingPaymentMethod.expiration_year.toString(),
          address_1: this.props.editingPaymentMethod.address_1,
          zip_code: this.props.editingPaymentMethod.zip_code,
          card_type: this.props.editingPaymentMethod.card_type,
          number: '',
          cvv: '',
        }
      })
    }
  }

  fetchPaymentMethod() {
    const action = Actions.paymentMethod.fetchPaymentMethod({id: this.props.match.params.id});
    this.props.dispatch(action);
  }

  updatePaymentMethodAttribute(opts) {
    let newNewPaymentMethod = Object.assign({}, this.state.newPaymentMethod);

    let hasErrors = false;
    //special validators
    if (opts.key === 'number') {

      if (opts.value.length > 16) {
        hasErrors = true;
      }

      if (opts.value.length > 0 && isNaN(opts.value)) {
        hasErrors = true;
      }

      if (!hasErrors) {
        //infer the credit card type
        const type = creditCardType(opts.value);
        if (type.length === 1) {
          newNewPaymentMethod['card_type'] = type[0].type;
        } else {
          newNewPaymentMethod['card_type'] = 'unknown';
        }
        newNewPaymentMethod['number'] = opts.value;
      }
    } else if (opts.key === 'cvv') {
      if (opts.value.length > 4) {
        hasErrors = true;
      }

      if (opts.value.length > 0 && isNaN(opts.value)) {
        hasErrors = true;
      }

      if (!hasErrors) {
        newNewPaymentMethod['cvv'] = opts.value;
      }
    } else {
      newNewPaymentMethod[opts.key] = opts.value;
    }
    this.setState({
      newPaymentMethod: newNewPaymentMethod
    })
  }

  formValid() {
    const newPaymentMethod = this.state.newPaymentMethod;
    const valid =
      isValidZipCode(newPaymentMethod.zip_code) &&
      isValidCardNumber(newPaymentMethod.number) &&
      isValidCvv(newPaymentMethod.cvv) &&
      newPaymentMethod.address_1 &&
      newPaymentMethod.first_name.length >= 2 &&
      newPaymentMethod.last_name.length >= 2 &&
      newPaymentMethod.address_1.length >= 2 &&
      isValidExpirationDate(newPaymentMethod.expiration_month, newPaymentMethod.expiration_year);

    return (valid);
  }

  submitPaymentMethodForm(e) {
    e.preventDefault();
    if (this.state.isSubmittingForm) return;
    if (!this.formValid()) return;

    this.setState({
      isSubmittingForm: true,
    }, () => {
      let data = this.state.newPaymentMethod;
      data.id = this.props.editingPaymentMethod.id;
      data.updateSubscriptions = this.state.updateSubscriptions;

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You updated your payment method!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/payment_methods');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to update your payment method!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isSubmittingForm: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Updating your payment method...'});
      this.props.dispatch(warn);

      const action = Actions.paymentMethod.updatePaymentMethod(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });
  }

  toggleUpdateSubscriptions() {
    this.setState({
      updateSubscriptions: !this.state.updateSubscriptions,
    })
  }

  render() {
    if (Object.keys(this.props.editingPaymentMethod).length === 0) {
      return(<div></div>);
    }
    return(
      <PaymentMethod
        {...this.props}
        newPaymentMethod={this.state.newPaymentMethod}
        isSubmittingForm={this.state.isSubmittingForm}
        toggleUpdateSubscriptions={this.toggleUpdateSubscriptions}
        updateSubscriptions={this.state.updateSubscriptions}
        headerText="Edit your payment method"
        updatePaymentMethodAttribute={this.updatePaymentMethodAttribute}
        submitPaymentMethodForm={this.submitPaymentMethodForm}
        formValid={this.formValid}
        confirmText="Update"
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    editingPaymentMethod: state.paymentMethods.editingPaymentMethod,
  };
}

export default connect(mapStateToProps)(withRouter(EditPaymentMethodContainer));
