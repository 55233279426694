const AddressSelector = (props) => {
  let selectedAddressID = 0;
  if (props.selectedAddress) {
    selectedAddressID = props.selectedAddress.id;
  }

  return(
    <select
      id="address-selector"
      className="form-control state-selector"
      value={selectedAddressID}
      onChange={(event) => props.selectAddress(parseInt(event.target.value))}
    >
      {props.addresses.map((address) => {
        let selectString = `${address.to}, ${address.street}, `;
        if (address.unit && address.unit.length >= 1) {
          selectString += `${address.unit}, `
        }
        selectString += `${address.city} ${address.state}`;
        return(
          <option
            value={address.id}
            key={address.id}
          >
            {selectString}
          </option>
        )
      })}
    </select>
  );
}

export default AddressSelector;
