export default function(amt, showZeroCents = false) {
  const dollars = Math.floor(amt);
  let cents = String(Math.round((amt - dollars) * 100));
  let positive = true;
  if (dollars < 0) {
    positive = false;
  }

  while(cents.length < 2) cents = `0${cents}`;
  let outString = '';
  if (showZeroCents || cents !== '00') {
    outString = `$${dollars}.${cents}`;
  } else {
    outString = `$${dollars}`;
  }

  if(!positive) {
    outString = '-' + outString.replace(/-/g, '');
  }

  return(outString);
}
