import 'whatwg-fetch';
import { stringify } from 'qs';

const getHeaders = (action) => {
  let headers = {
    'Accept': 'application/json', // needed for request.format.json?
    'Content-Type': 'application/json',
    'X-REQUESTED-WITH': 'XMLHttpRequest', // needed for request.xhr? which sidesteps mobylette
  };
  if (action.is_form) {
    headers = {
      'Accept': 'application/json', // needed for request.format.json?
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      'X-REQUESTED-WITH': 'XMLHttpRequest', // needed for request.xhr? which sidesteps mobylette
    };
  }

  // this is for the consumation of the forgot password token flow
  if (action.type === 'redux-token-auth/UPDATE_PASSWORD') {
    headers['uid'] = action.data.email;
    headers['client'] = action.data.client;
    headers['access-token'] = action.data.accessToken;
  } else {
    headers['uid'] = localStorage.uid;
    headers['client'] = localStorage.client;
    headers['access-token'] = localStorage['access-token'];
  }

  //used currently for external api request to zippopotam
  if (action.headers) headers = action.headers;
  return headers;
};

const success = (dispatch, action, data) => {
  dispatch({ type: `${action.type}:SUCCESS`, response: data, data: action.data });

  if (typeof action.success === 'function') {
    action.success(dispatch, data, action.data);
  }
};

const error = (dispatch, action, data) => {
  dispatch({ type: `${action.type}:ERROR`, response: data, data: action.data });

  if (typeof action.error === 'function') {
    action.error(dispatch, data, action.data);
  }
};

const complete = (dispatch, action, data) => {
  dispatch({ type: `${action.type}:COMPLETE`, response: data, data: action.data });

  if (typeof action.complete === 'function') {
    action.complete(dispatch, data, action.data);
  }
};

export default ({ dispatch }) => next => action => {
  if (!action.API) return next(action);

  dispatch({ type: `${action.type}:STARTED`, data: action.data });

  const method = action.method || 'GET';

  let body, data, url = action.url;

  if (method === 'GET' && action.data) {
    // get does not allow query parameters in the body
    // this accepts { key: value } where value can either be a string or an array.
    const query = stringify(action.data, { arrayFormat: 'brackets' });
    url += `?${query}`;
  } else if (method !== 'GET') {
    data = action.data || {};
    if (method === 'DELETE') data._method = 'DELETE';
    body = (action.is_form) ? stringify(action.data, { arrayFormat: 'brackets' }) : JSON.stringify(data);
  }

  const cache = window.__API_CACHE__;
  if (method === 'GET' && cache[url]) {
    success(dispatch, action, cache[url]);
    complete(dispatch, action);
    delete cache[url];
    return;
  }

  let credentials = 'same-origin';

  let headers = getHeaders(action);

  fetch(url, {
    method,
    body,
    headers,
    credentials,
  }).then((response) => {
    if (response.status >= 200 && response.status < 400) {
      // success
      response.json().then((data) => {
        // body is json
        success(dispatch, action, data);
        complete(dispatch, action, data);
      }).catch(() => {
        // body is not json -> sign out does this
        // errors can also get raised here from
        // invalid component render in response to success
        success(dispatch, action);
        complete(dispatch, action);
      });
    } else {
      // errors
      response.json().then((data) => {
        // body is json
        error(dispatch, action, data);
        complete(dispatch, action, data);
      }).catch(() => {
        // body is not json
        error(dispatch, action);
        complete(dispatch, action);
      });
    }
  }).catch((e) => {
    // network error
    error(dispatch, action);
    complete(dispatch, action);
    if (!e.match(/TypeError: Failed to fetch/)) {
      console.error(e);
    }
  });
};
