import { Link } from 'react-router-dom';

const preventClick = (e, props) => {
  e.preventDefault();
  props.submitEditForm(e);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.submitEditForm(e);
  }
};

const canSubmit = (props) => {
  return props.formValid() && !props.isSubmittingEditForm;
};

const Edit = (props) => {
  if (!props.customer) {
    return (<div></div>)
  }
  return (
    <section id='customer-edit-content'>
      <section id="cmp_df96442d-5a85-416d-9e47-2c7cdf41dac6" className="component-customer-edit">
        <div className="account-header">
          <div className="container-fluid">
            <h2 className="text-center">
              Update your information
            </h2>
          </div>
        </div>
        <div className="my-account">
          <div className="container-fluid">
            <div className="form-container">
              <div className="row content">
                <div className="col-lg-8 col-lg-offset-2 col-xs-12">
                  <div className="col-lg-8 col-lg-offset-2 col-xs-12" id="account-edit-view">
                    <div className="panel panel-cratejoy">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          Basic Information
                        </h3>
                      </div>
                      <InputTable {...props}/>
                      <div className="panel-footer">
                        <div className="row">
                          <div className="col-lg-12 col-xs-12">
                            <button
                              className={`btn btn-primary pull-right ${props.formValid() ? '': 'disabled'}`}
                              onClick={(e) => { props.formValid() && props.submitEditForm(e) }}
                            >
                              Save
                            </button>
                            <Link to="/customer/account" className="btn btn-primary pull-left">Cancel</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const InputTable = (props) => {
  return(
    <table className="table account-info">
      <tbody>
        <tr className="">
          <td className="col-lg-4 info-label ">First Name</td>
          <td className="col-lg-8 info-value ">
            <input
              className="form-control"
              name="name"
              placeholder="First Name"
              required=""
              type="text"
              value={props.newCustomer.first_name}
              onChange={(event) => props.updateNewCustomerAttribute({ key: 'first_name', value: event.target.value })}
              onKeyPress={(e) => {catchReturn(e, props)}}
            />
          </td>
        </tr>
        <tr className="">
          <td className="col-lg-4 info-label ">Last Name</td>
          <td className="col-lg-8 info-value ">
            <input
              className="form-control"
              name="name"
              placeholder="Last Name"
              required=""
              type="text"
              value={props.newCustomer.last_name}
              onChange={(event) => props.updateNewCustomerAttribute({ key: 'last_name', value: event.target.value })}
              onKeyPress={(e) => {catchReturn(e, props)}}
            />
          </td>
        </tr>
        <tr className="">
          <td className="col-lg-4 info-label ">EMail</td>
          <td className="col-lg-8 info-value ">
            <input
              className="form-control"
              id="email"
              name="email"
              placeholder="EMail"
              required=""
              type="email"
              value={props.newCustomer.email}
              onChange={(event) => props.updateNewCustomerAttribute({ key: 'email', value: event.target.value })}
              onKeyPress={(e) => {catchReturn(e, props)}}
            />
          </td>
        </tr>
        <tr className="">
          <td className="col-lg-4 info-label ">
            New Password
          </td>
          <td className="col-lg-8 info-value ">
            <input
              className="form-control"
              id="password1"
              name="password1"
              placeholder="New Password"
              type="password"
              value={props.newCustomer.password1}
              onChange={(event) => props.updateNewCustomerAttribute({ key: 'password1', value: event.target.value })}
              onKeyPress={(e) => {catchReturn(e, props)}}
            />
          </td>
        </tr>
        <tr className="">
          <td className="col-lg-4 info-label">
            Repeat New Password
          </td>
          <td className="col-lg-8 info-value">
            <input
              className="form-control"
              id="password2"
              name="password2"
              placeholder="New Password Again"
              type="password"
              value={props.newCustomer.password2}
              onChange={(event) => props.updateNewCustomerAttribute({ key: 'password2', value: event.target.value })}
              onKeyPress={(e) => {catchReturn(e, props)}}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );

  return(
    <div>
    </div>
  )
};

export default Edit;
