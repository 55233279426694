import { Link } from 'react-router-dom';
import { creditCardMonthsArray, creditCardYearsArray } from '../../../../../app/lib/constants.js';

const PaymentMethod = (props) => {

  const monthsArray = creditCardMonthsArray();
  const yearsArray = creditCardYearsArray();
  return(
    <section id='new-payment-method-content'>
      <section id="cmp_df96442d-5a85-416d-9e47-2c7cdf41dac6">
        <div className="account-header">
          <div className="container-fluid">
            <h2 className="text-center">
              {props.headerText}
            </h2>
          </div>
        </div>
        <div className="my-account">
          <div className="container-fluid">
            <div className="form-container">
              <div className="row content">
                <div className="col-lg-8 col-lg-offset-2 col-xs-12">
                  <div className="col-lg-8 col-lg-offset-2 col-xs-12" id="account-edit-view">
                    <div className="panel panel-cratejoy">
                      <div className="panel-heading">
                        <h3 className="panel-title">Basic Information</h3>
                      </div>
                      <table className="table payment-method-info">
                        <tbody>
                          <tr>
                            <td className="col-lg-4 info-label no-border">
                              First Name
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <input
                                className="form-control"
                                placeholder={`Jane`}
                                required=""
                                type="text"
                                value={props.newPaymentMethod['first_name']}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'first_name', value: event.target.value })}
                                onKeyPress={(e) => {catchReturn(e, props)}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-lg-4 info-label no-border">
                              Last Name
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <input
                                className="form-control"
                                placeholder={`Doe`}
                                required=""
                                type="text"
                                value={props.newPaymentMethod['last_name']}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'last_name', value: event.target.value })}
                                onKeyPress={(e) => {catchReturn(e, props)}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-lg-4 info-label no-border">
                              Billing Street Address
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <input
                                className="form-control"
                                placeholder={`123 Fake St`}
                                required=""
                                type="text"
                                value={props.newPaymentMethod['address_1']}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'address_1', value: event.target.value })}
                                onKeyPress={(e) => {catchReturn(e, props)}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-lg-4 info-label no-border">
                              Zip Code
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <input
                                className="form-control"
                                placeholder={'Zip Code'}
                                required=""
                                type="text"
                                value={props.newPaymentMethod['zip_code']}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'zip_code', value: event.target.value })}
                                onKeyPress={(e) => {catchReturn(e, props)}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className={`col-lg-4 info-label no-border`}>
                              Number <div className={`creditcard-${props.newPaymentMethod.card_type}`}></div>
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <input
                                className="form-control"
                                placeholder={`**** **** **** ****`}
                                required=""
                                type="number"
                                value={props.newPaymentMethod['number']}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'number', value: event.target.value })}
                                onKeyPress={(e) => {catchReturn(e, props)}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="col-lg-4 info-label no-border">
                              CVV
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <input
                                className="form-control"
                                placeholder={`000`}
                                required=""
                                type="number"
                                value={props.newPaymentMethod['cvv']}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'cvv', value: event.target.value })}
                                onKeyPress={(e) => {catchReturn(e, props)}}
                              />
                            </td>
                          </tr>
                          <tr >
                            <td className="col-lg-4 info-label no-border">
                              Expiration Month
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <select
                                className="form-control state-selector"
                                value={props.newPaymentMethod.expiration_month}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'expiration_month', value: event.target.value })}
                              >
                                <option
                                  value=""
                                >
                                </option>
                                {monthsArray.map((arr) => {
                                  return(
                                    <option
                                      value={arr[0]}
                                      key={arr[0]}
                                    >
                                      {`${arr[1]} - ${arr[2]}`}
                                    </option>
                                  )
                                })}
                              </select>
                            </td>
                          </tr>
                          <tr >
                            <td className="col-lg-4 info-label no-border">
                              Expiration Year
                            </td>
                            <td className="col-lg-8 info-value no-border">
                              <select
                                className="form-control state-selector"
                                value={props.newPaymentMethod.expiration_year}
                                onChange={(event) => props.updatePaymentMethodAttribute({ key: 'expiration_year', value: event.target.value })}
                              >
                                <option
                                  value=""
                                >
                                </option>
                                {yearsArray.map((int) => {
                                  return(
                                    <option
                                      value={int}
                                      key={int}
                                    >
                                      {int}
                                    </option>
                                  )
                                })}
                              </select>
                            </td>
                          </tr>
                          <tr
                            className="update-subscriptions"
                            onClick={() => { props.toggleUpdateSubscriptions() }}
                          >
                            <td className="col-lg-4 info-label no-border">
                              Update Subscriptions/Shipments
                            </td>
                            <td
                              className="col-lg-8 info-value no-border"
                            >
                              <input
                                id="update_subscriptions"
                                name="update_subscriptions"
                                type="checkbox"
                                checked={props.updateSubscriptions}
                                readOnly={true}
                              >
                              </input>
                              If selected, all subscriptions will be updated to use the new payment method.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="panel-footer no-border">
                        <div className="row">
                          <div className="col-lg-12 col-xs-12">
                            <div id="edit-payment-method-footer">
                              <button
                                id="save-form"
                                className={`btn btn-primary pull-right ${!canSubmit(props) ? 'disabled' : ''}`}
                                onClick={(e) => {preventClick(e, props)}}
                              >
                                {props.confirmText}
                              </button>
                              <Link to="/customer/payment_methods" className="btn btn-primary pull-left">
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const canSubmit = (props) => {
  return props.formValid() && !props.isSubmittingForm;
};

const preventClick = (e, props) => {
  e.preventDefault();
  props.submitPaymentMethodForm(e);
};

const catchReturn = (e, props) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    props.submitPaymentMethodForm(e);
  }
};

export default PaymentMethod;
