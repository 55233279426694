import { Link } from 'react-router-dom';
import Modal from 'react-modal';

const History = (props) => {
  // <SurveyModal {...props}/>
  return (
    <section id='history-content'>
      <div className="replace-with-old-survey-modal"></div>
      <section id="cmp_44cc9dc6-f54e-44d8-80f6-effe2e45dd3a" className="component-rich-text-center">
        <div className="container-fluid">
          <div className="content row">
            <div className="col-xs-12 text-center">
				      <span>
                <h1>Shipment History</h1>
              </span>
            </div>
            <div
              className="col-xs-12 text-center shipping-address-choice"
              onClick={() => { props.toggleAddressesShown() }}
            >
              <input
                id="is_gift"
                name="is_gift"
                type="checkbox"
                checked={props.addressesShown}
                readOnly={true}
              />
              <label className="gift-label">
                Show Shipping Addresses
              </label>
            </div>
            {(props.unshippedCounts && props.unshippedCounts.length > 0) && <div>
              <div>
                Unshipped Statistics:
                {props.unshippedCounts.map((arr, idx) => {
                  let word = "shipment";
                  if (arr[1] > 1) {
                    word += 's'
                  }

                  let futureElement = (<span></span>);

                  props.subscriptions.map((sub) => {
                    if (sub.id === arr[0]) {
                      let momentFutureDate = moment(sub.next_shipment_date);
                      let futureDate = momentFutureDate.format("MMM Do, YYYY");

                      if (moment().isAfter(momentFutureDate)) {
                        //momentFutureDate = momentFutureDate.add(5, 'days');
                        futureDate = momentFutureDate.format("MMM Do, YYYY");
                        futureElement = (<span className="future-shipping-date">Your shipment is being prepared! Preparation began on <span className="date">{futureDate}</span>. Check back in a few business days to see the exact shipping date for this subscription!</span>);
                      } else {
                        futureElement = (<span className="future-shipping-date">Next shipping date (est.): <span className="date">{futureDate}</span></span>)
                      }

                    }
                  });
                  return(<div key={idx}>
                    Subscription ID {arr[0]}, {arr[1]} future {word}. {futureElement}
                  </div>)
                })}
              </div>
            </div>}
          </div>
        </div>
      </section>
      <div className='container-fluid'>
        <div className='row flex-center'>
          {props.shipments.map((shipment, idx) => {
            if (shipment.earfleek_product || shipment.earfleek_premiere_product || shipment.product) {
              return(<EarfleekShipmentThumbnail shipment={shipment} {...props} key={idx} idx={idx}/>);
            } else {
              return(<div key={idx}></div>);
            }
          })}
        </div>
      </div>
    </section>
  )
};

const SurveyModal = (props) => {
  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '700px',
      minWidth: '260px',
      zIndex: 2,
    }
  };

  return(
    <Modal
      isOpen={props.surveyModalShown && !props.modalManuallyClosed}
      contentLabel="Survey Modal"
      ariaHideApp={false}
      style={customStyles}
      onRequestClose={props.closeModal}
    >
      <div className="survey-modal-container">
        <h2 className="survey-modal-header">
          Future Products Survey
        </h2>
        <div className="responses-header">If EarFleek were to offer a subscription to a new category of products, which of the following would you prefer the MOST?</div>
        <div className="survey-modal-body">
          <div className="responses-container">
            {props.surveyOptions.map((option, idx) => {
              return(
                <SurveyOption key={idx} option={option} {...props}/>
              )
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SurveyOption = (props) => {
  let cn = "survey-response col-lg-3 col-md-4 col-xs-6";

  if (props.currentChoice && props.currentChoice === props.option.name) {
    cn += ' selected';
  }

  return(
    <div
      className={cn}
      onClick={() => {props.surveyVote(props.option.name)}}
      key={props.option.id}
    >
      <div>
        <img
          src={`${props.option.cropped_image_path}`} alt=""
          className="survey-option-image"
        />
      </div>
      <div>
        {props.option.name}
      </div>
    </div>
  );
};

const EarfleekShipmentThumbnail = (props) => {
  let imgStyle;
  let crookedness;
  const shipment = props.shipment;

  const isPremiereWithPhotos = (shipment.earfleek_premiere_product && shipment.earfleek_premiere_product.catalog_photos && shipment.earfleek_premiere_product.catalog_photos.length > 0)

  if (!shipment.shipped_at) {
    return (<div></div>);
  }

  try {
    if (isPremiereWithPhotos) {
      imgStyle = {
        backgroundImage: `url(${props.shipment.earfleek_premiere_product.catalog_photos[0].image.medium.url})`,
      };
    } else {
      if (props.shipment.earfleek_product) {
        imgStyle = {
          backgroundImage: `url(${props.shipment.earfleek_product.catalog_photos[0].image.medium.url})`,
        };
      } else if (props.shipment.product) {
        imgStyle = {
          backgroundImage: `url(${props.shipment.product.catalog_photos[0].image.medium.url})`,
        };
      }
    }
  } catch(err) {
    crookedness = true;
    if (props.idx % 2 === 0) {
      imgStyle = {
        backgroundColor: '#fff',
      };
    } else {
      imgStyle = {
        backgroundColor: '#fff',
      };
    }
  }

  let addressTo;
  let addressStreet;
  let addressUnit;
  let addressCity;
  let addressState;
  let addressZip;

  if (props.addressesShown) {
    try { addressTo = shipment.address.to } catch(err) {}
    try { addressStreet = shipment.address.street } catch(err) {}
    try {
      if (shipment.address.unit && shipment.address.unit.length > 0) {
        addressUnit = `, ${shipment.address.unit}`
      }
    } catch(err) {}
    try { addressCity = shipment.address.city } catch(err) {}
    try { addressState = shipment.address.state } catch(err) {}
    try { addressZip = shipment.address.zip_code } catch(err) {}
  }

  if (addressUnit) {
    addressStreet = addressStreet + addressUnit;
  }

  let productTitle;

  if (shipment.earfleek_product) {
    productTitle = (
      <div className='shop-listing-item__name'>
        <Link to={`/products/${shipment.earfleek_product.slug}`}>
          {shipment.earfleek_product.title}
        </Link>
      </div>
    )
  } else if (isPremiereWithPhotos) {
    productTitle = (<div className='shop-listing-item__name'>
      <Link to={`/products/${shipment.earfleek_premiere_product.slug}`}>
        {shipment.earfleek_premiere_product.title}
      </Link>
    </div>)
  } else if (shipment.earfleek_premiere_product) {
    productTitle = (<div className='shop-listing-item__name'>
      {shipment.earfleek_premiere_product.title}
    </div>)
  } else if (shipment.product) {
    productTitle = (<div className='shop-listing-item__name'>
      <Link to={`/products/${shipment.product.slug}`}>
        {shipment.product.title}
      </Link>
    </div>)
  }

  let exceptionWord;

  if (shipment.damaged_at) {
    exceptionWord = 'Damaged';
    crookedness = true;
  } else if (shipment.dnr_at) {
    exceptionWord = 'Lost';
    crookedness = true;
  } else if (shipment.duplicate_received_at) {
    exceptionWord = 'General Issue';
    crookedness = true;
  }

  if (shipment.earfleek_premiere_product && !isPremiereWithPhotos) {
    if (exceptionWord) {
      exceptionWord = 'Premiere - ' + exceptionWord;
      crookedness = true;
    } else {
      exceptionWord = 'Premiere - No Image';
      crookedness = false;
    }
  }

  let humanizedAlgo;

  if (shipment.styling_algorithm === 'customer' || shipment.styling_algorithm === 'customer-in-style') {
    humanizedAlgo = 'Right Swipe, In Style Type';
  } else if (shipment.styling_algorithm === 'customer-out-of-style') {
    humanizedAlgo = 'Right Swipe, Out of Style Type';
  } else if (shipment.styling_algorithm === 'auto-stylist') {
    humanizedAlgo = 'Auto-Stylist, In Style Type';
  } else if (shipment.styling_algorithm === 'premiere-auto-stylist') {
    humanizedAlgo = 'Auto-Stylist';
  } else if (shipment.styling_algorithm === 'premiere-customer') {
    humanizedAlgo = 'Premiere - Self Style';
  } else if (shipment.styling_algorithm === 'upsell-purchase') {
    humanizedAlgo = 'Special Purchase';
  }

  let trackingNumber;

  try {
    if (shipment.label && shipment.label.easypost_json) {
      trackingNumber = JSON.parse(shipment.label.easypost_json).tracking_code
    }
  } catch(err) { }

  return(
    <div className='col-md-3 col-sm-4 col-xs-6 history-item'>
      <div className='col-xs-12 square-img-container'>
        {exceptionWord && <div className={`exception-word ${crookedness && 'crooked'}`}>
          {exceptionWord}
        </div>}
        <div style={imgStyle} className='shop-listing-item__image square-img-content'></div>
      </div>
      {productTitle}
      <div className='shop-listing-item__name'>Shipment ID: {shipment.id}</div>
      <div>
        Subscription ID: <Link to={`/customer/subscriptions/${shipment.subscription_id}`}>{shipment.subscription_id}</Link>
      </div>
      <div>
        Date Shipped: {moment(shipment.shipped_at).format("MMM Do, YYYY")}
      </div>
      {props.addressesShown && <div className="shipping-address">
        <div>
          Shipped to:
        </div>
        <div>
          {addressTo}
        </div>
        <div>
          {addressStreet}
        </div>
        <div>
          {addressCity}, {addressState} {addressZip}
        </div>
      </div>}
      {trackingNumber && trackingNumber.length === 22 && <div>
        Tracking Number: <a href={`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`} target="_blank">{trackingNumber}</a>
      </div>}
      {trackingNumber && trackingNumber.length === 30 && <div>
        Tracking Number: <a href={`https://www.dhl.com/us-en/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=${trackingNumber}`} target="_blank">{trackingNumber}</a>
      </div>}
      {humanizedAlgo && <div>
        Styling Method: {humanizedAlgo}
      </div>}
      {shipment.reshipment_for && <div>
        Reshipment for: {shipment.reshipment_for}
      </div>}
    </div>
  )
};

export default History;
