import { Link } from 'react-router-dom';

const Addresses = (props) => {
  if (!props.addresses) {
    return (<div></div>)
  }

  return(
    <section id='addresses-content'>
      <section id="cmp_df96442d-5a85-416d-9e47-2c7cdf41dac6" className="container">
        <div className="account-header row content">
          <div className="col-lg-4">

          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <h2 className="text-center">
              Your Addresses
            </h2>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center" id="new-address-button">
            <Link to="/customer/addresses/new" className="btn btn-primary">
              Add an Address
            </Link>
          </div>
        </div>
        <div className="my-account">
          <div className="container-fluid">
            <div className="form-container">
              <div className="row content">
                {props.addresses.map((address) => {
                  return(
                    <AddressBox
                      address={address}
                      key={address.id}
                      deleteAddress={props.deleteAddress}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
};

const AddressBox = (props) => {
  // <thead>
  // <tr>
  //   <td>
  //     property
  //   </td>
  //   <td>
  //     value
  //   </td>
  // </tr>
  // </thead>

  const attrs = ['to', 'company', 'street', 'unit', 'city', 'state', 'zip_code', 'country'];

  return(
    <div className="address-box col-lg-4 col-md-6 col-xs-12" key={props.address.id}>
      <div className="panel panel-cratejoy address-panel">
        <table>
          <tbody>
            {attrs.map((attr) => {
              return(
                <tr key={attr}>
                  <td>
                    {attr}:
                  </td>
                  <td>
                    {props.address[attr]}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Link
          to={`/customer/addresses/${props.address.id}`}
          className="btn btn-primary"
        >
          Edit
        </Link>
        <div className="row address-footer">
          <div className="col-xs-12">
            {props.address.earfleek_subscriptions.length === 0 &&
              <button
                className="btn delete-address"
                onClick={(e) => { props.deleteAddress(e, props.address.id) }}
              >
                Delete this address
              </button>
            }
            {props.address.earfleek_subscriptions.length > 0 && <div>
              Shipping address for:
              {props.address.earfleek_subscriptions.map((subscription, idx) => {
                return(
                  <div key={idx}>
                    <Link
                      to={`/customer/subscriptions/${subscription.id}`}
                    >
                      Subscription #{subscription.id}
                    </Link>
                  </div>
                );
              })}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addresses;
