const getSubscriptionProducts = () => {
  return {
    type: 'API:ONBOARDING:SUBSCRIPTION_PRODUCTS',
    API: true,
    url: `/api/v1/subscriptions/products`,
  };
};

const addEarfleekSubscriptionToCart = (earfleekSubscriptionProduct) => {
  return {
    type: 'EARFLEEK:SUBSCRIPTION:ADD_TO_CART',
    earfleekSubscriptionProduct: earfleekSubscriptionProduct,
    API: false,
  };
};

const setOnboardingStep = (step) => {
  return {
    type: 'EARFLEEK:ONBOARDING:SET_STEP',
    step: step,
    API: false,
  };
};

const setOnboardingType = (styleType) => {
  return {
    type: 'EARFLEEK:ONBOARDING:SET_TYPE',
    styleType: styleType,
    API: false,
  };
};

export default {
  getSubscriptionProducts,
  addEarfleekSubscriptionToCart,
  setOnboardingStep,
  setOnboardingType,
};
