const initialState = {
  subscriptionProducts: [],
  onboardingStep: 1,
  type: undefined,
  term: undefined,
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
    case 'API:ONBOARDING:SUBSCRIPTION_PRODUCTS:SUCCESS':
      return { ...state, subscriptionProducts: response };
    case 'EARFLEEK:ONBOARDING:SET_STEP':
      return { ...state, onboardingStep: action.step };
    case 'EARFLEEK:ONBOARDING:SET_TYPE':
      return { ...state, type: action.type };
    case 'EARFLEEK:ONBOARDING:SET_TERM':
      return { ...state, term: action.term };
    default:
      return state;
  }
}

export default reducer;
