import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Premiere from './premiere.jsx';

class PremiereContainer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.title = 'EarFleek - Premiere';
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Premiere
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(PremiereContainer));
