// this is overridden by preloaded state, beware
const initialState = {
  // message: {type: 'warn', text: 'asdf'},
  message: undefined,
  currentCustomer: undefined,
  referralInfo: JSON.parse(window.localStorage.getItem('EarfleekReferralInfo')) || {},
  // referralInfo: {
  //   firstName: "Shalane",
  //   lastName: "Hutchins",
  //   referredByCustomerID: 45001,
  // },
  showMobileMenu: true,
  postLoginRedirectUrl: undefined,
  becomeCustomer: {
    accessToken: undefined,
    client: undefined,
    uid: undefined,
  },
  shipmentHistory: [],
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
  case 'APP:SET_MESSAGE':
    return { ...state, message: action.message };
  case 'APP:CLEAR_MESSAGE':
    return { ...state, message: undefined };
  case 'APP:CLEAR_CURRENT_CUSTOMER':
    return { ...state, currentCustomer: undefined };
  case 'APP:MOBILE_MENU:HIDE':
    const el = document.getElementById('navbar-menu');
    if (el) {
      el.className = 'navbar-toggleable-sm collapse';
    }
    return { ...state, showMobileMenu: false };
  case 'APP:MOBILE_MENU:COLLAPSE':
    const navEl = document.getElementById('navbar-menu');
    if (navEl) {
      navEl.className = 'navbar-toggleable-sm collapse';
    }
    return { ...state };
  case 'APP:MOBILE_MENU:SHOW':
    return { ...state, showMobileMenu: true };
  case 'API:CUSTOMER:GET_SIGNED_IN_CUSTOMER:SUCCESS':
    return { ...state, currentCustomer: response };
  case 'API:CUSTOMER:UPDATE:SUCCESS':
    return { ...state, currentCustomer: response };
  case 'API:CUSTOMER:TOGGLE_SHIPMENT_NOTIFICATIONS:SUCCESS':
    return { ...state, currentCustomer: response };
  case 'API:CUSTOMER:SURVEY_VOTE:SUCCESS':
    return { ...state, currentCustomer: response };
  case 'API:CUSTOMER:GET_HISTORY:SUCCESS':
    return { ...state, shipmentHistory: response };
  case 'API:REFERRAL:CREATE:SUCCESS':
    return { ...state, currentCustomer: response };
  case 'API:CHECKOUT:CHECKOUT:SUCCESS':
    if (response.become_customer_hash && response.become_customer_hash.customer && response.become_customer_hash.client) {
      window.localStorage.setItem('access-token', response.become_customer_hash.access_token);
      window.localStorage.setItem('client', response.become_customer_hash.client);
      window.localStorage.setItem('uid', response.become_customer_hash.customer.uid);
    }
    return { ...state, becomeCustomer: response.become_customer_hash };
  default:
    return state;
  }
}

export default reducer;
