import * as Redux from 'redux';
import Reducers from '../reducers/consumer/reducers';
import apiMiddleware from './api_middleware';

const ConsumerStore = (function(Redux, Reducers) {
  const thunkMiddleware = (store) => next => action => {
    if (typeof action === 'function') {
      return action(store.dispatch, store.getState);
    }
    return next(action);
  };

  const preloadedState = Object.assign({
    reduxTokenAuth: {
      currentUser: {
        isLoading: false,
        isSignedIn: false,
        attributes: {
          first_name: null,
          last_name: null,
          email: null,
          id: null,
        },
      },
    },
  }, window.__PRELOADED_STATE__);

  // const preloadedState = window.__PRELOADED_STATE__;

  const middleware = Redux.applyMiddleware(thunkMiddleware, apiMiddleware),
        reducers = Redux.combineReducers(Reducers);

  return Redux.createStore(reducers, preloadedState, Redux.compose(
    middleware,
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  ));
})(Redux, Reducers);

export default ConsumerStore;
