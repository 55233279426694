import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../assets/stylesheets/components/consumer/history/history.scss';
import Actions from '../../../../app/actions/consumer/actions.js';

import History from './history.jsx';

class HistoryContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      addressesShown: false,
      modalManuallyClosed: false,
    };

    this.toggleAddressesShown = this.toggleAddressesShown.bind(this);
    this.surveyVote = this.surveyVote.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Shipment History';
    if (!this.props.customer) {
      const action = Actions.customer.getSignedInCustomer();
      this.props.dispatch(action);
    } else {
      const action2 = Actions.history.getHistory();
      this.props.dispatch(action2);
      //const action3 = Actions.survey.getSurveyOptions();
      //this.props.dispatch(action3);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.customer !== prevProps.customer) {
      const action2 = Actions.history.getHistory();
      this.props.dispatch(action2);
      //const action3 = Actions.survey.getSurveyOptions();
      //this.props.dispatch(action3);
    }
  }

  componentWillUnmount() {
  }

  toggleAddressesShown() {
    this.setState({
      addressesShown: !this.state.addressesShown
    })
  }

  closeModal() {
    this.setState({
      modalManuallyClosed: true
    })
  }

  surveyVote(optionName) {
    const action = Actions.survey.surveyVote({response: optionName});
    this.props.dispatch(action);
  }

  render() {
    return (
      <History
        {...this.props}
        toggleAddressesShown={this.toggleAddressesShown}
        addressesShown={this.state.addressesShown}
        modalManuallyClosed={this.state.modalManuallyClosed}
        surveyVote={this.surveyVote}
        closeModal={this.closeModal}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    shipments: state.history.shipments,
    unshippedCounts: state.history.unshippedCounts,
    subscriptions: state.history.subscriptions,
    surveyOptions: state.surveyOptions.surveyOptions,
    currentChoice: state.surveyOptions.currentChoice,
    surveyModalShown: (state.surveyOptions.currentChoice === '' && state.surveyOptions.surveyOptions.length > 0),
  };
}

export default connect(mapStateToProps)(withRouter(HistoryContainer));
