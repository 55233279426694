import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Subscribe from './subscribe.jsx';

class SubscribeContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      upgradeModalOpen: false,
      modalAdvertisedSubscription: undefined,
      modalAdvertisedSiblingSubscription: undefined,
    };

    this.addSubscriptionToCart = this.addSubscriptionToCart.bind(this);
    this.setTypeAndAdvance = this.setTypeAndAdvance.bind(this);
    this.downgrade = this.downgrade.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const action = Actions.onboarding.getSubscriptionProducts();
    this.props.dispatch(action);
    const action2 = Actions.app.hideMobileMenu();
    this.props.dispatch(action2);
    if (document.location.pathname.indexOf('three') !== -1) {
      document.title = 'EarFleek - Subscribe - 3 Months';
    } else {
      document.title = 'EarFleek - Subscribe';
    }
  }

  componentWillUnmount() {
    const action = Actions.app.showMobileMenu();
    this.props.dispatch(action);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  addSubscriptionToCart(subscriptionProduct) {
    const action = Actions.onboarding.addEarfleekSubscriptionToCart(subscriptionProduct);
    this.props.dispatch(action);
    this.props.history.push('/checkout');
  }

  setTypeAndAdvance(type, tier) {
    if (document.location.pathname.indexOf('three') !== -1) {
      // special case for three month prepays
      // add correct product to cart

      const threeMonthProduct = this.props.subscriptionProducts.filter(el => (el.tier === 'regular') && (el.primary_style_type === type) && (el.paid_months === 3))[0];

      this.addSubscriptionToCart(threeMonthProduct);
    } else {
      this.loadTermPage(type, tier);
    }
  }

  loadTermPage(inputType = undefined, inputTier = undefined) {

    let type = inputType;
    if (!type) {
      type = new URLSearchParams(document.location.search).get("type");
    }

    let tier = inputTier;
    if (!tier) {
      tier = new URLSearchParams(document.location.search).get("tier");
    }

    let obj = {};
    if (tier) {
      obj.tier = tier;
    }
    if (type) {
      obj.type = type;
    }

    let queryString = Object.keys(obj).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    }).join('&');

    this.props.history.push(`/subscribe?${queryString}`);
  }

  downgrade() {
    this.loadTermPage(undefined, 'regular');
  }

  closeModal() {
    this.setState({
      upgradeModalOpen: false,
    })
  }

  openModal(opts) {
    this.setState({
      upgradeModalOpen: true,
      modalAdvertisedSubscription: opts.modalAdvertisedSubscription,
      modalAdvertisedSiblingSubscription: opts.modalAdvertisedSiblingSubscription,
    })
  }

  render() {
    let type = new URLSearchParams(document.location.search).get("type");
    let tier = new URLSearchParams(document.location.search).get("tier") || 'regular';
    return (
      <Subscribe
        {...this.props}
        addSubscriptionToCart={this.addSubscriptionToCart}
        setTypeAndAdvance={this.setTypeAndAdvance}
        primaryStyleType={type}
        tier={tier}
        downgrade={this.downgrade}
        upgradeModalOpen={this.state.upgradeModalOpen}
        modalAdvertisedSubscription={this.state.modalAdvertisedSubscription}
        modalAdvertisedSiblingSubscription={this.state.modalAdvertisedSiblingSubscription}
        closeModal={this.closeModal}
        openModal={this.openModal}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    subscriptionProducts: state.onboarding.subscriptionProducts,
    onboardingStep: state.onboarding.onboardingStep,
  };
}

export default connect(mapStateToProps)(withRouter(SubscribeContainer));
