import address from './address_actions';
import earfleekOrders from './earfleek_orders_actions';
import app from './app_actions';
import auth from './auth_actions';
import checkout from './checkout_actions';
import customer from './customer_actions';
import onboarding from './onboarding_actions';
import history from './history_actions';
import order from './order_actions';
import tinderGame from './tinder_game_actions';
import paymentMethod from './payment_method_actions';
import survey from './survey_actions';
import policy from './policy_actions';
import products from './products_actions';
import refer from './refer_actions';
import shipment from './shipment_actions';
import subscription from './subscription_actions';
import vote from './vote_actions';

const Actions = {
  address,
  app,
  auth,
  checkout,
  customer,
  earfleekOrders,
  history,
  onboarding,
  order,
  tinderGame,
  paymentMethod,
  policy,
  products,
  refer,
  shipment,
  subscription,
  survey,
  vote,
};

export default Actions;
