import { Link } from 'react-router-dom';
import '../../../../../assets/stylesheets/components/consumer/earfleek_orders/earfleek_orders.scss';

const EarfleekOrder = (props) => {

  const earfleekOrder = props.iteratedEarfleekOrder || props.earfleekOrder;
  if (!earfleekOrder) {
    return(<div></div>)
  }

  if (!earfleekOrder.humanized_object) {
    return(<div>EARFLEEK ORDER</div>)
  }

  return(
    <section
      className="successful-earfleek-order col-lg-6 col-md-6 col-sm-12 col-xs-12"
      key={earfleekOrder.id}
    >
      <div className="checkout_content">
        <section className="cart_listing">
          <header>
            Your order was successfully placed.<br/>
            <small>( Order reference number #{earfleekOrder.id} )</small>
          </header>
          <table>
            <tbody>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th className="currency">Price</th>
              </tr>
              {earfleekOrder.humanized_object.humanized_subscriptions.map((hSub, idx) => {
                return(
                  <tr key={idx}>
                    <th>{hSub[0]}</th>
                    <th>1</th>
                    <th className="currency">{hSub[1]}</th>
                  </tr>
                )
              })}
              {earfleekOrder.humanized_object.humanized_products.map((hProduct, idx) => {
                return(
                  <tr key={idx}>
                    <th>{hProduct[0]}</th>
                    <th>1</th>
                    <th className="currency">{hProduct[1]}</th>
                  </tr>
                )
              })}
              {earfleekOrder.humanized_object.coupon && <tr id="coupons_row" className="detail-row">
                <td>
                  {earfleekOrder.humanized_object.coupon.coupon_string}
                </td>
                <td></td>
                <td>
                  {earfleekOrder.humanized_object.coupon.coupon_discount}
                </td>
              </tr>}
              <tr id="shipping_row" className="detail-row">
                <td><strong>Subtotal</strong></td>
                <td></td>
                <td className="currency" id="shipping_price">
                  {earfleekOrder.humanized_object.total_price}
                </td>
              </tr>
              <tr id="shipping_row" className="detail-row">
                <td><strong>Shipping</strong></td>
                <td></td>
                <td className="currency" id="shipping_price">
                  FREE
                </td>
              </tr>
              <tr className="tax-row detail-row">
                <td><strong>Sales Tax</strong></td>
                <td></td>
                <td className="currency">
                  {earfleekOrder.humanized_object.total_tax}
                </td>
              </tr>
              <tr id="shipping_row" className="detail-row">
                <td><strong>Total</strong></td>
                <td></td>
                <td className="currency" id="shipping_price">
                  {earfleekOrder.humanized_object.total}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <div className="cart_addresses">
          <div className="ship_to vertical">
            <section>
              <h3>
                Shipping to:
              </h3>
              <div className="vcard display ">
                <div className="adr">
                  <div className="name">{earfleekOrder.humanized_object.shipping_address.to}</div>
                  <div className="street-address">{earfleekOrder.humanized_object.shipping_address.street}</div>
                  <div className="extended-address">{earfleekOrder.humanized_object.shipping_address.unit}</div>
                  <span className="locality">{earfleekOrder.humanized_object.shipping_address.city} </span>
                  <span className="region">{earfleekOrder.humanized_object.shipping_address.state}, </span>
                  <span className="postal-code">{earfleekOrder.humanized_object.shipping_address.zip_code}</span>
                  <div className="country-name">{earfleekOrder.humanized_object.shipping_address.country}</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  )
};

export default EarfleekOrder;
