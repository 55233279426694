import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import HowItWorks from './how_it_works.jsx';

class HowItWorksContainer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    document.title = 'EarFleek - How It Works';
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <HowItWorks
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(HowItWorksContainer));
