import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Footer from './footer.jsx';

class FooterContainer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
  }

  render() {
    return <Footer {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(withRouter(FooterContainer));
