const initialState = {
  subscription: {
    subscription_product: {},
  },
  subscriptions: [],
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
    case 'API:SUBSCRIPTION:GET:SUCCESS':
      return { ...state, subscription: response };
    case 'API:SUBSCRIPTION:UPDATE:SUCCESS':
      return { ...state, subscription: response };
    case 'API:SUBSCRIPTION:CANCEL:SUCCESS':
      return { ...state, subscription: response };
    case 'API:SUBSCRIPTION:REACTIVATE:SUCCESS':
      return { ...state, subscription: response };
    case 'API:SUBSCRIPTION:SKIP:SUCCESS':
      return { ...state, subscription: response };
    case 'API:SUBSCRIPTIONS:GET:SUCCESS':
      return { ...state, subscriptions: response };
    default:
      return state;
  }
}

export default reducer;
