import { withRouter, Link } from 'react-router-dom';

const Login = (props) => {
  return (
    <div className="become-content">
      "Become" content
    </div>
  );
};

export default Login;
