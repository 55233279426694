import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/how_it_works/how_it_works.scss';

import part2BG from 'assets/images/consumer/how_it_works/part2BG.jpg'
import part3BG from 'assets/images/consumer/how_it_works/part3BG.jpg'
import part4BG from 'assets/images/consumer/how_it_works/part4BG.jpg'
import part5BG from 'assets/images/consumer/how_it_works/part5BG.jpg'

const HowItWorks = (props) => {
  const part1 = (
    <section id="cmp_b36e1ad0-1e52-4b17-8b51-b656a3551ca1" className="component-rich-text-center">
      <div className="container-fluid">
        <div className="content row">
          <div className="col-xs-12 text-center" id="blabla">
            <span><h2>How It Works</h2></span>
          </div>
        </div>
      </div>
    </section>
  );

  const part2Style = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url('${part2BG}')`,
  };

  const part2 = (
    <section id="cmp_ecfeb63c-be3f-4b7d-a403-30bc04ad4fed">
      <div className="row square-row ">
        <div className="col-md-6 col-xs-12 flex-center text-center hero-content">
          <div>
				    <span>
              <h3>
                1.
              </h3>
              <h1>
                SUBSCRIBE
              </h1>
              <h2>
                First go to <a href="/subscribe/">THIS</a> page and choose your style type (Don't worry you can change this later, and the SWIPE game lets you pick styles in any category) and checkout.
              </h2>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </span>
          </div>
        </div>
        <div
          className="col-md-6 col-xs-12"
          style={part2Style}>
        </div>
      </div>
    </section>
  );

  const part3Style = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url('${part3BG}')`,
  };

  const part3 = (
    <section id="cmp_0bd0756a-b589-4009-a0b8-2009d22ea5ba">
      <div className="row square-row row-reverse">
        <div className="col-md-6 col-xs-12 flex-center text-center hero-content">
          <div>
    				<span>
              <h3>2.</h3>
              <h1>Login</h1>
              <h2>Once you've subscribed you'll automatically be sent an email with a custom link to play the game. Please note: this can take 4 hours. Every time we get more inventory we'll send you an update!</h2>
            </span>
          </div>
        </div>
        <div className="col-md-6 col-xs-12" style={part3Style} />
      </div>
    </section>
  );

  const part4Style = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url('${part4BG}')`,
  };

  const part4 = (
    <section id="cmp_a4f6a58a-6dda-4812-8d20-f9ab8a8c5e0a">
      <div className="row square-row ">
        <div className="col-md-6 col-xs-12 flex-center text-center hero-content">
          <div>
				    <span>
              <h3>3.</h3>
              <h1>SWIPE</h1>
              <h2>Now the fun part! Swipe through our inventory. Swiping to the LEFT means "dislike" and swiping to the RIGHT means "like". We use this data to send you earrings we know you'll love!</h2>
            </span>
          </div>
        </div>
        <div className="col-md-6 col-xs-12" style={part4Style}></div>
      </div>
    </section>
  );

  const part5Style = {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url('${part5BG}')`,
  };

  //note the ids are the same
  const part5 = (
    <section id="cmp_0bd0756a-b589-4009-a0b8-2009d22ea5ba">
      <div className="row square-row row-reverse">
        <div className="col-md-6 col-xs-12 flex-center text-center hero-content">
          <div>
    				<span>
              <h3>4.</h3>
              <h1>Wear and Repeat</h1>
              <h2>Every month you'll receive a fun pair of earrings in the mail. (Yes, of course they're yours to keep). If you ever want to cancel, it's easy- you can do so from the login screen as well with no penalty.</h2>
            </span>
          </div>
        </div>
        <div className="col-md-6 col-xs-12" style={part5Style} />
      </div>
    </section>
  );


  return (
    <section id='how-it-works-content'>
      {part1}
      {part2}
      {part3}
      {part4}
      {part5}
    </section>
  )
};

export default HowItWorks;
