import CardValidator from 'card-validator';

function isPresent(value) {
  return value.length !== 0;
}

function isValidMonth(value) {
  return value > 0 && value < 13;
}

function isValidCardNumber(cardNumber) {
  return CardValidator.number(cardNumber).isValid;
}

function isValidCvv(cvv) {
  return CardValidator.cvv(cvv, 3).isValid || CardValidator.cvv(cvv, 4).isValid;
}

function isValidExpirationDate(expirationMonth, expirationYear) {
  return CardValidator.expirationDate({month: expirationMonth, year: expirationYear}).isValid;
}

function isValidEmail(email) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

function isValidZipCode(zip) {
  if (zip.length === 5) {
    return /^\d+$/.test(zip);
  } else if (zip.length === 10) {
    if (zip[5] === '-') {
      const firstPart = zip.split('-')[0];
      const secondPart = zip.split('-')[1];
      return /^\d+$/.test(firstPart) && /^\d+$/.test(secondPart)
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function isValidPassword(password) {
  return password.length > 7;
}

function isValidStateAbbreviation(stateAbbreviation) {
  if (stateAbbreviation === '') return false;
  // const regex = /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/
  const statesArr = ['AA', 'AE', 'AK', 'AL', 'AP', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'FM', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];
  return statesArr.indexOf(stateAbbreviation) > -1;
  // return regex.test(stateAbbreviation);
}

function isValidName(name) {
  const newString = name.replace(/[^a-z]/gi, '');
  return (newString.length >= 2);
}

function isValidStreetAddress(name) {
  const newString = name.replace(/[^a-z]/gi, '');
  return (newString.length >= 2);
}

function isValidCity(name) {
  const newString = name.replace(/[^a-z]/gi, '');
  return (newString.length >= 2);
}

export {
  isPresent,
  isValidCity,
  isValidEmail,
  isValidExpirationDate,
  isValidName,
  isValidPassword,
  isValidStateAbbreviation,
  isValidStreetAddress,
  isValidZipCode,
  isValidCardNumber,
  isValidCvv,
  isValidMonth,
};
