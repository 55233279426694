import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/subscriptions/cancel.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';

import BecomeQuarterly from './become_quarterly.jsx';

class BecomeQuarterlyContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      isBecomingQuarterly: false,
    };

    this.getSubscription = this.getSubscription.bind(this);
    this.becomeQuarterly = this.becomeQuarterly.bind(this);
  }

  componentDidMount() {
    document.title = 'EarFleek - Become Quarterly';
    this.getSubscription(this.props.match.params.id);
  }

  getSubscription(id) {
    const action = Actions.subscription.getSubscription({id: id});
    this.props.dispatch(action);
  }

  becomeQuarterly() {
    if (this.state.isBecomingQuarterly) return;

    this.setState({
      isBecomingQuarterly: true,
    }, () => {
      const data = {
        id: this.props.subscription.id,
      };

      const onSuccess = function(dispatch, data) {
        const callbackAction = Actions.app.setMessage({
          type: 'SUCCESS',
          text: 'You changed your subscription to quarterly!',
        }, 5000);
        this.props.dispatch(callbackAction);
        this.props.history.push('/customer/account');
      }.bind(this);

      const onError = function(dispatch, data) {
        let responseMsg;
        if (data && data.errors) {
          responseMsg = data.errors;
        } else {
          responseMsg = 'You failed to change your subscription to Quarterly!';
        }
        const callbackAction = Actions.app.setMessage({
          type: 'ERROR',
          text: responseMsg,
        }, 3000);
        this.setState({
          isBecomingQuarterly: false,
        });

        this.props.dispatch(callbackAction);
      }.bind(this);

      const warn = Actions.app.setMessage({ type: 'WARNING', text: 'Becoming Quarterly...'});
      this.props.dispatch(warn);

      const action = Actions.subscription.becomeQuarterly(
        data,
        onSuccess,
        onError
      );

      this.props.dispatch(action);
    });

  }

  render() {
    return(
      <BecomeQuarterly
        {...this.props}
        becomeQuarterly={this.becomeQuarterly}
        isBecomingQuarterly={this.state.isBecomingQuarterly}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    subscription: state.subscriptions.subscription,
  };
}

export default connect(mapStateToProps)(withRouter(BecomeQuarterlyContainer));
