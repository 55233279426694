import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../../../app/actions/consumer/actions.js';

import Products from './products.jsx';

class ProductsContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      topPaginationRef: undefined,
      bottomPaginationRef: undefined,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.setTopPaginationRef = this.setTopPaginationRef.bind(this);
    this.setBottomPaginationRef = this.setBottomPaginationRef.bind(this);
  }

  componentDidMount() {
    this.fetchEarfleekProducts();
    document.title = 'EarFleek Shop - All Products';
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.urlDiffersFromSearchParams()) {
      const qs = queryString.parse(this.props.location.search);
      this.fetchEarfleekProducts();
      this.forcePaginationToPage(parseInt(qs.page || 1) - 1);
    }
  }

  urlDiffersFromSearchParams() {

    const qs = queryString.parse(this.props.location.search);
    if (typeof qs.style_type !== 'undefined' && (this.props.style_type !== qs.style_type)) {
      return true;
    } else if (typeof qs.product_type !== 'undefined' && (this.props.product_type !== qs.product_type)) {
      return true;
    } else if (typeof qs.page !== 'undefined' && (parseInt(this.props.page) !== parseInt(qs.page))) {
      return true;
    } else if (typeof qs.per_page !== 'undefined' && (parseInt(this.props.per_page) !== parseInt(qs.per_page))) {
      return true;
    }
    return false;
  }

  fetchEarfleekProducts() {

    const qs = queryString.parse(this.props.location.search);

    let opts = {
      style_type: qs.style_type || 'all',
      product_type: qs.product_type|| 'earrings-regular',
      page: qs.page || 1,
      per_page: qs.per_page || 20,
    };

    const action = Actions.products.fetchEarfleekProducts(opts);
    this.props.dispatch(action);
  }

  handlePageClick(e) {
    const actualPage = e.selected + 1;

    if (actualPage === this.props.page) {
    } else {
      this.props.history.push(`/products?product_type=${this.props.product_type}&style_type=${this.props.style_type}&page=${actualPage}&per_page=${this.props.per_page}`);
      this.forcePaginationToPage(actualPage)
    }
  }

  forcePaginationToPage(idx) {

    if (this.state.topPaginationRef) {
      this.state.topPaginationRef.setState({ selected: idx });
    }

    if (this.state.bottomPaginationRef) {
      this.state.bottomPaginationRef.setState({ selected: idx });
    }
  }

  setTopPaginationRef(instance) {
    if (this.state.topPaginationRef) {
      return;
    }

    this.setState({
      topPaginationRef: instance,
    })
  }

  setBottomPaginationRef(instance) {
    if (this.state.bottomPaginationRef) {
      return;
    }

    this.setState({
      bottomPaginationRef: instance,
    })
  }

  render() {
    return (
      <Products
        {...this.props}
        handlePageClick={this.handlePageClick}
        topPaginationRef={this.state.topPaginationRef}
        setTopPaginationRef={this.setTopPaginationRef}
        bottomPaginationRef={this.state.bottomPaginationRef}
        setBottomPaginationRef={this.setBottomPaginationRef}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    products: state.products.earfleekProducts.products,
    page: state.products.earfleekProducts.page,
    per_page: state.products.earfleekProducts.per_page,
    pages: state.products.earfleekProducts.pages,
    style_type: state.products.earfleekProducts.style_type,
    product_type: state.products.earfleekProducts.product_type,
    currentUser: state.reduxTokenAuth.currentUser,
  };
}

export default connect(mapStateToProps)(withRouter(ProductsContainer));
