const getHistory = () => {
  return {
    type: 'API:HISTORY:GET',
    API: true,
    url: `/api/v1/history`,
  };
};

export default {
  getHistory,
};
