import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../../../../assets/stylesheets/components/consumer/shipments/shipments.scss';
import Actions from '../../../../../app/actions/consumer/actions.js';

import Shipment from './shipment.jsx';
import ConfirmedShipment from './confirmed_shipment.jsx';

class ShipmentContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      explainerOpen: false,
    };

    this.toggleExplainer = this.toggleExplainer.bind(this);
  }

  componentDidMount() {
    if (!this.props.customer) {
      const action = Actions.customer.getSignedInCustomer();
      this.props.dispatch(action);
    }
    if (this.props.product.vendor_number) {
      try {
        document.getElementById(this.props.product.vendor_number).scrollIntoView();
      } catch(err) {
        //this will error out when a hard refresh of the research page has occurred and the back button is pressed
      }
    }
    document.title = 'EarFleek - Premiere Self-Style';
    const action = Actions.customer.getSignedInCustomer();
    this.props.dispatch(action);
    this.getShipment(this.props.match.params.id);
    this.getAvailableEarfleekProducts();
  }

  getAvailableEarfleekProducts() {
    const action = Actions.products.getAvailablePremiereProducts();
    this.props.dispatch(action);
  }

  getShipment(id) {
    const action = Actions.shipment.getShipment({id: id});
    this.props.dispatch(action);
  }

  toggleExplainer() {
    this.setState({ explainerOpen: !this.state.explainerOpen});
  }

  render() {
    if (!this.props.customer) {
      return(<div></div>);
    } else if (this.props.shipment.earfleek_premiere_product_id) {
      return (
        <ConfirmedShipment
          {...this.props}
        />
      );
    } else {
      return(
        <Shipment
          {...this.props}
          explainerOpen={this.state.explainerOpen}
          toggleExplainer={this.toggleExplainer}
        />
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    customer: state.app.currentCustomer,
    reduxTokenAuth: state.reduxTokenAuth,
    shipment: state.shipments.shipment,
    earfleekProducts: state.products.earfleekProducts,
    product: state.products.product,
  };
}

export default connect(mapStateToProps)(withRouter(ShipmentContainer));
