import { Link } from 'react-router-dom';
import '../../../../assets/stylesheets/components/consumer/unauthenticated/reviews/reviews.scss';

import MSA from 'assets/images/consumer/reviews/MSA.png'
import helloSubscription from 'assets/images/consumer/reviews/hello_subscription.png'
import instagram from 'assets/images/consumer/reviews/instagram.png'
import youTube from 'assets/images/consumer/reviews/youtube.png'
import bow from 'assets/images/consumer/reviews/bow.png'

const Reviews = (props) => {

  const bowStyle = {
    backgroundImage: `url(${bow})`,
  };

  let lastBowStyle;

  if (window.innerWidth < 575) {
    lastBowStyle = {
      backgroundImage: `url(${bow})`,
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      paddingBottom: 60,
      paddingTop: 50,
      verticalAlign: 'middle',
    };
  }

  return (
    <section id="cmp_04ce2a8a-1645-45b9-aca9-e02f46884af8" className="component-rich-text-with-button">
      <div className="inner_page_banner text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner_banner_head">
                <h2>REVIEWS</h2>
                <p>Here's What Others Are Saying About EarFleek!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner_page_content_section review_logo">
        <div className="container">
          <div className="row text-center">
            <a
              href="https://www.mysubscriptionaddiction.com/best-subscription-boxes/best-jewelry-subscription-boxes"
              style={{ margin: '0 auto'}}
            >
              <img
                src="https://www.mysubscriptionaddiction.com/wp-content/uploads/2021/04/08/RC_MSA_BADGE-COLOR-V1-300x300.png"
                alt="My Subscription Addiction Reader's Choice Awards Winner"
              />
            </a>
          </div>
          <div className="row text-center">
            <div className="col-md-4 col-sm-4">
              <div className="logo_box" style={bowStyle}>
                <a href="https://www.mysubscriptionaddiction.com/category/earfleek-reviews" target="_blank">
                  <img src={MSA} alt="MSA"/>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">

            </div>
            <div className="col-md-4 col-sm-4">
              <div className="logo_box" style={bowStyle}>
                <a href="https://www.hellosubscription.com/?s=earfleek" target="_blank">
                  <img src={helloSubscription} alt="hello subscription logo"/>
                </a>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4 col-sm-4">
            </div>

            <div className="col-md-4 col-sm-4">
            </div>

            <div className="col-md-4 col-sm-4">
            </div>
          </div>

          <div className="row text-center">
            <div className="col-md-4 col-sm-4">
              <div className="social_logo_box" style={lastBowStyle}>
                <a href="https://www.instagram.com/explore/tags/earfleek/" target="_blank">
                  <img src={instagram} alt="Instagram logo"/>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="social_logo_box">
                <a href="https://www.youtube.com/results?lclk=rm%3Atoday&amp;search_query=earfleek" target="_blank">
                  <img src={youTube} alt="YouTube logo"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Reviews;
