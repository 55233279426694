const initialState = {
  policy: ''
};

function reducer(state = initialState, action) {
  const { data, response } = action;
  switch(action.type) {
    case 'API:POLICY:GET:SUCCESS':
      return { ...state, policy: response.policy};
    default:
      return state;
  }
}

export default reducer;
